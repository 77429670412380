import React, { FunctionComponent, ReactNode } from 'react';

import ActionButton from '../../../../components/buttons/ActionButton';
import t from '../../../../text-strings';
import MobileTopNav from '../../../../components/MobileTopNav';
import Wrap from '../../../../components/containers/Wrap';

interface Props {
  current: boolean;
  onNext: () => void;
  onBack: () => void;
  children: ReactNode;
  finish?: boolean;
}

const FormStepScreen: FunctionComponent<Props> = (
  {
    current,
    onNext,
    onBack,
    children,
    finish = false,
  }: Props,
) => (
  <Wrap size="wrap-authenticated" className={current ? 'block pb-32' : 'hidden'}>
    <MobileTopNav onClick={onBack} title={t('authorized.title')} />
    {children}
    {!finish && (
      <ActionButton
        label={t('register.siguiente')}
        onClick={onNext}
        fixed
      />
    )}
  </Wrap>
);

export default FormStepScreen;
