import React, { FunctionComponent } from 'react';

import { AppColors } from '../../styles';

interface Props {
  color?: AppColors;
  width?: number;
  height?: number;
  className?: string;
}

const Money: FunctionComponent<Props> = (
  {
    color = 'black',
    width = 12,
    height = 12,
    className = '',
  }: Props,
) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className={`w-${width} h-${height} text-${color} fill-current ${className}`}>
    <path d="M20 4H4C2.89 4 2.01 4.89 2.01 6L2 18C2 19.11 2.89 20 4 20H20C21.11 20 22 19.11 22 18V6C22 4.89 21.11 4 20 4ZM20 17C20 17.55 19.55 18 19 18H5C4.45 18 4 17.55 4 17V7C4 6.45 4.45 6 5 6H19C19.55 6 20 6.45 20 7V17ZM14 10C14.55 10 15 9.55 15 9C15 8.45 14.55 8 14 8H13V7.99C13 7.44 12.55 6.99 12 6.99C11.45 6.99 11 7.44 11 7.99V8H10C9.45 8 9 8.45 9 9V12C9 12.55 9.45 13 10 13H13V14H10C9.45 14 9 14.45 9 15C9 15.55 9.45 16 10 16H11C11 16.55 11.45 17 12 17C12.55 17 13 16.55 13 16H14C14.55 16 15 15.55 15 15V12C15 11.45 14.55 11 14 11H11V10H14Z" />
  </svg>
);

export default Money;
