import { useHistory } from 'react-router-dom';

const useNavigation = (): ((url: string) => void) => {
  const history = useHistory();

  const navigate = (url: string): void => {
    history.push(url);
  };

  return navigate;
};

export default useNavigation;
