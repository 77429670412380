import {
  action, Action, thunk, Thunk,
} from 'easy-peasy';
import _ from 'lodash/fp';

import {
  handleAxiosResponse,
  handleChangeResponse,
  handleRequestResponse,
} from '../../store-helpers';

import {
  CauParams,
  LoadRequestAttributes,
  LoadRequestParams,
  LoadRequestResponseAttributes,
  RequestAttributes,
  RequestHistoryParams,
  RequestStep,
  RollbackRequestParams,
  SelfconsumptionRequestParams,
  SupplyRequestParams,
  GenerationRequestParams,
  CupsRequestParams,
  ChangeRequestParams,
  RollbackChangeStateRequestParams,
  OpenRequests,
  Request,
} from './types';
import { defaultLoadRequest, defaultRequest } from './requests';

export interface RequestModel {
  request: RequestAttributes;
  loadrequest: LoadRequestAttributes;
  requestStep: RequestStep;
  requests: Request[];

  initloadrequest: Action<RequestModel, LoadRequestResponseAttributes>;
  initRequest: Action<RequestModel, RequestAttributes>;
  initRequests: Action<RequestModel, Request[]>;

  getrequesthistory: Thunk<RequestModel, RequestHistoryParams>;
  getloadrequest: Thunk<RequestModel, LoadRequestParams>;
  getopenrequests: Thunk<RequestModel, OpenRequests>;
  getallrequests: Thunk<RequestModel, OpenRequests>;
  getrequestfilter: Thunk<RequestModel, OpenRequests>;

  createsupplypointrequest: Thunk<RequestModel, SupplyRequestParams>;
  creategenerationrequest: Thunk<RequestModel, GenerationRequestParams>;
  createcupsrequest: Thunk<RequestModel, CupsRequestParams>;
  createcaurequest: Thunk<RequestModel, CauParams>;
  createselfconsumption: Thunk<RequestModel, SelfconsumptionRequestParams>;
  changestaterequest: Thunk<RequestModel, ChangeRequestParams>;

  rollbackcreaterequest: Thunk<RequestModel, RollbackRequestParams>;
  rollbackchangestaterequest: Thunk<
    RequestModel,
    RollbackChangeStateRequestParams
  >;

  setRequestStep: Action<RequestModel, RequestStep>;
}

const requestModel: RequestModel = {
  request: defaultRequest,
  loadrequest: defaultLoadRequest,
  requestStep: 'cliente',
  requests: [],

  initloadrequest: action((state, params) => {
    // @ts-ignore
    state.loadrequest = _.groupBy('section')(params.selectors);
  }),

  initRequest: action((state, params) => {
    state.request = params;
  }),

  initRequests: action((state, params) => {
    state.requests = params;
  }),

  getrequesthistory: thunk(async (actions, params, { injections }) => {
    const { requestService } = injections;
    return handleAxiosResponse(
      await requestService.getrequesthistory(params),
      actions.initRequest,
    );
  }),

  getloadrequest: thunk(async (actions, params, { injections }) => {
    const { requestService } = injections;
    return handleAxiosResponse(
      await requestService.getloadrequest(params),
      actions.initloadrequest,
    );
  }),

  getopenrequests: thunk(async (actions, params, { injections }) => {
    const { requestService } = injections;
    return handleAxiosResponse(
      await requestService.getopenrequests(params),
      actions.initRequests,
    );
  }),

  getallrequests: thunk(async (actions, params, { injections }) => {
    const { requestService } = injections;
    return handleAxiosResponse(
      await requestService.getallrequests(params),
      actions.initRequests,
    );
  }),

  getrequestfilter: thunk(async (actions, params, { injections }) => {
    const { requestService } = injections;
    return handleAxiosResponse(
      await requestService.getrequestfilter(params),
      actions.initRequests,
    );
  }),

  createsupplypointrequest: thunk(async (_actions, params, { injections }) => {
    const { requestService } = injections;
    return handleRequestResponse(
      await requestService.createsupplypointrequest(params),
    );
  }),

  creategenerationrequest: thunk(async (_actions, params, { injections }) => {
    const { requestService } = injections;
    return handleRequestResponse(
      await requestService.creategenerationrequest(params),
    );
  }),

  createcupsrequest: thunk(async (_actions, params, { injections }) => {
    const { requestService } = injections;
    return handleRequestResponse(
      await requestService.createcupsrequest(params),
    );
  }),

  createcaurequest: thunk(async (_actions, params, { injections }) => {
    const { requestService } = injections;
    return handleRequestResponse(await requestService.createcaurequest(params));
  }),

  createselfconsumption: thunk(async (_actions, params, { injections }) => {
    const { requestService } = injections;
    return handleRequestResponse(
      await requestService.createselfconsumption(params),
    );
  }),

  changestaterequest: thunk(async (_actions, params, { injections }) => {
    const { requestService } = injections;
    return handleChangeResponse(
      await requestService.changestaterequest(params),
    );
  }),

  rollbackcreaterequest: thunk(async (_actions, params, { injections }) => {
    const { requestService } = injections;
    return handleAxiosResponse(
      await requestService.rollbackcreaterequest(params),
    );
  }),

  rollbackchangestaterequest: thunk(
    async (_actions, params, { injections }) => {
      const { requestService } = injections;
      return handleAxiosResponse(
        await requestService.rollbackchangestaterequest(params),
      );
    },
  ),

  setRequestStep: action((state, params) => {
    state.requestStep = params;
  }),
};

export default requestModel;
