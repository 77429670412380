import {
  action, Action, thunk, Thunk,
} from 'easy-peasy';

import constants from '../../../services/constants';
import { handleAxiosResponse } from '../../store-helpers';

import { Municipality } from './types';

export interface DistributorsModel {
  municipalities: Municipality[];

  getMunicipalities: Thunk<DistributorsModel>;
  getlegalinfo: Thunk<DistributorsModel, string>;

  initMunicipalities: Action<DistributorsModel, Municipality[]>;
}

const distributorModel: DistributorsModel = {
  municipalities: [],

  getMunicipalities: thunk(async (actions, _params, { injections }) => {
    const { distributorService } = injections;
    return handleAxiosResponse(
      await distributorService.municipalities(),
      actions.initMunicipalities,
    );
  }),

  getlegalinfo: thunk(async (_actions, params, { injections }) => {
    const { distributorService } = injections;
    return distributorService.getlegalinfo({
      distributorId: constants.DISTRIBUTOR_ID,
      infoType: params,
    });
  }),

  initMunicipalities: action((state, params) => {
    state.municipalities = [...params];
  }),
};

export default distributorModel;
