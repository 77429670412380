import { PDFDocument, StandardFonts, rgb } from 'pdf-lib';
import qrcode from 'qrcode-generator';

interface PDF {
  // eslint-disable-next-line
  unifyDocuments: (files: any[]) => Promise<ArrayBuffer>;
  stampDocument: (
    file: ArrayBuffer,
    icon: string,
    code: string,
    url: string,
    form: string,
    distributor: string,
    distributorLong: string,
    footer: boolean,
  ) => Promise<ArrayBufferLike>;
}
// eslint-disable-next-line
const unifyDocuments = async (files: any[]): Promise<ArrayBuffer> => {
  const mergedPdf = await PDFDocument.create();
  for (let i = 0; i < files.length; i += 1) {
    // eslint-disable-next-line
    const pdfDoc = await PDFDocument.load(files[i]);
    // eslint-disable-next-line
    const copiedPagesA = await mergedPdf.copyPages(pdfDoc, pdfDoc.getPageIndices());
    copiedPagesA.forEach((page) => mergedPdf.addPage(page));
  }

  return (await mergedPdf.save({ updateFieldAppearances: false })).buffer;
};
// eslint-disable-next-line
const getImageFromBase64 = (base64: any, reColor = false): Promise<string> => (
  new Promise((resolve) => {
    const img = new Image();
    img.src = base64;
    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const context = canvas.getContext('2d');
      if (reColor) {
        // @ts-ignore
        context.globalCompositeOperation = 'source-over';
        // @ts-ignore
        context.drawImage(img, 0, 0);
        // @ts-ignore
        context.fillStyle = '#000';
        // @ts-ignore
        context.fillRect(0, 0, canvas.width, canvas.height);
        // @ts-ignore
        context.globalCompositeOperation = 'destination-in';
      }
      // @ts-ignore
      context.drawImage(img, 0, 0);
      resolve(canvas.toDataURL('image/png'));
    };
  })
);

const stampDocument = async (
  file: ArrayBuffer,
  icon: string,
  code: string,
  url: string,
  form: string,
  distributor: string,
  distributorLong: string,
  footer = false,
): Promise<ArrayBufferLike> => {
  const pdfDoc = await PDFDocument.load(file);
  const base64image = await getImageFromBase64(icon);
  const jpgImage = await pdfDoc.embedPng(base64image);
  let paginaActual = 0;

  let qrImage;
  if (url) {
    const qr = qrcode(0, 'M');
    qr.addData(url);
    qr.make();
    const div = document.createElement('div');
    div.innerHTML = qr.createImgTag();
    // @ts-ignore
    const qrimage = await getImageFromBase64(div.firstChild.attributes[0].value);
    qrImage = await pdfDoc.embedPng(qrimage);
  }
  qrImage = null;

  const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
  const helveticaBoldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);

  const startX = 0;
  const startY = 0;
  const { height, width } = pdfDoc.getPage(0).getSize();
  const stampSizeX = width - (startX * 2);
  const stampSizeY = 70;

  const distribucionElectrica = 'Distribución Eléctrica';
  const textWidthShortName = helveticaBoldFont.widthOfTextAtSize(distributor, 10);
  const textWidthElectricDistribution = helveticaFont.widthOfTextAtSize(distribucionElectrica, 10);

  const now = new Date();
  const fecha = `${now.getDate().toString().padStart(2, '0')}/${(now.getMonth() + 1).toString().padStart(2, '0')}/${now.getFullYear().toString().padStart(2, '0')}`;
  const hora = `${now.getHours().toString().padStart(2, '0')}:${now.getMinutes().toString().padStart(2, '0')}:${now.getSeconds().toString().padStart(2, '0')}`;
  const paginas = pdfDoc.getPageCount();

  if (footer) {
    pdfDoc.getPages().forEach(
      (page) => {
        paginaActual += 1;

        page.drawRectangle({
          x: startX + 20,
          y: 25,
          width: width - 40,
          height: 60,
          color: rgb(1, 1, 1),
          opacity: 0.2,
          borderOpacity: 1,
          borderColor: rgb(0, 0, 0),
          borderWidth: 2,
        });

        page.drawRectangle({
          x: startX + 20,
          y: 25,
          width: width - 40,
          height: 20,
          color: rgb(1, 1, 1),
          opacity: 0.2,
          borderOpacity: 1,
          borderColor: rgb(0, 0, 0),
          borderWidth: 2,
        });

        page.drawRectangle({
          x: startX + 20,
          y: 25,
          width: width - 40,
          height: 20,
          color: rgb(1, 1, 1),
          opacity: 0.2,
          borderOpacity: 1,
          borderColor: rgb(0, 0, 0),
          borderWidth: 2,
        });
        page.drawRectangle({
          x: startX + 20 + ((width - 40) / 2) + (((width - 40) / 2) - (((width - 40) / 2) / 3)),
          y: 25,
          width: (((width - 40) / 2) / 3),
          height: 20,
          color: rgb(1, 1, 1),
          opacity: 0.2,
          borderOpacity: 1,
          borderColor: rgb(0, 0, 0),
          borderWidth: 2,
        });

        page.drawRectangle({
          x: startX + 20,
          y: 45,
          width: width - 40,
          height: 20,
          color: rgb(1, 1, 1),
          opacity: 0.2,
          borderOpacity: 1,
          borderColor: rgb(0, 0, 0),
          borderWidth: 2,
        });

        page.drawRectangle({
          x: startX + 20,
          y: 25,
          width: (width - 40) / 2,
          height: 60,
          color: rgb(1, 1, 1),
          opacity: 0.2,
          borderOpacity: 1,
          borderColor: rgb(0, 0, 0),
          borderWidth: 2,
        });
        page.drawText(
          `${distributorLong}`,
          {
            x: startX + 25,
            y: 71,
            size: 11,
            color: rgb(0, 0, 0),
            font: helveticaBoldFont,
          },
        );
        page.drawText(
          `https://${url}`,
          {
            x: startX + 25 + ((width - 40) / 2),
            y: 71,
            size: 11,
            color: rgb(0, 0, 0),
            font: helveticaFont,
          },
        );

        page.drawText(
          `${form}`,
          {
            x: startX + 25,
            y: 51,
            size: 11,
            color: rgb(0, 0, 0),
            font: helveticaFont,
          },
        );

        const codigoLength = helveticaBoldFont.widthOfTextAtSize('Código: ', 11);
        page.drawText(
          'Código: ',
          {
            x: startX + 25 + ((width - 40) / 2),
            y: 51,
            size: 11,
            color: rgb(0, 0, 0),
            font: helveticaBoldFont,
          },
        );
        page.drawText(
          code,
          {
            x: startX + 25 + ((width - 40) / 2) + codigoLength,
            y: 51,
            size: 11,
            color: rgb(0, 0, 0),
            font: helveticaFont,
          },
        );

        const fechaLength = helveticaBoldFont.widthOfTextAtSize('Fecha: ', 11);
        const horaLength = helveticaBoldFont.widthOfTextAtSize('Hora: ', 11);
        page.drawText(
          'Fecha: ',
          {
            x: startX + 25,
            y: 31,
            size: 11,
            color: rgb(0, 0, 0),
            font: helveticaBoldFont,
          },
        );
        page.drawText(
          fecha,
          {
            x: startX + 25 + fechaLength,
            y: 31,
            size: 11,
            color: rgb(0, 0, 0),
            font: helveticaFont,
          },
        );

        page.drawText(
          'Hora: ',
          {
            x: startX + 25 + ((width - 40) / 2),
            y: 31,
            size: 11,
            color: rgb(0, 0, 0),
            font: helveticaBoldFont,
          },
        );
        page.drawText(
          hora,
          {
            x: startX + 25 + ((width - 40) / 2) + horaLength,
            y: 31,
            size: 11,
            color: rgb(0, 0, 0),
            font: helveticaFont,
          },
        );

        page.drawText(
          `Pág. ${paginaActual}/${paginas}`,
          {
            x: startX + 25 + ((width - 40) / 2) + (((width - 40) / 2) - (((width - 40) / 2) / 3)),
            y: 31,
            size: 11,
            color: rgb(0, 0, 0),
            font: helveticaFont,
          },
        );
      },
    );
  }

  pdfDoc.getPage(0).drawRectangle({
    x: startX,
    y: height - startY - stampSizeY,
    width,
    height: stampSizeY,
    color: rgb(0.063, 0.055, 0.118),
  });

  pdfDoc.getPage(0).drawImage(jpgImage, {
    x: startX,
    y: height - startY - stampSizeY,
    width: (stampSizeX / 4),
    height: stampSizeY,
  });

  pdfDoc.getPage(0).drawText(
    `${distributor}`,
    {
      x: width - 25 - textWidthShortName,
      y: height - (startY / 3) - (stampSizeY / 3) - 5,
      size: 10,
      color: rgb(1, 1, 1),
      font: helveticaBoldFont,
    },
  );

  pdfDoc.getPage(0).drawText(
    'Distribución Eléctrica',
    {
      x: width - 25 - textWidthElectricDistribution,
      y: height - (startY / 3) - (stampSizeY / 3) - 15 - 5,
      size: 10,
      color: rgb(1, 1, 1),
      font: helveticaFont,
    },
  );

  if (qrImage) {
    pdfDoc.getPage(0).drawLine({
      start: { x: (startX * 3) + (stampSizeX - 100), y: height - startY },
      end: { x: (startX * 3) + (stampSizeX - 100), y: height - startY - stampSizeY },
      thickness: 1,
      color: rgb(0, 0, 0),
    });

    pdfDoc.getPage(0).drawImage(qrImage, {
      x: (startX * 2) + (stampSizeX - 84),
      y: height - startY - stampSizeY + 1,
      width: 68,
      height: 68,
    });
  }
  return (await pdfDoc.save({ updateFieldAppearances: false })).buffer;
};

const pdfLib: PDF = {
  unifyDocuments,
  stampDocument,
};

export default pdfLib;
