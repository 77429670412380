import React, { FunctionComponent } from 'react';

import Icons from '../assets/icons';
import useNavigation from '../services/navigation';
import { routes } from '../services/routing';
import t from '../text-strings';

import ActionButton from './buttons/ActionButton';

interface WarningAdviceProps {
    text: string,
    description: string,
}

const WarningAdvice: FunctionComponent<WarningAdviceProps> = (
  { text, description } : WarningAdviceProps,
) => {
  const navigate = useNavigation();

  return (
    <div className="w-full border border-orange-light rounded-sm bg-white flex flex-col items-center text-center mb-20 lg:flex-row lg:text-left">
      <div className=""><Icons.Warning height={24} width={24} className="mt-12 lg:m-16" /></div>
      <div className="mt-12 mx-24 lg:my-16 lg:ml-0 lg:flex lg:flex-col lg:flex-grow">
        <div className="font-semibold lg:text-md">{text}</div>
        <div className="text-sm mt-4 lg:text-base">{description}</div>
      </div>
      <ActionButton
        label={t('consumption.card.label')}
        onClick={(): void => navigate(routes.tipsToSave)}
        className="my-12 lg:mr-16"
      />
    </div>
  );
};

export default WarningAdvice;
