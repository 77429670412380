import _ from 'lodash/fp';
import React, { ChangeEvent, FocusEvent, FunctionComponent } from 'react';
import classnames from 'classnames';

import classNames from '../../services/classNames';
import { Municipality } from '../../store/model/distributors/types';

import FormGroup from './components/FormGroup';
import InputError from './components/InputError';
import InputLabel from './components/InputLabel';

interface Props {
  name: string;
  value: number;
  selectables: Municipality[];
  placeholder: string;
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
  onBlur?: (e: FocusEvent<HTMLSelectElement>) => void;
  touched?: boolean;
  errors?: string;
  required?: boolean;
  containerClassName?: string;
  labelClassName?: string;
  selectClassName?: string;
}

const SelectLocality: FunctionComponent<Props> = (
  {
    name,
    value,
    selectables,
    placeholder,
    onChange,
    onBlur = undefined,
    touched = false,
    errors = '',
    required = false,
    containerClassName = '',
    labelClassName = '',
    selectClassName = '',
  }: Props,
) => {
  const labelClass = classnames(classNames('block', labelClassName), {
    'opacity-100': _.gt(value, 0),
    'opacity-0': !_.gt(value, 0),
  });

  const selectClass = classnames('block w-full pb-2 mb-4 bg-transparent border-b border-black focus:outline-none active:outline-none bodycopy', {
    'text-gray-400': !_.gt(value, 0),
    'text-black': _.gt(value, 0),
  });

  return (
    <FormGroup className={classNames('', containerClassName)}>
      <InputLabel
        name={name}
        label={placeholder}
        className={classNames(labelClass, labelClassName)}
        required={required}
      />
      <select
        name={name}
        id={name}
        value={value}
        onChange={onChange}
        className={classNames(selectClass, selectClassName)}
        onBlur={onBlur}
      >
        <option value="0">{`${placeholder}${required ? '*' : ''}`}</option>
        {_.map((item: Municipality) => (
          <option key={item.municipalityId} value={item.municipalityId}>{item.name}</option>
        ))(selectables)}
      </select>
      <InputError message={touched ? errors : ''} />
    </FormGroup>
  );
};

export default SelectLocality;
