import { useEffect, useState } from 'react';

import { useStoreActions } from '../store/hooks';
import { FAQAttributes, FAQTypes } from '../store/model/contact/types';

const useFAQs = (type: FAQTypes): [boolean, FAQAttributes[]] => {
  const { getquestionsbytype } = useStoreActions((actions) => actions.contact);
  const [isLoading, setIsLoading] = useState(true);
  const [faqs, setFaqs] = useState<FAQAttributes[]>([]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const response: string | FAQAttributes[] = await getquestionsbytype({ type });
      if (typeof response !== 'string') setFaqs(response);
      setIsLoading(false);
    })();
  }, [type]);

  return [isLoading, faqs];
};

export default useFAQs;
