import React, { FunctionComponent, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash/fp';
import { useFormik } from 'formik';

import t from '../../../../text-strings';
import Icons from '../../../../assets/icons';
import Caption from '../../../../components/text-components/Caption';
import Title from '../../../../components/text-components/Title';
import { routes } from '../../../../services/routing';
import { useStoreState } from '../../../../store/hooks';
import {
  Request,
  RequestHistoryParams,
} from '../../../../store/model/requests/types';
import Tag from '../../../../components/Tag';
import date from '../../../../services/date';
import InternalLink from '../../../../components/InternalLink';
import AuthenticatedLayout from '../../../../layout/AuthenticatedLayout';
import Form from '../../../../components/forms/Form';
import { getrequesthistory } from '../../../../api/services/requests';
import useNavigation from '../../../../services/navigation';
import constants from '../../../../services/constants';
import useQuery from '../../../../services/query';
import FormResponse from '../../../../components/forms/FormResponse';
import Modal from '../../../../components/Modal';
import Bodycopy from '../../../../components/text-components/Bodycopy';
import TextInput from '../../../../components/forms/TextInput';

interface Props {
  request: Request;
}

const RequestCard: FunctionComponent<Props> = ({ request }: Props) => (
  <Link
    to={routes.requests.history(request.code)}
    className="relative block w-full p-8 mb-16 bg-white rounded-sm shadow-blur lg:flex"
  >
    <div className="flex flex-col justify-between w-full">
      <div className="pb-8 mb-6 border-b border-gray-300 lg:border-0 lg:pt-4">
        <div className="flex items-center justify-between w-full">
          <Title size="small" className="mb-4 pr-80 lg:pr-0 lg:w-9/10">
            {request.name}
          </Title>
          <Icons.AngleRight color="blue" className="flex" />
        </div>
        <Caption className="text-sm font-semibold truncate" color="gray-500">
          {t('requests.code_solicitud')}
          :
          {request.code}
        </Caption>
        <Caption
          className="text-sm font-semibold truncate lg:mt-4"
          color="gray-500"
        >
          {request.address}
        </Caption>
      </div>
      <div className="lg:mr-2">
        <div className="hidden h-1 bg-gray-300 lg:mb-8 lg:flex" />
        <div className="flex lg:mt-1 h-max lg:w-auto">
          <Icons.Calendar className="mr-6" />
          <div className="">
            <p className="btn">{request.daysLimit}</p>
            <Caption color="gray-500">
              {date.format(request.limitDate, 'DD MMMM')}
            </Caption>
          </div>
        </div>
      </div>
      <Tag
        label={request.stateName}
        type="suspendida"
        className="absolute z-40 -top-6 right-8"
      />
    </div>
  </Link>
);

const RequestList: FunctionComponent = () => {
  const { requests } = useStoreState((state) => state.requests);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [shownRequests, setShownRequests] = useState(requests);
  const [allRequests, setAllRequests] = useState(true);
  const [activeRequests, setActiveRequests] = useState(false);
  const [endedRequests, setEndedRequests] = useState(false);
  const navigate = useNavigation();
  const query = useQuery();
  const error = query.get('error') || '';

  useEffect(() => {
    (async () => {
      setLoading(false);
    })();
  }, []);

  const handleAllRequests = (): void => {
    setShownRequests(requests);

    setAllRequests(true);
    setActiveRequests(false);
    setEndedRequests(false);
  };

  const handleActiveRequests = (): void => {
    setShownRequests(requests.filter((r) => r.active));

    setAllRequests(false);
    setActiveRequests(true);
    setEndedRequests(false);
  };

  const handleEndedRequests = (): void => {
    setShownRequests(requests.filter((r) => !r.active));

    setAllRequests(false);
    setActiveRequests(false);
    setEndedRequests(true);
  };

  const handleOnSumbit = async (
    values: RequestHistoryParams,
  ): Promise<void> => {
    const result = await getrequesthistory(values);
    if (typeof result === 'string') {
      setMessage(result);
      return;
    }
    navigate(routes.requests.history(values.codeRequest));
  };

  const toggleModal = (): void => setModal(!modal);

  const { values, handleChange, handleSubmit } = useFormik({
    initialValues: {
      codeRequest: '',
      distributorId: constants.DISTRIBUTOR_ID,
    },
    onSubmit: handleOnSumbit,
  });

  if (loading) return null;

  return (
    <AuthenticatedLayout
      title={t('common.navigation.mis_solicitudes')}
      headerMobile={false}
    >
      <div className="pt-12 bg-white lg:bg-transparent">
        <div className="w-11/12 mx-auto max-w-screen-content lg:mr-0 lg:ml-auto">
          <div className="flex w-full lg:hidden">
            <InternalLink
              to={routes.requests.index}
              label=""
              icon={<Icons.Back />}
              className="z-30 mb-8"
            />
            <div className="flex justify-center w-full text-base font-semibold -ml-18">
              {t('common.navigation.mis_solicitudes')}
            </div>
          </div>
          <InternalLink
            to={routes.requests.index}
            label="Atrás"
            icon={<Icons.Back />}
            className="hidden mb-24 lg:flex"
          />
          <Title tag="h3" className="hidden mb-12 lg:block">
            {t('common.navigation.mis_solicitudes')}
          </Title>
          <div className="lg:flex lg:flex-row lg:justify-between">
            <Form
              onSubmit={handleSubmit}
              className="mb-2 lg:max-w-2/3 lg:w-3/4"
            >
              <div className="flex items-start w-full py-2 mb-10 lg:mb-0 lg:flex-col">
                <div className="flex flex-row w-full">
                  <TextInput
                    name="codeRequest"
                    value={values.codeRequest}
                    placeholder={t('requests.code_placeholder')}
                    onChange={handleChange('codeRequest')}
                    containerClassName="lg:mb-12"
                    maxW
                  />
                  <button type="submit">
                    <Icons.Search color="blue" className="mb-4 ml-2 lg:mb-14" />
                  </button>
                </div>
              </div>
            </Form>
          </div>
          {(!_.isEmpty(message) || !_.isEmpty(error)) && (
            <div className="-mt-16 mb-22">
              <FormResponse message={_.isEmpty(message) ? error : message} />
            </div>
          )}
          <Modal isOpen={modal} toggleOpen={toggleModal}>
            <Title size="small" className="mb-12">
              {t('requests.n_solicitud')}
            </Title>
            <Bodycopy>{t('requests.modal')}</Bodycopy>
          </Modal>
          <div className="flex">
            <div
              onClick={() => handleAllRequests()}
              className="block w-auto pt-8 mr-12 cursor-pointer lg:pt-0"
              title="Todas"
            >
              <span
                className={`${
                  allRequests
                    ? 'font-semibold text-black'
                    : 'font-normal text-gray-500'
                } block mb-6 text-sm whitespace-nowrap lg:mb-4`}
              >
                Todas
              </span>
              <span
                className={`${
                  allRequests ? 'block w-full h-2 bg-gradient' : ''
                }`}
              />
            </div>
            <div
              onClick={() => handleActiveRequests()}
              className="block w-auto pt-8 mr-12 cursor-pointer lg:pt-0"
              title="Activas"
            >
              <span
                className={`${
                  activeRequests
                    ? 'font-semibold text-black'
                    : 'font-normal text-gray-500'
                } block mb-6 text-sm whitespace-nowrap lg:mb-4`}
              >
                Activas
              </span>
              <span
                className={`${
                  activeRequests ? 'block w-full h-2 bg-gradient' : ''
                }`}
              />
            </div>
            <div
              onClick={() => handleEndedRequests()}
              className="block w-auto pt-8 cursor-pointer lg:pt-0"
              title="Finalizadas"
            >
              <span
                className={`${
                  endedRequests
                    ? 'font-semibold text-black'
                    : 'font-normal text-gray-500'
                } block mb-6 text-sm whitespace-nowrap lg:mb-4`}
              >
                Finalizadas
              </span>
              <span
                className={`${
                  endedRequests ? 'block w-full h-2 bg-gradient' : ''
                }`}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="w-11/12 mx-auto max-w-screen-content lg:mr-0 lg:ml-auto">
        {shownRequests.length > 0 && (
          <div
            id="horizontal-request-list"
            className="pt-16 lg:grid lg:grid-cols-3 gap-x-10 lg:mt-6"
          >
            {_.map((request: Request) => (
              <RequestCard key={request.code} request={request} />
            ))(shownRequests)}
          </div>
        )}
        {shownRequests.length === 0 && (
          <div className="text-sm font-normal text-center text-gray-500 lg:text-base mt-28 lg:mt-24">
            No tienes ninguna solicitud finalizada
          </div>
        )}
      </div>
    </AuthenticatedLayout>
  );
};

export default RequestList;
