import React, { FunctionComponent } from 'react';

import helpers from '../../services/helpers';
import { Any } from '../../store/model/global';

interface BarLegendPrevYearProps {
    powerConsumed: Any;
    powerProvided: Any;
}

const BarLegendPrevYear: FunctionComponent<BarLegendPrevYearProps> = (
  { powerConsumed, powerProvided }: BarLegendPrevYearProps,
) => (
  <div className="mt-8 flex items-center">
    <div className="flex">
      <div className="bg-yellow min-h-6 min-w-6 h-6 w-6 rounded mr-4 flex mt-2" />
      <div className="flex flex-col">
        <div className="text-sm">Energía consumida</div>
        <div className="text-sm text-gray-400">
          {`${helpers.roundStatNumber(powerConsumed)} kW`}
        </div>
      </div>
    </div>
    <div className="flex ml-18">
      <div className="bg-black min-h-6 min-w-6 h-6 w-6 rounded mr-4 flex mt-2" />
      <div className="flex flex-col">
        <div className="text-sm">Energía vertida</div>
        <div className="text-sm text-gray-400">{`${helpers.roundStatNumber(powerProvided)} kW`}</div>
      </div>
    </div>
  </div>
);

export default BarLegendPrevYear;
