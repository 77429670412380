import React, { FunctionComponent } from 'react';

import { AppColors } from '../../styles';

interface Props {
  color?: AppColors;
  width?: number;
  height?: number;
  className?: string;
}

const Bolt: FunctionComponent<Props> = ({
  color = 'black',
  width = 12,
  height = 12,
  className = '',
}: Props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`w-${width} h-${height} text-${color} stroke-current ${className}`}
  >
    <path
      d="M16.7836 15.5836C16.6808 15.7616 16.5328 15.9094 16.3546 16.0118C16.1764 16.1142 15.9742 16.1677 15.7686 16.1669H15.7666H4.2267C4.02355 16.1655 3.82429 16.111 3.64865 16.0089C3.47291 15.9067 3.32693 15.7604 3.22515 15.5844C2.3559 14.063 2.02188 12.294 2.27652 10.5603C2.53122 8.82617 3.36012 7.22759 4.63065 6.02022C5.90118 4.81286 7.53992 4.06648 9.28475 3.90046C10.7683 3.75931 12.2557 4.04467 13.5748 4.71488L12.9421 5.1356C11.6307 4.54536 10.1696 4.36241 8.7491 4.61436C7.23221 4.88342 5.8424 5.63412 4.78571 6.75516C3.72901 7.87621 3.06168 9.30792 2.88265 10.838C2.70362 12.3682 3.02243 13.9153 3.7918 15.25L3.93608 15.5003H4.22498H15.775H16.0639L16.2082 15.2499C16.8002 14.2227 17.128 13.0646 17.1624 11.8795C17.194 10.7924 16.9777 9.71366 16.5315 8.72461L16.9618 8.07736C17.5013 9.12367 17.7996 10.2813 17.8302 11.4648C17.8676 12.908 17.5054 14.3333 16.7836 15.5836ZM16.7836 15.5836L17.2122 15.831L16.7836 15.5836Z"
      fill={color}
      stroke={color}
    />
    <path
      d="M14.0972 7.57035L10.7971 12.5205C10.6958 12.6143 10.5786 12.6895 10.4509 12.7425C10.3093 12.8012 10.1574 12.8314 10.0041 12.8314C9.8508 12.8314 9.69899 12.8012 9.55736 12.7425C9.41573 12.6837 9.28706 12.5977 9.1787 12.4892L8.82501 12.8425L9.17796 12.4885C9.06939 12.3802 8.98325 12.2517 8.92448 12.1101C8.8657 11.9685 8.83545 11.8167 8.83545 11.6634C8.83545 11.5101 8.8657 11.3583 8.92448 11.2167C8.97752 11.089 9.05286 10.9718 9.14685 10.8706L14.0972 7.57035Z"
      fill={color}
      stroke={color}
    />
  </svg>
);

export default Bolt;
