import React, { ChangeEvent, FunctionComponent, useState } from 'react';
import { useFormik } from 'formik';
import _ from 'lodash/fp';

import Icons from '../../../assets/icons';
import Bodycopy from '../../../components/text-components/Bodycopy';
import Title from '../../../components/text-components/Title';
import PublicLayout from '../../../layout/PublicLayout';
import MobileTopNav from '../../../components/MobileTopNav';
import InternalLink from '../../../components/InternalLink';
import { routes } from '../../../services/routing';
import useNavigation from '../../../services/navigation';
import t from '../../../text-strings';
import TextInput from '../../../components/forms/TextInput';
import formInit from '../../../forms/initial-values';
import { useStoreActions, useStoreState } from '../../../store/hooks';
import { ExistCupsParams } from '../../../store/model/contract/types';
import schemas from '../../../forms/schemas';
import SubmitButton from '../../../components/buttons/SubmitButton';
import Form from '../../../components/forms/Form';
import StreetSelect from '../../../components/forms/StreetSelect';

const CupsGuesser: FunctionComponent = () => {
  const { getexistcups } = useStoreActions((actions) => actions.contract);
  const { streets } = useStoreState((state) => state.contract);
  const [apiMessages, setApiMessages] = useState<string[]>([]);
  const [done, setDone] = useState(false);
  const navigate = useNavigation();

  const handleOnSubmit = async (values: ExistCupsParams): Promise<void> => {
    const response = await getexistcups(values);
    setDone(true);
    setApiMessages(response);
  };

  const {
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    touched,
    errors,
  } = useFormik({
    initialValues: formInit.existCups,
    onSubmit: handleOnSubmit,
    validationSchema: schemas.existCups,
  });

  const handleStreet = (e: ChangeEvent<HTMLSelectElement>): void => {
    const { value } = e.target;
    setFieldValue('streetTypeId', parseInt(value, 10));
  };

  return (
    <PublicLayout onlyDesktop title="CUPS">
      <MobileTopNav
        title={t('supply.cups_back_button')}
        onClick={() => navigate(routes.supply.guide)}
      />
      <div className="hidden lg:block wrap pt-20 mb-10">
        <InternalLink
          label={t('supply.cups_back_button')}
          to={routes.supply.guide}
          icon={<Icons.Back />}
        />
      </div>
      <div className="wrap lg:flex pb-40 lg:pb-20">
        <div className="lg:max-w-160 w-full mb-12 lg:mb-0">
          <Title tag="h1" className="mb-4">{t('supply.cups_title')}</Title>
          <Bodycopy>{t('supply.cups_subtitle')}</Bodycopy>
        </div>
        <div className="lg:flex-1 lg:ml-12">
          <Form onSubmit={handleSubmit}>
            <StreetSelect
              name="streetTypeId"
              value={values.streetTypeId}
              selectables={streets}
              placeholder={t('supply.streetTypeId')}
              onChange={handleStreet}
              onBlur={handleBlur('streetTypeId')}
              touched={touched.streetTypeId}
              errors={errors.streetTypeId}
              required
            />
            <TextInput
              name="cp"
              value={values.cp}
              placeholder={t('supply.cups_postal_code')}
              onChange={handleChange('cp')}
              onBlur={handleBlur('cp')}
              touched={touched.cp}
              errors={errors.cp}
              required
            />
            <TextInput
              name="address"
              value={values.street}
              placeholder={t('supply.street')}
              onChange={handleChange('street')}
              onBlur={handleBlur('street')}
              touched={touched.street}
              errors={errors.street}
              required
            />
            <TextInput
              name="number"
              value={values.number}
              placeholder={t('supply.number')}
              onChange={handleChange('number')}
              onBlur={handleBlur('number')}
              touched={touched.number}
              errors={errors.number}
              required
            />
            {_.isEmpty(apiMessages) && done && (
              <div className="max-w-240 w-full bg-red-light p-4 rounded-xs mb-6" onClick={() => setDone(false)}>
                <Bodycopy className="text-red">{t('supply.sin_coincidencias')}</Bodycopy>
              </div>
            )}
            {_.map((address: string) => (
              <Bodycopy key={address} className="mb-4">{address}</Bodycopy>
            ))(apiMessages)}
            <SubmitButton fixed label={t('supply.cups_button')} />
          </Form>
        </div>
      </div>
    </PublicLayout>
  );
};

export default CupsGuesser;
