import React, { FunctionComponent } from 'react';

import { AppColors } from '../../styles';

interface Props {
  color?: AppColors;
  width?: number;
  height?: number;
  className?: string;
}

const Help: FunctionComponent<Props> = (
  {
    color = 'black',
    width = 12,
    height = 12,
    className = '',
  }: Props,
) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className={`w-${width} h-${height} text-${color} stroke-current ${className}`}>
    <path d="M12 22.0001C17.5229 22.0001 22 17.5229 22 12.0001C22 6.47721 17.5229 2.00006 12 2.00006C6.47718 2.00006 2.00003 6.47721 2.00003 12.0001C2.00003 17.5229 6.47718 22.0001 12 22.0001Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill="transparent" />
    <path d="M9.09006 9.00014C9.32516 8.33181 9.78921 7.76825 10.4 7.40928C11.0108 7.0503 11.729 6.91908 12.4272 7.03885C13.1255 7.15863 13.7589 7.52167 14.2151 8.06367C14.6714 8.60567 14.9211 9.29166 14.9201 10.0001C14.9201 12.0001 11.9201 13.0001 11.9201 13.0001" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill="transparent" />
    <path d="M12 15.9999V16.4999" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill="transparent" />
  </svg>
);

export default Help;
