import _ from 'lodash/fp';

import date from '../../../services/date';
import { LoadRequestItemAttributes } from '../requests/types';

import { SessionAttributes } from './types';

export interface Session {
  get: <T>(value: string) => T;
  firstNameLetter: string;
  firstName: string;
  id: number;
  mobile: string;
  provinceId: (provinces: LoadRequestItemAttributes[]) => number;
}

const defaultSession: SessionAttributes = {
  id: 0,
  name: '',
  nif: '',
  address: '',
  locality: '',
  province: '',
  provinceId: 0,
  pc: '',
  movilPrefix: '',
  movil: '',
  email: '',
  distributorId: 0,
  distributorIdentity: '',
  userTypeId: 0,
  super: false,
  validated: false,
  permissions: [],
  notifyNonPaymentCuts: false,
  notifyByEmail: false,
  notifyFaults: false,
  notifyScheduledCuts: false,
  token: '',
};

const session = (sessionAttributes = defaultSession): Session => {
  const get = <T>(value: string): T => _.get(value)(sessionAttributes);
  // @ts-ignore
  const firstNameLetter: string = _.first(get('name'));
  // @ts-ignore
  const firstName: string = _.flow(
    _.split(' '),
    _.first,
  )(get('name'));

  const id: number = get('id');

  const mobile: string = _.flow(
    _.get('movil'),
    _.trim,
    _.split(''),
    _.reverse,
    _.take(9),
    _.reverse,
    _.join(''),
  )(sessionAttributes);

  const provinceId = (provinces: LoadRequestItemAttributes[]): number => {
    const provinceID = _.flow(
      _.find(['value', sessionAttributes.province]),
      _.get('selectorId'),
    )(provinces);
    if (_.isUndefined(provinceID)) return 78;

    return provinceID;
  };

  return {
    get,
    firstNameLetter,
    firstName,
    id,
    mobile,
    provinceId,
  };
};

export const expiracyDate = (long: boolean): Date => (
  long ? date.addToCurrent(2, 'years') : date.addToCurrent(7, 'days')
);

export default session;
