import React, { FunctionComponent } from 'react';

import images from '../../../../assets/images';
import Image from '../../../../components/Image';
import ListItem from '../../../../components/ListItem';
import Caption from '../../../../components/text-components/Caption';
import Title from '../../../../components/text-components/Title';
import t from '../../../../text-strings';

const KeyConcepts: FunctionComponent = () => (
  <div>
    <Title className="mb-12">{t('self.conceptos_clave.title')}</Title>
    <div className="lg:flex lg:flex-wrap lg:justify-between">
      <div className="lg:w-1/2 lg:mb-16 pr-1">
        <ul>
          <ListItem
            position={1}
            description={t('self.conceptos_clave.list.1')}
          />
        </ul>
        <Image
          src={images.autoconsumo.keyConcepts1}
          className="my-8 max-w-168 w-full lg:mb-0 lg:max-w-192"
        />
      </div>
      <div className="lg:w-1/2 lg:mb-16 pl-1">
        <ul>
          <ListItem
            position={2}
            description={t('self.conceptos_clave.list.2')}
          />
        </ul>
        <Image src={images.autoconsumo.keyConcepts2} className="w-200 mb-8" />
        <div className="flex justify-between w-full mb-8 max-w-168">
          <div className="flex items-baseline">
            <div className="w-6 h-6 bg-black rounded-full mr-2" />
            <Caption>{t('self.conceptos_clave.general')}</Caption>
          </div>
          <div className="flex items-baseline">
            <div className="w-6 h-6 bg-gradient rounded-full mr-2" />
            <Caption>{t('self.conceptos_clave.autoconsumo')}</Caption>
          </div>
        </div>
        <Caption color="gray-500">{t('self.conceptos_clave.grafico')}</Caption>
      </div>
    </div>
  </div>
);

export default KeyConcepts;
