import React, { FunctionComponent } from 'react';

import { AppColors } from '../../styles';

interface Props {
  color?: AppColors;
  width?: number;
  height?: number;
  className?: string;
}

const Facebook: FunctionComponent<Props> = (
  {
    color = 'black',
    width = 12,
    height = 12,
    className = '',
  }: Props,
) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className={`w-${width} h-${height} text-${color} fill-current ${className}`}>
    <path d="M11.9999 0.0498047C5.37256 0.0498047 -0.00012207 5.42249 -0.00012207 12.0499C-0.00012207 17.9939 4.32614 22.9165 9.99858 23.8697V14.5534H7.10381V11.2009H9.99858V8.72881C9.99858 5.86053 11.7504 4.29748 14.3095 4.29748C15.5352 4.29748 16.5885 4.38881 16.8942 4.42903V7.42715L15.1193 7.42801C13.7279 7.42801 13.4596 8.08907 13.4596 9.05948V11.1991H16.7797L16.3467 14.5517H13.4596V23.9499C19.3969 23.2273 23.9999 18.1792 23.9999 12.0464C23.9999 5.42249 18.6272 0.0498047 11.9999 0.0498047Z" />
  </svg>
);

export default Facebook;
