import React, { FunctionComponent, useState } from 'react';
import classnames from 'classnames';
import _ from 'lodash/fp';

import MobileMenu from './components/MobileMenu';
import DesktopMenu from './components/DesktopMenu';
import BrandLogo from './components/BrandLogo';
import MenuBars from './components/MenuBars';
import ProfileIcon from './components/ProfileIcon';

interface Props {
  background: 'transparent' | 'black';
  onlydesktop?: boolean;
  thin?: boolean;
  withTitle?: boolean;
  title?: string;
}

const Header: FunctionComponent<Props> = ({
  background,
  onlydesktop = false,
  thin = false,
  withTitle = false,
  title = '',
}: Props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const absolute = _.isEqual(background, 'transparent');

  const headerClass = classnames('site-header', {
    'bg-black': isMenuOpen || !absolute,
    'bg-transparent': !isMenuOpen && absolute,
    'top-0 left-0 w-full z-50': absolute || isMenuOpen,
    absolute: absolute && !isMenuOpen,
    fixed: isMenuOpen,
    'hidden lg:flex': onlydesktop,
    flex: !onlydesktop,
  });

  const innerHeaderClass = classnames('inner-site-header', {
    wrap: !thin,
    'wrap-thin': thin,
  });

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  return (
    <header id="site-header" className={headerClass}>
      <div className={innerHeaderClass}>
        <MenuBars open={isMenuOpen} toggleOpen={toggleMenu} />
        <BrandLogo withTitle={withTitle} title={title} />
        <ProfileIcon />
        <DesktopMenu />
      </div>
      <MobileMenu open={isMenuOpen} thin={thin} />
    </header>
  );
};

export default Header;
