import React, { FunctionComponent } from 'react';
import _ from 'lodash/fp';

import Title from '../../components/text-components/Title';
import AuthenticatedLayout from '../../layout/AuthenticatedLayout';
import { useStoreActions, useStoreState } from '../../store/hooks';
import t from '../../text-strings';
import ActionButton from '../../components/buttons/ActionButton';
import ContractCard from '../../components/cards/ContractCard';
import LinkButton from '../../components/buttons/LinkButton';
import { routes } from '../../services/routing';
import Icons from '../../assets/icons';
import InfoField from '../../components/text-components/InfoField';
import { Contract } from '../../store/model/contract/contract';
import Wrap from '../../components/containers/Wrap';
import Bodycopy from '../../components/text-components/Bodycopy';
import BreakdownsAlerts from '../../components/alerts/BreakdownsAlerts';

const Profile: FunctionComponent = () => {
  const { session } = useStoreState((state) => state.session);
  const { contracts } = useStoreState((state) => state.contract);
  const { logout } = useStoreActions((actions) => actions.session);

  return (
    <AuthenticatedLayout title={t('common.navigation.profile')}>
      <div className="flex-1 flex flex-col justify-between">
        <div>
          {
          _.map((contract: Contract) => (
            <div className="mt-6 sticky" key={contract.title}>
              <BreakdownsAlerts alias={contract.get('alias')} />
            </div>
          ))(contracts)
          }
          <div className="pt-16 pb-8 lg:pt-24 lg:pb-32 bg-white lg:bg-transparent">
            <Wrap size="wrap-authenticated">
              <Title tag="h2" className="mb-12">
                {`¡${t('common.bienvenido')} ${session.firstName}!`}
              </Title>
              <div className="lg:flex lg:mb-20">
                <InfoField
                  label={t('models.user.attributes.name')}
                  value={session.get('name')}
                  className="mb-8 lg:mb-0 lg:w-1/2"
                  avoidButtonLeftMargin={false}
                />
                <InfoField
                  label={t('models.user.attributes.nif')}
                  value={session.get('nif')}
                  className="mb-8 lg:mb-0 lg:w-1/2"
                  avoidButtonLeftMargin={false}
                />
              </div>
              <div className="lg:flex">
                <InfoField
                  label={t('models.user.attributes.email')}
                  value={session.get('email')}
                  className="mb-8 lg:mb-0 lg:w-1/2"
                  avoidButtonLeftMargin={false}
                />
                <InfoField
                  label={t('models.user.attributes.movil')}
                  value={session.get('movil')}
                  className="w-1/2"
                  avoidButtonLeftMargin={false}
                />
              </div>
              <div className="flex justify-end">
                <LinkButton
                  to={routes.editProfile}
                  label={t('profile.editar')}
                />
              </div>
            </Wrap>
          </div>
          <div className="pt-12 lg:pt-0 wrap-authenticated">
            <Title className="mb-12">{t('profile.tus_contratos')}</Title>
          </div>
          {_.isEmpty(contracts) ? (
            <div className="pb-4 lg:pb-12 wrap-authenticated">
              <Bodycopy>{t('profile.no_contratos')}</Bodycopy>
            </div>
          ) : (
            <div className="pb-4 lg:pb-12 w-full overflow-visible">
              <div className="flex wrap-authenticated overflow-x-scroll overflow-y-visible py-8">
                {_.map((contract: Contract) => (
                  <ContractCard
                    key={contract.get('id5')}
                    contract={contract}
                  />
                ))(contracts)}
              </div>
            </div>
          )}
          <Wrap size="wrap-authenticated" className="mb-16">
            <LinkButton
              label={t('profile.add_autorizado')}
              to={routes.authorized.create}
              icon={<Icons.Replace />}
            />
          </Wrap>
        </div>
        <div className="bg-white py-16 relative">
          <div className="absolute top-0 left-full w-screen-full h-full bg-white" />
          <Wrap size="wrap-authenticated">
            <ActionButton
              label={t('common.navigation.cerrar_sesion')}
              onClick={() => logout()}
              type="link"
            />
          </Wrap>
        </div>
      </div>
    </AuthenticatedLayout>
  );
};

export default Profile;
