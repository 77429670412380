import React, { FunctionComponent } from 'react';

import Modal from '../../../../components/Modal';
import Bodycopy from '../../../../components/text-components/Bodycopy';
import Caption from '../../../../components/text-components/Caption';
import Title from '../../../../components/text-components/Title';
import t from '../../../../text-strings';

interface Props {
  isOpen: boolean;
  close: () => void;
}

const CallUs: FunctionComponent<Props> = ({ isOpen, close }: Props) => (
  <Modal isOpen={isOpen} toggleOpen={close}>
    <Title className="mb-16">{t('supply.call_us.title')}</Title>
    <Bodycopy className="mb-16">{t('supply.call_us.description')}</Bodycopy>
    <a href={`tel:${t('supply.call_us.telefono')}`} className="btn block text-blue lg:hidden mb-8">
      {t('supply.call_us.telefono')}
    </a>
    <p className="btn text-blue mb-8 hidden lg:block">{t('supply.call_us.telefono')}</p>
    <Caption>{t('supply.call_us.atencion')}</Caption>
  </Modal>
);

export default CallUs;
