import React, { FunctionComponent, ReactNode } from 'react';

import classNames from '../../../services/classNames';

interface Props {
  children: ReactNode;
  className?: string;
  maxW?: boolean;
}

const FormGroup: FunctionComponent<Props> = ({
  children,
  className = '',
  maxW = false,
}: Props) => (
  <div className={classNames(`w-full ${maxW ? '' : 'max-w-240'}`, className)}>
    {children}
  </div>
);

export default FormGroup;
