import React, { FunctionComponent } from 'react';
import { InlineWidget } from 'react-calendly';

import Icons from '../../assets/icons';
import Wrap from '../../components/containers/Wrap';
import InternalLink from '../../components/InternalLink';
import Bodycopy from '../../components/text-components/Bodycopy';
import Title from '../../components/text-components/Title';
import PublicLayout from '../../layout/PublicLayout';
import { routes } from '../../services/routing';
import t from '../../text-strings';

const Calendly: FunctionComponent = () => (
  <PublicLayout title={t('contact.agendar')}>
    <div className="wrap py-20 my-10">
      <InternalLink
        label={t('contact.habla')}
        to={routes.contact}
        icon={<Icons.Back />}
      />
    </div>
    <Wrap size="wrap" className="lg:flex lg:pb-20">
      <div className="mb-22 lg:mb-0 lg:w-192">
        <Title>{t('contact.agendar')}</Title>
      </div>
      <div className="lg:flex-1 lg:ml-28">
        <Bodycopy className="mb-10 max-w-240">{t('contact.calendly')}</Bodycopy>
        <InlineWidget url="https://calendly.com/cuerva" styles={{ minWidth: '320px', height: '630px' }} />
      </div>
    </Wrap>
  </PublicLayout>
);

export default Calendly;
