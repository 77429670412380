import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

import Title from '../../../components/text-components/Title';
import { useStoreState } from '../../../store/hooks';
import Caption from '../../../components/text-components/Caption';
import t from '../../../text-strings';
import { routes } from '../../../services/routing';

const Avatar: FunctionComponent = () => {
  const { session } = useStoreState((state) => state.session);

  return (
    <Link to={routes.profile} className="flex mb-24">
      <div className="bg-gradient flex items-center justify-center rounded-full w-24 h-24 min-w-24 mr-8">
        <Title tag="h4" size="small">{session.firstNameLetter}</Title>
      </div>
      <div className="">
        <Caption className="thin mb-2">{t('common.bienvenido')}</Caption>
        <Title size="small">{session.firstName}</Title>
      </div>
    </Link>
  );
};

export default Avatar;
