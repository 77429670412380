import React, { FunctionComponent } from 'react';
import _ from 'lodash/fp';

import { RequestAttributes } from '../../../../store/model/requests/types';
import t from '../../../../text-strings';
import useRequestHistory from '../useHistory';

import InactiveState from './InactiveState';
import ActiveState from './ActiveState';
import FinalState from './FinalState';

interface Props {
  request: RequestAttributes;
  id: string;
}

export const stateNames = {
  analizando: t('history.analizando'),
  requerida: t('history.requerida'),
  viabilidad: t('history.viabilidad'),
  denegada: t('history.denegada'),
  afeccion: t('history.afeccion'),
  desestimada: t('history.desestimada'),
  revisando: t('history.revisando'),
  propuesta: t('history.propuesta'),
  permisos: t('history.permisos'),
  emision: t('history.emision'),
  cancelada: t('history.cancelada'),
};

const SelfConsumptionFlow: FunctionComponent<Props> = ({
  request,
  id,
}: Props) => {
  const requestHistory = useRequestHistory(request, id);

  const {
    name: currentState,
    createdDate,
    finalState,
  } = requestHistory.details;

  return (
    <>
      {!_.isEqual(currentState, stateNames.analizando)
      && !_.isEqual(currentState, stateNames.requerida) ? (
        <InactiveState number={1} name={stateNames.analizando} />
        ) : (
          <ActiveState
            number={1}
            name={stateNames.analizando}
            stateName={currentState}
          />
        )}

      {!_.isEqual(currentState, stateNames.viabilidad)
      && !_.isEqual(currentState, stateNames.denegada)
      && !_.isEqual(currentState, stateNames.afeccion) ? (
        <InactiveState number={2} name={stateNames.viabilidad} />
        ) : (
          <>
            {finalState ? (
              <FinalState
                name={stateNames.viabilidad}
                stateName={currentState}
                createdDate={createdDate}
              />
            ) : (
              <ActiveState
                number={2}
                name={stateNames.viabilidad}
                stateName={currentState}
              />
            )}
          </>
        )}
      {!_.isEqual(currentState, stateNames.revisando)
      && !_.isEqual(currentState, stateNames.desestimada)
      && !_.isEqual(currentState, stateNames.propuesta) ? (
        <InactiveState number={3} name={stateNames.revisando} />
        ) : (
          <ActiveState
            number={3}
            name={stateNames.revisando}
            stateName={currentState}
          />
        )}
      {!_.isEqual(currentState, stateNames.permisos)
      && !_.isEqual(currentState, stateNames.emision) ? (
        <InactiveState number={4} name={stateNames.permisos} line={false} />
        ) : (
          <>
            {finalState ? (
              <FinalState
                name={stateNames.permisos}
                stateName={currentState}
                createdDate={createdDate}
                good
              />
            ) : (
              <ActiveState
                number={4}
                name={stateNames.permisos}
                stateName={currentState}
              />
            )}
          </>
        )}
    </>
  );
};

export default SelfConsumptionFlow;
