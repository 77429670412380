import React, { FunctionComponent, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import Caption from '../../components/text-components/Caption';

interface Props {
  to: string;
  label: string;
  icon: ReactNode;
  isActive: boolean;
}

const Tab: FunctionComponent<Props> = (
  {
    to,
    label,
    icon,
    isActive,
  }: Props,
) => {
  const activeClass = classnames('w-10 h-1 mt-2', { 'bg-gradient': isActive });

  return (
    <Link to={to} className="w-1/4 max-w-64 flex flex-col items-center justify-center">
      {icon}
      <Caption color={isActive ? 'black' : 'gray-400'} className="mt-2 hidden xs:block">
        {label}
      </Caption>
      <div className={activeClass} />
    </Link>
  );
};

export default Tab;
