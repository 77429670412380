import { useFormik } from 'formik';
import React, { FunctionComponent, useState } from 'react';

import { useStoreActions } from '../../store/hooks';
import t from '../../text-strings';
import AuthenticationLayout from '../../layout/AuthenticationLayout';
import { routes } from '../../services/routing';
import Form from '../../components/forms/Form';
import TextInput from '../../components/forms/TextInput';
import FormResponse from '../../components/forms/FormResponse';
import SubmitButton from '../../components/buttons/SubmitButton';
import Bodycopy from '../../components/text-components/Bodycopy';
import useNavigation from '../../services/navigation';
import formInit from '../../forms/initial-values';
import schemas from '../../forms/schemas';
import { ResetPasswordParams } from '../../store/model/passwords/types';
import MobileTopNav from '../../components/MobileTopNav';
import IDHelper from '../../components/forms/IDHelper';

const PasswordReset: FunctionComponent = () => {
  const { reset } = useStoreActions((actions) => actions.passwords);
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const navigate = useNavigation();

  const handleOnSubmit = async (values: ResetPasswordParams): Promise<void> => {
    const result = await reset(values);
    if (typeof result === 'string') {
      setMessage(result);
      return;
    }
    setSuccess(result);
  };

  const {
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    errors,
    values,
    isSubmitting,
    isValid,
  } = useFormik({
    initialValues: formInit.resetPassword,
    onSubmit: handleOnSubmit,
    validationSchema: schemas.resetPassword,
  });

  return (
    <AuthenticationLayout>
      <MobileTopNav onClick={() => navigate(routes.login)} title={t('passwords.reset_title')} />
      <Form onSubmit={handleSubmit} className="wrap-thin pt-12">
        <Bodycopy className="max-w-240 w-full mb-8 lg:mb-12">{t('passwords.reset_description')}</Bodycopy>
        <TextInput
          name="nif"
          value={values.nif}
          placeholder={t('models.user.attributes.nif')}
          onChange={handleChange('nif')}
          onBlur={handleBlur('nif')}
          touched={touched.nif}
          errors={errors.nif}
          icon={<IDHelper />}
        />
        {success && (
          <Bodycopy color="green">
            {t('passwords.reset_success')}
          </Bodycopy>
        )}
        <FormResponse message={message} className="mb-2" />
        <SubmitButton
          label={t('passwords.recovery')}
          disabled={isSubmitting || !isValid}
          fixed
        />
      </Form>
    </AuthenticationLayout>
  );
};

export default PasswordReset;
