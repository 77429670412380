import { BlobServiceClient, ContainerClient } from '@azure/storage-blob';
import _ from 'lodash/fp';

import {
  AzureConfiguration,
  DirectoryParams,
  FileParams,
  StreamParams,
} from './types';

const configuration: AzureConfiguration = {
  account: process.env.REACT_APP_AZURE_ACCOUNT || 'https://portalclientesdso.blob.core.windows.net/',
  sas: process.env.REACT_APP_AZURE_SAS || '?sv=2020-10-02&ss=btqf&srt=sco&st=2022-03-08T11%3A49%3A29Z&se=2122-03-09T11%3A49%3A00Z&sp=rwdxl&sig=jVB53YCGrhdze6wOYrC87iiuyaterUnC9wR6YfF%2FMUo%3D',
};

const blobServiceClient: BlobServiceClient = (
  new BlobServiceClient(`${configuration.account}${configuration.sas}`)
);

const client = (distributor: string): ContainerClient => (
  blobServiceClient.getContainerClient(distributor)
);

// eslint-disable-next-line
const blobsByHierachy = (directory: DirectoryParams): any => (
  client(directory.distributor).listBlobsByHierarchy('/', { prefix: directory.dir })
);

// eslint-disable-next-line
const blobsFlat = (directory: DirectoryParams): any => (
  client(directory.distributor).listBlobsFlat({ prefix: directory.dir })
);

// eslint-disable-next-line
const blobToBase64 = async (blob: any): Promise<any> => (
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => resolve(reader.result?.toString().split(',')[1]);
    reader.onerror = (error) => reject(error);
  })
);

// eslint-disable-next-line
const getDirectory = async (directory: DirectoryParams): Promise<any[]> => {
  const result = [];
  // eslint-disable-next-line
  for await (const blob of blobsByHierachy(directory)) {
    result.push(blob);
  }
  // @ts-ignore
  return _.map((x) => x.name)(result);
};

// eslint-disable-next-line
const getTree = async (directory: DirectoryParams): Promise<any[]> => {
  const result = [];
  // eslint-disable-next-line
  for await (const blob of blobsFlat(directory)) {
    result.push(blob);
  }

  return result;
};

const download = async (directory: DirectoryParams): Promise<string> => {
  const fileClient = client(directory.distributor).getBlockBlobClient(directory.dir);
  const downloadBlockBlobResponse = await fileClient.download();
  return `
    data:application/pdf;base64,
    ${encodeURI(await blobToBase64(await downloadBlockBlobResponse.blobBody))}
  `;
};

const upload = async (params: FileParams): Promise<void> => {
  const folder = client(params.blob).getBlockBlobClient(params.path);
  await folder.upload(params.file, params.file.size);
};

const uploadStream = async (params: StreamParams): Promise<void> => {
  const folder = client(params.blob).getBlockBlobClient(params.path);
  await folder.uploadData(params.stream);
};

const destroy = async (params: FileParams): Promise<void> => {
  const currentClient = client(params.blob);
  const folder = await getTree({ distributor: params.blob, dir: params.path });
  // eslint-disable-next-line
  for await (const file of folder) {
    const currentFolder = currentClient.getBlockBlobClient(file.name);
    await currentFolder.delete();
  }
};

export interface Azure {
  // eslint-disable-next-line
  getDirectory: (directory: DirectoryParams) => Promise<any[]>;
  // eslint-disable-next-line
  getTree: (directory: DirectoryParams) => Promise<any[]>;
  download: (directory: DirectoryParams) => Promise<string>;
  upload: (params: FileParams) => Promise<void>;
  uploadStream: (params: StreamParams) => Promise<void>;
  destroy: (params: FileParams) => Promise<void>
}

const azureAPI = (): Azure => ({
  getDirectory,
  getTree,
  download,
  upload,
  uploadStream,
  destroy,
});

export default azureAPI;
