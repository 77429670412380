import { AxiosResponse } from 'axios';

import {
  AppointmentParams,
  ContactParams,
  DocumentationParams,
  FAQParams,
} from '../../store/model/contact/types';
import {
  axiosGet, axiosPost, setURL, queryParams,
} from '../axios';

export const sendappointment = async (params: AppointmentParams): Promise<AxiosResponse> => (
  axiosPost(setURL('/Contact', '/sendappointment'), params)
);

export const getquestionsbytype = async (params: FAQParams): Promise<AxiosResponse> => (
  axiosGet(setURL('/Contact', '/getquestionsbytype?', queryParams(params)))
);

export const sendcontact = async (params: ContactParams): Promise<AxiosResponse> => (
  axiosPost(setURL('/Contact', '/sendcontact'), params)
);

export const getdocumentationtags = async (): Promise<AxiosResponse> => (
  axiosGet(setURL('/Contact', '/getdocumentationtags'))
);

export const getdocumentation = async (params: DocumentationParams): Promise<AxiosResponse> => (
  axiosPost(setURL('/Contact', '/getdocumentation'), params)
);
