import React, { FunctionComponent, ReactNode } from 'react';

import Main from './components/Main';
import Header from './header/Header';

export interface Props {
  children: ReactNode;
}

const AuthenticationLayout: FunctionComponent<Props> = ({ children }: Props) => (
  <Main>
    <Header background="black" onlydesktop />
    <main className="flex-1 flex flex-col">{children}</main>
  </Main>
);

export default AuthenticationLayout;
