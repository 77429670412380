import { AxiosResponse } from 'axios';

import {
  ExistCupsParams,
  AliasParams,
  ConsumptionParams,
  CounterParams,
  DistributorParams,
  StateParams,
  MeasureParams,
} from '../../store/model/contract/types';
import {
  axiosGet,
  axiosPost,
  axiosDelete,
  axiosPut,
  queryParams,
  setURL,
} from '../axios';

export const getcontracts = async (nif: string): Promise<AxiosResponse> => axiosGet(setURL('/Contract', '/getcontractsbynif?', queryParams({ nif })));

export const getexistcups = async (
  params: ExistCupsParams,
): Promise<AxiosResponse> => axiosGet(setURL('/Contract', '/getexistcups?', queryParams(params)));

export const getstreettypes = async (): Promise<AxiosResponse> => axiosGet(setURL('/Contract', '/getstreettypes'));

export const createalias = async (
  params: AliasParams,
): Promise<AxiosResponse> => axiosPost(setURL('/Contract', '/createalias'), params);

export const getconsumption = async (
  params: ConsumptionParams,
): Promise<AxiosResponse> => axiosPost(setURL('/Contract', '/getconsumption'), params);

export const getpower = async (
  params: ConsumptionParams,
): Promise<AxiosResponse> => axiosPost(setURL('/Contract', '/getpower'), params);

export const getctdata = async (
  params: CounterParams,
): Promise<AxiosResponse> => axiosGet(setURL('/CT', '/getctdata?', queryParams(params)));

export const getmeasuresbycups = async (cups: string): Promise<AxiosResponse> => axiosGet(setURL('/CT', '/getmeasuresbycups?', queryParams({ cups })));

export const createscheduledmeasure = async (
  params: MeasureParams,
): Promise<AxiosResponse> => axiosPost(setURL('/CT', '/createscheduledmeasure'), params);

export const updatescheduledmeasure = async (
  params: MeasureParams,
): Promise<AxiosResponse> => axiosPut(setURL('/CT', '/updatescheduledmeasure'), params);

export const getscheduledsbycups = async (
  cups: string,
): Promise<AxiosResponse> => axiosGet(setURL('/CT', '/getscheduledsbycups?', queryParams({ cups })));

export const reconnecticp = async (cups: string): Promise<AxiosResponse> => axiosGet(setURL('/CT', '/reconnecticp?', queryParams({ cups })));

export const getscheduledmeasureinfo = async (
  scheduledId: number,
): Promise<AxiosResponse> => axiosGet(
  setURL('/CT', '/getscheduledmeasureinfo?', queryParams({ scheduledId })),
);

export const takemeasure = async (
  cups: string,
  comments?: string,
): Promise<AxiosResponse> => axiosGet(setURL('/CT', '/takemeasure?', queryParams({ cups, comments })));

export const deletescheduledmeasure = async (
  scheduledId: string,
): Promise<AxiosResponse> => axiosDelete(
  setURL('/CT', '/deletescheduledmeasure?', queryParams({ scheduledId })),
);

export const getexistdistributor = async (
  params: DistributorParams,
): Promise<AxiosResponse> => axiosGet(setURL('/Contract', '/getexistdistributor?', queryParams(params)));

export const getstatecontracts = async (
  params: StateParams,
): Promise<AxiosResponse> => axiosGet(setURL('/Contract', '/getstatecontracts?', queryParams(params)));

export const getcsvhourly = async (
  params: ConsumptionParams,
): Promise<AxiosResponse> => axiosPost(setURL('/Contract', '/downloadcsvhourly'), params);

export const getcsvquarterly = async (
  params: ConsumptionParams,
): Promise<AxiosResponse> => axiosPost(setURL('/Contract', '/downloadcsvquarter'), params);

export const getcsvpower = async (
  params: ConsumptionParams,
): Promise<AxiosResponse> => axiosPost(setURL('/Contract', '/downloadcsvpower'), params);

export const getpdfmeasurecertificate = async (
  params: ConsumptionParams,
): Promise<AxiosResponse> => axiosPost(setURL('/Contract', '/downloadpdfmeasurecertificate'), params);
