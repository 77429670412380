import authorizedModel, { AuthorizedModel } from './authorized';
import contractModel, { ContractModel } from './contract';
import distributorModel, { DistributorsModel } from './distributors';
import passwordModel, { PasswordModel } from './passwords';
import registerModel, { RegisterModel } from './register';
import sessionModel, { SessionModel } from './session';
import userModel, { UserModel } from './user';
import requestModel, { RequestModel } from './requests/index';
import contactModel, { ContactModel } from './contact';
import { RaffleModel, raffleModel } from './raffle';

export type TouchedDocs = {
  front: boolean;
  back: boolean;
  authorization?: boolean;
}

export interface StoreModel {
  session: SessionModel;
  user: UserModel;
  register: RegisterModel;
  passwords: PasswordModel;
  contract: ContractModel;
  authorized: AuthorizedModel;
  distributors: DistributorsModel;
  requests: RequestModel;
  contact: ContactModel;
  raffle: RaffleModel;
}

const storeModel: StoreModel = {
  session: sessionModel,
  user: userModel,
  register: registerModel,
  passwords: passwordModel,
  contract: contractModel,
  authorized: authorizedModel,
  distributors: distributorModel,
  requests: requestModel,
  contact: contactModel,
  raffle: raffleModel,
};

export default storeModel;

export const cacheStorage = {
  AreYouOwnerValue: undefined as number | undefined,
};
