import React, { FunctionComponent } from 'react';

import t from '../text-strings';

export interface Props {
  src: string;
  alt?: string;
  className?: string;
}

const Image: FunctionComponent<Props> = (
  {
    src,
    alt = t('common.default_image_alt'),
    className = '',
  }: Props,
) => (
  <img src={src} alt={alt} className={className} />
);

export default Image;
