import _ from 'lodash/fp';
import React, {
  ChangeEvent,
  FunctionComponent,
  useEffect,
  useState,
} from 'react';

import Icons from '../../assets/icons';
import Accordion from '../../components/Accordion';
import LinkCard from '../../components/cards/LinkCard';
import Wrap from '../../components/containers/Wrap';
import Modal from '../../components/Modal';
import Bodycopy from '../../components/text-components/Bodycopy';
import Title from '../../components/text-components/Title';
import PublicLayout from '../../layout/PublicLayout';
import useNavigation from '../../services/navigation';
import { routes } from '../../services/routing';
import useFAQs from '../../services/use-faq';
import { FAQAttributes } from '../../store/model/contact/types';
import t from '../../text-strings';

const Contact: FunctionComponent = () => {
  const [loading, faqs] = useFAQs('Preguntas_frecuentes');
  const [currentFaqs, setCurrentFaqs] = useState<FAQAttributes[]>([]);
  const [search, setSearch] = useState('');
  const [open, setOpen] = useState(false);
  const navigate = useNavigation();

  const toggleOpen = (): void => setOpen(!open);

  useEffect(() => setCurrentFaqs(faqs), [loading]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    setSearch(value);

    if (_.isEmpty(search)) {
      setCurrentFaqs(faqs);
      return;
    }

    const newFaqs = _.filter((faq: FAQAttributes) => (
      faq.title.toLowerCase().includes(value.toLowerCase())
      || faq.text.toLowerCase().includes(value.toLowerCase())
    ))(faqs);
    setCurrentFaqs(newFaqs);
  };

  return (
    <PublicLayout title={t('common.navigation.contacto')}>
      <Wrap size="wrap" className="lg:flex py-16 lg:py-36">
        <div className="mb-22 lg:mb-0 lg:w-192">
          <Title size="small" className="mb-12">{t('contact.habla')}</Title>
          <LinkCard
            to={routes.contactForm}
            label={t('contact.enviar_mensaje')}
            icon={<Icons.Message color="white" />}
            className="mb-4"
          />
          <LinkCard
            to={routes.book}
            label={t('contact.agendar')}
            icon={<Icons.Phone color="white" />}
            className="mb-8"
          />
          <LinkCard
            to={`${routes.contactForm}?select=fraude`}
            label={t('contact.fraude')}
            icon={<Icons.Phone color="white" />}
            className="mb-8"
          />
          <button
            type="button"
            className="text-sm font-semibold text-blue hidden xl:block"
            onClick={(): void => navigate(routes.callUs)}
          >
            <span className="label">{t('contact.prefieres')}</span>
          </button>
          <button
            type="button"
            className="text-sm font-semibold text-blue xl:hidden block"
            onClick={toggleOpen}
          >
            <span className="label">{t('contact.prefieres')}</span>
          </button>
        </div>
        <div className="lg:flex-1 lg:ml-16">
          <Title className="mb-16">{t('contact.faqs')}</Title>
          <div className="lg:flex mb-16 lg:items-center">
            <div className="flex w-full max-w-240 border-b border-black py-2 items-center mb-10 lg:mb-0">
              <input
                type="text"
                name="search"
                id="search"
                value={search}
                placeholder={t('contact.buscando')}
                onChange={handleChange}
                className="bg-transparent flex-1 body-copy text-black block py-px placeholder-gray-400"
              />
              <Icons.Search color="blue" />
            </div>
          </div>
          {_.map((faq: FAQAttributes) => (
            <Accordion
              key={faq.questionId}
              title={faq.title}
              text={faq.text}
            />
          ))(currentFaqs)}
        </div>
        <Modal isOpen={open} toggleOpen={toggleOpen}>
          <Title size="small" className="mb-12">
            {t('contact.llamanos')}
          </Title>
          <Bodycopy className="mb-8">{t('contact.averias')}</Bodycopy>
          <Title className="text-blue mb-4">900 37 38 13</Title>
          <Bodycopy className="mb-24">{t('contact.24h')}</Bodycopy>
          <Bodycopy className="mb-8">{t('contact.otras')}</Bodycopy>
          <Title className="text-blue mb-4">958 570 360</Title>
          <Bodycopy className="mb-2">{t('contact.horario')}</Bodycopy>
          <Bodycopy className="mb-24">{t('contact.estacional')}</Bodycopy>
        </Modal>
      </Wrap>
    </PublicLayout>
  );
};

export default Contact;
