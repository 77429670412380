import React, {
  ChangeEvent, FunctionComponent, useEffect, useState,
} from 'react';
import { useFormik } from 'formik';
import _ from 'lodash/fp';

import { useStoreActions, useStoreState } from '../../../store/hooks';
import useHandleRequestResponse from '../../../services/handle-request-response';
import { CauParams, FormID } from '../../../store/model/requests/types';
import formInit from '../../../forms/initial-values';
import schemas from '../../../forms/schemas';
import constants from '../../../services/constants';
import AuthenticatedLayout from '../../../layout/AuthenticatedLayout';
import Form from '../../../components/forms/Form';
import t from '../../../text-strings';
import { instalationData, requestData } from '../../../store/model/requests/requests';

import RequestDataStep from './components/RequestData';
import InstalationDataStep from './components/InstalationData';
import SideNavLink from './components/SideNavLink';

const TOTAL_STEPS = 2;

const CauRequest: FunctionComponent = () => {
  const { session } = useStoreState((state) => state.session);
  const {
    getloadrequest,
    createcaurequest,
    setRequestStep,
  } = useStoreActions((actions) => actions.requests);
  const [loading, setLoading] = useState(true);
  const [
    message,
    success,
    sendedPdf,
    handleResponse,
  ] = useHandleRequestResponse<CauParams>();

  const handleOnSubmit = async (values: CauParams): Promise<void> => {
    handleResponse(await createcaurequest(values), values);
  };

  const {
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    isSubmitting,
    isValid,
  } = useFormik({
    initialValues: formInit.cau(session),
    onSubmit: handleOnSubmit,
    validationSchema: schemas.cau,
  });

  useEffect(() => {
    (async () => {
      setRequestStep('solicitud');
      await getloadrequest({
        distributorId: constants.DISTRIBUTOR_ID,
        formCode: constants.CAU_CODE as FormID,
      });
      setLoading(false);
    })();
  }, []);

  if (loading) return null;

  const handleSelect = (e: ChangeEvent<HTMLSelectElement>): void => {
    const { value, name } = e.target;
    if (_.isEmpty(value)) {
      setFieldValue(name, 0);
      return;
    }
    setFieldValue(name, parseInt(value, 10));
  };

  return (
    <AuthenticatedLayout title="" onlyDesktop sidebar={false}>
      <Form onSubmit={handleSubmit} className="lg:flex lg:flex-1">
        <div className="lg:w-7/10">
          <RequestDataStep
            title={t('requests.cau_request')}
            step={1}
            totalSteps={TOTAL_STEPS}
            // @ts-ignore
            values={_.pick(requestData)(values)}
            handleChange={handleChange}
            handleSelect={handleSelect}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
            errors={errors}
            touched={touched}
          />
          <InstalationDataStep
            title={t('requests.cau_request')}
            step={2}
            totalSteps={TOTAL_STEPS}
            // @ts-ignore
            values={_.pick(instalationData)(values)}
            handleChange={handleChange}
            handleSelect={handleSelect}
            setFieldValue={setFieldValue}
            handleBlur={handleBlur}
            errors={errors}
            touched={touched}
            message={message}
            success={success}
            isSubmitting={isSubmitting}
            isValid={isValid}
            sendedPdf={sendedPdf}
            formCode={constants.CAU_CODE}
          />
        </div>
        <div className="hidden w-3/10 lg:flex flex-col bg-white relative pt-72 pl-32">
          <div className="bg-white absolute top-0 left-full w-screen-full h-full" />
          <SideNavLink
            label={t('requests.forms.solicitud')}
            currentStep="solicitud"
          />
          <SideNavLink
            label={t('requests.forms.instalacion')}
            currentStep="instalacion"
          />
        </div>
      </Form>
    </AuthenticatedLayout>
  );
};

export default CauRequest;
