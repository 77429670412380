import _ from 'lodash/fp';

export interface Active {
  operative: boolean;
  soil: boolean;
  power: boolean;
  marketer: boolean;
  callUs: boolean;
  noCupsRusticSupply: boolean;
  noCupsUrbanSupply: boolean;
  newCups: boolean;
  noCupsClear: boolean;
  inactiveRusticSupply: boolean;
  inactiveUrbanNewCups: boolean;
  inactiveUrbanSupply: boolean;
}

export type SupplyGuide = {
  cups: number;
  operative: number;
  soil: number;
  power: number;
}

const cupsRadios = [
  {
    value: 'Sí',
    selectorId: 1,
  },
  {
    value: 'No',
    selectorId: 2,
  },
  {
    value: 'No tengo claro si tengo CUPS',
    selectorId: 3,
  },
];

const operativeRadios = [
  {
    value: 'Lleva más de 3 años',
    selectorId: 1,
  },
  {
    value: 'Lleva menos de 3 años',
    selectorId: 2,
  },
  {
    value: 'No lo tengo claro',
    selectorId: 3,
  },
];

const soilRadios = [
  {
    value: 'Urbano',
    selectorId: 1,
  },
  {
    value: 'Rústico',
    selectorId: 2,
  },
];

const powerRadios = [
  {
    value: '<50kW',
    selectorId: 1,
  },
  {
    value: '>50kW',
    selectorId: 2,
  },
];

const operativeActive = (values: SupplyGuide): boolean => (
  _.isEqual(values.cups, 1)
);

const isCupsNotClear = (values: SupplyGuide): boolean => _.isEqual(values.cups, 3);

const soilActive = (values: SupplyGuide): boolean => (
  _.isEqual(values.cups, 2) || _.isEqual(values.operative, 1)
);

const powerActive = (values: SupplyGuide): boolean => (
  _.isEqual(values.soil, 1)
);

const isMarketer = (values: SupplyGuide): boolean => (
  _.isEqual(values.operative, 2)
);

const isCallUs = (values: SupplyGuide): boolean => (
  _.isEqual(values.operative, 3)
);

const isNoCupsRusticSupply = (values: SupplyGuide): boolean => (
  _.isEqual(values.cups, 2) && _.isEqual(values.soil, 2)
);

const isNoCupsUrban = (values: SupplyGuide): boolean => (
  _.isEqual(values.cups, 2) && _.isEqual(values.soil, 1)
);

const isNoCupsUrbanSupply = (values: SupplyGuide): boolean => (
  isNoCupsUrban(values) && _.isEqual(values.power, 2)
);

const isNewCups = (values: SupplyGuide): boolean => (
  isNoCupsUrban(values) && _.isEqual(values.power, 1)
);

const isInactiveCups = (values: SupplyGuide): boolean => (
  _.isEqual(values.cups, 1) && _.isEqual(values.operative, 1)
);

const isInactiveRusticSupply = (values: SupplyGuide): boolean => (
  isInactiveCups(values) && _.isEqual(values.soil, 2)
);

const isInactiveUrban = (values: SupplyGuide): boolean => (
  isInactiveCups(values) && _.isEqual(values.soil, 1)
);

const isInactiveUrbanNewCups = (values: SupplyGuide): boolean => (
  isInactiveUrban(values) && _.isEqual(values.power, 1)
);

const isInactiveUrbanSupply = (values: SupplyGuide): boolean => (
  isInactiveUrban(values) && _.isEqual(values.power, 2)
);

export const radios = {
  cups: cupsRadios,
  operative: operativeRadios,
  soil: soilRadios,
  power: powerRadios,
};

export const helperMethods = [
  'noCupsClear',
  'marketer',
  'callUs',
  'noCupsRusticSupply',
  'noCupsUrbanSupply',
  'newCups',
  'inactiveRusticSupply',
  'inactiveUrbanNewCups',
  'inactiveUrbanSupply',
];

const supplyGuide = (values: SupplyGuide): Active => ({
  operative: operativeActive(values),
  soil: soilActive(values),
  power: powerActive(values),
  marketer: isMarketer(values),
  noCupsClear: isCupsNotClear(values),
  callUs: isCallUs(values),
  noCupsRusticSupply: isNoCupsRusticSupply(values),
  noCupsUrbanSupply: isNoCupsUrbanSupply(values),
  newCups: isNewCups(values),
  inactiveRusticSupply: isInactiveRusticSupply(values),
  inactiveUrbanNewCups: isInactiveUrbanNewCups(values),
  inactiveUrbanSupply: isInactiveUrbanSupply(values),
});

export default supplyGuide;
