import React, { FunctionComponent } from 'react';

const SpinnerSmall: FunctionComponent = () => {
  const spinnerStyle: React.CSSProperties = {
    color: 'rgb(20, 16, 27)',
    fontFamily: 'FKCuervaGrotesk',
    display: 'block',
    alignItems: 'center',
    position: 'relative',
    left: '0px',
    fontSize: '200px',
    width: '100%',
    height: '220px',
    animation: 'spin 1s ease-in-out infinite',
    margin: '0 auto',
    textAlign: 'center',
  };

  return (
    <div style={spinnerStyle}>
      *
    </div>
  );
};

export default SpinnerSmall;
