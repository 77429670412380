import React, { FunctionComponent } from 'react';
import { useLocation } from 'react-router-dom';

import Icons from '../../assets/icons';
import { routes } from '../../services/routing';
import t from '../../text-strings';
import useSelectedURL from '../../services/selected-url';

import Avatar from './components/Avatar';
import SidebarLink from './components/SidabarLink';

const Sidebar: FunctionComponent = () => {
  const isSelected = useSelectedURL();
  const location = useLocation();
  const isMyRequests = location.pathname.split('/')[1] === 'mis-solicitudes';
  const isMeasureDetails = location.pathname.split('/')[1] === 'measure';

  return (
    <aside className="hidden w-1/5 pt-24 pr-8 bg-white lg:block lg:sticky lg:top-0 lg:h-screen">
      <div className="absolute top-0 h-full bg-white w-screen-full right-full" />
      <Avatar />
      <SidebarLink
        to={routes.consumption}
        label={t('common.navigation.mi_consumo')}
        icon={<Icons.Bolt />}
        isActive={isSelected(routes.consumption)}
      />
      <SidebarLink
        to={routes.counter}
        label={t('common.navigation.mi_contador')}
        icon={<Icons.Counter />}
        isActive={
          isSelected(routes.counter)
          || isSelected(routes.counterSchedule)
          || isMeasureDetails
        }
      />
      <SidebarLink
        to={routes.requests.index}
        label={t('common.navigation.mis_solicitudes')}
        icon={<Icons.Document />}
        isActive={isSelected(routes.requests.index) || isMyRequests}
      />
      <SidebarLink
        to={routes.breakdowns}
        label={t('common.navigation.no_tengo_luz')}
        icon={<Icons.Fraud />}
        isActive={isSelected(routes.breakdowns)}
      />
    </aside>
  );
};

export default Sidebar;
