import React, { FunctionComponent, useEffect, useState } from 'react';
import classnames from 'classnames';

import Icons from '../../assets/icons';
import Caption from '../text-components/Caption';
import date from '../../services/date';
import t from '../../text-strings';

interface Props {
  alias: string;
  startDate: string;
}

const BreakdownAlert: FunctionComponent<Props> = ({ alias, startDate }: Props) => {
  const [open, setOpen] = useState(true);

  const containerClass = classnames(
    'bg-white w-full lg:w-11/12 max-w-screen-content lg:ml-auto p-8 rounded-sm border-l-4 border-yellow mb-8', {
      hidden: !open,
    },
  );

  const closeAlert = (): void => setOpen(!open);

  const setMessage = (): string => (
    date.isSameDay(new Date(), startDate)
      ? t('breakdowns.alerts.averia.hoy', `${date.format(startDate, 'HH:mm')}`)
      : t('breakdowns.alerts.averia.otro', `${date.format(startDate, 'ddd D/M, HH:mm')}`)
  );

  useEffect(() => setOpen(true), [alias]);

  return (
    <div className={containerClass}>
      <div className="flex">
        <div className="flex-1 pt-1 px-4">
          <p className="btn mb-4">{setMessage()}</p>
          <Caption>{alias}</Caption>
        </div>
        <div className="min-w-12" onClick={closeAlert}>
          <Icons.Close color="yellow" />
        </div>
      </div>
    </div>
  );
};

export default BreakdownAlert;
