import { useState } from 'react';
import _ from 'lodash/fp';

import { RequestAttributesResponse } from '../store/model/requests/types';
import { documentData } from '../store/model/requests/requests';
import t from '../text-strings';
import { useStoreActions } from '../store/hooks';

import documents from './documents';
import pdfLib from './pdf';

type Response = string | RequestAttributesResponse;

const docsToArray = _.flow(_.pick(documentData), _.valuesIn);

const useHandleRequestResponse = <T>(): [
  string,
  boolean,
  string,
  (r: Response, v: T) => Promise<void>
] => {
  const { rollbackcreaterequest } = useStoreActions(
    (actions) => actions.requests,
  );
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(true);
  const [sendedPdf, setSendedPdf] = useState('');

  const handleResponse = async (
    r: string | RequestAttributesResponse,
    v: T,
  ): Promise<void> => {
    if (typeof r === 'string') {
      setMessage(r);
      setSuccess(false);
      return;
    }

    setMessage(t('requests.forms.uploading'));
    const docsArray: File[] = docsToArray(v);
    const docs: File[] = _.filter((doc: File) => !_.isEmpty(doc.name))(
      docsArray,
    );
    let sealedDoc: ArrayBufferLike;
    // @ts-ignore
    const azurePath = `${v.code}/${r.year}/${r.month}/${r.day}/${r.code}/0/0/`;

    try {
      const justificante = Uint8Array.from(atob(r.pdf), (c) => c.charCodeAt(0));
      await documents.uploadStreams(
        `${azurePath}JustifucanteSolicitud.pdf`,
        [justificante],
        r.blob,
      );
      setMessage(t('requests.forms.sealing'));

      if (!_.isEmpty(docs)) {
        await documents.uploadFiles(azurePath, docs, r.blob);
        const arrayBuffer = await documents.filesToArray(docs);

        const unifyDoc = await pdfLib.unifyDocuments([
          justificante,
          ...arrayBuffer,
        ]);
        sealedDoc = await pdfLib.stampDocument(
          unifyDoc,
          r.icon,
          r.code,
          r.identity,
          r.formName,
          r.distributorName,
          r.distributorLongName,
          true,
        );
      } else {
        sealedDoc = await pdfLib.stampDocument(
          justificante,
          r.icon,
          r.code,
          r.identity,
          r.formName,
          r.distributorName,
          r.distributorLongName,
          true,
        );
      }
      await documents.uploadStreams(
        `${azurePath}Unificado/DocumentoUnificado.pdf`,
        [sealedDoc],
        r.blob,
      );
      // eslint-disable-next-line
      // @ts-ignore
      setSendedPdf(
        `data:application/pdf;base64,${btoa(
          new Uint8Array(sealedDoc).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            '',
          ),
        )}`,
      );
      setMessage('');
    } catch (error) {
      setSuccess(false);
      setMessage(t('requests.forms.undoing'));
      // @ts-ignore
      // const azureDeletePath = `${v.code}/${r.year}/${r.month}/${r.day}/${r.code}/`;
      // await documents.deleteAzurePath(azureDeletePath, r.blob);
      const rollbackResponse = await rollbackcreaterequest({
        requestId: r.requestId,
        // @ts-ignore
        codeForm: v.code,
        rollbackError: `${(JSON.stringify(error), JSON.stringify(r))}`,
      });
      if (typeof rollbackResponse === 'string') {
        setMessage(rollbackResponse);
        return;
      }
      setMessage(t('requests.forms.canceled'));
    }
  };

  return [message, success, sendedPdf, handleResponse];
};

export default useHandleRequestResponse;
