import React, { FunctionComponent } from 'react';

import { AppColors } from '../../styles';

interface Props {
  color?: AppColors;
  width?: number;
  height?: number;
  className?: string;
}

const DropdownUp: FunctionComponent<Props> = (
  {
    color = 'black',
    width = 6,
    height = 6,
    className = '',
  }: Props,
) => (
  <svg viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg" className={`w-${width} h-${height} text-${color} fill-current ${className}`}>
    <path d="M2.11953 6.70945L5.99953 2.82945L9.87953 6.70945C10.2695 7.09945 10.8995 7.09945 11.2895 6.70945C11.6795 6.31945 11.6795 5.68945 11.2895 5.29945L6.69953 0.709453C6.30953 0.319453 5.67953 0.319453 5.28953 0.709453L0.699533 5.29945C0.309532 5.68945 0.309532 6.31945 0.699533 6.70945C1.08953 7.08945 1.72953 7.09945 2.11953 6.70945Z" />
  </svg>
);

export default DropdownUp;
