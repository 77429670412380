import { useState } from 'react';
import _ from 'lodash/fp';

import useNavigation from './navigation';

const useHandleResponse = (route: string): [string, (response: string | boolean) => void] => {
  const navigate = useNavigation();
  const [message, setMessage] = useState('');

  const handleResponse = (response: string | boolean): void => {
    if (typeof response === 'boolean') {
      if (!_.isEmpty(route)) {
        navigate(route);
      }
      return;
    }

    setMessage(response);
  };

  return [message, handleResponse];
};

export default useHandleResponse;
