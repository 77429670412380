import React, { FunctionComponent, useEffect, useState } from 'react';
import classnames from 'classnames';

import Icons from '../../assets/icons';
import Caption from '../text-components/Caption';
import date from '../../services/date';
import t from '../../text-strings';

interface Props {
  alias: string;
  startDate: string;
  endDate: string;
}

const ScheduledCutsAlert: FunctionComponent<Props> = ({ alias, startDate, endDate }: Props) => {
  const [open, setOpen] = useState(true);

  const containerClass = classnames(
    'bg-white w-full lg:w-11/12 max-w-screen-content lg:ml-auto p-8 rounded-sm border-l-4 border-blue mb-8', {
      hidden: !open,
    },
  );

  const closeAlert = (): void => setOpen(!open);

  const startDateFormat = date.format(startDate, 'ddd D/M, HH:mm');

  const endDateFormat = date.isSameDay(startDate, endDate)
    ? date.format(endDate, 'HH:mm')
    : date.format(endDate, 'ddd D/M, HH:mm');

  const schedule = `${startDateFormat} - ${endDateFormat}`;

  useEffect(() => setOpen(true), [alias]);

  return (
    <div className={containerClass}>
      <div className="flex">
        <div className="flex-1 pt-1 px-4">
          <p className="btn mb-4">
            {t('breakdowns.alerts.corte_programado.message', schedule)}
          </p>
          <Caption>{alias}</Caption>
        </div>
        <div className="min-w-12" onClick={closeAlert}>
          <Icons.Close color="blue" />
        </div>
      </div>
    </div>
  );
};

export default ScheduledCutsAlert;
