import { isEmpty } from 'lodash';
import _ from 'lodash/fp';

import date from '../../../services/date';
import { useStoreActions, useStoreState } from '../../../store/hooks';

const START_DAY = 16;
const END_DAY = 15;

export interface Consumption {
  periods: string[][];
  buildAPIDate: (currentDate: string) => string;
}

const getStartAndFinishDates = (): [Date, Date] => {
  const currentDay = parseInt(date.format(new Date(), 'DD'), 10);

  if (currentDay < START_DAY) {
    return [
      date.getDateFromNow(START_DAY, -1),
      date.addToCurrent(END_DAY - currentDay, 'days'),
    ];
  }

  return [
    date.addToCurrent(START_DAY - currentDay, 'days'),
    date.getDateFromNow(END_DAY, 1),
  ];
};

export const usePeriod = (defaultPeriod: string[]): [string[], (newPeriod: string[]) => void] => {
  const { setPeriod } = useStoreActions((actions) => actions.contract);
  const { period } = useStoreState((state) => state.contract);

  if (isEmpty(period)) setPeriod(defaultPeriod);

  const onChangePeriod = (newPeriod: string[]): void => {
    setPeriod(newPeriod);
  };

  return [period, onChangePeriod];
};

const useConsumption = (): Consumption => {
  const [startDateBase, endDateBase] = getStartAndFinishDates();

  const periods: string[][] = [];
  for (let i = 0; i < 12; i += 1) {
    periods.push([
      date.removeToCurrent(new Date(startDateBase), i, 'months'),
      date.removeToCurrent(new Date(endDateBase), i, 'months'),
    ]);
  }

  const buildAPIDate = _.flow(
    _.split('/'),
    _.reverse,
    _.join('-'),
  );

  return {
    periods,
    buildAPIDate,
  };
};

export default useConsumption;
