import { FormikErrors, FormikTouched } from 'formik';
import _ from 'lodash/fp';
import React, { FunctionComponent } from 'react';

import IDHelper from '../../../../components/forms/IDHelper';
import TextInput from '../../../../components/forms/TextInput';
import Title from '../../../../components/text-components/Title';
import useNavigation from '../../../../services/navigation';
import { routes } from '../../../../services/routing';
import useAuthSteps from '../../../../store/model/authorized/authorized';
import { AuthorizedForm } from '../../../../store/model/authorized/types';
import t from '../../../../text-strings';
import FormStepsNavigation from '../components/FormStepNavigation';
import FormStepScreen from '../components/FormStepScreen';

/* eslint-disable @typescript-eslint/no-explicit-any */
interface Props {
  values: AuthorizedForm;
  onChange: {
    (e: React.ChangeEvent<any>): void;
    <T_1 = string | React.ChangeEvent<any>>(field: T_1): T_1 extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
  onBlur: {
    (e: React.FocusEvent<any, Element>): void;
    <T = any>(fieldOrEvent: T): T extends string
      ? (e: any) => void
      : void;
  };
  touched: FormikTouched<AuthorizedForm>;
  errors: FormikErrors<AuthorizedForm>;
}
/* eslint-enable @typescript-eslint/no-explicit-any */

const Supply: FunctionComponent<Props> = (
  {
    values,
    onChange,
    onBlur,
    touched,
    errors,
  }: Props,
) => {
  const [step, setStep] = useAuthSteps();
  const navigate = useNavigation();

  return (
    <FormStepScreen
      onNext={() => setStep('id-card')}
      onBack={() => navigate(routes.profile)}
      current={_.isEqual(step, 'supply')}
    >
      <FormStepsNavigation blockClassNames={['bg-black', '', '', '']} />
      <Title className="mb-12">{t('authorized.supply')}</Title>
      <TextInput
        name="entitleUserNif"
        value={values.entitleUserNif}
        placeholder={t('models.authorized.attributes.nif')}
        onChange={onChange('entitleUserNif')}
        onBlur={onBlur('entitleUserNif')}
        touched={touched.entitleUserNif}
        errors={errors.entitleUserNif}
        icon={<IDHelper />}
        required
      />
      <TextInput
        name="cups"
        value={values.cups}
        placeholder={t('models.authorized.attributes.cups')}
        onChange={onChange('cups')}
        onBlur={onBlur('cups')}
        touched={touched.cups}
        errors={errors.cups}
        required
      />
    </FormStepScreen>
  );
};

export default Supply;
