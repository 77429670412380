import React, { FunctionComponent, ReactNode } from 'react';
import classnames from 'classnames';

import Icons from '../assets/icons';

export interface Props {
  isOpen: boolean;
  toggleOpen?: () => void;
  children: ReactNode;
  large?: boolean;
  unclosable?: boolean;
}

const Modal: FunctionComponent<Props> = ({
  isOpen,
  toggleOpen,
  children,
  large = false,
  unclosable = false,
}) => {
  const infoClass = classnames('info-modal', {
    flex: isOpen,
    hidden: !isOpen,
  });

  const innerInfoClass = classnames('info-inner-modal', { large });

  return (
    <div className={infoClass}>
      <div className={innerInfoClass}>
        <div
          className="info-inner-close"
          onClick={unclosable ? undefined : toggleOpen}
        >
          <Icons.Close color="blue" />
        </div>
        <div className="px-16 py-16 modal-wrap">{children}</div>
      </div>
    </div>
  );
};

export default Modal;
