import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import images from '../../../assets/images';
import Image from '../../../components/Image';
import { routes } from '../../../services/routing';
import t from '../../../text-strings';
import Title from '../../../components/text-components/Title';

interface Props {
  withTitle: boolean;
  title: string;
}

const BrandLogo: FunctionComponent<Props> = ({ withTitle, title }: Props) => {
  const brandClass = classnames({
    'hidden lg:block': withTitle,
    block: !withTitle,
  });
  const titleClass = classnames('header-title', {
    'lg:hidden': withTitle,
    hidden: !withTitle,
  });
  return (
    <>
      <Link to={routes.landing} className={brandClass}>
        <Image src={images.logo.white} alt={t('common.header.logo_alt')} className="max-w-40 lg:max-w-48" />
      </Link>
      <Title color="white" size="small" className={titleClass}>{title}</Title>
    </>
  );
};

export default BrandLogo;
