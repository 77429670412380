import React, { FunctionComponent } from 'react';

import Wrap from '../../components/containers/Wrap';
import PublicLayout from '../../layout/PublicLayout';
import useLegal from '../../services/use-legal';
import t from '../../text-strings';

const LegalWarning: FunctionComponent = () => {
  const info = useLegal('LegalWarning');

  return (
    <PublicLayout title={t('common.navigation.aviso_legal')}>
      <div id="legal">
        <Wrap size="wrap" className="py-16">
          {/* eslint-disable-next-line */}
          <div dangerouslySetInnerHTML={{ __html: `${info}` }} />
        </Wrap>
      </div>
    </PublicLayout>
  );
};

export default LegalWarning;
