import { action, Action } from 'easy-peasy';

import { FormStep } from './types';

export interface RegisterModel {
  step: FormStep;

  setStep: Action<RegisterModel, FormStep>;
}

const registerModel: RegisterModel = {
  step: 'permissions',

  setStep: action((state, step) => {
    state.step = step;
  }),
};

export default registerModel;
