import React, { ChangeEvent, FunctionComponent, useState } from 'react';
import _ from 'lodash/fp';

import Icons from '../../../../assets/icons';
import Title from '../../../../components/text-components/Title';
import Bodycopy from '../../../../components/text-components/Bodycopy';
import t from '../../../../text-strings';
import Toggle from '../../../../components/Toggle';
import FormResponse from '../../../../components/forms/FormResponse';

import IconCircled from './IconCircled';

const Calculator: FunctionComponent = () => {
  const [rustic, setRustic] = useState(false);
  const toggleRustic = (): void => setRustic(!rustic);
  const [power, setPower] = useState(0);
  const [error, setError] = useState(false);

  const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;

    if (_.gt(_.size(value), 5)) return;

    if (_.isEmpty(value)) {
      setPower(0);
      return;
    }

    const numberValue = parseFloat(_.replace(',', '.')(value));
    if (_.isNaN(numberValue)) {
      setPower(0);
      return;
    }

    if (_.gt(numberValue, 100)) {
      setPower(numberValue);
      setError(true);
      return;
    }

    setPower(numberValue);
    setError(false);
  };

  const extension = (power * 17.374714).toFixed(2);
  const acceso = (power * 19.703137).toFixed(2);
  const enganche = (9.044760).toFixed(2);

  return (
    <>
      <IconCircled>
        <Icons.Money />
      </IconCircled>
      <div className="xl:flex xl:justify-between">
        <div className="mb-12 xl:mb-0 xl:w-5/12 xl:max-w-192">
          <Title tag="h1" className="mb-12">{t('supply.procesos.cuanto')}</Title>
          <Bodycopy>{t('supply.procesos.cuanto_description')}</Bodycopy>
        </div>
        <div className="xl:w-1/2 xl:max-w-224">
          <Toggle
            variants={['Suelo urbano', 'Suelo rústico']}
            selected={rustic}
            onToggle={toggleRustic}
          />
          <div className="flex items-center my-12">
            <label htmlFor="power" className="mr-4">{t('supply.procesos.potencia')}</label>
            <input
              type="number"
              name="power"
              id="power"
              placeholder="2,4"
              onChange={onChange}
              step={0.00}
              value={`${_.isEqual(0, power) ? '' : power}`}
              className="bg-transparent border-b border-black block w-full max-w-24 text-right mr-1"
            />
            <Bodycopy>kW</Bodycopy>
          </div>
          {(error || rustic) && (
            <FormResponse
              message={rustic
                ? 'Para cualquier solicitud en suelo rústico es necesario revisar las instalaciones existentes ya que es posible que no este electrificada la zona, se le mandará un presupuesto junto a la propuesta previa que realizaremos una vez recibamos la solicitud de suministro.'
                : 'Se le mandará un presupuesto junto a la propuesta previa que realizaremos una vez recibamos la solicitud de suministro.'}
            />
          )}
          {!error && !rustic && (
            <div>
              <div className="flex mb-12">
                <div className="flex-1">
                  <p className="btn mb-2">{t('supply.procesos.extension')}</p>
                  <Bodycopy>{t('supply.procesos.extension_description')}</Bodycopy>
                </div>
                <div className="w-28">
                  <Title size="small" tag="h4">{t('supply.procesos.extension_price', _.replace('.', ',')(`${extension}`))}</Title>
                </div>
              </div>
              <div className="flex mb-12">
                <div className="flex-1">
                  <p className="btn mb-2">{t('supply.procesos.acceso')}</p>
                  <Bodycopy>{t('supply.procesos.acceso_description')}</Bodycopy>
                </div>
                <div className="w-28">
                  <Title size="small" tag="h4">{t('supply.procesos.acceso_price', _.replace('.', ',')(`${acceso}`))}</Title>
                </div>
              </div>
              <div className="flex mb-12">
                <div className="flex-1">
                  <p className="btn mb-2">{t('supply.procesos.enganche')}</p>
                  <Bodycopy>{t('supply.procesos.enganche_description')}</Bodycopy>
                </div>
                <div className="w-28">
                  <Title size="small" tag="h4">{t('supply.procesos.enganche_price', _.replace('.', ',')(`${enganche}`))}</Title>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Calculator;
