import React, { FunctionComponent } from 'react';

import Icons from '../../../../assets/icons';
import InternalLink from '../../../../components/InternalLink';
import Modal from '../../../../components/Modal';
import Bodycopy from '../../../../components/text-components/Bodycopy';
import { routes } from '../../../../services/routing';
import t from '../../../../text-strings';

interface Props {
  isOpen: boolean;
  close: () => void;
}

const InactiveUrbanSupply: FunctionComponent<Props> = ({ isOpen, close }: Props) => (
  <Modal isOpen={isOpen} toggleOpen={close}>
    <div className="mb-16">
      <Icons.Info color="blue" />
    </div>
    <Bodycopy className="mb-12">{t('supply.inactive_urban_supply')}</Bodycopy>
    <InternalLink
      to={routes.supply.supplyProcess}
      label={t('supply.punto_suministro')}
      className="text-blue"
      icon={<Icons.AddDocument color="blue" />}
    />
  </Modal>
);

export default InactiveUrbanSupply;
