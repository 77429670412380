const DISTRIBUTOR_ID = 1;
const POSTAL_CODE_REGEX = /^[0-9]+$/;
const CAU_CODE = 'CAU';
const CUPS_CODE = 'CUPS';
const SUPPLY_CODE = 'SAD';
const SELFCONSUMPTION_CODE = 'SARA';
const GENERATION_CODE = 'SARG';

const constants = {
  DISTRIBUTOR_ID,
  POSTAL_CODE_REGEX,
  CAU_CODE,
  CUPS_CODE,
  SUPPLY_CODE,
  SELFCONSUMPTION_CODE,
  GENERATION_CODE,
};

export default constants;
