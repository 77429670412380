import React, { FunctionComponent, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import _ from 'lodash/fp';

import AuthenticatedLayout from '../../../layout/AuthenticatedLayout';
import { useStoreActions } from '../../../store/hooks';
import t from '../../../text-strings';
import Form from '../../../components/forms/Form';
import { AuthorizedForm, AuthorizedParams } from '../../../store/model/authorized/types';
import formInit from '../../../forms/initial-values';
import schemas from '../../../forms/schemas';
import { TouchedDocs } from '../../../store/model';
import useHandleAuthorized from '../../../services/handle-authorized';
import helpers from '../../../forms/helpers';

import Supply from './steps/Supply';
import SideNavLink from './components/SideNavLink';
import IDCard from './steps/IDCard';
import Authorization from './steps/Authorization';
import Complete from './steps/Complete';

const AddAuthorized: FunctionComponent = () => {
  const { create } = useStoreActions((actions) => actions.authorized);
  const [message, success, handleResponse] = useHandleAuthorized();
  const { setStep } = useStoreActions((actions) => actions.authorized);
  const [touchedDocs, setTouchedDocs] = useState<TouchedDocs>({
    front: false,
    back: false,
    authorization: false,
  });

  const handleOnSubmit = async (values: AuthorizedForm): Promise<void> => {
    // @ts-ignore
    const authParams: AuthorizedParams = _.omit(['idFront', 'idBack', 'authorization'])(values);
    handleResponse(await create(authParams), values);
  };

  const {
    handleSubmit,
    values,
    handleChange,
    handleBlur,
    setFieldValue,
    touched,
    errors,
    isSubmitting,
    isValid,
  } = useFormik({
    initialValues: formInit.authorized,
    onSubmit: handleOnSubmit,
    validationSchema: schemas.authorized,
  });

  useEffect(() => setStep('supply'), []);

  const onBlurDocs = (name: 'front' | 'back' | 'authorization'): void => {
    const newTouched = touchedDocs;
    newTouched[name] = true;
    setTouchedDocs(newTouched);
  };

  const isCompleted = helpers.isAuthCompleted(values);
  const hasErrors = helpers.hasAuthErrors(errors, touched, touchedDocs);

  return (
    <AuthenticatedLayout title={t('authorized.title')} onlyDesktop sidebar={false}>
      <Form onSubmit={handleSubmit} className="flex-1 lg:flex">
        <div className="lg:w-7/10 lg:pt-20">
          <Supply
            values={values}
            onChange={handleChange}
            onBlur={handleBlur}
            touched={touched}
            errors={errors}
          />
          <IDCard
            values={values}
            onBlur={onBlurDocs}
            touched={touchedDocs}
            errors={errors}
            setFieldValue={setFieldValue}
          />
          <Authorization
            values={values}
            onBlur={onBlurDocs}
            touched={touchedDocs}
            errors={errors}
            setFieldValue={setFieldValue}
            message={message}
            success={success}
            disabled={isSubmitting || !isValid}
          />
          <Complete />
        </div>
        <div className="hidden w-1/3 lg:flex flex-col bg-white relative pt-72 pl-32">
          <div className="bg-white absolute top-0 left-full w-screen-full h-full" />
          <SideNavLink
            label={t('authorized.supply')}
            currentStep="supply"
            isComplete={isCompleted.suply}
            hasErrors={hasErrors.suply}
          />
          <SideNavLink
            label={t('authorized.id_card')}
            currentStep="id-card"
            isComplete={isCompleted.idCard}
            hasErrors={hasErrors.idCard}
          />
          <SideNavLink
            label={t('authorized.auth')}
            currentStep="authorization"
            isComplete={isCompleted.authorization}
            hasErrors={hasErrors.authorization}
          />
        </div>
      </Form>
    </AuthenticatedLayout>
  );
};

export default AddAuthorized;
