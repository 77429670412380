import { useFormik } from 'formik';
import React, {
  ChangeEvent, FunctionComponent, useState, useEffect,
} from 'react';

import Icons from '../../assets/icons';
import SubmitButton from '../../components/buttons/SubmitButton';
import Wrap from '../../components/containers/Wrap';
import CheckboxInput from '../../components/forms/CheckboxInput';
import Form from '../../components/forms/Form';
import FormResponse from '../../components/forms/FormResponse';
import IDHelper from '../../components/forms/IDHelper';
import TextInput from '../../components/forms/TextInput';
import InternalLink from '../../components/InternalLink';
import Bodycopy from '../../components/text-components/Bodycopy';
import Subtitle from '../../components/text-components/Subtitle';
import Title from '../../components/text-components/Title';
import ActionButton from '../../components/buttons/ActionButton';
import Modal from '../../components/Modal';
import formInit from '../../forms/initial-values';
import schemas from '../../forms/schemas';
import AuthenticatedLayout from '../../layout/AuthenticatedLayout';
import useNavigation from '../../services/navigation';
import { routes } from '../../services/routing';
import { useStoreActions, useStoreState } from '../../store/hooks';
import { EditUserParams, GetUserParams, UserAttributes } from '../../store/model/user/types';
import t from '../../text-strings';

const EditProfile: FunctionComponent = () => {
  const {
    saveuserchanges,
    getuserbynif,
  } = useStoreActions((actions) => actions.user);
  const { init } = useStoreActions((actions) => actions.session);
  const { session } = useStoreState((state) => state.session);
  const [message, setMessage] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigation();

  const toggleModal = (): void => setIsOpen(!isOpen);

  const updateSession = async (params: GetUserParams): Promise<void> => {
    const response: UserAttributes = await getuserbynif(params);
    if (typeof response === 'string') {
      setMessage(response);
      return;
    }
    init({
      id: response.id,
      name: response.nameClient,
      nif: response.nifClient,
      address: response.addressClient,
      locality: response.localityClient,
      province: session.get('province'),
      provinceId: response.provinceClientId,
      pc: response.pcClient,
      movilPrefix: response.movilePrefixClient,
      movil: response.movilClient,
      email: response.emailClient,
      distributorId: response.distributorId,
      distributorIdentity: response.distributor,
      userTypeId: response.userTypeId,
      super: session.get('super'),
      validated: session.get('validated'),
      permissions: session.get('permissions'),
      token: session.get('token'),
      notifyNonPaymentCuts: response.notifyNonPaymentCuts,
      notifyByEmail: response.notifyByEmail,
      notifyFaults: response.notifyFaults,
      notifyScheduledCuts: response.notifyScheduledCuts,
    });
    navigate(routes.profile);
  };

  const handleOnSubmit = async (params: EditUserParams): Promise<void> => {
    const response = await saveuserchanges(params);
    if (typeof response === 'boolean') {
      const { nif, distributorId } = params;
      await updateSession({ nif, distributorId });
      return;
    }
    setMessage(response);
  };

  const {
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
    isSubmitting,
    isValid,
  } = useFormik({
    initialValues: formInit.editUser(session),
    onSubmit: handleOnSubmit,
    validationSchema: schemas.editUser,
  });

  useEffect(() => {
    if (!values.notifyFaults && !values.notifyNonPaymentCuts && !values.notifyScheduledCuts) {
      setFieldValue('notifyByEmail', false);
    }
  }, [values.notifyFaults, values.notifyNonPaymentCuts, values.notifyScheduledCuts]);

  const handleEmailNotifications = async (e: ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = e.target;

    await setFieldValue(`${id}`, checked);

    if (checked) {
      await setFieldValue('notifyByEmail', true);
    }
  };

  return (
    <AuthenticatedLayout title={t('profile.editar')}>
      <Wrap size="wrap-authenticated" className="pt-8 pb-16 lg:pt-16 lg:pb-32">
        <InternalLink
          label={t('profile.back')}
          to={routes.profile}
          icon={<Icons.Back />}
          className="mb-8 lg:mb-16"
        />
        <Title className="mb-8">{t('profile.editar')}</Title>
        <Form onSubmit={handleSubmit}>
          <TextInput
            name="nif"
            placeholder={t('models.user.attributes.nif')}
            value={values.nif}
            onChange={handleChange('nif')}
            touched={touched.nif}
            errors={errors.nif}
            icon={<IDHelper />}
            required
          />
          <TextInput
            name="name"
            placeholder={t('models.user.attributes.name')}
            value={values.name}
            onChange={handleChange('name')}
            touched={touched.name}
            errors={errors.name}
            required
          />
          <TextInput
            name="email"
            placeholder={t('models.user.attributes.email')}
            value={values.email}
            onChange={handleChange('email')}
            touched={touched.email}
            errors={errors.email}
            required
          />
          <TextInput
            name="address"
            placeholder={t('models.user.attributes.address')}
            value={values.address}
            onChange={handleChange('address')}
            touched={touched.address}
            errors={errors.address}
            required
          />
          <TextInput
            name="pc"
            placeholder={t('models.user.attributes.pc')}
            value={values.pc}
            onChange={handleChange('pc')}
            touched={touched.pc}
            errors={errors.pc}
            required
          />
          <TextInput
            name="location"
            placeholder={t('models.user.attributes.locality')}
            value={values.location}
            onChange={handleChange('location')}
            touched={touched.location}
            errors={errors.location}
            required
          />
          <TextInput
            name="movil"
            placeholder={t('models.user.attributes.movil')}
            value={values.movil}
            onChange={handleChange('movil')}
            touched={touched.movil}
            errors={errors.movil}
            required
          />
          <Title className="mt-8 mb-4">{t('profile.notificaciones')}</Title>
          <div className="flex mb-12">
            <Subtitle>{t('profile.que_notificaciones')}</Subtitle>
            <ActionButton
              type="link"
              label=""
              onClick={toggleModal}
              icon={<Icons.Help color="blue" />}
              right
            />
            <Modal isOpen={isOpen} toggleOpen={toggleModal}>
              <p className="btn mb-8">{t('profile.title_info_notificaciones')}</p>
              <Bodycopy className="mb-4">{t('profile.body_info_notificaciones')}</Bodycopy>
            </Modal>
          </div>
          <div className="flex flex-col w-full max-w-240 justify-between items-center lg:flex-row">
            <CheckboxInput
              name="notifyNonPaymentCuts"
              value={values.notifyNonPaymentCuts}
              onChange={handleEmailNotifications}
            >
              <Bodycopy>{t('models.user.attributes.notifyNonPaymentCuts')}</Bodycopy>
            </CheckboxInput>
            <CheckboxInput
              name="notifyScheduledCuts"
              value={values.notifyScheduledCuts}
              onChange={handleEmailNotifications}
            >
              <Bodycopy>{t('models.user.attributes.notifyScheduledCuts')}</Bodycopy>
            </CheckboxInput>
            <CheckboxInput
              name="notifyFaults"
              value={values.notifyFaults}
              onChange={handleEmailNotifications}
            >
              <Bodycopy>{t('models.user.attributes.notifyFaults')}</Bodycopy>
            </CheckboxInput>
          </div>
          <FormResponse message={message} className="mb-8" />
          <SubmitButton
            disabled={isSubmitting || !isValid}
            label={t('profile.send')}
          />
        </Form>
      </Wrap>
    </AuthenticatedLayout>
  );
};

export default EditProfile;
