import React, { FunctionComponent } from 'react';

import images from '../assets/images';

import Image from './Image';

const EUFundedFooter: FunctionComponent = () => (
  <div className="block lg:flex lg:align-middle items-center mt-24 justify-between lg:mt-0">
    <p className="text-xs">Portal financiado por la Unión Europea - NextGenerationEU</p>
    <div className="flex my-6 gap-24">
      <Image src={images.funded.euFund} className="h-16 w-auto" />
      <Image src={images.funded.projectPlan} className="h-16 w-auto" />
    </div>
  </div>
);

export default EUFundedFooter;
