import _ from 'lodash/fp';
import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  ResponsiveContainer,
  Bar,
  XAxis,
  YAxis,
  Line,
  ComposedChart,
  Label,
  Tooltip,
  Cell,
  // eslint-disable-next-line
} from "recharts";

import Icons from '../../../../assets/icons';
import Accordion from '../../../../components/Accordion';
import ActionButton from '../../../../components/buttons/ActionButton';
import Title from '../../../../components/text-components/Title';
import t from '../../../../text-strings';
import Modal from '../../../../components/Modal';
import Bodycopy from '../../../../components/text-components/Bodycopy';
import { Contract } from '../../../../store/model/contract/contract';
import { ConsumptionParams } from '../../../../store/model/contract/types';
import { useStoreActions, useStoreState } from '../../../../store/hooks';
import date from '../../../../services/date';
import { FAQAttributes } from '../../../../store/model/contact/types';
import useFAQs from '../../../../services/use-faq';
import { Any } from '../../../../store/model/global';
import helpers from '../../../../services/helpers';

interface Props {
  contract: Contract;
}

const buildDateTooltip = (dateString: string): string => {
  if (!dateString) return 'Sin dato';
  if (date.format(dateString, 'DD') === '01' && date.format(dateString, 'HH:mm') === '00:00') {
    return 'Sin dato';
  }
  return date.format(dateString, 'DD MMM YYYY');
};

const buildTimeTooltip = (dateString: string): string => {
  if (!dateString) return 'Sin dato';
  if (date.format(dateString, 'DD') === '01' && date.format(dateString, 'HH:mm') === '00:00') {
    return 'Sin dato';
  }
  return date.format(dateString, 'HH:mm');
};

const BarChartTooltip: FunctionComponent = ({ active, payload }: Any) => {
  if (active && payload && payload.length) {
    return (
      <div className="flex flex-col p-6 bg-white border border-black rounded-sm">
        <div className="flex items-center mb-2">
          <p className="text-sm font-semibold">Potencia máx.</p>
        </div>
        {
          (buildDateTooltip(payload[0].payload.maxDate) !== 'Sin dato')
          && (
            <div className="flex items-center mb-2">
              <p className="text-xs">
                {buildDateTooltip(payload[0].payload.maxDate)}
              </p>
            </div>
          )
        }
        {
          (buildTimeTooltip(payload[0].payload.maxDate) !== 'Sin dato')
          && (
            <div className="flex items-center mb-2">
              <p className="text-xs">
                {buildTimeTooltip(payload[0].payload.maxDate)}
              </p>
            </div>
          )
        }

        <div className="flex items-center mb-2">
          <p className="text-xs">
            {`${helpers.toStringDecimal(payload[0].payload.mp, 2)} kW`}
          </p>
        </div>
      </div>
    );
  }

  return null;
};

const TIME = 'T00:00:00.000Z';
// eslint-disable-next-line
const roundNumber = (number: any, decimals: number): number => {
  const rounder = 10 ** decimals;
  return Math.round((number + Number.EPSILON) * rounder) / rounder;
};

const Power: FunctionComponent<Props> = ({ contract }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const { getpower } = useStoreActions((actions) => actions.contract);
  const { power } = useStoreState((actions) => actions.contract);
  const [isLoading, setIsLoading] = useState(true);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [loading, faqs] = useFAQs('Consumo_potencia');
  const highPower = contract.highPower();

  const calculatePowerDates = () => {
    const cur = new Date();
    cur.setDate(0); // now cur is last day of PAST month

    const prev = new Date();
    prev.setFullYear(prev.getFullYear() - 1);
    prev.setDate(1); // now prev is first day of current month in past year

    return {
      startDate: prev,
      endDate: cur,
    };
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const dates = calculatePowerDates();
      const consumptionParams: ConsumptionParams = {
        cup: contract.get('cups'),
        startDate: `${date.format(dates.startDate, 'YYYY-MM-DD')}${TIME}`,
        endDate: `${date.format(dates.endDate, 'YYYY-MM-DD')}${TIME}`,
      };
      const result = getpower(consumptionParams);
      setIsLoading(!result);
    })();
  }, [contract]);

  if (isLoading) {
    return null;
  }

  const calculateData = () => {
    interface chartData {
      name: string;
      mp: number;
      hp: number;
      maxDate: string;
    }
    const arr: chartData[] = [];
    const calculatedDates = calculatePowerDates();
    for (let month = 0; month <= 11; month += 1) {
      const arrDate = new Date(calculatedDates.startDate);
      arrDate.setMonth(arrDate.getMonth() + month);
      arr.push({
        name: `${date.format(arrDate, 'MMM')[0].toUpperCase()}${date.format(arrDate, 'MMM').substring(1)}`,
        mp: 0,
        hp: highPower,
        maxDate: '',
      });
    }
    return arr;
  };
  const data = calculateData();

  power.powerDays.forEach((element, index) => {
    data[index].mp += element.power;
    data[index].maxDate += element.date;
  });

  const toggleOpen = (): void => setIsOpen(!isOpen);

  const setPosition = (id: string): number => document.getElementById(id)?.offsetTop || 0;

  const goToSection = (id: string): void => window.scrollTo({ top: setPosition(id), behavior: 'smooth' });

  function parseLabelY(label: string) {
    const numberedLabel = Number(label);

    if (numberedLabel === 0) return label;

    if (numberedLabel < 1) return `${roundNumber(numberedLabel, 1)}`;

    return `${roundNumber(numberedLabel, 0)}`;
  }

  const handleClick = (dataClicked: any, index: number) => {
    if (activeIndex === index) {
      setActiveIndex(-1);
    } else {
      setActiveIndex(index);
    }
  };

  // @ts-ignore
  const showAdvice = _.flow(
    _.map('power'),
    _.max,
    _.gt(_, highPower),
  )(power.powerDays);

  return (
    <>
      {showAdvice && (
        <div className="flex flex-col items-center w-full mb-20 text-center bg-white border rounded-sm border-orange-light lg:flex-row lg:text-left">
          <div className="">
            <Icons.Warning height={24} width={24} className="mt-12 lg:m-16" />
          </div>
          <div className="mx-24 mt-12 lg:my-16 lg:ml-0 lg:flex lg:flex-col lg:flex-grow">
            <div className="font-semibold lg:text-md">
              {t('consumption.card_power.title')}
            </div>
          </div>
          <ActionButton
            label={t('consumption.card_power.label')}
            type="primary"
            onClick={() => goToSection('reducir')}
            className="my-12 lg:mr-16"
          />
        </div>
      )}
      <div className="lg:border-b lg:border-gray-400">
        <Title tag="h2" size="small" className="mb-12">
          {t('consumption.power.detalle')}
        </Title>
        <ActionButton
          label={t('consumption.power.real_contratada')}
          onClick={toggleOpen}
          icon={<Icons.Info color="blue" />}
          right
          type="link"
          className="mb-12"
        />
        <Modal isOpen={isOpen} toggleOpen={toggleOpen}>
          <p className="mb-12 ml-8 btn">{t('consumption.power.modal.title')}</p>
          <Bodycopy className="mb-8 ml-8 mr-8">
            {t('consumption.power.modal.paragraph_1')}
          </Bodycopy>
          <Bodycopy className="mb-8 ml-8 mr-8">
            {t('consumption.power.modal.paragraph_1')}
          </Bodycopy>
        </Modal>
        <div className="w-full h-auto">
          <div className="relative bg-white rounded-sm pb-128">
            <ResponsiveContainer width="100%" className="absolute inset-0">
              <ComposedChart
                data={data}
                margin={{
                  top: 70,
                  left: 15,
                  bottom: 10,
                  right: 5,
                }}
              >
                <XAxis
                  dataKey="name"
                  axisLine={false}
                  tickLine={false}
                  tick={{ fill: '#A6A6A6' }}
                />
                <YAxis
                  axisLine={false}
                  tickLine={false}
                  // eslint-disable-next-line
                  tickFormatter={parseLabelY}
                  tick={{ fill: '#A6A6A6' }}
                  tickMargin={0}
                >
                  <Label
                    position="top"
                    fill="#A6A6A6"
                    offset={35}
                    fontSize={15}
                    fontWeight="500"
                    style={{ textAnchor: 'start' }}
                  >
                    kW/Meses
                  </Label>
                </YAxis>
                <Tooltip content={<BarChartTooltip />} cursor={false} />
                <Bar
                  dataKey="mp"
                  fill="#FFDA00"
                  onClick={handleClick}
                  barSize={10}
                  radius={[5, 5, 5, 5]}
                >
                  {data.map((entry, index) => (
                    <Cell
                      cursor="pointer"
                      fill={
                        activeIndex === -1
                          || (activeIndex > -1 && index === activeIndex)
                          ? '#FFDA00'
                          : '#D4D4D4'
                      }
                      // eslint-disable-next-line
                      key={`cell-${index}`}
                    />
                  ))}
                </Bar>
                <Line
                  type="monotone"
                  dot={false}
                  dataKey="hp"
                  strokeDasharray="4 3"
                  strokeWidth={2}
                  stroke="#FB3737"
                />
              </ComposedChart>
            </ResponsiveContainer>
          </div>
          <div className="flex items-center">
            <div className="w-6 h-6 mr-4 rounded bg-yellow min-h-6" />
            <div className="mr-6 text-sm font-semibold lg:mr-18">
              {t('consumption.power.potencia_maxima')}
            </div>
            <div className="w-6 h-6 mb-4 mr-4 border-b-2 border-dotted border-red min-h-6" />
            <div className="pt-8">
              <p className="text-sm font-semibold">
                {t('consumption.power.potencia_contratada')}
              </p>
              <p className="text-xs text-gray-500">
                {highPower}
                kW
              </p>
            </div>
          </div>
        </div>
        <div id="reducir" className="h-screen pt-20">
          <Title tag="h2" size="small" className="mb-12">
            {t('consumption.power.reducir')}
          </Title>
          {!loading && (
            <div className="mb-40">
              {_.map((faq: FAQAttributes) => (
                <Accordion
                  key={faq.questionId}
                  title={faq.title}
                  text={faq.text}
                />
              ))(faqs)}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Power;
