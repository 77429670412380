import { thunk, Thunk } from 'easy-peasy';

import { handleAxiosResponse, isValidResponse, handleRegister } from '../../store-helpers';

import {
  UserParams, ValidationParams, EditUserParams, GetUserParams, RollbarUser,
} from './types';

export interface UserModel {
  create: Thunk<UserModel, UserParams>;
  validate: Thunk<UserModel, ValidationParams>;
  saveuserchanges: Thunk<UserModel, EditUserParams>;
  getuserbynif: Thunk<UserModel, GetUserParams>;
  rollbackcreateuser: Thunk<UserModel, RollbarUser>;
}

const userModel: UserModel = {
  create: thunk(async (_actions, params, { injections }) => {
    const { userService } = injections;
    return handleRegister(await userService.create(params));
  }),

  validate: thunk(async (_actions, params, { injections }) => {
    const { userService } = injections;
    return handleAxiosResponse(await userService.validate(params));
  }),

  saveuserchanges: thunk(async (_actions, params, { injections }) => {
    const { userService } = injections;
    return handleAxiosResponse(await userService.saveuserchanges(params));
  }),

  getuserbynif: thunk(async (_actions, params, { injections }) => {
    const { userService } = injections;
    const response = await userService.getuserbynif(params);
    if (isValidResponse(response)) {
      return response.data;
    }
    return response.data.message;
  }),

  rollbackcreateuser: thunk(async (_actions, params, { injections }) => {
    const { userService } = injections;
    return handleAxiosResponse(await userService.rollbackcreateuser(params));
  }),
};

export default userModel;
