import React, { FunctionComponent, ReactNode } from 'react';

import classNames from '../../services/classNames';

interface Props {
  size: 'wrap' | 'wrap-thin' | 'wrap-authenticated';
  children: ReactNode;
  className?: string;
}

const Wrap: FunctionComponent<Props> = ({ size, children, className = '' }: Props) => (
  <div className={classNames(size, className)}>
    {children}
  </div>
);

export default Wrap;
