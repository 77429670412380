import { AxiosResponse } from 'axios';

import {
  EditUserParams,
  GetUserParams,
  RollbarUser,
  UserParams,
  ValidationParams,
} from '../../store/model/user/types';
import {
  axiosGet, axiosPost, queryParams, setURL,
} from '../axios';
import { RaffleParams } from '../../store/model/raffle/types';

export const create = async (params: UserParams): Promise<AxiosResponse> => (
  axiosPost(setURL('/Login', '/createuser'), params)
);

export const validate = async (params: ValidationParams): Promise<AxiosResponse> => (
  axiosPost(setURL('/Login', '/validateuser?', queryParams(params)))
);

export const saveuserchanges = async (params: EditUserParams): Promise<AxiosResponse> => (
  axiosPost(setURL('/Request', '/saveuserchanges'), params)
);

export const getuserbynif = async (params: GetUserParams): Promise<AxiosResponse> => (
  axiosGet(setURL('/Request', '/getuserbynif?', queryParams(params)))
);

export const rollbackcreateuser = async (params: RollbarUser): Promise<AxiosResponse> => (
  axiosPost(setURL('/Login', '/rollbackcreateuser'), params)
);

export const registerToRaffle = async (params: RaffleParams): Promise<AxiosResponse> => (
  axiosPost(setURL('/Base', '/raffle'), params)
);
