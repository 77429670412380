import React, { FunctionComponent } from 'react';

import Icons from '../../assets/icons';
import LinkCard from '../../components/cards/LinkCard';
import Title from '../../components/text-components/Title';
import PublicLayout from '../../layout/PublicLayout';
import { routes } from '../../services/routing';
import t from '../../text-strings';
import Content from '../../layout/components/Content';
import Bodycopy from '../../components/text-components/Bodycopy';

const Generation: FunctionComponent = () => (
  <PublicLayout title={t('generation.title')}>
    <div className="wrap py-16 lg:flex lg:justify-center">
      <Content>
        <Title tag="h1" className="mb-12">{t('generation.tramites_frecuentes')}</Title>
        <div className="lg:flex lg:flex-wrap lg:justify-between mb-12">
          <LinkCard
            label={t('generation.acceso_conexion')}
            to={routes.requests.generation}
            icon={<Icons.Bolt color="white" />}
            className="mb-4 lg:w-200 hd:w-224 lg:mb-12"
          />
          <LinkCard
            label={t('generation.capacidades_nodales')}
            to={routes.nodalCapacities}
            icon={<Icons.Settings color="white" />}
            className="mb-4 lg:w-200 hd:w-224 lg:mb-12"
          />
        </div>
        <div>
          <Title className="mb-12">{t('generation.conceptos')}</Title>
          <Title size="small" tag="h4" className="mb-2">{t('generation.items.1.title')}</Title>
          <Bodycopy className="mb-12">{t('generation.items.1.description')}</Bodycopy>
          <Title size="small" tag="h4" className="mb-2">{t('generation.items.2.title')}</Title>
          <Bodycopy className="mb-12">{t('generation.items.2.description')}</Bodycopy>
          <Title size="small" tag="h4" className="mb-2">{t('generation.items.3.title')}</Title>
          <Bodycopy className="mb-12">{t('generation.items.3.description')}</Bodycopy>
        </div>
      </Content>
    </div>
  </PublicLayout>
);

export default Generation;
