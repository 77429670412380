import React, {
  ChangeEvent,
  FocusEvent,
  FunctionComponent,
  ReactNode,
} from 'react';
import classnames from 'classnames';

import classNames from '../../services/classNames';

import FormGroup from './components/FormGroup';
import InputError from './components/InputError';
import Input from './components/Input';
import InputLabel from './components/InputLabel';

export interface Props {
  name: string;
  value: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  children: ReactNode;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  touched?: boolean;
  errors?: string;
  readonly?: boolean;
  disabled?: boolean;
  containerClassName?: string;
  inputClassName?: string;
}

const CheckboxInput: FunctionComponent<Props> = ({
  name,
  value,
  onChange,
  children,
  onBlur = undefined,
  touched = false,
  errors = '',
  readonly = false,
  disabled = false,
  containerClassName = '',
  inputClassName = '',
}: Props) => {
  const innerInputLabelClass = classnames({
    'bg-yellow': value,
    'bg-transparent': !value,
  });

  return (
    <FormGroup className={classNames('checkbox-input', containerClassName)}>
      <div className="flex items-center">
        <Input
          type="checkbox"
          name={name}
          checked={value}
          className="hidden"
          readonly={readonly}
          disabled={disabled}
          onChange={onChange}
          onBlur={onBlur}
        />
        <InputLabel
          name={name}
          label={<span className={innerInputLabelClass} />}
          className={classNames('checkbox', inputClassName)}
        />
        <InputLabel name={name} label={children} className="inline" />
      </div>
      <InputError message={touched ? errors : ''} />
    </FormGroup>
  );
};

export default CheckboxInput;
