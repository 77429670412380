import React, { FunctionComponent, useState } from 'react';

import Icons from '../../assets/icons';
import t from '../../text-strings';
import Modal from '../Modal';
import Bodycopy from '../text-components/Bodycopy';
import Title from '../text-components/Title';

const IDHelper: FunctionComponent = () => {
  const [open, setOpen] = useState(false);

  const toggleOpen = (): void => setOpen(!open);

  return (
    <div className="absolute top-10 right-0 cursor-pointer" onClick={toggleOpen}>
      <Icons.Help color="blue" />
      <Modal isOpen={open} toggleOpen={toggleOpen}>
        <div className="px-4 py-2">
          <Title size="small" className="mb-16">{t('common.id_format')}</Title>
          <Bodycopy className="mb-4">{t('common.dni_ej')}</Bodycopy>
          <Title className="mb-16">{t('common.dni_number')}</Title>
          <Bodycopy className="mb-4">{t('common.nie_ej')}</Bodycopy>
          <Title className="mb-16">{t('common.nie_number')}</Title>
          <Bodycopy className="mb-4">{t('common.cif_ej')}</Bodycopy>
          <Title className="mb-16">{t('common.cif_number')}</Title>
        </div>
      </Modal>
    </div>
  );
};

export default IDHelper;
