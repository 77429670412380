import React, { FunctionComponent } from 'react';

import { AppColors } from '../../styles';

interface Props {
  color?: AppColors;
  width?: number;
  height?: number;
  className?: string;
}

const Growth: FunctionComponent<Props> = (
  {
    color = 'black',
    width = 12,
    height = 12,
    className = '',
  }: Props,
) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className={`w-${width} h-${height} text-${color} stroke-current ${className}`}>
    <path d="M23 6L13.5 15.5L8.5 10.5L1 18" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill="transparent" />
    <path d="M17 6H23V12" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill="transparent" />
  </svg>
);

export default Growth;
