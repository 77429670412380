import { AxiosResponse } from 'axios';

import { ChangePasswordParams, ResetPasswordParams } from '../../store/model/passwords/types';
import { axiosPost, setURL } from '../axios';

export const reset = async (params: ResetPasswordParams): Promise<AxiosResponse> => (
  axiosPost(setURL('/Login', '/resetpassword'), params)
);

export const change = async (params: ChangePasswordParams): Promise<AxiosResponse> => (
  axiosPost(setURL('/Login', '/changepassword'), params)
);
