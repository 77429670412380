import React, { ChangeEvent, FocusEvent, FunctionComponent } from 'react';
import classnames from 'classnames';
import _ from 'lodash/fp';

import classNames from '../../services/classNames';

import FormGroup from './components/FormGroup';
import InputLabel from './components/InputLabel';
import Input from './components/Input';
import InputError from './components/InputError';

export interface Props {
  name: string;
  value: string;
  placeholder: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  touched?: boolean;
  errors?: string;
  readonly?: boolean;
  disabled?: boolean;
  required?: boolean;
  containerClassName?: string;
  labelClassName?: string;
  inputClassName?: string;
}

const EmailInput: FunctionComponent<Props> = (
  {
    name,
    value,
    placeholder,
    onChange,
    onBlur = undefined,
    touched = false,
    errors = '',
    readonly = false,
    disabled = false,
    required = false,
    containerClassName = '',
    labelClassName = '',
    inputClassName = '',
  }: Props,
) => {
  const labelClass = classnames(classNames('block', labelClassName), {
    'opacity-100': !_.isEmpty(value),
    'opacity-0': _.isEmpty(value),
  });

  return (
    <FormGroup className={classNames('email-input', containerClassName)}>
      <InputLabel
        name={name}
        label={placeholder}
        className={labelClass}
        required={required}
      />
      <Input
        type="email"
        name={name}
        placeholder={placeholder}
        className={classNames('email', inputClassName)}
        value={value}
        readonly={readonly}
        disabled={disabled}
        onChange={onChange}
        onBlur={onBlur}
        required={required}
      />
      <InputError message={touched ? errors : ''} />
    </FormGroup>
  );
};

export default EmailInput;
