import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash/fp';

import t from '../../../../text-strings';
import Icons from '../../../../assets/icons';
import Caption from '../../../../components/text-components/Caption';
import Title from '../../../../components/text-components/Title';
import { routes } from '../../../../services/routing';
import { Request } from '../../../../store/model/requests/types';
import Tag from '../../../../components/Tag';
import date from '../../../../services/date';
import LinkButton from '../../../../components/buttons/LinkButton';
import InternalLink from '../../../../components/InternalLink';
import { Any } from '../../../../store/model/global';

interface Props {
  request: Request;
}

const RequestCard: FunctionComponent<Props> = ({ request }: Props) => (
  <Link
    to={routes.requests.history(request.code)}
    className="relative block w-full p-8 mb-16 rounded-sm shadow-blur lg:flex lg:bg-white max-w-168"
  >
    <div className="flex flex-col justify-between">
      <div className="pb-8 mb-6 border-b border-gray-300 lg:border-0 lg:pt-4">
        <div className="flex items-center justify-between w-full lg:w-152">
          <Title size="small" className="mb-4 pr-80">
            {request.name}
          </Title>
          <Icons.AngleRight color="blue" />
        </div>
        <Caption className="text-sm font-semibold truncate" color="gray-500">
          {t('requests.code_solicitud')}
          :
          {request.code}
        </Caption>
        <Caption
          className="text-sm font-semibold truncate lg:mt-4"
          color="gray-500"
        >
          {request.address}
        </Caption>
      </div>
      <div>
        <div className="hidden h-1 bg-gray-300 lg:mb-8 lg:flex" />
        <div className="flex lg:mt-1 h-max">
          <Icons.Calendar className="mr-6" />
          <div className="">
            <p className="btn">{request.daysLimit}</p>
            <Caption color="gray-500">
              {date.format(request.limitDate, 'DD MMMM')}
            </Caption>
          </div>
        </div>
      </div>
      <Tag
        label={request.stateName}
        type="suspendida"
        className="absolute z-40 text-sm font-semibold -top-6 right-8"
      />
    </div>
  </Link>
);

const RequestList: FunctionComponent<Any> = ({ requests }: Any) => {
  const hasRequests = requests.length > 0;
  const hasMoreHidden = requests.length > 5;

  const slicedRequests = requests.slice(0, 5);

  return (
    <div>
      {!hasRequests && (
        <p className="mt-24 mb-24 text-sm text-center text-gray-500">
          No tienes ninguna solicitud
        </p>
      )}
      {hasRequests && (
        <div className="pt-6 lg:flex lg:flex-row lg:overflow-x-scroll gap-x-10">
          {_.map((request: Request) => (
            <RequestCard key={request.code} request={request} />
          ))(slicedRequests)}
          {hasMoreHidden && (
            <div className="relative items-center hidden p-8 mb-16 rounded-sm lg:flex lg:flex-col min-w-156 max-w-168 shadow-blur lg:bg-white">
              <p className="px-12 pt-24 pb-16 text-center">
                ¿Tienes una solicitud activa o finalizada que no encuentras?
              </p>
              <div className="">
                <LinkButton
                  type="secondary"
                  to={routes.requests.all}
                  label="Ver todas"
                />
              </div>
            </div>
          )}
          {hasMoreHidden && (
            <div className="flex justify-center">
              <InternalLink
                to={routes.requests.all}
                label="Ver todas"
                icon={(
                  <Icons.AngleRight
                    height={18}
                    width={18}
                    color="blue"
                    className="pt-6"
                  />
                )}
                right
                className="mb-24 lg:hidden text-blue"
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default RequestList;
