import React, { ChangeEvent, FocusEvent, FunctionComponent } from 'react';
import classnames from 'classnames';

import Bodycopy from '../../text-components/Bodycopy';

import Input from './Input';
import InputLabel from './InputLabel';

interface Props {
  name: string;
  label: string;
  value: number;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  checked: boolean;
  readonly: boolean;
  disabled: boolean;
  no_break_line: boolean;
}

const Radio: FunctionComponent<Props> = (
  {
    name,
    label,
    value,
    onChange,
    onBlur,
    checked,
    readonly,
    disabled,
    no_break_line,
  }: Props,
) => {
  const innerLabelClass = classnames({ 'bg-yellow': checked, 'bg-transparent': !checked });
  const className = no_break_line ? 'radio-input inlineflex mg-rg-35' : 'radio-input';

  return (
    <div className={className}>
      <Input
        type="radio"
        name={name}
        id={label}
        className="hidden"
        value={`${value}`}
        readonly={readonly}
        disabled={disabled}
        onChange={onChange}
        onBlur={onBlur}
        checked={checked}
      />
      <InputLabel
        name={label}
        label={<span className={innerLabelClass} />}
        className="radio"
      />
      <InputLabel
        name={label}
        label={<Bodycopy>{label}</Bodycopy>}
        className="block"
      />
    </div>
  );
};

export default Radio;
