import _ from 'lodash/fp';

import t from '../../../text-strings';

import {
  ContractAttributes, EnergyParams, PowerParams,
} from './types';

const state = _.cond([
  [_.isEqual('A'), _.constant('Activa')],
  [_.isEqual('S'), _.constant('Supendida')],
  [_.isEqual('D'), _.constant('Desconectado')],
  [_.isEqual('M'), _.constant('Mantenimiento')],
]);

export interface Contract {
  get: <T>(value: string) => T;
  getState: () => string;
  title: string;
  ownership: () => string;
  highPower: () => number;
  lowPower: () => number;
  address: string;
}

export const defaultContract: ContractAttributes = {
  alias: '',
  authorized: false,
  responsablePsNombresYApellidos: '',
  cifNif: '',
  idcontrato: '',
  cups: '',
  fechaalta: '',
  fechaaltaLocal: '',
  fechacorte: '',
  fechacorteLocal: '',
  estado: '',
  direccionCups: '',
  cpCups: '',
  poblacionCups: '',
  provinciaCups: '',
  municipioCups: '',
  potMaxReconocida: 0,
  pcp1: 0,
  pcp2: 0,
  pcp3: 0,
  pcp4: 0,
  pcp5: 0,
  pcp6: 0,
  distribuidora: '',
  telefono1: '',
  telefono2: '',
  telefono3: '',
  correoElectronico: '',
  icpActivo: '',
  tienemaximetro: '',
  codct: '',
  nombrect: '',
  tramoTension: 0,
  linea: '',
  tipoInstalacion: '',
  id5: '',
  tarifa: '',
};

export const defaultEnergyParams: EnergyParams = {
  consumptionCurrent: {
    consumptionDaysComplete: [],
    consumptionByTypes: [],
    totalConsumption: 0,
    totalConsumptionProvided: 0,
    autoConsumptionByTypes: [],
    totalAutoConsumption: 0,
    totalAutoConsumptionProvided: 0,
  },
  consumptionLastYear: {
    consumptionDays: [],
    percentage: '0%',
    arrowDirection: true,
    totalConsumption: 0,
  },
  consumptionLastMonth: {
    consumptionDays: [],
    percentage: '0%',
    arrowDirection: true,
    totalConsumption: 0,
  },
  autoConsumptionLastMonth: {
    consumptionDays: [],
    percentage: '0%',
    arrowDirection: true,
    totalConsumption: 0,
  },
  autoConsumptionLastYear: {
    consumptionDays: [],
    percentage: '0%',
    arrowDirection: true,
    totalConsumption: 0,
  },
};

export const defaultPowerParams: PowerParams = {
  powerDays: [],
};

const contract = (contractAttributes = defaultContract): Contract => {
  const get = <T>(value: string): T => _.get(value)(contractAttributes);

  const title: string = _.isEmpty(get('alias')) ? get('direccionCups') : get('alias');

  const address = `${get('direccionCups')} - ${get('municipioCups')}`;

  const ownership = (): string => (
    contractAttributes.authorized
      ? t('models.contract.autorizado')
      : t('models.contract.attributes.titular')
  );

  const getState = () => state(get('estado'));

  const highPower = (): number => (
    contractAttributes.pcp4 || contractAttributes.pcp1
  );

  const lowPower = (): number => (
    contractAttributes.pcp6 || contractAttributes.pcp3
  );

  return {
    get,
    title,
    ownership,
    getState,
    highPower,
    lowPower,
    address,
  };
};

export default contract;
