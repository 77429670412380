import React, { FunctionComponent, ReactNode } from 'react';
import classnames from 'classnames';
import _ from 'lodash/fp';

export interface Props {
  label: string;
  onClick: () => void;
  type?: 'primary' | 'secondary' | 'link';
  icon?: ReactNode;
  fixed?: boolean;
  disabled?: boolean;
  className?: string;
  right?: boolean;
}

const ActionButton: FunctionComponent<Props> = ({
  label,
  onClick,
  type = 'primary',
  icon,
  fixed = false,
  disabled = false,
  className = '',
  right,
}: Props) => {
  const buttonClass = classnames(className, {
    [`btn-${type}-fixed`]: fixed,
    [`btn-${type}`]: !fixed,
    'text-blue': type === 'link' && !disabled,
    'text-gray-500': type === 'link' && disabled,
    right,
  });

  return (
    <button
      type="button"
      className={buttonClass}
      disabled={disabled}
      onClick={onClick}
    >
      {!_.isUndefined(icon) && <span className="icon">{icon}</span>}
      <span className="label">{label}</span>
    </button>
  );
};

export default ActionButton;
