import React, { FunctionComponent } from 'react';

import Icons from '../../assets/icons';
import Wrap from '../../components/containers/Wrap';
import InternalLink from '../../components/InternalLink';
import Bodycopy from '../../components/text-components/Bodycopy';
import Title from '../../components/text-components/Title';
import PublicLayout from '../../layout/PublicLayout';
import { routes } from '../../services/routing';
import t from '../../text-strings';

const CallUs: FunctionComponent = () => (
  <PublicLayout title={t('contact.llamanos')}>
    <div className="wrap pt-20 mb-10">
      <InternalLink
        label={t('contact.habla')}
        to={routes.contact}
        icon={<Icons.Back />}
      />
    </div>
    <Wrap size="wrap" className="lg:flex lg:pb-20">
      <div className="mb-22 lg:mb-0 lg:w-192">
        <Title>{t('contact.llamanos')}</Title>
      </div>
      <div className="lg:flex-1 lg:ml-16">
        <div className="flex mb-28">
          <div className="flex-1">
            <Title size="small" className="mb-12">{t('contact.averias')}</Title>
            <Title className="mb-6 text-blue">900 37 38 13</Title>
            <Bodycopy>{t('contact.24h')}</Bodycopy>
          </div>

          <div className="flex-1">
            <Title size="small" className="mb-12">{t('contact.otras')}</Title>
            <Title className="mb-6 text-blue">958 570 360</Title>
            <Bodycopy>{t('contact.horario')}</Bodycopy>
            <Bodycopy>{t('contact.estacional')}</Bodycopy>
          </div>
        </div>
        <div>
          <Title size="small" className="mb-16">{t('contact.prefieres_2')}</Title>
          <InternalLink
            to={routes.book}
            label={t('contact.agendar')}
            className="text-blue"
          />
        </div>
      </div>
    </Wrap>
  </PublicLayout>
);

export default CallUs;
