import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

import { routes } from '../../../services/routing';
import t from '../../../text-strings';

const Legal: FunctionComponent = () => (
  <div className="flex">
    <div className="w-1/2 lg:flex lg:w-auto">
      <Link to={routes.cookiePolicy} target="_blank" className="caption mb-4 block lg:mb-0 lg:mr-16">
        {t('common.navigation.cookies')}
      </Link>
      <Link to={routes.qualityPolicies} target="_blank" className="caption block lg:mr-16">
        {t('common.navigation.calidad')}
      </Link>
    </div>
    <div className="w-1/2 lg:flex lg:w-auto">
      <Link to={routes.legalWarning} target="_blank" className="caption mb-4 block text-right lg:mb-0 lg:mr-16 lg:text-left">
        {t('common.navigation.aviso_legal')}
      </Link>
      <Link to={routes.privacyPolicy} target="_blank" className="caption block text-right lg:mr-16 lg:text-left">
        {t('common.navigation.datos')}
      </Link>
    </div>
  </div>
);

export default Legal;
