import React, { ChangeEvent, FocusEvent, FunctionComponent } from 'react';
import classnames from 'classnames';
import _ from 'lodash/fp';

import classNames from '../../services/classNames';
import Icons from '../../assets/icons';

import FormGroup from './components/FormGroup';
import InputLabel from './components/InputLabel';
import InputError from './components/InputError';

interface Props {
  name: string;
  value: string;
  placeholder: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  errors?: string;
  required?: boolean;
  containerClassName?: string;
  labelClassName?: string;
  inputClassName?: string;
  acceptExtension?: string;
}

const FileInput: FunctionComponent<Props> = (
  {
    name,
    value,
    placeholder,
    onChange,
    onBlur = undefined,
    errors = '',
    required = false,
    containerClassName = '',
    labelClassName = '',
    inputClassName = '',
    acceptExtension = '',
  }: Props,
) => {
  const placeholderClass = classnames(classNames('block', labelClassName), {
    'opacity-100': !_.isEmpty(value),
    'opacity-0': _.isEmpty(value),
  });

  const labelClass = classnames(classNames('input-label', inputClassName), {
    'text-gray-400': _.isEmpty(value),
    'text-black': !_.isEmpty(value),
  });

  return (
    <FormGroup className={classNames('file-input', containerClassName)}>
      <InputLabel
        name={name}
        label={placeholder}
        className={placeholderClass}
        required={required}
      />
      <div className="flex items-center mb-4 border-b border-black">
        <label htmlFor={name} className={labelClass}>
          {_.isEmpty(value) ? placeholder : value}
          {_.isEmpty(value) && required && <sup>*</sup>}
        </label>
        <label htmlFor={name}>
          <Icons.Search color="blue" />
        </label>
      </div>

      <input
        type="file"
        name={name}
        id={name}
        className="hidden"
        onChange={onChange}
        onBlur={onBlur}
        accept={`.pdf, .dwg, ${acceptExtension}`}
      />
      <InputError message={errors} />
    </FormGroup>
  );
};

export default FileInput;
