import React, { FunctionComponent } from 'react';

import { AppColors } from '../../styles';

interface Props {
  color?: AppColors;
  width?: number;
  height?: number;
  className?: string;
}

const Message: FunctionComponent<Props> = (
  {
    color = 'black',
    width = 12,
    height = 12,
    className = '',
  }: Props,
) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className={`w-${width} h-${height} text-${color} stroke-current ${className}`}>
    <path d="M22 2L11 13" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill="transparent" />
    <path d="M22 2L15 22L11 13L2 9L22 2Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill="transparent" />
  </svg>
);

export default Message;
