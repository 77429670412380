import React, { FunctionComponent, ReactNode } from 'react';

export interface Props {
  icon: ReactNode;
}

const CircledIcon: FunctionComponent<Props> = ({ icon }) => (
  <div className="bg-gradient min-w-22 w-22 h-22 rounded-full flex items-center justify-center">
    <div className="w-18 h-18 rounded-full flex items-center justify-center bg-cream">
      {icon}
    </div>
  </div>
);

export default CircledIcon;
