import React, { FunctionComponent } from 'react';

import Icons from '../../../../assets/icons';
import Caption from '../../../../components/text-components/Caption';

interface Props {
  name: string;
  stateName: string;
}

const CanceledState: FunctionComponent<Props> = (
  {
    name,
    stateName,
  }: Props,
) => (
  <div className="lg:flex-1">
    <div className="flex items-center">
      <div className="w-22 h-22 lg:w-28 lg:h-28 flex items-center justify-center rounded-full bg-red-light">
        <div className="bg-white w-20 h-20 lg:w-24 lg:h-24 flex items-center justify-center rounded-full">
          <Icons.Close width={8} height={8} color="red" />
        </div>
      </div>
      <div className="ml-8 flex-1 lg:hidden">
        <p className="btn mb-2">{name}</p>
        <Caption color="blue">{stateName}</Caption>
      </div>
      <div className="lg:flex justify-center hidden flex-1 mx-8">
        <div className="h-px bg-red-light w-full" />
      </div>
    </div>
    <div className="flex my-4">
      <div className="w-22 flex justify-center lg:hidden">
        <div className="w-px bg-red-light" />
      </div>
      <div>
        <div className="flex-1 hidden lg:block mb-4">
          <p className="btn mb-2">{name}</p>
          <a href="#current_state" className="block">
            <Caption color="blue" className="hover:underline">{stateName}</Caption>
          </a>
        </div>
      </div>
    </div>
  </div>
);

export default CanceledState;
