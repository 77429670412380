import _ from 'lodash/fp';
import React, { FunctionComponent, ReactNode } from 'react';

import ActionButton from '../buttons/ActionButton';
import classNames from '../../services/classNames';
import t from '../../text-strings';

import Bodycopy from './Bodycopy';
import Caption from './Caption';

interface Props {
  label: string;
  value: string | ReactNode;
  className?: string;
  action?: () => void;
  actionText?: string;
  avoidButtonLeftMargin: boolean
}

const InfoField: FunctionComponent<Props> = ({
  label,
  value,
  className = '',
  action,
  actionText = t('common.continuar'),
  avoidButtonLeftMargin = false,
}: Props) => (
  <div className={classNames('', className)}>
    <div className="flex items-center justify-between mb-2 lg:justify-start lg:pr-4">
      <Caption color="gray-400">{label}</Caption>
      {!_.isUndefined(action) && (
        <ActionButton
          label={actionText}
          onClick={action}
          type="link"
          className={!avoidButtonLeftMargin ? 'small lg:ml-96' : 'small lg:ml-96 mg-lf-15'}
        />
      )}
    </div>
    {typeof value === 'string' ? <Bodycopy>{value}</Bodycopy> : value}
  </div>
);

export default InfoField;
