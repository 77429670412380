import React, { FunctionComponent } from 'react';

import images from '../../../../assets/images';
import Image from '../../../../components/Image';
import Bodycopy from '../../../../components/text-components/Bodycopy';
import t from '../../../../text-strings';
import ConsumptionCard from '../componentes/ConsumptionCard';
import Savings from '../componentes/Savings';

const IndividualWithoutSurplus: FunctionComponent = () => (
  <div className="pt-12 lg:pt-14 pb-28 lg:pb-40 w-full">
    <Bodycopy className="mb-12 hidden lg:block">{t('self.individual.sin_excedentes.fig_caption')}</Bodycopy>
    <Image
      src={images.autoconsumo.individualSinExcedentes}
      className="mb-10 lg:mb-48 mx-auto"
    />
    <Bodycopy className="mb-24 lg:hidden">{t('self.individual.sin_excedentes.fig_caption')}</Bodycopy>
    <Savings />
    <ConsumptionCard
      mobile={images.autoconsumo.individualSinExcedentesFacturaMVL}
      desktop={images.autoconsumo.individualSinExcedentesFactura}
    />
  </div>
);

export default IndividualWithoutSurplus;
