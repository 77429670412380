import _ from 'lodash/fp';
import React, { FunctionComponent } from 'react';

import Accordion from '../../components/Accordion';
import InfoAlert from '../../components/alerts/InfoAlert';
import BreakdownCard from '../../components/cards/BreakdownCard';
import HorizontalList from '../../components/containers/HorizontalList';
import Wrap from '../../components/containers/Wrap';
import Bodycopy from '../../components/text-components/Bodycopy';
import Title from '../../components/text-components/Title';
import AuthenticatedLayout from '../../layout/AuthenticatedLayout';
import { useStoreState } from '../../store/hooks';
import { BreakdownStates } from '../../store/model/contract/types';
import t from '../../text-strings';
import useFAQs from '../../services/use-faq';
import { FAQAttributes } from '../../store/model/contact/types';
import Icons from '../../assets/icons';
import InternalLink from '../../components/InternalLink';
import { routes } from '../../services/routing';

const Breakdowns: FunctionComponent = () => {
  const { breakdowns } = useStoreState((state) => state.contract);
  const { contracts } = useStoreState((state) => state.contract);
  const [loading, faqs] = useFAQs('Averias_y_cortes');
  const [isloading, incidencias] = useFAQs('Incidencias_particulares');

  return (
    <AuthenticatedLayout title={t('common.navigation.averias_cortes')}>
      <Wrap size="wrap-authenticated" className="pt-16 lg:pt-24">
        <div className="">
          <Title tag="h1" className="mb-4 lg:mb-8">{t('breakdowns.incidencias')}</Title>
          <Bodycopy className="mb-12">{t('breakdowns.incidencias_descripcion')}</Bodycopy>
        </div>
        {_.isEmpty(breakdowns.join) && (
          <InfoAlert message={_.isEmpty(contracts) ? t('breakdowns.no_contracts') : t('breakdowns.no_alerts')} className="flex-1" />
        )}
        {!_.isEmpty(breakdowns.join) && (
          <div className="hidden lg:flex flex-wrap">
            {_.map((state: BreakdownStates) => (
              <BreakdownCard
                key={`${state.alias}_${state.type}`}
                state={state}
              />
            ))(breakdowns.join)}
          </div>
        )}

      </Wrap>
      {!_.isEmpty(breakdowns.join) && (
        <HorizontalList
          className="lg:hidden"
          innerClassName="px-1/24"
        >
          {_.map((state: BreakdownStates) => (
            <BreakdownCard
              key={`${state.alias}_${state.type}`}
              state={state}
            />
          ))(breakdowns.join)}
        </HorizontalList>
      )}
      <Wrap size="wrap-authenticated" className="mt-12">
        <div className="flex p-8 rounded-sm bg-orange-lightest mb-20">
          <Icons.Warning color="orange" className="mr-6" />
          <div className="flex-1">
            <Bodycopy color="orange" className="mb-4">
              {t('landing.aviso.text')}
            </Bodycopy>
            <InternalLink
              to={`${routes.contactForm}?select=incidencia`}
              label={t('landing.aviso.link')}
              className="text-blue"
            />
          </div>
        </div>
      </Wrap>

      <Wrap size="wrap-authenticated" className="py-16 lg:flex">
        <div className="lg:w-144">
          <Title className="mb-4 lg:mb-8">{t('breakdowns.incidencias_particulares.title')}</Title>
          <Bodycopy className="mb-12 lg:mb-0">{t('breakdowns.incidencias_particulares.descripcion')}</Bodycopy>
        </div>
        {!isloading && (
          <div className="lg:flex-1">
            {_.map((faq: FAQAttributes) => (
              <Accordion
                key={faq.questionId}
                title={faq.title}
                text={faq.text}
              />
            ))(incidencias)}
          </div>
        )}
      </Wrap>
      <div className="bg-white py-16 lg:bg-transparent">
        <Wrap size="wrap-authenticated" className="lg:flex">
          <Title className="mb-12 lg:mb-0 lg:w-144">{t('breakdowns.faq.title')}</Title>
          {!loading && (
          <div className="lg:flex-1">
            {_.map((faq: FAQAttributes) => (
              <Accordion
                key={faq.questionId}
                title={faq.title}
                text={faq.text}
              />
            ))(faqs)}
          </div>
          )}
        </Wrap>
      </div>
    </AuthenticatedLayout>
  );
};

export default Breakdowns;
