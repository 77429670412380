import { FormikErrors, FormikTouched } from 'formik';
import React, { ChangeEvent, FunctionComponent } from 'react';
import _ from 'lodash/fp';
import { Link } from 'react-router-dom';

import FormStepScreen from '../components/FormStepScreen';
import FormStepsNavigation from '../components/FormStepsNavigation';
import Title from '../../../../components/text-components/Title';
import Bodycopy from '../../../../components/text-components/Bodycopy';
import t from '../../../../text-strings';
import EmailInput from '../../../../components/forms/EmailInput';
import PasswordInput from '../../../../components/forms/PasswordInput';
import SubmitButton from '../../../../components/buttons/SubmitButton';
import useSteps from '../../../../store/model/register/register';
import { UserForm } from '../../../../forms/initial-values';
import Modal from '../../../../components/Modal';
import Icons from '../../../../assets/icons';
import { routes } from '../../../../services/routing';
import CheckboxInput from '../../../../components/forms/CheckboxInput';

/* eslint-disable @typescript-eslint/no-explicit-any */
interface Props {
  values: UserForm;
  onChange: {
    (e: React.ChangeEvent<any>): void;
    <T_1 = string | React.ChangeEvent<any>>(field: T_1): T_1 extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
  onBlur: {
    (e: React.FocusEvent<any, Element>): void;
    <T = any>(fieldOrEvent: T): T extends string
      ? (e: any) => void
      : void;
  };
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
    ) => Promise<void> | Promise<FormikErrors<UserForm>>
  touched: FormikTouched<UserForm>;
  errors: FormikErrors<UserForm>;
  message: string;
  success: boolean;
  disabled: boolean;
}
/* eslint-enable @typescript-eslint/no-explicit-any */

const YourAccessData: FunctionComponent<Props> = (
  {
    values,
    onChange,
    onBlur,
    setFieldValue,
    touched,
    errors,
    message,
    disabled,
    success,
  }: Props,
) => {
  const [step, setStep] = useSteps();

  const handleConditions = (e: ChangeEvent<HTMLInputElement>): void => {
    const { checked } = e.target;
    setFieldValue('conditions', checked);
  };

  return (
    <FormStepScreen
      onNext={() => setStep('permissions')}
      onBack={() => setStep('supply-point')}
      current={_.isEqual(step, 'access-data')}
      finish
    >
      <FormStepsNavigation blockClassNames={['bg-black', 'bg-black', 'bg-black', 'bg-black']} />
      <Title className="mb-4 lg:mb-8">{t('register.casi_terminado')}</Title>
      <Bodycopy className="mb-4 lg:mb-8 max-w-240 w-full">{t('register.casi_terminado_subtitle')}</Bodycopy>
      <EmailInput
        name="email"
        placeholder={t('models.user.attributes.email')}
        value={values.email}
        onChange={onChange('email')}
        onBlur={onBlur('email')}
        touched={touched.email}
        errors={errors.email}
        required
      />
      <EmailInput
        name="repeatEmail"
        placeholder={t('models.user.attributes.repetir_email')}
        value={values.repeatEmail}
        onChange={onChange('repeatEmail')}
        onBlur={onBlur('repeatEmail')}
        touched={touched.repeatEmail}
        errors={errors.repeatEmail}
        required
      />
      <PasswordInput
        name="password"
        placeholder={t('models.user.attributes.password')}
        value={values.password}
        onChange={onChange('password')}
        onBlur={onBlur('password')}
        touched={touched.password}
        errors={errors.password}
        required
      />
      <PasswordInput
        name="repeatPassword"
        placeholder={t('models.user.attributes.repetir_password')}
        value={values.repeatPassword}
        onChange={onChange('repeatPassword')}
        onBlur={onBlur('repeatPassword')}
        touched={touched.repeatPassword}
        errors={errors.repeatPassword}
        required
      />
      <CheckboxInput
        name="conditions"
        value={values.conditions}
        onChange={handleConditions}
        onBlur={onBlur}
        touched={touched.conditions}
        errors={errors.conditions}
        containerClassName="mb-12"
      >
        {t('register.conditions')}
        <Link to={routes.privacyPolicy} target="_blank" className="text-blue">
          {t('register.privacy')}
        </Link>
      </CheckboxInput>
      <Modal isOpen={!_.isEmpty(message) && success} unclosable>
        <Title size="small" className="mb-12">{t('register.veriff_title')}</Title>
        <Bodycopy>{message}</Bodycopy>
      </Modal>
      <Modal
        isOpen={!_.isEmpty(message) && !success}
        toggleOpen={(): void => window.location.reload()}
      >
        <Icons.Bad color="red" className="mb-6" />
        <Bodycopy color="red">{message}</Bodycopy>
      </Modal>
      <SubmitButton
        label={t('register.terminar')}
        disabled={disabled}
        fixed
      />
    </FormStepScreen>
  );
};

export default YourAccessData;
