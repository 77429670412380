import _ from 'lodash/fp';

import azureAPI from '../store/model/azure/azure';
import {
  RequestAttributes,
  StateDetailsAttributes,
} from '../store/model/requests/types';

export type Downloadable = {
  filename: string;
  blobURL: string;
}

interface Documents {
  // eslint-disable-next-line
  uploadStreams: (path: string, streams: any[], blob: string) => Promise<void>;
  uploadFiles: (path: string, files: File[], blob: string) => Promise<void>;
  // eslint-disable-next-line
  filesToArray: (files: File[]) => Promise<any>;
  deleteAzurePath: (path: string, blob: string) => Promise<void>;
  downloadFiles: (request: RequestAttributes) => Promise<Downloadable>;
  downloadHistoryFile: (
    request: RequestAttributes,
    stateDetails: StateDetailsAttributes
  ) => Promise<Downloadable>;
  saveB64Document: (file: File) => Promise<string>;
}
// eslint-disable-next-line
const uploadStreams = async (path: string, streams: any[], blob: string): Promise<void> => {
  // eslint-disable-next-line
  for (const stream of streams) {
    // eslint-disable-next-line
    await azureAPI().uploadStream({ path, stream, blob });
  }
};

const uploadFiles = async (path: string, files: File[], blob: string): Promise<void> => {
  // eslint-disable-next-line
  for (const file of files) {
    // eslint-disable-next-line
    await azureAPI().upload({ path: `${path}${file.name}`, file, blob });
  }
};

// eslint-disable-next-line
const filesToArray = async (files: File[]): Promise<any> => {
  // eslint-disable-next-line
  const arrayBuffer: any[] = [];
  const promises = files.map(async (file: File) => (
    new Promise((resolve) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);
      fileReader.onload = () => resolve(arrayBuffer.push(fileReader.result));
    })
  ));
  await Promise.all(promises);
  return arrayBuffer;
};

const deleteAzurePath = async (path: string, blob: string): Promise<void> => {
  await azureAPI().destroy({ path, file: '', blob });
};

const b64ToBlob = (b64Data: string, contentType = '', sliceSize = 512): Blob => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i += 1) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  return new Blob(byteArrays, { type: contentType });
};

const joinURL = _.flow(
  _.valuesIn,
  _.join('/'),
);

const urlRequest = _.flow(
  _.pick(['formCode', 'year', 'month', 'day', 'code']),
  joinURL,
);

const urlState = _.flow(
  _.pick(['stateCodeName', 'historialId']),
  joinURL,
);

const downloadFile = async (
  path: string,
  blob: string,
  filename: string,
): Promise<Downloadable> => {
  try {
    const filePath = await azureAPI().getDirectory({ distributor: blob, dir: path });
    if (_.isEmpty(filePath)) return { filename: '', blobURL: '' };
    const downloadFilePath = filePath[0] || '';
    const downloadParams = { distributor: blob, dir: downloadFilePath };
    const pdfDataUri = await azureAPI().download(downloadParams);
    const blobImg = b64ToBlob(pdfDataUri.split(',')[1], 'application/pdf');
    const blobURL = URL.createObjectURL(blobImg);
    return { filename, blobURL };
  } catch (error) {
    return { filename: '', blobURL: error as string };
  }
};

const downloadFiles = async (request: RequestAttributes): Promise<Downloadable> => {
  const path = `${urlRequest(request)}/0/0/Unificado/`;

  return downloadFile(path, request.blob, request.code);
};

const downloadHistoryFile = async (
  request: RequestAttributes,
  stateDetails: StateDetailsAttributes,
): Promise<Downloadable> => {
  const filename = `${stateDetails.name}_${stateDetails.createdDate}`;
  const path = `${urlRequest(request)}/${urlState(stateDetails)}/Unificado/`;
  return downloadFile(path, request.blob, filename);
};

const saveB64Document = async (file: File): Promise<string> => (
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onerror = reject;
    reader.onloadend = () => {
      // @ts-ignore
      resolve(reader.result?.toString().split(',')[1]);
    };
  })
);

const documents: Documents = {
  uploadStreams,
  uploadFiles,
  filesToArray,
  deleteAzurePath,
  downloadFiles,
  downloadHistoryFile,
  saveB64Document,
};

export default documents;
