import React, { FunctionComponent } from 'react';

import date from '../../services/date';
import helpers from '../../services/helpers';
import { Any } from '../../store/model/global';

interface LineLegendPrevMonthProps {
    energy: Any;
    startPeriod: Any;
    endPeriod: Any;
}

const LineLegendPrevMonth: FunctionComponent<LineLegendPrevMonthProps> = (
  { energy, startPeriod, endPeriod }: LineLegendPrevMonthProps,
) => (
  <div className="flex items-center mt-8">
    <div className="flex">
      <div className="flex w-6 h-6 mt-2 mr-4 bg-black rounded min-h-6 min-w-6" />
      <div className="flex flex-col">
        <div className="text-sm">
          {`${helpers.titleString(date.getPreviousMonthName(startPeriod))} / ${date.getMonthName(startPeriod)} ${Number(date.getYear(startPeriod))}`}
        </div>
        <div className="text-sm text-gray-400">
          {`${helpers.roundStatNumber(energy.consumptionLastMonth?.totalConsumption)}kWh`}
        </div>
      </div>
    </div>
    <div className="flex ml-18">
      <div className="flex w-6 h-6 mt-2 mr-4 rounded bg-yellow min-h-6 min-w-6" />
      <div className="flex flex-col">
        <div className="text-sm">
          {`${helpers.titleString(date.getMonthName(startPeriod))} / ${date.getMonthName(endPeriod)} ${date.getYear(startPeriod)}`}
        </div>
        <div className="text-sm text-gray-400">
          {`${helpers.roundStatNumber(energy.consumptionCurrent?.totalConsumption)}kWh`}
        </div>
      </div>
    </div>
  </div>
);

export default LineLegendPrevMonth;
