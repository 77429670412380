import _ from 'lodash/fp';
import React, { FunctionComponent, useState } from 'react';

import Title from '../../components/text-components/Title';
import Content from '../../layout/components/Content';
import PublicLayout from '../../layout/PublicLayout';
import { useStoreState } from '../../store/hooks';
import { Municipality } from '../../store/model/distributors/types';
import t from '../../text-strings';
import Icons from '../../assets/icons';
import Select from '../../components/Select';
import defaultMunicipality from '../../store/model/distributors/distributors';
import Bodycopy from '../../components/text-components/Bodycopy';
import { BASE_URL } from '../../api/axios';

const NodalCapacities: FunctionComponent = () => {
  const { municipalities } = useStoreState((state) => state.distributors);
  const [selectedMunicipality, setMunicipality] = useState(defaultMunicipality);

  const onChange = (municipality: Municipality): void => {
    setMunicipality(municipality);
  };

  const findPDF = (): string => {
    const API = BASE_URL;
    const URL = `${API}/Distributor/getcapacidadnodal?distributorId=${selectedMunicipality.distributorId}`;
    return URL;
  };

  const selectedName = (): string => (
    _.isEmpty(selectedMunicipality.name)
      ? t('nodalCapacities.selecciona')
      : selectedMunicipality.name
  );

  return (
    <PublicLayout title={t('nodalCapacities.title')}>
      <div className="wrap py-24 lg:py-40 lg:flex lg:justify-between">
        <div className="hidden lg:block">
          <Title className="mb-12">{t('nodalCapacities.title')}</Title>
        </div>
        <Content>
          <Bodycopy className="mb-12">{t('nodalCapacities.paragraph_1')}</Bodycopy>
          <Bodycopy className="mb-24">{t('nodalCapacities.paragraph_2')}</Bodycopy>
          <div className="md:flex md:justify-between md:items-center">
            <Select selected={selectedName()} type="select-underline" className="mb-12 md:mb-0">
              {!_.isEmpty(selectedMunicipality.name) && (
                <div key="empty" onClick={() => onChange(defaultMunicipality)}>
                  <Bodycopy color="gray-400">{t('nodalCapacities.selecciona')}</Bodycopy>
                </div>
              )}
              {_.map((municipality: Municipality) => (
                <div key={municipality.municipalityId} onClick={() => onChange(municipality)}>
                  <Bodycopy className="truncate">{municipality.name}</Bodycopy>
                </div>
              ))(municipalities)}
            </Select>
            {!_.isEmpty(selectedMunicipality.name) && (
            <a
              href={findPDF()}
              target="_blank"
              rel="noreferrer"
              className="text-blue inline-flex py-2 px-4"
            >
              <span className="mr-2">
                {/* @ts-ignore */}
                {t('nodalCapacities.ver_pdf', t(`nodalCapacities.${selectedMunicipality.distributorId}`))}
              </span>
              <span><Icons.Document color="blue" /></span>
            </a>
            )}
          </div>

        </Content>
      </div>
    </PublicLayout>
  );
};

export default NodalCapacities;
