import React, { FunctionComponent } from 'react';

import helpers from '../../services/helpers';
import Icons from '../../assets/icons';
import t from '../../text-strings';

interface ConsumptionTrendLabelProps {
    consumption: {
        percentage: string;
        arrowDirection: number;
    }
}

const ConsumptionTrendLabel: FunctionComponent<ConsumptionTrendLabelProps> = (
  { consumption } : ConsumptionTrendLabelProps,
) => (
  <div className="m-2 mb-12 rounded-sm">
    <div className="flex mr-8 text-xl">
      <div className="font-semibold">
        {helpers.cleanPercentage(`${consumption?.percentage}`)}
      </div>
      <div className="mt-2 ml-2">
        {consumption?.arrowDirection ? <Icons.Growth color="black" /> : <Icons.Decay color="black" />}
      </div>
    </div>
    <div className="mt-2 text-base font-normal">
      {consumption?.arrowDirection ? t('consumption.energy.more_last_year') : t('consumption.energy.less_last_year')}
    </div>
  </div>
);

export default ConsumptionTrendLabel;
