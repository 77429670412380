import _ from 'lodash/fp';
import React, { ChangeEvent, FocusEvent, FunctionComponent } from 'react';

import RadioButtonsInput from '../../../../components/forms/RadioButtonsInput';
import Title from '../../../../components/text-components/Title';
import useNavigation from '../../../../services/navigation';
import { routes } from '../../../../services/routing';
import useSteps from '../../../../store/model/register/register';
import t from '../../../../text-strings';
import FormStepScreen from '../components/FormStepScreen';
import FormStepsNavigation from '../components/FormStepsNavigation';

interface Props {
  userTypeId: number
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  touched?: boolean;
  errors?: string;
}

const radiobuttons = [
  {
    value: t('models.user.attributes.user_type.types.particular'),
    selectorId: 1,
  },
  {
    value: t('models.user.attributes.user_type.types.empresa'),
    selectorId: 2,
  },
  {
    value: t('models.user.attributes.user_type.types.autonomo'),
    selectorId: 3,
  },
];

const YourPermissions: FunctionComponent<Props> = (
  {
    userTypeId,
    onChange,
    onBlur,
    touched,
    errors,
  }: Props,
) => {
  const [step, setStep] = useSteps();
  const navigate = useNavigation();

  return (
    <FormStepScreen
      onNext={() => setStep('personal-data')}
      onBack={() => navigate(routes.login)}
      current={_.isEqual(step, 'permissions')}
    >
      <FormStepsNavigation blockClassNames={['bg-black', '', '', '']} />
      <Title className="mb-4 lg:mb-12">{t('register.bienvenida')}</Title>
      <RadioButtonsInput
        name="userTypeId"
        value={userTypeId}
        placeholder={t('models.user.attributes.user_type.name')}
        radios={radiobuttons}
        onChange={onChange}
        onBlur={onBlur}
        touched={touched}
        errors={errors}
      />
    </FormStepScreen>
  );
};

export default YourPermissions;
