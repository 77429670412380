import React, { FunctionComponent, ReactNode } from 'react';

import { Colors } from '../../styles';
import classNames from '../../services/classNames';

export interface Props {
  children: ReactNode;
  size?: 'small' | 'medium' | 'large';
  tag?: 'h1' | 'h2' | 'h3' | 'h4';
  color?: Colors;
  className?: string;
}

const Title: FunctionComponent<Props> = (
  {
    children,
    size = 'medium',
    tag: Tag = 'h3',
    color = 'black',
    className = '',
  }: Props,
) => (
  <Tag className={classNames(`title-${size} text-${color}`, className)}>
    {children}
  </Tag>
);

export default Title;
