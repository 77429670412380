import React, { FunctionComponent, useEffect, useState } from 'react';
import _ from 'lodash/fp';
import { useParams } from 'react-router-dom';

import { useStoreActions, useStoreState } from '../../../../store/hooks';
import useRequestHistory from '../useHistory';
import useHandleChangeResponse from '../../../../services/handle-change-response';
import useNavigation from '../../../../services/navigation';
import Title from '../../../../components/text-components/Title';
import Bodycopy from '../../../../components/text-components/Bodycopy';
import t from '../../../../text-strings';
import Icons from '../../../../assets/icons';
import ActionButton from '../../../../components/buttons/ActionButton';
import Modal from '../../../../components/Modal';
import { routes } from '../../../../services/routing';
import documents, { Downloadable } from '../../../../services/documents';
import Caption from '../../../../components/text-components/Caption';
import TextInput from '../../../../components/forms/TextInput';

type RouteParams = {
  id: string;
};

const CurrentStateCard: FunctionComponent = () => {
  const { request } = useStoreState((state) => state.requests);
  const { changestaterequest } = useStoreActions((actions) => actions.requests);
  const { id } = useParams<RouteParams>();
  const requestHistory = useRequestHistory(request, id);
  const [message, success, isModal, handleResponse] = useHandleChangeResponse();
  const navigate = useNavigation();
  const [downloadURL, setDownloadURL] = useState<Downloadable>();
  const [loading, setLoading] = useState(false);

  const currentHistoricalIdCache: number = requestHistory.accept.historicalId;

  const {
    acceptButton,
    cancelButton,
    uploadFile,
    name: currentState,
    description,
  } = requestHistory.details;

  useEffect(() => {
    (async () => {
      if (!_.gt(_.size(request.stateDetails), 1)) return;
      const downloadable = await documents.downloadHistoryFile(
        request,
        request.stateDetails[1],
      );
      if (!_.isEmpty(downloadable.filename)) {
        setDownloadURL(downloadable);
      }
    })();
  }, [request]);

  const handleAccept = async (): Promise<void> => {
    setLoading(true);
    requestHistory.accept.day = request.day;
    requestHistory.accept.month = request.month;
    requestHistory.accept.year = request.year;
    handleResponse(
      await changestaterequest(requestHistory.accept),
      requestHistory.docs,
      currentState,
      currentHistoricalIdCache,
    ).then(() => setLoading(false));
  };

  const handleCancel = async (): Promise<void> => {
    setLoading(true);
    requestHistory.accept.day = request.day;
    requestHistory.accept.month = request.month;
    requestHistory.accept.year = request.year;
    handleResponse(
      await changestaterequest(requestHistory.cancel),
      requestHistory.docs,
      currentState,
      currentHistoricalIdCache,
    ).then(() => setLoading(false));
  };

  const withDescription = uploadFile || acceptButton || cancelButton;
  const userComment = !acceptButton
    && !cancelButton
    && !uploadFile
    && (_.isEqual(currentState, t('history.analizando'))
      || _.isEqual(currentState, t('history.revisando'))
      || _.isEqual(currentState, t('history.permisos')));

  return (
    <div id="current_state" className="mb-16">
      <Title size="small" tag="h3" className="mb-8 lg:mb-12">
        {t('history.current_state')}
      </Title>
      <div className="bg-white rounded-sm py-12 px-8 lg:py-16 lg:px-16 mb-8 lg:flex">
        <div className="lg:flex-1">
          <Title size="small" tag="h3" className="mb-2">
            {requestHistory.details.name}
          </Title>
          <Caption tag="p" className="text-gray-400 mb-8 lg:mb-12">
            {requestHistory.details.createdDate}
          </Caption>
          {_.isEqual(currentState, t('history.cancelada_gestor')) && (
            <div className="mb-8 lg:mb-12">
              <p className="btn mb-4">{t('history.comment')}</p>
              <Bodycopy>{description}</Bodycopy>
            </div>
          )}
          {!_.isNil(requestHistory.description)
            && !_.isEmpty(requestHistory.description) && (
              <div className="mb-8 lg:mb-12">
                <p className="btn mb-4">
                  {userComment
                    ? t('history.user_comment')
                    : t('history.comment')}
                </p>
                <Bodycopy>{requestHistory.description}</Bodycopy>
              </div>
          )}
          {!_.isUndefined(downloadURL) && (
            <a
              download={`${downloadURL.filename}.pdf`}
              href={downloadURL.blobURL}
              className="mb-8 lg:mb-12 flex btn text-blue"
            >
              <Icons.Document color="blue" className="mr-2" />
              {_.isEqual(currentState, t('history.revisando'))
              || _.isEqual(currentState, t('history.propuesta'))
                ? t('history.download_prop')
                : t('history.download_docs')}
            </a>
          )}
          {withDescription && (
            <div className="">
              <TextInput
                name="observations"
                placeholder={t('history.observations')}
                value={requestHistory.observations}
                onChange={requestHistory.handleObservations}
              />
            </div>
          )}
          {uploadFile && (
            <div className="btn text-blue flex mb-8 lg:mb-12">
              <Icons.Attach color="blue" />
              <label htmlFor="docs" className="ml-2">
                {_.isEmpty(requestHistory.docs)
                  ? t('history.upload')
                  : t('history.modify')}
              </label>
              <input
                type="file"
                name="docs"
                id="docs"
                onChange={requestHistory.handleDocumentsChange}
                className="hidden"
                multiple
                accept=".pdf"
              />
            </div>
          )}
          <div className="flex">
            {acceptButton && (
              <ActionButton
                label={
                  _.isEqual(t('history.propuesta'), currentState)
                    ? t('history.accept')
                    : t('history.send')
                }
                onClick={() => handleAccept()}
                icon={
                  _.isEqual(t('history.propuesta'), currentState) ? (
                    <Icons.Good />
                  ) : (
                    <Icons.Go />
                  )
                }
                right
                className="my-4 sm:my-0 sm:ml-4"
                disabled={
                  (_.isEqual(t('history.requerida'), currentState)
                    && _.isEmpty(requestHistory.docs)
                    && _.isEmpty(requestHistory.observations))
                  || loading
                }
              />
            )}
            {cancelButton && (
              <ActionButton
                type="secondary"
                label={t('history.cancel')}
                onClick={() => handleCancel()}
                icon={<Icons.Bad color="white" />}
                right
                className="my-4 sm:my-0 sm:ml-4"
                disabled={loading}
              />
            )}
          </div>
        </div>
        <div className="h-px w-full bg-gray-300 my-16 lg:hidden" />
        <div className="flex lg:w-1/2 justify-end">
          <div className="flex w-1/2 lg:w-auto lg:mr-14">
            <Icons.Calendar />
            <div className="ml-2">
              <p className="btn mb-2">{requestHistory.details.daysCurrent}</p>
              <Caption tag="p" className="text-gray-400">
                En este estado
              </Caption>
            </div>
          </div>
          <div className="flex w-1/2 lg:w-auto">
            <Icons.Calendar />
            <div className="ml-2">
              <p className="btn mb-2">{requestHistory.details.daysLimit}</p>
              <Caption tag="p" className="text-gray-400">
                {requestHistory.details.limitDate}
              </Caption>
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={!_.isEmpty(message) && isModal} unclosable>
        <Icons.Info color="blue" className="mb-6" />
        <Bodycopy>{message}</Bodycopy>
      </Modal>
      <Modal
        isOpen={!isModal && success}
        toggleOpen={() => window.location.reload()}
      >
        <Icons.Good color="green" className="mb-6" />
        <Bodycopy>{t('history.success')}</Bodycopy>
      </Modal>
      <Modal
        isOpen={!isModal && !success}
        toggleOpen={() => navigate(routes.requests.index)}
      >
        <Icons.Bad color="red" className="mb-6" />
        <Bodycopy>{message}</Bodycopy>
      </Modal>
    </div>
  );
};

export default CurrentStateCard;
