import { useFormik } from 'formik';
import _ from 'lodash/fp';
import React, {
  ChangeEvent,
  FunctionComponent,
  useEffect,
  useState,
} from 'react';

import Form from '../../../components/forms/Form';
import formInit from '../../../forms/initial-values';
import schemas from '../../../forms/schemas';
import AuthenticatedLayout from '../../../layout/AuthenticatedLayout';
import constants from '../../../services/constants';
import { useStoreActions, useStoreState } from '../../../store/hooks';
import {
  clientData,
  documentData,
  instalationData,
  requestData,
} from '../../../store/model/requests/requests';
import { FormID, SupplyParams, SupplyRequestParams } from '../../../store/model/requests/types';
import t from '../../../text-strings';
import useHandleRequestResponse from '../../../services/handle-request-response';

import SideNavLink from './components/SideNavLink';
import ClientDataStep from './components/ClientData';
import InstalationDataStep from './components/InstalationData';
import RequestDataStep from './components/RequestData';
import DocumentDataStep from './components/DocumentData';

const TOTAL_STEPS = 4;

const SupplyRequest: FunctionComponent = () => {
  const { session } = useStoreState((state) => state.session);
  const {
    getloadrequest,
    createsupplypointrequest,
    setRequestStep,
  } = useStoreActions((actions) => actions.requests);
  const [loading, setLoading] = useState(true);
  const [
    message,
    success,
    sendedPdf,
    handleResponse,
  ] = useHandleRequestResponse<SupplyParams>();

  const handleOnSubmit = async (values: SupplyParams): Promise<void> => {
    // @ts-ignore
    const params: SupplyRequestParams = _.omit([
      'authorization',
      'tehcnicalMemory',
      'technicalDraft',
    ])(values);
    handleResponse(await createsupplypointrequest(params), values);
  };

  const {
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    isSubmitting,
    isValid,
  } = useFormik({
    initialValues: formInit.supply(session),
    onSubmit: handleOnSubmit,
    validationSchema: schemas.supply,
  });

  useEffect(() => {
    (async () => {
      setRequestStep('cliente');
      await getloadrequest({
        distributorId: constants.DISTRIBUTOR_ID,
        formCode: constants.SUPPLY_CODE as FormID,
      });
      setLoading(false);
    })();
  }, []);

  if (loading) {
    return null;
  }

  const handleSelect = (e: ChangeEvent<HTMLSelectElement>): void => {
    const { value, name } = e.target;
    if (_.isEmpty(value)) {
      setFieldValue(name, 0);
      return;
    }
    setFieldValue(name, parseInt(value, 10));
  };

  return (
    <AuthenticatedLayout title="" onlyDesktop sidebar={false}>
      <Form onSubmit={handleSubmit} className="lg:flex lg:flex-1">
        <div className="lg:w-7/10">
          <ClientDataStep
            title={t('requests.supply_point_request')}
            totalSteps={TOTAL_STEPS}
          // @ts-ignore
            values={_.pick(clientData)(values)}
            handleChange={handleChange}
            handleSelect={handleSelect}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
            errors={errors}
            touched={touched}
          />
          <RequestDataStep
            title={t('requests.supply_point_request')}
            prev="cliente"
            step={2}
            totalSteps={TOTAL_STEPS}
          // @ts-ignore
            values={_.pick(requestData)(values)}
            handleChange={handleChange}
            handleSelect={handleSelect}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
            errors={errors}
            touched={touched}
          />
          <InstalationDataStep
            title={t('requests.supply_point_request')}
            step={3}
            next="documentacion"
            totalSteps={TOTAL_STEPS}
          // @ts-ignore
            values={_.pick(instalationData)(values)}
            handleChange={handleChange}
            handleSelect={handleSelect}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
            errors={errors}
            touched={touched}
            formCode={constants.SUPPLY_CODE}
          />
          <DocumentDataStep
            title={t('requests.supply_point_request')}
            prev="instalacion"
            step={4}
            totalSteps={TOTAL_STEPS}
            // @ts-ignore
            values={_.pick(documentData)(values)}
            setFieldValue={setFieldValue}
            handleBlur={handleBlur}
            errors={errors}
            message={message}
            success={success}
            isSubmitting={isSubmitting}
            isValid={isValid}
            sendedPdf={sendedPdf}
            formCode={constants.SUPPLY_CODE}
          />
        </div>
        <div className="hidden w-3/10 lg:flex flex-col bg-white relative pt-72 pl-32">
          <div className="bg-white absolute top-0 left-full w-screen-full h-full" />
          <SideNavLink
            label={t('requests.forms.cliente')}
            currentStep="cliente"
          />
          <SideNavLink
            label={t('requests.forms.solicitud')}
            currentStep="solicitud"
          />
          <SideNavLink
            label={t('requests.forms.instalacion')}
            currentStep="instalacion"
          />
          <SideNavLink
            label={t('requests.forms.documentacion')}
            currentStep="documentacion"
          />
        </div>
      </Form>
    </AuthenticatedLayout>
  );
};

export default SupplyRequest;
