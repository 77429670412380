import React, { FunctionComponent, ReactNode } from 'react';

import classNames from '../../services/classNames';

interface Props {
  children: ReactNode;
  className?: string;
}

const Content: FunctionComponent<Props> = ({ children, className = '' }: Props) => (
  <div className={classNames('max-w-screen-content w-11/12 mx-auto lg:mx-0', className)}>
    {children}
  </div>
);

export default Content;
