import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash/fp';
import classnames from 'classnames';

import { LinkProps, NavLinkProps } from '../navigation';

const MOBILE_CLASS = 'border-t border-dashed border-gray-400 lg:hidden';

const NavLink: FunctionComponent<NavLinkProps> = (
  {
    to,
    label,
    subLinks,
  }: NavLinkProps,
) => {
  const linkClass = classnames('block py-8 text-base font-semibold lg:text-white', {
    'lg:hidden': !_.isUndefined(subLinks),
  });

  const buttonClass = classnames('nav-button', {
    hidden: _.isUndefined(subLinks),
  });

  const submenuClass = classnames('submenu');
  return (
    <li className="nav-link">
      <Link to={to} className={linkClass}>
        {label}
      </Link>
      {!_.isUndefined(subLinks) && (
        <div className={buttonClass}>
          {label}
        </div>
      )}
      {!_.isUndefined(subLinks) && (
      <>
        <ul className={MOBILE_CLASS}>
          {_.map((link: LinkProps) => (
            <li key={link.label}>
              <Link to={link.to} className="py-8 block text-sm">
                {link.label}
              </Link>
            </li>
          ))(subLinks)}
        </ul>
        <ul className={submenuClass}>
          {_.map((link: LinkProps) => (
            <li key={link.label}>
              <Link to={link.to} className="py-8 block text-sm">
                {link.label}
              </Link>
            </li>
          ))(subLinks)}
        </ul>
      </>
      )}
    </li>
  );
};

export default NavLink;
