import React, { FunctionComponent } from 'react';

import helpers from '../../services/helpers';
import { Any } from '../../store/model/global';

const LineChartTooltip: FunctionComponent = (
  { active, payload } : Any,
) => {
  if (active && payload && payload.length) {
    return (
      <div className="border border-black rounded-sm flex flex-col bg-white p-6">
        <div className="flex items-center mb-2">
          <div className="bg-yellow min-h-6 min-w-6 h-6 w-6 rounded mr-4" />
          <p className="text-xs font-medium">{`${helpers.toStringDecimal(payload[1].value, 2)} kW`}</p>
        </div>
        <div className="flex items-center">
          <div className="bg-black min-h-6 min-w-6 h-6 w-6 rounded mr-4" />
          <p className="text-xs font-medium">{`${helpers.toStringDecimal(payload[0].value, 2)} kW`}</p>
        </div>
      </div>
    );
  }

  return null;
};

export default LineChartTooltip;
