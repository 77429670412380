import React, { FunctionComponent, ReactNode } from 'react';
import { Link } from 'react-router-dom';

import Bodycopy from '../../../components/text-components/Bodycopy';

interface Props {
  to: string;
  label: string;
  icon: ReactNode;
}

const PrivateLink: FunctionComponent<Props> = ({ to, label, icon }) => (
  <Link to={to} className="private-area-button">
    {icon}
    <Bodycopy className="uppercase mt-4">
      <strong>{label}</strong>
    </Bodycopy>
  </Link>
);

export default PrivateLink;
