import React, { FunctionComponent } from 'react';

import { AppColors } from '../../styles';

interface Props {
  color?: AppColors;
  width?: number;
  height?: number;
  className?: string;
}

const AddDocument: FunctionComponent<Props> = (
  {
    color = 'black',
    width = 12,
    height = 12,
    className = '',
  }: Props,
) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className={`w-${width} h-${height} text-${color} fill-current ${className}`}>
    <path d="M18 12C17.45 12 17 12.45 17 13V18.22C17 18.77 16.55 19.22 16 19.22H6C5.45 19.22 5 18.77 5 18.22V8C5 7.45 5.45 7 6 7H11C11.55 7 12 6.55 12 6C12 5.45 11.55 5 11 5H5C3.9 5 3 5.9 3 7V19C3 20.1 3.9 21 5 21H17C18.1 21 19 20.1 19 19V13C19 12.45 18.55 12 18 12Z" />
    <path d="M21.02 5H19V2.98C19 2.44 18.56 2 18.02 2H17.99C17.44 2 17 2.44 17 2.98V5H14.99C14.45 5 14.01 5.44 14 5.98C14 5.99 14 6 14 6.01C14 6.56 14.44 7 14.99 7H17V9.01C17 9.55 17.44 10 17.99 9.99C18 9.99 18.01 9.99 18.02 9.99C18.56 9.99 19 9.55 19 9.01V7H21.02C21.56 7 22 6.56 22 6.02V5.98C22 5.44 21.56 5 21.02 5Z" />
    <path d="M14 9H8C7.45 9 7 9.45 7 10C7 10.55 7.45 11 8 11H14C14.55 11 15 10.55 15 10C15 9.45 14.55 9 14 9Z" />
    <path d="M14 12H8C7.45 12 7 12.45 7 13C7 13.55 7.45 14 8 14H14C14.55 14 15 13.55 15 13C15 12.45 14.55 12 14 12Z" />
    <path d="M14 15H8C7.45 15 7 15.45 7 16C7 16.55 7.45 17 8 17H14C14.55 17 15 16.55 15 16C15 15.45 14.55 15 14 15Z" />
  </svg>
);

export default AddDocument;
