import React, { FunctionComponent } from 'react';

import Icons from '../assets/icons';

import Title from './text-components/Title';

export interface Props {
  onClick: () => void;
  title: string;
}

const MobileTopNav: FunctionComponent<Props> = ({ onClick, title }: Props) => (
  <div className="lg:hidden wrap pt-8 mb-24 relative">
    <div onClick={onClick} className="block absolute top-8 left-0">
      <Icons.Back />
    </div>
    <Title size="small" tag="h1" className="text-center w-full">{title}</Title>
  </div>
);

export default MobileTopNav;
