import React, { FunctionComponent } from 'react';
import _ from 'lodash/fp';

import Bodycopy from '../../../components/text-components/Bodycopy';
import Title from '../../../components/text-components/Title';
import LandingLayout from '../../../layout/LandingLayout';
import t from '../../../text-strings';
import LinkCard from '../../../components/cards/LinkCard';
import InfoCard from '../../../components/cards/InfoCard';
import Icons from '../../../assets/icons';
import Image from '../../../components/Image';
import images from '../../../assets/images';
import Accordion from '../../../components/Accordion';
import Wrap from '../../../components/containers/Wrap';
import HorizontalList from '../../../components/containers/HorizontalList';
import Banner from '../../../components/containers/Banner';
import { routes } from '../../../services/routing';
import { FAQAttributes } from '../../../store/model/contact/types';
import useFAQs from '../../../services/use-faq';
import InternalLink from '../../../components/InternalLink';

import BannerForm from './components/BannerForm';

type CardLink = {
  label: string;
  to: string;
}

const cardLinks: CardLink[] = [
  {
    label: t('landing.link_cards.averias_cortes'),
    to: routes.breakdowns,
  },
  {
    label: t('landing.link_cards.consumo'),
    to: routes.consumption,
  },
  {
    label: t('landing.link_cards.solicitudes'),
    to: routes.requests.index,
  },
  {
    label: t('landing.link_cards.fraude'),
    to: `${routes.contactForm}?select=fraude`,
  },
];

const justifyCenter = {
  justifyContent: 'center',
};

const Landing: FunctionComponent = () => {
  const [loading, faqs] = useFAQs('Preguntas_frecuentes_2');
  return (
    <LandingLayout>
      <Banner images={images.banner}>
        <Wrap size="wrap-thin" className="mt-36 lg:mt-72">
          <Title tag="h2" color="white" size="large" className="w-full mb-16 max-w-176 lg:max-w-screen-md">
            {t('landing.banner_title')}
          </Title>
          <Bodycopy color="white" className="w-full mb-8 max-w-176 lg:max-w-screen-md">
            {t('landing.banner_subtitle')}
          </Bodycopy>
          <BannerForm />
        </Wrap>
      </Banner>
      <HorizontalList
        className="relative mb-40 lg:mb-56 md:wrap-thin"
        innerClassName="px-1/24 absolute -top-16 left-0 md:px-0 md:justify-between"
      >
        {_.map((cardLink: CardLink) => (
          <LinkCard
            key={cardLink.label}
            to={cardLink.to}
            label={cardLink.label}
          />
        ))(cardLinks)}
      </HorizontalList>
      <Wrap size="wrap-thin">
        <div className="flex p-8 mb-20 rounded-sm bg-orange-lightest">
          <Icons.Warning color="orange" className="mr-6" />
          <div className="flex-1">
            <Bodycopy color="orange" className="mb-4">
              {t('landing.aviso.text')}
            </Bodycopy>
            <InternalLink
              to={`${routes.contactForm}?select=incidencia`}
              label={t('landing.aviso.link')}
              className="text-blue"
            />
          </div>
        </div>
        <Title className="mb-12">{t('landing.conexiones.title')}</Title>
        <div
          className="flex flex-wrap mb-16 sm:justify-around lg:justify-between lg:mb-32"
          style={justifyCenter}
        >
          <InfoCard
            title={t('landing.conexiones.suministro.title')}
            description={t('landing.conexiones.suministro.subtitle')}
            icon={<Icons.Bolt />}
            toPrivate={routes.supply.index}
            labelPrivate={t('landing.conexiones.button')}
            toInfo=""
            labelInfo=""
          />
          <InfoCard
            title={t('landing.conexiones.autoconsumo.title')}
            description={t('landing.conexiones.autoconsumo.subtitle')}
            icon={<Icons.Home />}
            toPrivate={routes.selfConsumption.process}
            labelPrivate={t('landing.conexiones.button')}
            toInfo={routes.selfConsumption.index}
            labelInfo={t('landing.conexiones.link')}
          />
          <InfoCard
            title={t('landing.conexiones.generacion.title')}
            description={t('landing.conexiones.generacion.subtitle')}
            icon={<Icons.People />}
            toPrivate={routes.generation}
            labelPrivate={t('landing.conexiones.button')}
            toInfo=""
            labelInfo=""
          />
        </div>
      </Wrap>
      <Wrap size="wrap-thin">
        <Title className="mb-16">{t('landing.grupo_cuerva.title')}</Title>
        <div className="mb-24 lg:mb-32 sm:flex sm:flex-wrap lg:flex-nowrap lg:justify-between lg:items-center">
          <div className="flex flex-col items-center mb-8 sm:w-1/2 lg:mb-0">
            <Image src={images.distribuidoras.bermejales} alt={t('landing.grupo_cuerva.bermejales')} className="h-16 lg:h-12 h56" />
          </div>
          <div className="hidden w-px h-24 bg-gray-500 lg:block" />
          <div className="flex flex-col items-center mb-8 sm:w-1/2 lg:mb-0">
            <Image src={images.distribuidoras.guadalfeo} alt={t('landing.grupo_cuerva.guadalfeo')} className="h-16 lg:h-12 h56" />
          </div>
          <div className="hidden w-px h-24 bg-gray-500 lg:block" />
          <div className="flex flex-col items-center mb-8 sm:w-1/2 lg:mb-0">
            <Image src={images.distribuidoras.buenaventura} alt={t('landing.grupo_cuerva.buenaventura')} className="h-16 lg:h-12 h56" />
          </div>
          <div className="hidden w-px h-24 bg-gray-500 lg:block" />
          <div className="flex flex-col items-center mb-8 sm:w-1/2 lg:mb-0">
            <Image src={images.distribuidoras.gregorio} alt={t('landing.grupo_cuerva.gregorio')} className="h-16 lg:h-12 h56" />
          </div>
        </div>
      </Wrap>
      <div className="mb-20 lg:mb-0">
        <Banner images={images.subbanner}>
          <Wrap size="wrap-thin" className="flex flex-col items-center justify-center flex-1">
            <Title color="white" className="mb-16">{t('landing.somos_tu_distribuidora')}</Title>
            <BannerForm />
          </Wrap>
        </Banner>
      </div>
      <div className="pt-16 bg-white pb-28 lg:pt-36 lg:pb-40">
        <Wrap size="wrap-thin" className="lg:flex">
          <div className="lg:min-w-144 lg:max-w-144 lg:w-144">
            <Title className="mb-12">{t('landing.faq')}</Title>
          </div>
          {!loading && (
          <div className="lg:flex-1">
            {_.map((faq: FAQAttributes) => (
              <Accordion
                key={faq.questionId}
                title={faq.title}
                text={faq.text}
              />
            ))(faqs)}
          </div>
          )}
        </Wrap>
      </div>
    </LandingLayout>
  );
};

export default Landing;
