import {
  action, Action, thunk, Thunk,
} from 'easy-peasy';

import { handleAxiosResponse, handleDataResponse } from '../../store-helpers';

import {
  AppointmentParams,
  ContactParams,
  DocumentationAttributes,
  DocumentationParams,
  FAQParams,
  TagAttributes,
} from './types';

export interface ContactModel {
  tag: TagAttributes[];
  documentation: DocumentationAttributes[];
  sendappointment: Thunk<ContactModel, AppointmentParams>;
  getquestionsbytype: Thunk<ContactModel, FAQParams>;
  sendcontact: Thunk<ContactModel, ContactParams>;
  getdocumentationtags: Thunk<ContactModel>;
  getdocumentation: Thunk<ContactModel, DocumentationParams>;
  initTags: Action<ContactModel, TagAttributes[]>;
  initDocumentation: Action<ContactModel, DocumentationAttributes[]>;
}

const contactModel: ContactModel = {
  tag: [],
  documentation: [],

  sendappointment: thunk(async (_actions, params, { injections }) => {
    const { contactService } = injections;
    return handleAxiosResponse(await contactService.sendappointment(params));
  }),

  getquestionsbytype: thunk(async (_actions, params, { injections }) => {
    const { contactService } = injections;
    return handleDataResponse(await contactService.getquestionsbytype(params));
  }),

  sendcontact: thunk(async (_actions, params, { injections }) => {
    const { contactService } = injections;
    return contactService.sendcontact(params);
  }),

  getdocumentationtags: thunk(async (actions, params, { injections }) => {
    const { contactService } = injections;
    return handleAxiosResponse(
      await contactService.getdocumentationtags(params),
      actions.initTags,
    );
  }),

  getdocumentation: thunk(async (actions, params, { injections }) => {
    const { contactService } = injections;
    return handleAxiosResponse(
      await contactService.getdocumentation(params),
      actions.initDocumentation,
    );
  }),

  initTags: action((state, params) => {
    state.tag = params;
  }),

  initDocumentation: action((state, params) => {
    state.documentation = params;
  }),
};

export default contactModel;
