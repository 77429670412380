import React, { FunctionComponent, ReactNode } from 'react';

interface Props {
  children: ReactNode;
  bgColor?: 'white' | 'cream';
}

const IconCircled: FunctionComponent<Props> = ({ children, bgColor = 'cream' }: Props) => (
  <div className="w-22 h-22 rounded-full bg-gradient flex flex-col items-center justify-center mb-8 lg:hidden">
    <div className={`w-20 h-20 rounded-full bg-${bgColor} flex flex-col items-center justify-center`}>
      {children}
    </div>
  </div>
);

export default IconCircled;
