import { useState } from 'react';
import _ from 'lodash/fp';

import { useStoreActions, useStoreState } from '../store/hooks';
import { RequestChangeResponseAttributes } from '../store/model/requests/types';
import azureAPI from '../store/model/azure/azure';
import t from '../text-strings';

import documents from './documents';
import pdfLib from './pdf';

type Response = string | RequestChangeResponseAttributes;

const useHandleChangeResponse = (): [
  string,
  boolean,
  boolean,
  (
    r: Response,
    d: File[],
    currentState: string,
    prevHistoricalId: number) => Promise<void>
] => {
  const { rollbackchangestaterequest } = useStoreActions(
    (actions) => actions.requests,
  );
  const { request } = useStoreState((state) => state.requests);

  const [message, setMessage] = useState('');
  const [isModal, setIsModal] = useState(true);
  const [success, setSuccess] = useState(true);

  const handleResponse = async (
    r: string | RequestChangeResponseAttributes,
    d: File[],
    currentState: string,
    prevHistoricalId: number,
  ): Promise<void> => {
    if (typeof r === 'string') {
      setMessage(r);
      setSuccess(false);
      setIsModal(false);
      return;
    }

    const azurePath = `${r.formCode}/${request.year}/${request.month}/${request.day}/${r.code}/${r.stateCodeName}/${prevHistoricalId}/`;

    setMessage(t('history.uploads.iniciando'));
    try {
      let documento;
      if (!_.isNil(r.pdfType)) {
        setMessage(t('history.uploads.uploading'));
        documento = Uint8Array.from(atob(r.pdfType), (c) => c.charCodeAt(0));
        const path = `${azurePath}${currentState.replace(
          /\s/g,
          '',
        )}-Salida.pdf`;
        await documents.uploadStreams(path, [documento], r.blob);
      }

      if (!_.isEmpty(d)) {
        setMessage(t('history.uploads.uploading'));
        for (let index = 0; index < _.size(d); index += 1) {
          const docPath = `${azurePath}${currentState.replace(
            /\s/g,
            '',
          )}-Entrada(${index + 1}).pdf`;
          // eslint-disable-next-line
          await azureAPI().upload({
            path: docPath,
            file: d[index],
            blob: r.blob,
          });
        }
      }

      if (!_.isUndefined(documento) || !_.isEmpty(d)) {
        setMessage(t('history.uploads.uploading'));
        const arrayBuffer = await documents.filesToArray(
          d.filter((x) => x !== null),
        );
        const unifyDoc = await pdfLib.unifyDocuments(
          [documento || null, ...(arrayBuffer || null)].filter(
            (x) => x !== null,
          ),
        );
        const sealedDoc = await pdfLib.stampDocument(
          unifyDoc,
          r.icon,
          r.code,
          r.identity,
          r.formName,
          r.distributorName,
          r.distributorLongName,
          true,
        );
        await documents.uploadStreams(
          `${azurePath}Unificado/DocumentoUnificado.pdf`,
          [sealedDoc],
          r.blob,
        );
      }
      setIsModal(false);
    } catch (_error) {
      setMessage(t('history.uploads.error'));
      setSuccess(false);
      // await documents.deleteAzurePath(azurePath, r.blob);
      setMessage(t('history.uploads.undoing'));
      const rollbackResponse = await rollbackchangestaterequest({
        historicalId: r.historicalId,
        rollbackError: '',
      });
      if (typeof rollbackResponse === 'string') {
        setMessage(rollbackResponse);
      }
      setIsModal(false);
    }
  };

  return [message, success, isModal, handleResponse];
};

export default useHandleChangeResponse;
