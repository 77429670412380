import React, { FunctionComponent } from 'react';
import classnames from 'classnames';
import _ from 'lodash/fp';

import navLinks, { NavLinkProps } from '../navigation';
import Title from '../../../components/text-components/Title';
import t from '../../../text-strings';

import NavLink from './NavLink';
import PrivateArea from './PrivateArea';

interface Props {
  open: boolean;
  thin: boolean;
}

const MobileMenu: FunctionComponent<Props> = ({ open, thin }: Props) => {
  const mobileMenuClass = classnames('mobile-menu', {
    'left-0 z-50': open,
    '-left-full': !open,
  });
  const innerMobileMenuClass = classnames('py-16', {
    wrap: !thin,
    'wrap-thin': thin,
  });

  return (
    <div className={mobileMenuClass} style={{ height: 'calc(100% - 56px)' }}>
      <div className={innerMobileMenuClass}>
        <ul>
          {_.map((navLink: NavLinkProps) => (
            <NavLink
              key={navLink.label}
              to={navLink.to}
              label={navLink.label}
              subLinks={navLink.subLinks}
            />
          ))(navLinks)}
        </ul>
        <div>
          <Title tag="h4" size="small" className="mb-8 text-center">
            {t('common.navigation.area_personal')}
          </Title>
          <PrivateArea />
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
