import { FormikErrors, FormikTouched } from 'formik';
import _ from 'lodash/fp';
import React, { ChangeEvent, FunctionComponent } from 'react';

import NumberInput from '../../../../components/forms/NumberInput';
import RadioButtonsInput from '../../../../components/forms/RadioButtonsInput';
import SelectInput from '../../../../components/forms/SelectInput';
import TextInput from '../../../../components/forms/TextInput';
import { useStoreState } from '../../../../store/hooks';
import {
  CauParams,
  CupsParams,
  GenerationParams,
  RequestData,
  RequestStep,
  SelfconsumptionParams,
  SupplyParams,
} from '../../../../store/model/requests/types';
import t from '../../../../text-strings';
import SelectLocality from '../../../../components/forms/SelectLocality';

import StepPage from './StepPage';

interface Props {
  title: string;
  prev?: RequestStep;
  step: number
  totalSteps: number;
  values: RequestData;
  handleChange: {
    // eslint-disable-next-line
    (e: React.ChangeEvent<any>): void;
    // eslint-disable-next-line
    <T_1 = string | React.ChangeEvent<any>>(field: T_1): T_1 extends React.ChangeEvent<any> 
      ? void
      // eslint-disable-next-line
      : (e: string | React.ChangeEvent<any>) => void;
  };
  handleBlur: {
    // eslint-disable-next-line
    (e: React.FocusEvent<any, Element>): void;
    // eslint-disable-next-line
    <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
  };
  handleSelect: (e: ChangeEvent<HTMLSelectElement>) => void;
  // eslint-disable-next-line
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<void> | Promise<FormikErrors<SupplyParams | SelfconsumptionParams | CauParams | GenerationParams | CupsParams>>;
  // eslint-disable-next-line
  errors: FormikErrors<SupplyParams | SelfconsumptionParams | CauParams | GenerationParams | CupsParams>;
  // eslint-disable-next-line
  touched: FormikTouched<SupplyParams | SelfconsumptionParams | CauParams | GenerationParams | CupsParams>;
}

const RequestDataStep: FunctionComponent<Props> = (
  {
    title,
    prev,
    step,
    totalSteps,
    values,
    handleChange,
    handleSelect,
    handleBlur,
    setFieldValue,
    errors,
    touched,
  }: Props,
) => {
  const { loadrequest } = useStoreState((state) => state.requests);
  const { municipalities } = useStoreState((state) => state.distributors);
  const findDistributorID = (value: number): number => _.flow(
    _.find(['municipalityId', value]),
    _.get('distributorId'),
  )(municipalities);

  const onChangeMunicipalitiy = (e: ChangeEvent<HTMLSelectElement>): void => {
    const { value, selectedOptions } = e.target;
    const intValue = parseInt(value, 10);
    setFieldValue('municipalityId', parseInt(value, 10));
    setFieldValue('locality', !_.gt(intValue, 0) ? '' : selectedOptions[0].label);
    setFieldValue('distributorId', !_.gt(intValue, 0) ? 0 : findDistributorID(intValue));
  };

  const onChangeTerrain = (e: ChangeEvent<HTMLInputElement>): void => {
    const { value, name } = e.target;
    setFieldValue(name, parseInt(value, 10));
  };

  return (
    <StepPage
      title={title}
      next="instalacion"
      prev={prev}
      totalSteps={totalSteps}
      current="solicitud"
      step={step}
    >
      {!_.isUndefined(values.address) && (
        <TextInput
          name="address"
          value={values.address}
          placeholder={t('models.requests.request.address')}
          onChange={handleChange('address')}
          onBlur={handleBlur('address')}
          touched={touched.address}
          errors={errors.address}
          required
        />
      )}
      {!_.isUndefined(values.locality) && !_.isUndefined(values.municipalityId) && (
        <SelectLocality
          name="municipalityId"
          value={values.municipalityId}
          selectables={municipalities}
          placeholder={t('models.requests.request.locality')}
          onChange={onChangeMunicipalitiy}
          onBlur={handleBlur('municipalityId')}
          touched={touched.municipalityId}
          errors={errors.municipalityId}
          required
        />
      )}
      {!_.isUndefined(values.provinceId) && (
        <SelectInput
          name="provinceId"
          value={values.provinceId}
          selectables={loadrequest.ProvinceId}
          placeholder={t('models.requests.request.province')}
          onChange={handleSelect}
          onBlur={handleBlur('provinceId')}
          touched={touched.provinceId}
          errors={errors.provinceId}
          required
          readonly
        />
      )}
      {!_.isUndefined(values.pc) && (
        <TextInput
          name="pc"
          value={values.pc}
          placeholder={t('models.requests.request.pc')}
          onChange={handleChange('pc')}
          onBlur={handleBlur('pc')}
          touched={touched.pc}
          errors={errors.pc}
          required
        />
      )}
      {!_.isUndefined(values.location) && (
        <TextInput
          name="location"
          value={values.location}
          placeholder={t('models.requests.request.location')}
          onChange={handleChange('location')}
          onBlur={handleBlur('location')}
          touched={touched.location}
          errors={errors.location}
          required
        />
      )}
      {!_.isUndefined(values.cups) && (
        <TextInput
          name="cups"
          value={values.cups}
          placeholder={t('models.requests.request.cups')}
          onChange={handleChange('cups')}
          onBlur={handleBlur('cups')}
          touched={touched.cups}
          errors={errors.cups}
          required
        />
      )}
      {!_.isUndefined(values.powerContract) && (
        <NumberInput
          name="powerContract"
          value={_.isEqual(values.powerContract, 0) ? '' : `${values.powerContract}`}
          placeholder={t('models.requests.request.power_contract')}
          onChange={handleChange('powerContract')}
          onBlur={handleBlur('powerContract')}
          touched={touched.powerContract}
          errors={errors.powerContract}
          required
        />
      )}
      {!_.isUndefined(values.terrainType) && (
        <RadioButtonsInput
          name="terrainType"
          value={values.terrainType}
          radios={loadrequest.TerrainType}
          placeholder={t('models.requests.request.terrain_type')}
          onChange={onChangeTerrain}
          onBlur={handleBlur('terrainType')}
          touched={touched.terrainType}
          errors={errors.terrainType}
        />
      )}
    </StepPage>
  );
};

export default RequestDataStep;
