import moment from 'moment';
import 'moment/locale/es';
import _ from 'lodash/fp';

const months = {
  ene: '01',
  feb: '02',
  mar: '03',
  abr: '04',
  may: '05',
  jun: '06',
  jul: '07',
  ago: '08',
  sep: '09',
  oct: '10',
  nov: '11',
  dic: '12',
};

const setDate = (currentDate: string): string => {
  const arrayDate = _.split(' ')(currentDate);
  // @ts-ignore
  return `${months[_.replace('.', '')(arrayDate[1])]}/${arrayDate[0]}/${
    arrayDate[2]
  }`;
};

const date = {
  init: (): void => {
    moment.locale('es');
  },

  format: (
    currentDate: string | Date,
    format: string,
    currentFormat = '',
  ): string => moment(currentDate, currentFormat).format(format),

  addToCurrent: (
    days: number,
    type: moment.unitOfTime.DurationConstructor,
  ): Date => moment(new Date()).add(days, type).toDate(),

  addDaysToDate: (currentDate: Date, days: number): Date => moment(currentDate).add(days, 'd').toDate(),

  removeToCurrent: (
    currentDate: Date,
    quantity: number,
    type: moment.unitOfTime.DurationConstructor,
  ): string => moment(currentDate).subtract(quantity, type).format('DD/MM/YYYY'),

  toDate: (currentDate: string): string => moment(currentDate).toString(),

  getMonthName: (currentDate: string): string => moment(currentDate, 'DD/MM/YYYY').format('MMMM'),

  getPreviousMonthName: (currentDate: string): string => moment(currentDate, 'DD/MM/YYYY').subtract(1, 'month').format('MMMM'),

  getYear: (currentDate: string): string => moment(currentDate, 'DD/MM/YYYY').format('YYYY'),

  now: (): string => moment().format('DD/MM/YYYY'),

  getDaysBetweenDates: (date1: string, date2: string): string[] => {
    const dates = [];
    const moment1 = moment(date1, 'DD/MM/YYYY');
    const moment2 = moment(date2, 'DD/MM/YYYY');

    while (moment1.isSameOrBefore(moment2)) {
      dates.push(moment1.format('DD-MM-YYYY'));
      moment1.add(1, 'days');
    }

    return dates;
  },

  differenceInhours: (start: string, end: string): string => moment(end).diff(moment(start), 'hours').toString(),

  differenceInDays: (start: string, end: string): number => moment(end, 'DD/MM/yyyy').diff(moment(start, 'DD/MM/yyyy'), 'd'),

  // eslint-disable-next-line
  difference: (start: Date, end: Date, unit: moment.unitOfTime.Diff): number => moment(end).diff(moment(start), unit),

  daysUntilToday: (currentDate: string): string => moment(new Date())
    .diff(moment(setDate(currentDate)), 'days')
    .toString(),

  isSameDay: (start: string | Date, end: string | Date): boolean => {
    const startDay = date.format(start, 'YYYY-MM-DD');
    const endDay = date.format(end, 'YYYY-MM-DD');
    return moment(startDay).isSame(endDay);
  },

  isBrefore: (start: string | Date, end: string | Date): boolean => {
    const startDay = date.format(start, 'YYYY-MM-DD HH:mm');
    const endDay = date.format(end, 'YYYY-MM-DD HH:mm');
    return moment(startDay).isBefore(endDay);
  },

  isAfter: (start: string | Date, end: string | Date): boolean => {
    const startDay = date.format(start, 'YYYY-MM-DD HH:mm');
    const endDay = date.format(end, 'YYYY-MM-DD HH:mm');
    return moment(startDay).isAfter(endDay);
  },

  getDateFromNow: (day: number, numMonths: number): Date => moment().set('date', day).add(numMonths, 'month').toDate(),

  startOfMonth: (): Date => moment().startOf('month').toDate(),

  nowDate: (): Date => moment().toDate(),

  isBetween: (
    start: string | Date,
    end: string | Date,
    selectedDate = new Date(),
  ): boolean => {
    const startDate = date.format(start, 'YYYY-MM-DD', 'DD/MM/YYYY');
    const endDate = date.format(end, 'YYYY-MM-DD', 'DD/MM/YYYY');

    return moment(selectedDate).isBetween(startDate, endDate, 'day', '[]');
  },
};

export default date;
