import _ from 'lodash/fp';
import React, { FunctionComponent, ReactNode } from 'react';

import LinkButton from '../buttons/LinkButton';
import InternalLink from '../InternalLink';
import Bodycopy from '../text-components/Bodycopy';
import Title from '../text-components/Title';

export interface Props {
  title: string;
  description: string;
  icon?: ReactNode;
  toPrivate: string;
  labelPrivate: string;
  toInfo: string;
  labelInfo: string;
}

const InfoCard: FunctionComponent<Props> = (
  {
    title,
    description,
    icon,
    toPrivate,
    labelPrivate,
    toInfo,
    labelInfo,
  }: Props,
) => (
  <article className="info-card">
    <header className="flex mb-12 w-full">
      <div className="flex-1">
        <Title size="small" tag="h4" className="mb-2">{title}</Title>
        <Bodycopy>{description}</Bodycopy>
      </div>
      {!_.isUndefined(icon) && icon}
    </header>
    <div className="w-full flex justify-between">
      <LinkButton
        to={toPrivate}
        label={labelPrivate}
        type="secondary"
      />
      <InternalLink
        to={toInfo}
        label={labelInfo}
        className="text-blue"
      />
    </div>
  </article>
);

export default InfoCard;
