import React, { FunctionComponent } from 'react';

import Icons from '../../../assets/icons';

interface Props {
  className?: string;
}

const SocialMedia: FunctionComponent<Props> = ({ className }: Props) => (
  <div className={className}>
    <a href="https://www.facebook.com/cuerva.energia" target="_blank" rel="noreferrer" className="block mr-8 lg:mr-16">
      <Icons.Facebook />
      <span className="hidden">Facebook</span>
    </a>
    <a href="https://www.instagram.com/cuerva.energia/" target="_blank" rel="noreferrer" className="block mr-8 lg:mr-16">
      <Icons.Instagram />
      <span className="hidden">Instagram</span>
    </a>
    <a href="https://x.com/Cuerva/" target="_blank" rel="noreferrer" className="block mr-8 lg:mr-16">
      <Icons.Twitter />
      <span className="hidden">Twitter</span>
    </a>
    <a href="https://www.linkedin.com/company/cuerva/" target="_blank" rel="noreferrer" className="block mr-8 lg:mr-16">
      <Icons.LinkedIn />
      <span className="hidden">LinkedIn</span>
    </a>
    <a
      href="https://www.youtube.com/channel/UC73bW6ltCM3-gaZPrP2DFRQ"
      target="_blank"
      rel="noreferrer"
      className="block mr-8 lg:mr-0"
      style={{ position: 'relative', top: '2px' }}
    >
      <Icons.Youtube />
      <span className="hidden">Youtube</span>
    </a>
  </div>
);

export default SocialMedia;
