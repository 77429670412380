import React, { FunctionComponent } from 'react';

import { AppColors } from '../../styles';

interface Props {
  color?: AppColors;
  width?: number;
  height?: number;
  className?: string;
}

const Notifications: FunctionComponent<Props> = (
  {
    color = 'black',
    width = 12,
    height = 12,
    className = '',
  }: Props,
) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className={`w-${width} h-${height} text-${color} stroke-current ${className}`}>
    <path d="M18 8C18 6.4087 17.3679 4.88258 16.2426 3.75736C15.1174 2.63214 13.5913 2 12 2C10.4087 2 8.88258 2.63214 7.75736 3.75736C6.63214 4.88258 6 6.4087 6 8C6 15 3 17 3 17H21C21 17 18 15 18 8Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill="transparent" />
    <path d="M13.73 21C13.5542 21.3031 13.3018 21.5547 12.9982 21.7295C12.6946 21.9044 12.3504 21.9965 12 21.9965C11.6496 21.9965 11.3054 21.9044 11.0018 21.7295C10.6982 21.5547 10.4458 21.3031 10.27 21" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill="transparent" />
  </svg>
);

export default Notifications;
