import { FormikErrors } from 'formik';
import _ from 'lodash';
import React, { ChangeEvent, FunctionComponent } from 'react';

import Icons from '../../../../assets/icons';
import Bodycopy from '../../../../components/text-components/Bodycopy';
import Title from '../../../../components/text-components/Title';
import { UserForm } from '../../../../forms/initial-values';
import { TouchedDocs } from '../../../../store/model';
import useSteps from '../../../../store/model/register/register';
import t from '../../../../text-strings';
import FormStepScreen from '../components/FormStepScreen';
import FormStepsNavigation from '../components/FormStepsNavigation';

/* eslint-disable @typescript-eslint/no-explicit-any */
interface Props {
  values: UserForm;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<UserForm>>;
  onBlur: (name: 'front' | 'back') => void;
  touched: TouchedDocs;
  errors: FormikErrors<UserForm>;
}
/* eslint-enable @typescript-eslint/no-explicit-any */

const YourIDCard: FunctionComponent<Props> = ({
  values,
  setFieldValue,
  onBlur,
  touched,
  errors,
}: Props) => {
  const [step, setStep] = useSteps();

  const handleDocument = (e: ChangeEvent<HTMLInputElement>): void => {
    const { files, name } = e.target;
    if (_.isNil(files)) return;
    setFieldValue(name, files[0]);
  };

  return (
    <FormStepScreen
      onNext={() => setStep('supply-point')}
      onBack={() => setStep('personal-data')}
      current={_.isEqual(step, 'id-card')}
    >
      <FormStepsNavigation
        blockClassNames={['bg-black', 'bg-black', 'bg-black', '', '']}
      />
      <Title className="mb-4 lg:mb-8">{t('register.id_card')}</Title>
      <Bodycopy className="mb-16 lg:mb-20 max-w-240 w-full">
        {t('register.id_card_subtitle')}
      </Bodycopy>
      <div
        className="mb-16 lg:mb-20 cursor-pointer"
        onClick={(): void => onBlur('front')}
      >
        <label
          htmlFor="idFront"
          className="flex items-center btn text-blue mb-2"
        >
          <Icons.Attach color="blue" className="mr-4" />
          {_.isEmpty(values.idFront.name)
            ? t('models.user.attributes.id_front')
            : t('models.user.attributes.id_front_complete')}
        </label>
        <input
          type="file"
          name="idFront"
          id="idFront"
          onChange={handleDocument}
          className="hidden"
          accept="image/*, .pdf"
        />
        {touched.front && <Bodycopy color="red">{errors.idFront}</Bodycopy>}
      </div>
      <div
        className="mb-16 lg:mb-20 cursor-pointer"
        onClick={(): void => onBlur('back')}
      >
        <label
          htmlFor="idBack"
          className="flex items-center btn text-blue mb-2"
        >
          <Icons.Attach color="blue" className="mr-4" />
          {_.isEmpty(values.idBack.name)
            ? t('models.user.attributes.id_back')
            : t('models.user.attributes.id_back_complete')}
        </label>
        <input
          type="file"
          name="idBack"
          id="idBack"
          onChange={handleDocument}
          className="hidden"
          accept="image/*, .pdf"
        />
        {touched.back && <Bodycopy color="red">{errors.idBack}</Bodycopy>}
      </div>
    </FormStepScreen>
  );
};

export default YourIDCard;
