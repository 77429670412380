import React, { FunctionComponent, ReactNode } from 'react';

type Images = {
  mobile: string;
  desktop: string;
}

interface Props {
  children: ReactNode;
  images: Images;
}

const LG_SCREEN = 1024;

const Banner: FunctionComponent<Props> = (
  {
    children,
    images,
  }: Props,
) => {
  const windowWidth = window.innerWidth;
  let image: string = images.desktop;
  if (windowWidth < LG_SCREEN) {
    image = images.mobile;
  }

  return (
    <div className="banner" style={{ backgroundImage: `url(${image})` }}>
      <div className="inner-banner">
        {children}
      </div>
    </div>
  );
};

export default Banner;
