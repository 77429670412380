import _ from 'lodash/fp';
import { useLocation } from 'react-router-dom';

const useSelectedURL = (): ((value: string) => boolean) => {
  const location = useLocation();

  return _.isEqual(location.pathname);
};

export default useSelectedURL;
