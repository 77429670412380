import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';

import './styles/index.scss';
import 'typeface-manrope';
import App from './App';
import reportWebVitals from './reportWebVitals';

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID || 'GTM-PRKPTJL',
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// eslint-disable-next-line no-console
reportWebVitals();
