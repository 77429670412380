import t from '../../../text-strings';
import { ListData } from '../../../components/ListItem';

const processDocumentList: ListData[] = [
  {
    position: 1,
    title: t('supply.procesos.listado_documentación.1.title'),
  },
  {
    position: 2,
    title: t('supply.procesos.listado_documentación.2.title'),
  },
  {
    position: 3,
    title: t('supply.procesos.listado_documentación.3.title'),
    description: t('supply.procesos.listado_documentación.3.description'),
  },
  {
    position: 4,
    title: t('supply.procesos.listado_documentación.4.title'),
    description: t('supply.procesos.listado_documentación.4.description'),
  },
  {
    position: 5,
    title: t('supply.procesos.listado_documentación.5.title'),
    description: t('supply.procesos.listado_documentación.5.description'),
  },
  {
    position: 6,
    title: t('supply.procesos.listado_documentación.6.title'),
  },
];

export default processDocumentList;
