import _ from 'lodash/fp';
import React, {
  ChangeEvent,
  FocusEvent,
  FunctionComponent,
  ReactNode,
  useState,
} from 'react';
import classnames from 'classnames';

import classNames from '../../services/classNames';
import Bodycopy from '../text-components/Bodycopy';
import Modal from '../Modal';
import Icons from '../../assets/icons';

import FormGroup from './components/FormGroup';
import InputError from './components/InputError';
import Radio from './components/Radio';

export type RadioProps = {
  selectorId: number;
  value: string;
  formCode?: string;
  section?: string;
};

export interface Props {
  name: string;
  value: number;
  placeholder: string;
  radios: RadioProps[];
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  touched?: boolean;
  errors?: string;
  readonly?: boolean;
  disabled?: boolean;
  containerClassName?: string;
  labelClassName?: string;
  children?: ReactNode;
}

const RadioButtonsInput: FunctionComponent<Props> = ({
  name,
  value,
  placeholder,
  radios,
  onChange,
  onBlur = undefined,
  touched = false,
  errors = '',
  readonly = false,
  disabled = false,
  containerClassName = '',
  labelClassName = '',
  children,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  // @ts-ignore
  const valueNumber = parseInt(value, 10);
  const checked = _.isEqual(valueNumber);
  const hasInfo = !_.isUndefined(children);
  const toggleInfo = (): void => setIsOpen(!isOpen);

  const labelClass = classnames(classNames('mb-12', labelClassName), {
    'flex items-center': hasInfo,
  });

  return (
    <FormGroup className={classNames('radiobuttons-input', containerClassName)}>
      <Bodycopy className={labelClass}>
        {placeholder}
        {hasInfo && (
          <button type="button" className="block ml-4" onClick={toggleInfo}>
            <Icons.Info color="blue" />
          </button>
        )}
      </Bodycopy>
      {_.map((radio: RadioProps) => (
        <Radio
          key={radio.selectorId}
          name={name}
          label={radio.value}
          value={radio.selectorId}
          readonly={readonly}
          disabled={disabled}
          onChange={onChange}
          onBlur={onBlur}
          checked={checked(radio.selectorId)}
          no_break_line
        />
      ))(radios)}
      <InputError message={touched ? errors : ''} />
      {hasInfo && (
        <Modal isOpen={isOpen} toggleOpen={toggleInfo}>
          {children}
        </Modal>
      )}
    </FormGroup>
  );
};

export default RadioButtonsInput;
