import React, { FunctionComponent } from 'react';
import classnames from 'classnames';

import Wrap from '../../components/containers/Wrap';
import EUFundedFooter from '../../components/EuFundedFooter';

import Legal from './components/Legal';
import SocialMedia from './components/SocialMedia';
import WebMap from './components/WebMap';

interface Props {
  thin?: boolean;
  onlyDesktop?: boolean;
}

const Footer: FunctionComponent<Props> = ({ thin = false, onlyDesktop = false }: Props) => (
  <footer className={classnames('bg-gradient-v pb-8 pt-22', { 'hidden lg:block': onlyDesktop })}>
    <Wrap size={thin ? 'wrap-thin' : 'wrap'}>
      <WebMap />
      <div className="block lg:hidden">
        <SocialMedia className="flex items-center" />
        <div className="w-full h-px bg-black my-8" />
        <Legal />
        <EUFundedFooter />
      </div>
      <div className="hidden lg:block">
        <div className="flex justify-between">
          <Legal />
          <SocialMedia className="flex items-center" />
        </div>
        <div className="w-full h-px bg-black my-8" />
        <EUFundedFooter />
      </div>
    </Wrap>
  </footer>
);

export default Footer;
