import React, { FunctionComponent } from 'react';
import {
  PieChart, Pie, Label, Cell, ResponsiveContainer,
} from 'recharts';

import helpers from '../../services/helpers';
import { ConsumptionByTypes } from '../../store/model/contract/types';

import CircleLabel from './CircleLabel';

interface PieAreaProps {
    consumptionByTypes: ConsumptionByTypes[];
}

interface PieChartSubtitleProps {
    type: ConsumptionByTypes
}

const COLORS = [['#F9AC39', '#EDEDED'], ['#FFDA00', '#EDEDED'], ['#FBD59D', '#EDEDED']];

const PieChartSubtitle: FunctionComponent<PieChartSubtitleProps> = (
  { type }: PieChartSubtitleProps,
) => (
  <div className="w-1/3 text-center">
    <div className="text-xs font-medium">
      {type?.typeHour}
    </div>
    <div className="text-xs font-medium text-gray-400">
      {type?.typeText}
    </div>
  </div>
);

const PieArea: FunctionComponent<PieAreaProps> = (
  { consumptionByTypes }: PieAreaProps,
) => {
  const [peakType, valleyType, flatType] = consumptionByTypes;

  const peakValue = helpers.percentageToNumber(peakType?.porcentage);

  const peakPieData = [
    { name: 'used', value: peakValue },
    { name: 'not_used', value: 100 - peakValue },
  ];

  const valleyValue = helpers.percentageToNumber(
    consumptionByTypes[1]?.porcentage,
  );
  const valleyPieData = [
    { name: 'used', value: valleyValue },
    { name: 'not_used', value: 100 - valleyValue },
  ];

  const flatValue = helpers.percentageToNumber(
    consumptionByTypes[2]?.porcentage,
  );
  const flatPieData = [
    { name: 'used', value: flatValue },
    { name: 'not_used', value: 100 - flatValue },
  ];

  return (
    <>
      <div className="flex flex-row relative pb-72 mt-12">
        <div className="absolute inset-0 lg:inset">
          <ResponsiveContainer width="33%" className="absolute inset-y-0 left-0">
            <PieChart>
              <Pie
                data={peakPieData}
                innerRadius="85%"
                outerRadius="87%"
                cornerRadius={20}
                paddingAngle={5}
                startAngle={90}
                endAngle={450}
                dataKey="value"
              >
                {peakPieData.map((_entry, index) => (
                  <Cell
                    // eslint-disable-next-line
                    key={`cell-${index}`}
                    fill={COLORS[0][index % COLORS.length]}
                    stroke={COLORS[0][index % COLORS.length]}
                    strokeWidth={index === 0 ? 4 : 1}
                  />
                ))}
                <Label
                  position="center"
                  content={(
                    <CircleLabel
                      valueBox={{ cx: '50%', cy: '45%' }}
                      percentage={helpers.cleanPercentage(peakType?.porcentage, 1)}
                      energy={helpers.roundPieEnery(peakType?.consumption)}
                    />
                  )}
                />
              </Pie>
            </PieChart>
          </ResponsiveContainer>
          <ResponsiveContainer width="33%" className="absolute inset-x-1/3">
            <PieChart>
              <Pie
                data={valleyPieData}
                innerRadius="85%"
                outerRadius="87%"
                cornerRadius={20}
                paddingAngle={5}
                startAngle={90}
                endAngle={450}
                dataKey="value"
              >
                {valleyPieData.map((_entry, index) => (
                  <Cell
                    // eslint-disable-next-line
                    key={`cell-${index}`}
                    fill={COLORS[1][index % COLORS.length]}
                    stroke={COLORS[1][index % COLORS.length]}
                    strokeWidth={index === 0 ? 4 : 1}
                  />
                ))}
                <Label
                  position="center"
                  content={(
                    <CircleLabel
                      valueBox={{ cx: '50%', cy: '45%' }}
                      percentage={helpers.cleanPercentage(valleyType?.porcentage, 1)}
                      energy={helpers.roundPieEnery(valleyType?.consumption)}
                    />
                    )}
                />
              </Pie>
            </PieChart>
          </ResponsiveContainer>
          <ResponsiveContainer width="33%" className="absolute inset-y-0 right-0">
            <PieChart>
              <Pie
                data={flatPieData}
                innerRadius="85%"
                outerRadius="87%"
                cornerRadius={20}
                paddingAngle={5}
                startAngle={90}
                endAngle={450}
                dataKey="value"
              >
                {flatPieData.map((_entry, index) => (
                  <Cell
                    // eslint-disable-next-line
                    key={`cell-${index}`}
                    fill={COLORS[2][index % COLORS.length]}
                    stroke={COLORS[2][index % COLORS.length]}
                    strokeWidth={index === 0 ? 4 : 1}
                  />
                ))}
                <Label
                  position="center"
                  content={(
                    <CircleLabel
                      valueBox={{ cx: '50%', cy: '45%' }}
                      percentage={helpers.cleanPercentage(flatType?.porcentage, 1)}
                      energy={helpers.roundPieEnery(flatType?.consumption)}
                    />
                    )}
                />
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>
      <div className="flex">
        <PieChartSubtitle type={peakType} />
        <PieChartSubtitle type={valleyType} />
        <PieChartSubtitle type={flatType} />
      </div>
    </>
  );
};

export default PieArea;
