import _ from 'lodash/fp';
import React, { FunctionComponent } from 'react';

import Bodycopy from '../../../../components/text-components/Bodycopy';
import Title from '../../../../components/text-components/Title';
import useNavigation from '../../../../services/navigation';
import { routes } from '../../../../services/routing';
import useAuthSteps from '../../../../store/model/authorized/authorized';
import t from '../../../../text-strings';
import FormStepsNavigation from '../components/FormStepNavigation';
import FormStepScreen from '../components/FormStepScreen';

const Complete: FunctionComponent = () => {
  const [step, setStep] = useAuthSteps();
  const navigate = useNavigation();

  return (
    <FormStepScreen
      onNext={() => setStep('id-card')}
      onBack={() => navigate(routes.profile)}
      current={_.isEqual(step, 'complete')}
      finish
    >
      <FormStepsNavigation blockClassNames={['bg-black', 'bg-black', 'bg-black', 'bg-black']} />
      <Title className="mb-12">{t('authorized.complete')}</Title>
      <Bodycopy className="mb-10 lg:mb-20 max-w-240">{t('authorized.complete_subtitle_1')}</Bodycopy>
      <Bodycopy className="max-w-240">{t('authorized.complete_subtitle_2')}</Bodycopy>
    </FormStepScreen>
  );
};

export default Complete;
