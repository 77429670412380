import { useFormik } from 'formik';
import React, { FunctionComponent, useEffect, useState } from 'react';
import _ from 'lodash/fp';

import Wrap from '../../../../components/containers/Wrap';
import Form from '../../../../components/forms/Form';
import FormResponse from '../../../../components/forms/FormResponse';
import Title from '../../../../components/text-components/Title';
import useNavigation from '../../../../services/navigation';
import useQuery from '../../../../services/query';
import { routes } from '../../../../services/routing';
import { useStoreActions, useStoreState } from '../../../../store/hooks';
import {
  OpenRequests,
  RequestHistoryParams,
} from '../../../../store/model/requests/types';
import t from '../../../../text-strings';
import constants from '../../../../services/constants';
import Icons from '../../../../assets/icons';
import Modal from '../../../../components/Modal';
import Bodycopy from '../../../../components/text-components/Bodycopy';
import InternalLink from '../../../../components/InternalLink';
import TextInput from '../../../../components/forms/TextInput';

import RequestList from './RequestsList';

const RequestHistory: FunctionComponent = () => {
  const { requests } = useStoreState((state) => state.requests);
  const { session } = useStoreState((state) => state.session);
  const { getallrequests } = useStoreActions((actions) => actions.requests);
  const { getrequesthistory } = useStoreActions((actions) => actions.requests);
  const [message, setMessage] = useState('');
  const [modal, setModal] = useState(false);
  const query = useQuery();
  const error = query.get('error') || '';
  const navigate = useNavigation();

  const handleOnSumbit = async (
    values: RequestHistoryParams,
  ): Promise<void> => {
    const result = await getrequesthistory(values);
    if (typeof result === 'string') {
      setMessage(result);
      return;
    }
    navigate(routes.requests.history(values.codeRequest));
  };

  useEffect(() => {
    (async () => {
      const params: OpenRequests = { nif: session.get('nif') };
      await getallrequests(params);
    })();
  }, []);

  const toggleModal = (): void => setModal(!modal);

  const { values, handleChange, handleSubmit } = useFormik({
    initialValues: {
      codeRequest: '',
      distributorId: constants.DISTRIBUTOR_ID,
    },
    onSubmit: handleOnSumbit,
  });

  const hasMoreHidden = requests.length > 5;

  return (
    <div className="pt-12 mb-20 bg-white lg:pt-24 lg:bg-transparent">
      <Wrap size="wrap-authenticated">
        <Title tag="h3" className="hidden mb-12 lg:block">
          {t('common.navigation.mis_solicitudes')}
        </Title>
        <div className="lg:flex lg:flex-row lg:justify-between">
          <Form
            onSubmit={handleSubmit}
            className="w-full mb-2 lg:max-w-2/3 lg:w-3/4"
          >
            <div className="flex items-start w-full py-2 mb-22 lg:mb-0 lg:flex-col">
              <div className="flex flex-row w-full">
                <TextInput
                  name="codeRequest"
                  value={values.codeRequest}
                  placeholder={t('requests.code_placeholder')}
                  onChange={handleChange('codeRequest')}
                  containerClassName="lg:mb-12"
                  maxW
                />
                <button type="submit">
                  <Icons.Search color="blue" className="mb-4 ml-2 lg:mb-14" />
                </button>
              </div>
            </div>
          </Form>
          {hasMoreHidden && (
            <div className="justify-center hidden lg:flex">
              <InternalLink
                to={routes.requests.all}
                label="Ver todas"
                className="mb-24 text-blue"
              />
            </div>
          )}
        </div>
        {(!_.isEmpty(message) || !_.isEmpty(error)) && (
          <div className="-mt-16 mb-22">
            <FormResponse message={_.isEmpty(message) ? error : message} />
          </div>
        )}
        <Modal isOpen={modal} toggleOpen={toggleModal}>
          <Title size="small" className="mb-12">
            {t('requests.n_solicitud')}
          </Title>
          <Bodycopy>{t('requests.modal')}</Bodycopy>
        </Modal>
        <div className="z-50">
          <RequestList requests={requests} />
        </div>
      </Wrap>
    </div>
  );
};

export default RequestHistory;
