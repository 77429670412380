import React, { FunctionComponent, ReactNode } from 'react';

import Icons from '../../../../assets/icons';
import Caption from '../../../../components/text-components/Caption';
import t from '../../../../text-strings';

interface Props {
  icon: ReactNode;
  label: string;
}

const IconCircle: FunctionComponent<Props> = ({ icon, label }: Props) => (
  <div className="flex flex-col items-center">
    <div className="flex flex-col justify-center items-center border border-black rounded-full w-24 h-24 mb-4 sm:w-32 sm:h-32">
      {icon}
    </div>
    <Caption>{label}</Caption>
  </div>
);

const IconsRow: FunctionComponent = () => (
  <div className="flex justify-between max-w-168 w-full mb-24 lg:mb-36">
    <IconCircle
      icon={<Icons.Home />}
      label={t('supply.icons.viviendas')}
    />
    <IconCircle
      icon={<Icons.People />}
      label={t('supply.icons.ferias')}
    />
    <IconCircle
      icon={<Icons.Tool />}
      label={t('supply.icons.obras')}
    />
    <IconCircle
      icon={<Icons.Pin />}
      label={t('supply.icons.parcelas')}
    />
  </div>
);

export default IconsRow;
