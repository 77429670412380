import React, { FunctionComponent } from 'react';
import { Switch } from 'react-router-dom';

import { paths } from '../services/routing';

import AuthenticationPage from './AuthenticationPage';
import Landing from './public/landing/Landing';
import Login from './authentication/Login';
import PublicPage from './PublicPage';
import Register from './authentication/register/Register';
import Registered from './authentication/Registered';
import PasswordReset from './authentication/PasswordReset';
import PasswordChange from './authentication/PasswordChange';
import PrivatePage from './PrivatePage';
import Consumption from './authenticated/consumption/Consumption';
import Requests from './authenticated/requests/Requests';
import Breakdowns from './authenticated/Breakdowns';
import Notifications from './authenticated/Notifications';
import Profile from './authenticated/Profile';
import Validation from './authentication/Validation';
import AddAuthorized from './authenticated/authorizations/AddAuthorized';
import AuthorizedCreated from './authenticated/AuthorizedCreated';
import ContractDetails from './authenticated/ContractDetails';
import Supply from './public/electrical-supply/Supply';
import SupplyGuide from './public/electrical-supply/SupplyGuide';
import SupplyProcess from './public/electrical-supply/SupplyProcess';
import CupsProcess from './public/electrical-supply/CupsProcess';
import CupsGuesser from './public/electrical-supply/CupsGuesser';
import SelfConsumption from './public/self-consumption/SelfConsumption';
import SelfConsumptionProcess from './public/self-consumption/SelfConsumptionProcess';
import Generation from './public/Generation';
import NodalCapacities from './public/NodalCapacities';
import TipsToSave from './authenticated/consumption/TipsToSave';
import SupplyRequest from './authenticated/requests/SupplyRequest';
import GenerationRequest from './authenticated/requests/GenerationRequest';
import History from './authenticated/requests/History';
import CupsRequest from './authenticated/requests/CupsRequest';
import CauRequest from './authenticated/requests/CauRequest';
import SelfConsumptionRequest from './authenticated/requests/SelfconsumptionRequest';
import RequestsListComplete from './authenticated/requests/components/RequestsListComplete';
import EditProfile from './authenticated/EditProfile';
import Contact from './public/Contact';
import ContactForm from './public/ContactForm';
import Normative from './public/Normative';
import LegalWarning from './public/LegalWarning';
import CookiePolicy from './public/CookiePolicy';
import PrivacyPolicy from './public/PrivacyPolicy';
import QualityPolicy from './public/QualityPolicy';
import CallUs from './public/CallUs';
import Calendly from './public/Calendly';
import Counter from './authenticated/Counter';
import CounterSchedule from './authenticated/CounterSchedule';
import MeasureDetails from './authenticated/MeasureDetails';
import Raffle from './public/Raffle';

const Routing: FunctionComponent = () => (
  <Switch>
    <PublicPage path={paths.landing} exact>
      <Landing />
    </PublicPage>

    <PublicPage path={paths.raffle} exact>
      <Raffle />
    </PublicPage>

    <PublicPage path={paths.supply.index} exact>
      <Supply />
    </PublicPage>
    <PublicPage path={paths.supply.guide}>
      <SupplyGuide />
    </PublicPage>
    <PublicPage path={paths.supply.supplyProcess}>
      <SupplyProcess />
    </PublicPage>
    <PublicPage path={paths.supply.cupsProcess}>
      <CupsProcess />
    </PublicPage>
    <PublicPage path={paths.supply.cupsGuesser}>
      <CupsGuesser />
    </PublicPage>

    <PublicPage path={paths.selfConsumption.index} exact>
      <SelfConsumption />
    </PublicPage>
    <PublicPage path={paths.selfConsumption.process} exact>
      <SelfConsumptionProcess />
    </PublicPage>

    <PublicPage path={paths.generation}>
      <Generation />
    </PublicPage>

    <PublicPage path={paths.nodalCapacities}>
      <NodalCapacities />
    </PublicPage>

    <PublicPage path={paths.contact}>
      <Contact />
    </PublicPage>
    <PublicPage path={paths.callUs}>
      <CallUs />
    </PublicPage>
    <PublicPage path={paths.book}>
      <Calendly />
    </PublicPage>
    <PublicPage path={paths.contactForm}>
      <ContactForm />
    </PublicPage>
    <PublicPage path={paths.normative}>
      <Normative />
    </PublicPage>

    <PublicPage path={paths.legalWarning}>
      <LegalWarning />
    </PublicPage>
    <PublicPage path={paths.cookiePolicy}>
      <CookiePolicy />
    </PublicPage>
    <PublicPage path={paths.privacyPolicy}>
      <PrivacyPolicy />
    </PublicPage>
    <PublicPage path={paths.qualityPolicies}>
      <QualityPolicy />
    </PublicPage>

    <AuthenticationPage path={paths.login}>
      <Login />
    </AuthenticationPage>
    <AuthenticationPage path={paths.register}>
      <Register />
    </AuthenticationPage>
    <AuthenticationPage path={paths.registered}>
      <Registered />
    </AuthenticationPage>
    <AuthenticationPage path={paths.validation}>
      <Validation />
    </AuthenticationPage>
    <AuthenticationPage path={paths.password.recovery}>
      <PasswordReset />
    </AuthenticationPage>
    <AuthenticationPage path={paths.password.confirmation}>
      <PasswordChange />
    </AuthenticationPage>

    <PrivatePage path={paths.consumption}>
      <Consumption />
    </PrivatePage>
    <PrivatePage path={paths.counter}>
      <Counter />
    </PrivatePage>
    <PrivatePage path={paths.measure}>
      <MeasureDetails />
    </PrivatePage>
    <PrivatePage path={paths.counterSchedule}>
      <CounterSchedule />
    </PrivatePage>
    <PrivatePage path={paths.tipsToSave}>
      <TipsToSave />
    </PrivatePage>
    <PrivatePage path={paths.requests.index} exact>
      <Requests />
    </PrivatePage>
    <PrivatePage path={paths.requests.all}>
      <RequestsListComplete />
    </PrivatePage>
    <PrivatePage path={paths.requests.supply}>
      <SupplyRequest />
    </PrivatePage>
    <PrivatePage path={paths.requests.generation}>
      <GenerationRequest />
    </PrivatePage>
    <PrivatePage path={paths.requests.cups}>
      <CupsRequest />
    </PrivatePage>
    <PrivatePage path={paths.requests.cau}>
      <CauRequest />
    </PrivatePage>
    <PrivatePage path={paths.requests.selfconsumption}>
      <SelfConsumptionRequest />
    </PrivatePage>

    <PrivatePage path={paths.requests.history}>
      <History />
    </PrivatePage>

    <PrivatePage path={paths.breakdowns}>
      <Breakdowns />
    </PrivatePage>
    <PrivatePage path={paths.notifications}>
      <Notifications />
    </PrivatePage>
    <PrivatePage path={paths.profile}>
      <Profile />
    </PrivatePage>
    <PrivatePage path={paths.editProfile}>
      <EditProfile />
    </PrivatePage>
    <PrivatePage path={paths.contract}>
      <ContractDetails />
    </PrivatePage>
    <PrivatePage path={paths.authorized.create}>
      <AddAuthorized />
    </PrivatePage>
    <PrivatePage path={paths.authorized.created}>
      <AuthorizedCreated />
    </PrivatePage>
  </Switch>
);

export default Routing;
