import React, {
  ChangeEvent,
  FunctionComponent,
  useEffect,
  useState,
} from 'react';
import { useFormik } from 'formik';
import _ from 'lodash/fp';

import AuthenticationLayout from '../../../layout/AuthenticationLayout';
import Form from '../../../components/forms/Form';
import t from '../../../text-strings';
import { useStoreActions } from '../../../store/hooks';
import { UserParams } from '../../../store/model/user/types';
import formInit, { UserForm } from '../../../forms/initial-values';
import schemas from '../../../forms/schemas';
import helpers from '../../../forms/helpers';
import useRegisterResponse from '../../../services/use-register-response';
import { TouchedDocs } from '../../../store/model';

import YourPermissions from './steps/YourPermissions';
import YourPersonalData from './steps/YourPersonalData';
import YourSuplyPoint from './steps/YourSupplyPoint';
import YourAccessData from './steps/YourAccessData';
import SideNavLink from './components/SideNavLink';
import YourIDCard from './steps/YourIDCard';

const Register: FunctionComponent = () => {
  const { create } = useStoreActions((actions) => actions.user);
  const [message, success, handleResponse] = useRegisterResponse();
  const { setStep } = useStoreActions((actions) => actions.register);
  const [docsTouched, setDocsTouched] = useState<TouchedDocs>({
    front: false,
    back: false,
  });

  const handleOnSubmit = async (values: UserForm): Promise<void> => {
    // @ts-ignore;
    const docs: File[] = _.flow(
      _.pick(['idFront', 'idBack']),
      _.valuesIn,
    )(values);
    // @ts-ignore;
    const userParams: UserParams = _.omit([
      'repeatPassowrd',
      'repeatEmail',
      'idFront',
      'idBack',
    ])(values);

    userParams.nif = userParams.nif.toUpperCase();

    handleResponse(await create(userParams), docs);
  };

  const {
    handleSubmit,
    values,
    setFieldValue,
    handleBlur,
    touched,
    errors,
    handleChange,
    isSubmitting,
    isValid,
  } = useFormik({
    initialValues: formInit.user,
    onSubmit: handleOnSubmit,
    validationSchema: schemas.register,
    validateOnMount: true,
  });

  useEffect(() => setStep('permissions'), []);

  const isCompleted = helpers.isCompleted(values);
  const hasErrors = helpers.hasErrors(errors, touched, docsTouched);

  const onBlurDocs = (name: 'front' | 'back'): void => {
    const newTouched = docsTouched;
    newTouched[name] = true;
    setDocsTouched(newTouched);
  };

  return (
    <AuthenticationLayout>
      <Form onSubmit={handleSubmit} className="flex-1 wrap lg:flex">
        <div className="lg:w-7/10 lg:pt-20">
          <YourPermissions
            userTypeId={values.userTypeId}
            onBlur={handleBlur('userTypeId')}
            touched={touched.userTypeId}
            errors={errors.userTypeId}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setFieldValue('userTypeId', parseInt(e.target.value, 10));
            }}
          />
          <YourPersonalData
            values={values}
            onChange={handleChange}
            onBlur={handleBlur}
            touched={touched}
            errors={errors}
          />
          <YourIDCard
            values={values}
            onBlur={onBlurDocs}
            touched={docsTouched}
            errors={errors}
            setFieldValue={setFieldValue}
          />
          <YourSuplyPoint
            values={values}
            onChange={handleChange}
            onBlur={handleBlur}
            touched={touched}
            errors={errors}
          />
          <YourAccessData
            values={values}
            onChange={handleChange}
            onBlur={handleBlur}
            setFieldValue={setFieldValue}
            touched={touched}
            errors={errors}
            message={message}
            success={success}
            disabled={isSubmitting || !isValid}
          />
        </div>
        <div className="hidden w-1/3 lg:flex flex-col bg-white relative pt-72 pl-32">
          <div className="bg-white absolute top-0 left-full w-screen-full h-full" />
          <SideNavLink
            label={t('register.permisos')}
            currentStep="permissions"
            isComplete={isCompleted.permissions}
            hasErrors={hasErrors.permissions}
          />
          <SideNavLink
            label={t('register.datos')}
            currentStep="personal-data"
            isComplete={isCompleted.data}
            hasErrors={hasErrors.data}
          />
          <SideNavLink
            label={t('register.id_card')}
            currentStep="id-card"
            isComplete={isCompleted.idCard}
            hasErrors={hasErrors.idCard}
          />
          <SideNavLink
            label={t('register.suministro')}
            currentStep="supply-point"
            isComplete={isCompleted.supply}
            hasErrors={hasErrors.supply}
          />
          <SideNavLink
            label={t('register.acceso')}
            currentStep="access-data"
            isComplete={isCompleted.access}
            hasErrors={hasErrors.access}
          />
        </div>
      </Form>
    </AuthenticationLayout>
  );
};

export default Register;
