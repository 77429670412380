import React, { FunctionComponent } from 'react';
import classnames from 'classnames';

export interface Props {
  variants: string[];
  selected: boolean;
  onToggle: () => void;
  underline?: boolean;
}

const Toggle: FunctionComponent<Props> = (
  {
    variants,
    selected,
    onToggle,
    underline = false,
  }: Props,
) => {
  const toggleClass = classnames('p-2 relative w-full cursor-pointer h-22', {
    'border border-black rounded-xl max-w-168 lg:border-0': !underline,
    'border-b border-black': underline,
  });

  const innerClass = 'flex items-center justify-between absolute top-2 left-0 w-full h-18';

  const variantsClass = classnames('btn w-1/2 relative rounded-xl h-18 flex items-center', {
    'text-center': !underline,
  });

  const firstVariantClass = classnames(variantsClass, {
    'text-white lg:text-black': !selected && !underline,
    'justify-center': !underline,
    'justify-start': underline,
  });

  const secondVariantClass = classnames(variantsClass, {
    'text-white lg:text-black': selected && !underline,
    'justify-center': !underline,
    'justify-end': underline,
  });

  const labelClass = classnames('absolute w-1/2', {
    'rounded-xl bg-black top-2 h-18 lg:bg-gradient lg:bottom-0 lg:h-2 lg:top-auto': !underline,
    'bg-gradient bottom-0 h-2': underline,
  });

  return (
    <div onClick={onToggle} className={toggleClass}>
      <p
        className={labelClass}
        style={{ left: selected ? 'calc(50% - 4px)' : '4px' }}
      />
      <div className={innerClass}>
        <p className={firstVariantClass}>
          <span>{variants[0]}</span>
        </p>
        <p className={secondVariantClass}>
          <span>{variants[1]}</span>
        </p>
      </div>
    </div>
  );
};

export default Toggle;
