import { useEffect, useState } from 'react';

import { useStoreActions } from '../store/hooks';

type LegalType = 'LegalWarning' | 'QualityPolicies' | 'PrivacyPolicy' | 'CookiesPolicy';

const useLegal = (type: LegalType): string => {
  const { getlegalinfo } = useStoreActions((actions) => actions.distributors);
  const [info, setInfo] = useState('');

  useEffect(() => {
    (async () => {
      const response = await getlegalinfo(type);
      setInfo(response.data);
    })();
  }, []);

  return info;
};

export default useLegal;
