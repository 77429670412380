import React, { FunctionComponent, useEffect, useState } from 'react';

import LinkButton from '../../components/buttons/LinkButton';
import FormResponse from '../../components/forms/FormResponse';
import Subtitle from '../../components/text-components/Subtitle';
import Title from '../../components/text-components/Title';
import AuthenticationLayout from '../../layout/AuthenticationLayout';
import useQuery from '../../services/query';
import { routes } from '../../services/routing';
import { useStoreActions } from '../../store/hooks';
import constants from '../../services/constants';
import useNavigation from '../../services/navigation';
import useHandleResponse from '../../services/handle-response';
import MobileTopNav from '../../components/MobileTopNav';

const Validation: FunctionComponent = () => {
  const { validate } = useStoreActions((actions) => actions.user);
  const [message, handleFormResponse] = useHandleResponse('');
  const [isLoading, setIsLoading] = useState(true);
  const query = useQuery();
  const navigate = useNavigation();

  useEffect(() => {
    (async () => {
      const code = query.get('codigo') || '';
      handleFormResponse(
        await validate({ keyValidation: code, distributorId: constants.DISTRIBUTOR_ID }),
      );
      setIsLoading(false);
    })();
  }, []);

  return (
    <AuthenticationLayout>
      <MobileTopNav onClick={() => navigate(routes.landing)} title="Validación" />
      <div className="wrap-thin flex-1 flex flex-col justify-center items-center">
        {isLoading ? (
          <Title>Validando...</Title>
        ) : (
          <div className="flex flex-col items-center">
            {message === '' ? (
              <>
                <Title className="mb-4">Validado</Title>
                <Subtitle className="mb-8">Ya puedes acceder a tu cuenta</Subtitle>
                <LinkButton
                  label="acceder"
                  to={routes.login}
                />
              </>
            ) : (
              <FormResponse message={message} className="mb-12" />
            )}
          </div>
        )}
      </div>
    </AuthenticationLayout>
  );
};

export default Validation;
