import React,
{
  CSSProperties, FunctionComponent, useEffect, useState,
} from 'react';
import Snowfall from 'react-snowfall';
import { useFormik } from 'formik';
import moment from 'moment';

import PublicLayout from '../../layout/PublicLayout';
import t from '../../text-strings';
import ActionButton from '../../components/buttons/ActionButton';
import Image from '../../components/Image';
import images from '../../assets/images';
import Accordion from '../../components/Accordion';
import TextInput from '../../components/forms/TextInput';
import SelectInput from '../../components/forms/SelectInput';
import { LoadRequestItemAttributes } from '../../store/model/requests/types';
import CheckboxInput from '../../components/forms/CheckboxInput';
import Bodycopy from '../../components/text-components/Bodycopy';
import Modal from '../../components/Modal';
import { useStoreActions } from '../../store/hooks';

const Raffle: FunctionComponent = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [messageModal, setMessageModal] = useState('');
  const [titleModal, setTitleModal] = useState('Hay un error en el formulario');
  const [sendStatus, setSendStatus] = useState(false);
  const [sendText, setSendText] = useState('Enviar participación');
  const toggleModal = (): void => setIsOpen(!isOpen);

  useEffect(() => {
    // Expand accordion on start
    const ac = document.getElementById('father-acc')?.firstChild?.firstChild;
    if (ac) {
      (ac as HTMLElement).click();
    }
  }, []);

  const {
    register,
  } = useStoreActions((actions) => actions.raffle);

  const gdpr = {
    textAlign: 'justify',
  } as CSSProperties;

  const noPadding = {
    padding: '0px',
    // background: '#130e1e',
    // background: rgb(19,14,30);
    background: 'linear-gradient(180deg, rgba(19,14,30,1) 0%, rgba(19,14,30,1) 82.5%, rgba(255,255,255,1) 82.5%)',
  };
  const noPaddingHeader = {
    padding: '0px',
    // background: '#130e1e',
    // background: rgb(19,14,30);
    background: 'linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 83.5%, rgba(19,14,30,1) 83.5%)',
  };
  const titulo = {
    textAlign: 'center',
  } as CSSProperties;

  const enlace = {
    textDecoration: 'underline',
    fontWeight: 'bold',
  } as CSSProperties;

  const headerMobile = {
    background: '#130e1e', // `url('${images.sorteoMobile}')`,
    // height: '512px',
    color: '#fff',
  } as CSSProperties;

  const textoHeaderMobile = {
    padding: '1rem',
  } as CSSProperties;

  const bannerBlancoMobile = {
    background: '#fff',
    width: '100%',
    color: '#130e1e',
    padding: '0.5rem 1rem',
    marginTop: '1rem',
    display: 'flex',
    justifyContent: 'space-evenly',
  } as CSSProperties;

  const formStyle = {
    marginTop: '1rem',
  } as CSSProperties;

  const center = {
    textAlign: 'center',
  } as CSSProperties;

  const justified = {
    textAlign: 'justify',
  } as CSSProperties;

  const ganadores = {
    marginTop: '15px',
  } as CSSProperties;

  const scrollToForm = () => {
    const bases = document.getElementById('ganadores');
    const scroll = {
      top: (bases?.offsetTop ?? 0),
      behavior: 'smooth',
    // eslint-disable-next-line no-undef
    } as ScrollToOptions;
    if (bases) window.scrollTo(scroll);
  };

  const municipios = [
    'ALBONDÓN', 'ALBUÑOL', 'ALPUJARRA', 'AGRÓN', 'ARENAS',
    'BÉRCHULES', 'CÁDIAR', 'CALICASAS', 'ESCÚZAR', 'FORNES',
    'JÁTAR', 'JAYENA', 'JUVILES', 'LA MALAHÁ', 'LÁCHAR',
    'SAN GREGORIO', 'SORVILÁN', 'VALLE DEL ZALABÍ',
  ];
  const municipiosForm: LoadRequestItemAttributes[] = [
    {
      selectorId: 1, value: 'ALBONDÓN', formCode: '', section: '',
    },
    {
      selectorId: 2, value: 'ALBUÑOL', formCode: '', section: '',
    },
    {
      selectorId: 3, value: 'ALPUJARRA', formCode: '', section: '',
    },
    {
      selectorId: 4, value: 'AGRÓN', formCode: '', section: '',
    },
    {
      selectorId: 5, value: 'ARENAS', formCode: '', section: '',
    },
    {
      selectorId: 6, value: 'BÉRCHULES', formCode: '', section: '',
    },
    {
      selectorId: 7, value: 'CÁDIAR', formCode: '', section: '',
    },
    {
      selectorId: 8, value: 'CALICASAS', formCode: '', section: '',
    },
    {
      selectorId: 9, value: 'ESCÚZAR', formCode: '', section: '',
    },
    {
      selectorId: 10, value: 'FORNES', formCode: '', section: '',
    },
    {
      selectorId: 11, value: 'JÁTAR', formCode: '', section: '',
    },
    {
      selectorId: 12, value: 'JAYENA', formCode: '', section: '',
    },
    {
      selectorId: 13, value: 'JUVILES', formCode: '', section: '',
    },
    {
      selectorId: 14, value: 'LA MALAHÁ', formCode: '', section: '',
    },
    {
      selectorId: 15, value: 'LÁCHAR', formCode: '', section: '',
    },
    {
      selectorId: 16, value: 'SAN GREGORIO', formCode: '', section: '',
    },
    {
      selectorId: 17, value: 'SORVILÁN', formCode: '', section: '',
    },
    {
      selectorId: 18, value: 'VALLE DEL ZALABÍ', formCode: '', section: '',
    },
  ];

  const toggleError = (message: string) => {
    setMessageModal(message);
    setIsOpen(true);
  };

  const handleOnSubmit = async (val: unknown) => {
    const form: typeof values = val as typeof values;
    const reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!form.email.toString().trim().length) {
      toggleError('El campo "Email" es obligatorio');
      return;
    }
    if (reg.test(form.email) === false) {
      toggleError('El campo "Email" no tiene el formato correcto');
      return;
    }
    if (!form.nombre.toString().trim().length) {
      toggleError('El campo "Nombre" es obligatorio');
      return;
    }
    if (!municipios[(+form.municipio) - 1]) {
      toggleError('El campo "Municipio" es obligatorio');
      return;
    }
    if (!form.consent) {
      toggleError('Debe aceptar las condiciones del sorteo');
      return;
    }
    const mun = municipios[(+form.municipio) - 1];
    setSendText('Enviando participación...');
    setSendStatus(true);
    try {
      const response = await register({
        NombreCompleto: form.nombre,
        Municipio: mun,
        Telefono: form.telefono !== '' ? form.telefono : undefined,
        Email: form.email,
        FechaAlta: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
        Comercial: form.comercial,
      });
      if (response === undefined) throw new Error('Algo salió mal');
      setIsSent(true);
    } catch (err) {
      setMessageModal('Ocurrió un error al enviar la participación. '
        + 'Por favor inténtalo más tarde.');
      setSendText('Error al enviar la participación');
      setTitleModal('Error en la participación');
      setIsOpen(true);
    }
  };

  const {
    values,
    handleChange,
  } = useFormik({
    initialValues: {
      email: '' as string,
      nombre: '' as string,
      municipio: -1 as number,
      consent: false as boolean,
      comercial: false as boolean,
      telefono: '' as string,
    },
    onSubmit: handleOnSubmit,
  });

  const getGDPR = (distributor: string) => (
    <div style={gdpr}>
      La empresa responsable del tratamiento de sus datos es el Responsable:&nbsp;
      <b>
        {distributor}
      </b>
      &nbsp;con domicilio en: Calle Santa Lucía, nº 1K
      18194 Churriana De La Vega, Granada. Email: dpo@cuervaenergia.com.
      www.cuervaenergia.com. El Responsable del Tratamiento se toma muy en serio
      la protección de su privacidad y de los datos personales de sus clientes.
      Por lo tanto, tu información personal se conserva de forma segura y se trata
      con el máximo cuidado.
      <br />
      <br />
      De conformidad con lo establecido en la normativa aplicable en materia de
      protección de datos, y concretamente en el Reglamento (UE) 2016/679 del
      Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a la
      protección de las personas físicas en lo que respecta al tratamiento de datos
      personales y a la libre circulación de estos datos, cada participante, con la
      aceptación de estas Bases Legales consiente que los datos personales facilitados
      para la participación en este concurso sean incorporados a una actividad de
      tratamiento titularidad de&nbsp;
      <b>
        {distributor}
      </b>
      &nbsp;para tramitar la
      participación en el concurso y para comunicarle el premio en caso de que
      resultase ganador, además, si es cliente, para poder verificar que los datos que
      constan en su expediente están actualizados.
      <br />
      <br />
      Los participantes garantizan que los datos personales facilitados son veraces y
      se hacen responsables de comunicar a&nbsp;
      <b>
        {distributor}
      </b>
      , cualquier
      modificación de los mismos.
      <b>
        {distributor}
      </b>
      &nbsp;se reserva el derecho
      a excluir de la presente promoción a todo Participante que haya facilitado
      datos falsos.
      <br />
      <br />
      Los Participantes tienen derecho a (i) acceder a sus datos personales, así como
      a (ii) solicitar la rectificación de los datos inexactos o, en su caso,
      solicitar su supresión, (iii) solicitar la limitación del tratamiento de sus
      datos, (iv) oponerse al tratamiento de sus datos y (v) solicitar su
      portabilidad.
      <br />
      <br />
      Puede presentar una reclamación a la A.E.P.D., mediante la sede electrónica de
      la Autoridad o por medios no electrónicos, en el caso que entienda vulnerados
      sus derechos. Tiene más información sobre nuestra política de privacidad en
      nuestra web anteriormente indicada.
    </div>
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const formulario = () => (
    <Accordion
      key="quiero-participar"
      title="Formulario de inscripción"
      text={!isSent
        ? (
          <div>
            <TextInput
              name="email"
              placeholder={t('models.user.attributes.email')}
              value={values.email}
              onChange={handleChange('email')}
              required
            />
            <TextInput
              name="nombre"
              placeholder={t('models.user.attributes.name')}
              value={values.nombre}
              onChange={handleChange('nombre')}
              required
            />
            <SelectInput
              name="municipio"
              value={values.municipio}
              selectables={municipiosForm}
              placeholder="Elige el municipio"
              onChange={handleChange('municipio')}
              required
            />
            <TextInput
              name="telefono"
              placeholder={t('models.user.attributes.movil')}
              value={values.telefono}
              onChange={handleChange('telefono')}
            />
            <CheckboxInput
              name="consent"
              onChange={handleChange('consent')}
              value={values.consent}
            >
              He leído, comprendo y acepto las bases del sorteo y tratamiento de mis
              datos personales*
            </CheckboxInput>
            <CheckboxInput
              name="comercial"
              onChange={handleChange('comercial')}
              value={values.comercial}
            >
              Acepto el envío de comunicaciones comerciales.
            </CheckboxInput>
            <div className="grid content-center">
              <ActionButton label={sendText} className="mt-8 center" onClick={() => handleOnSubmit(values)} disabled={sendStatus} />
            </div>
            <Modal isOpen={isOpen} toggleOpen={toggleModal}>
              <p className="btn mb-8">{titleModal}</p>
              <Bodycopy className="mb-4">{messageModal}</Bodycopy>
            </Modal>
          </div>
        ) : <Bodycopy className="mb-4">¡Gracias por participar!</Bodycopy>}
    />
  );

  return (
    <PublicLayout title={t('raffle.title')}>
      <Snowfall />
      <link
        href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap-grid.min.css"
        rel="stylesheet"
        crossOrigin="anonymous"
      />
      <div className="container-fluid" style={noPadding}>
        <div className="row justify-content-center">
          <div className="col-xl-6 col-md-8 col-12 hidden sm:block">
            <Image src={images.sorteoDesktop} />
          </div>
          <div className="col-xl-6 col-md-8 col-12 block sm:hidden" style={headerMobile}>
            <Image src={images.sorteoMobile} />
            <p style={textoHeaderMobile}>
              *El acontecimiento anual más esperado.
              Te esperan regalos, sorresas y un
              <b> GRAN SORTEO</b>
              .
            </p>
            <div style={bannerBlancoMobile}>
              <span>Cabalgata de Reyes</span>
              <span>05.01.2024</span>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid" style={noPaddingHeader}>
        <div className="row justify-content-center">
          <div className="col-xl-6 col-md-8 col-12 hidden sm:block">
            <Image src={images.sorteoHeaderPc} />
          </div>
          <div className="col-xl-6 col-md-8 col-12 block sm:hidden" style={headerMobile}>
            <Image src={images.sorteoHeaderMobile} />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6 col-md-8 col-12" id="bases-legales">
            <br />
            <div style={center}>
              <ActionButton label="GANADORES DEL SORTEO" onClick={scrollToForm} />
            </div>
            <br />
            <br />
            <div style={center} className="container-fluid" id="ganadores">
              <div className="row">
                <div className="col-12" style={ganadores}>
                  <Image src={images.ganadores1} />
                  <a href="https://app-sorteos.com/w/MGJM57">
                    Pulsa aquí para ir al certificado del sorteo
                  </a>
                  <br />
                  <br />
                  <Bodycopy>
                    Los ganadores podrán personarse en las oficinas de Cuerva a partir del 16 de
                    enero hasta el 9 de febrero en horario de lunes a jueves de 8:00 a 14:00 y de
                    15:00 a 17:00 y viernes de 08:00 a 14:00h.
                  </Bodycopy>
                </div>
              </div>
            </div>
            <br />
            <br />
            <Accordion
              key="bases-legales"
              title="Bases para la participación en el sorteo"
              text={(
                <div style={justified}>
                  <h3 style={titulo}><b>BASES PARA LA PARTICIPACIÓN EN EL SORTEO</b></h3>
                  <h1 style={titulo}><b>“Una noche mágica”</b></h1>
                  <br />
                  <p>
                    Grupo Empresarial Cuerva, con domicilio social en Calle Nueva de san Antón,
                    20 - PISO 5 A, y con CIF B18955765 (en adelante “El Organizador”), llevará a
                    cabo un Sorteo denominado “Una noche mágica” (en adelante, “El Sorteo”) que se
                    desarrollará de conformidad con lo establecido en las presentes bases.
                  </p>
                  <br />
                  <div>
                    <p><b>1.- EMPRESA ORGANIZADORA DE LAS PROMOCIONES</b></p>
                    <p>Grupo Empresarial Cuerva</p>
                    <p>B18955765</p>
                    <p>Calle Nueva de san Antón, 20 - PISO 5 A</p>
                    <p>Granada</p>
                    <p>18005</p>
                    <p>Granada</p>
                  </div>
                  <br />
                  <div>
                    <p><b>2.-FECHA DE INICIO Y FECHA DE FINALIZACIÓN</b></p>
                    <p>
                      Las bases de promoción se aplicarán al sorteo que empezará la noche del 5 de
                      enero en la Cabalgata de Reyes y finalizará el día 12 de enero a las 12:00
                      horas.
                    </p>
                  </div>
                  <br />
                  <div>
                    <p><b>3.- REQUISITOS DE PARTICIPACIÓN</b></p>
                    <p>Los requisitos de participación serán los siguientes:</p>
                    <p>
                      Podrán participar aquellas personas que sean mayores de edad.
                    </p>
                  </div>
                  <br />
                  <div>
                    <p><b>4.- MECÁNICA DEL SORTEO Y CONDICIÓN DE LOS PREMIOS</b></p>
                    <p>
                      Los usuarios podrán participar en el sorteo a través de un
                      <b> código QR </b>
                      que aparecerá en un
                      <b> marcapáginas de imán </b>
                      que será lanzado a través de
                      la cabalgata de Reyes en los distintos municipios en donde distribuyen
                      electricidad las empresas distribuidoras de energía pertenecientes
                      al Grupo Empresarial Cuerva.
                    </p>
                    <p>
                      Para poder participar en el sorteo, el usuario deberá acceder a la landing
                      page &quot;
                      <a style={enlace} href="https://distribucion.cuervaenergia.com/sorteo">
                        https://distribucion.cuervaenergia.com/sorteo
                      </a>
                      &quot; creada para este, dentro del período de participación descrito en el
                      punto 2, y completar el registro en el sorteo rellenando su nombre, apellidos,
                      correo electrónico y dirección.
                    </p>
                    <br />
                    <p>
                      <b> Los premios serán:</b>
                      <br />
                      <p>
                        18 tarjetas regalo &quot;El Corte Ingl&eacute;s&quot; por importe de 50€.
                      </p>
                    </p>
                    <br />
                    <p>
                      El sorteo tiene una fecha límite para la participación indicada, que será el
                      día 12 de enero a las 12:00 horas, momento a partir del cual se llevará a cabo
                      el sorteo. El/la ganador/a conoce y consiente que su nombre completo y primer
                      apellido sea publicado a través de la landing page &quot;
                      <a style={enlace} href="https://distribucion.cuervaenergia.com/sorteo">
                        https://distribucion.cuervaenergia.com/sorteo
                      </a>
                      &quot; creada para el sorteo, en nuestras redes sociales (
                      <a style={enlace} href="https://www.linkedin.com/company/cuerva/">
                        LinkedIn,
                      </a>
                      &nbsp;
                      <a style={enlace} href="https://x.com/Cuerva/">
                        X,
                      </a>
                      &nbsp;
                      <a style={enlace} href="https://www.facebook.com/cuerva.energia/">
                        Facebook
                      </a>
                      ), junto con el certificado de validez del mismo y contactado a través de
                      teléfono.
                    </p>
                    <br />
                    <p>
                      <b>
                        El resultado del sorteo se realizará a través de Easypromos o AppSorteos
                      </b>
                      , aplicaciones de terceros que realizan sorteos aleatorios, ofreciendo un
                      certificado de validez.
                      <br />
                    </p>
                    <br />
                    <p>
                      De no reclamar el premio en el periodo de 20 días, el organizador comunicará
                      el premio a la persona suplente, el cual dispondrá del mismo periodo de 20
                      días para su reclamación, en caso de no comparecer, se declarará desierto.
                    </p>
                    <br />
                    <p>
                      Los premios son intransferibles. Se limita a un premio por persona. El
                      organizador se reserva el derecho a sustituir el premio por otro análogo
                      de mismo o superior valor en caso de fuerza mayor. En ningún caso se podrán
                      canjear los premios por su valor en metálico.
                    </p>
                    <br />
                    <p>
                      <u>Condiciones para tarjetas regalo &quot;El Corte Inglés&quot;</u>
                      <br />
                      <p>
                        El/la ganador/a del premio debe personarse en las oficinas de Cuerva en
                        Calle Santa Lucía 1K, 18194, Churriana de la Vega, Granada. Podrá hacerlo
                        hasta el 31 de enero para recoger la tarjeta correspondiente.
                      </p>
                    </p>
                  </div>
                  <br />
                  <div>
                    <p><b>5.- DESCALIFICACIONES Y PENALIZACIONES</b></p>
                    <p>
                      Si se evidenciara que cualquiera de los participantes no cumple con los
                      requisitos exigidos en las Bases, o los datos proporcionados para participar
                      no fueran válidas, su participación se considerará nula y quedarán
                      automáticamente excluidos del sorteo,perdiendo todo derecho sobre los premios
                      otorgados en virtud de este sorteo.
                    </p>
                  </div>
                  <br />

                  <div>
                    <p><b>6.- EXONERACIÓN DE RESPONSABILIDAD</b></p>
                    <p>
                      El ganador se responsabiliza de recaudar cualquier permiso o consentimiento
                      necesario para disfrute del premio y de cualquier incidente que se produzca
                      durante el mismo.
                    </p>
                    <br />
                    <p>
                      Los premios quedan sujetos a las presentes bases legales y no será posible
                      sustituirlos total o parcialmente por dinero en metálico.
                    </p>
                    <br />
                    <p>
                      Los premios no serán susceptibles de cambio, alteración o compensación a
                      petición de los ganadores. Si se rechaza el premio, por parte del ganador,
                      no se le ofrecerá ningún alternativo. Queda estrictamente prohibida su venta
                      o enajenación.
                    </p>
                    <br />
                    <p>
                      Los participantes del sorteo exoneran, de la forma más amplia que en derecho
                      proceda y sin limitar en modo sus derechos como consumidores, al organizador,
                      por cualquier tipo de responsabilidad, sanción, reclamación, demanda o causa
                      civil, mercantil, penal, administrativa, incluyendo indemnizaciones de
                      cualquier naturaleza y/o índole, gastos, costas (con expresa inclusión de los
                      honorarios de letrados y procuradores).
                    </p>
                    <br />
                    <p>
                      El participante acepta que el organizador pueda aplazar, acortar, prorrogar,
                      modificar, cancelar o suspender el sorteo como consecuencia de causas de
                      fuerza mayor ajenas a su control, comprometiéndose a informar a los
                      participantes de esta circunstancia lo antes posible.
                    </p>
                    <br />
                    <p>
                      El organizador queda facultado para resolver cualquier contingencia no
                      prevista en las presentes bases legales.
                    </p>
                  </div>

                  <br />
                  <div>
                    <p><b>7.- CAMBIOS</b></p>
                    <p>
                      Nos reservamos el derecho de modificar o ampliar estas bases, en la medida
                      en que no perjudique o menoscabe los derechos de los participantes en el
                      sorteo.
                      <br />
                      El simple hecho de participar en un sorteo implica la aceptación íntegra de
                      las presentes Bases. En caso de no ser aceptado el premio según consta en
                      estas Bases, se entendería que se renuncia al mismo.
                    </p>
                  </div>

                  <br />
                  <div>
                    <p><b>8.- LEGISLACIÓN APLICABLE Y JURISDICCIÓN</b></p>
                    <p>
                      Estas bases legales se regirán de conformidad con la ley española. Serán
                      competentes para resolver cualquier reclamación o controversia que pudiera
                      plantearse en relación con la validez, interpretación o cumplimiento de estas
                      bases los Juzgados y Tribunales de la ciudad de Granada.
                    </p>
                  </div>
                  <br />

                  <div>
                    <p><b>9.- CONSIDERACIONES FINALES</b></p>
                    <p>
                      El presente sorteo tiene carácter gratuito para los participantes. Tanto el
                      sorteo como los premios están sujetos a cambios o modificaciones.
                    </p>
                  </div>
                  <br />

                  <div>
                    <p><b>10.- PROTECCIÓN DE DATOS</b></p>
                    <Accordion
                      key="guadalfeo"
                      title="CLÁUSULA SORTEO - Eléctrica del Guadalfeo, S.L"
                      text={getGDPR('Eléctrica del Guadalfeo, S.L')}
                    />
                    <Accordion
                      key="buenaventura"
                      title="CLÁUSULA SORTEO - Hidroeléctrica San Buenaventura, S.L"
                      text={getGDPR('Hidroeléctrica San Buenaventura, S.L')}
                    />
                    <Accordion
                      key="bermejales"
                      title="CLÁUSULA SORTEO - Distribuidora Eléctrica Bermejales, S.L"
                      text={getGDPR('Distribuidora Eléctrica Bermejales, S.L')}
                    />
                    <Accordion
                      key="gregorio"
                      title="CLÁUSULA SORTEO - Distribuidora Eléctrica San Gregorio, S.L"
                      text={getGDPR('Distribuidora Eléctrica San Gregorio, S.L')}
                    />
                  </div>
                  <br />

                  <br />
                </div>
              )}
            />

          </div>
        </div>
        <br />
        <hr />
        <br />
        <div className="row justify-content-center" id="quiero-participar">
          <div className="col-xl-6 col-md-8 col-12 center" id="father-acc" style={formStyle}>
            <Accordion
              key="quiero-participar"
              title="Sorteo cerrado"
              text={<Bodycopy>El plazo de inscripción cerró el 12/01/2024 a las 12:00hs</Bodycopy>}
            />
          </div>
        </div>
      </div>
    </PublicLayout>
  );
};

export default Raffle;
