import React, { FunctionComponent, useState } from 'react';

import Icons from '../../../../assets/icons';
import images from '../../../../assets/images';
import ActionButton from '../../../../components/buttons/ActionButton';
import Image from '../../../../components/Image';
import Modal from '../../../../components/Modal';
import Bodycopy from '../../../../components/text-components/Bodycopy';
import { Any } from '../../../../store/model/global';
import t from '../../../../text-strings';

const ImagesRow: FunctionComponent<Any> = () => (
  <div className="flex justify-between gap-10">
    <div className="flex flex-col w-1/3 items-center">
      <Image
        src={images.autoconsumo.compartido.vecinos}
        className="mb-4 mx-auto w-24 h-24"
      />
      <p className="text-xs text-center w-2/3 lg:w-3/4">
        {t('consumption.shared.buildings')}
      </p>
    </div>
    <div className="flex flex-col w-1/3 items-center">
      <Image
        src={images.autoconsumo.compartido.empresas}
        className="mb-4 mx-auto w-24 h-24"
      />
      <p className="text-xs text-center w-2/3 lg:w-full">
        {t('consumption.shared.companies')}
      </p>
    </div>
    <div className="flex flex-col w-1/3 items-center">
      <Image
        src={images.autoconsumo.compartido.municipios}
        className="mb-4 mx-auto w-24 h-24"
      />
      <p className="text-xs text-center w-2/3 lg:w-full">
        {t('consumption.shared.localities')}
      </p>
    </div>
  </div>
);

const WhatIsSection: FunctionComponent<Any> = () => (
  <div>
    <h3 className="lg:text-md font-semibold">
      {t('consumption.shared.section1_title')}
    </h3>
    <div className="flex flex-col lg:flex-row gap-18 mt-8 mb-20 lg:gap-0">
      <Bodycopy className="lg:max-w-1/2 lg:mr-36">
        {t('consumption.shared.section1_body')}
      </Bodycopy>
      <ImagesRow />
    </div>
  </div>
);

const EnergyDistribution: FunctionComponent = () => (
  <div className="lg:flex lg:flex-row lg:gap-10 lg:mt-40">
    <div className="lg:max-w-1/2 lg:mr-36">
      <h3 className="lg:text-md font-semibold mb-8">
        {t('consumption.shared.section2_title')}
      </h3>
      <Bodycopy>{t('consumption.shared.section2_body')}</Bodycopy>
    </div>
    <div className="flex flex-col justify-center">
      <p className="text-xs font-medium mt-8 mb-12 lg:text-sm lg:mt-0">
        {t('consumption.shared.section2_aux')}
      </p>
      <div className="flex self-start">
        <Image
          src={images.autoconsumo.compartido.inscolectiva1}
          className="h-auto w-full"
        />
      </div>
    </div>
  </div>
);

const EnergyMonetize: FunctionComponent = () => (
  <div className="lg:flex lg:flex-row lg:gap-10 lg:mt-40">
    <div className="lg:max-w-1/2 lg:mr-36">
      <h3 className="font-semibold mt-20 mb-8">
        {t('consumption.shared.section3_title')}
      </h3>
      <Bodycopy>{t('consumption.shared.section3_body1')}</Bodycopy>
      <br />
      <Bodycopy>{t('consumption.shared.section3_body2')}</Bodycopy>
      <br />
      <Bodycopy>{t('consumption.shared.section3_body3')}</Bodycopy>
    </div>
    <div className="flex flex-col justify-center">
      <p className="text-xs mt-14 mb-8 lg:text-sm">
        {t('consumption.shared.section3_aux')}
      </p>
      <div className="flex self-start">
        <Image
          src={images.autoconsumo.compartido.instcolectivacon}
          className="h-auto w-full"
        />
      </div>
    </div>
  </div>
);

const Shared: FunctionComponent = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [knowMoreIsOpen, setKnowMoreIsOpen] = useState(false);
  const toggleModal = (): void => setIsOpen(!isOpen);

  return (
    <div className="w-full mx-auto lg:border-b-4 border-cream lg:-mb-2">
      <WhatIsSection />
      <EnergyDistribution />
      <EnergyMonetize />
      <div className="hidden lg:flex">
        <div className="lg:flex lg:flex-row lg:gap-10 lg:mt-24">
          <div className="lg:max-w-1/2 lg:mr-36">
            <Bodycopy>{t('consumption.shared.know_more_body')}</Bodycopy>
            <ActionButton
              type="link"
              label="¿Por qué hay instalaciones con sistema antivertido?"
              onClick={toggleModal}
              right
              className="text-left mt-14"
            />
            <Modal isOpen={isOpen} toggleOpen={toggleModal}>
              <p className="btn mb-8">Sistema antivertido</p>
              <Bodycopy className="mb-4">
                Para instalaciones descritas en el RD 1183/2020 es necesario
                solicitar permisos de acceso y conexión a la red para poder
                verter energía. En esos casos, hay autoconsumos que deciden no
                verter, agilizando el proceso de alta pero renunciando a
                compensar la energía vertida a la red.
              </Bodycopy>
            </Modal>
          </div>
          <div className="flex flex-col justify-center">
            <div className="flex self-start">
              <Image
                src={images.autoconsumo.compartido.instcolecticasin}
                className="h-auto w-full"
              />
            </div>
          </div>
        </div>
      </div>
      {knowMoreIsOpen && (
        <div className="flex flex-col items-start lg:hidden">
          <Bodycopy className="mt-16 mb-12">
            {t('consumption.shared.know_more_body')}
          </Bodycopy>
          <div className="flex self-start">
            <Image
              src={images.autoconsumo.compartido.instcolecticasin}
              className="h-auto w-full"
            />
          </div>
          <ActionButton
            type="link"
            label="¿Por qué hay instalaciones con sistema antivertido?"
            onClick={toggleModal}
            right
            className="mt-14 text-left"
          />
          <Modal isOpen={isOpen} toggleOpen={toggleModal}>
            <p className="btn mb-8">Sistema antivertido</p>
            <Bodycopy className="mb-4">
              Para instalaciones descritas en el RD 1183/2020 es necesario
              solicitar permisos de acceso y conexión a la red para poder verter
              energía. En esos casos, hay autoconsumos que deciden no verter,
              agilizando el proceso de alta pero renunciando a compensar la
              energía vertida a la red.
            </Bodycopy>
          </Modal>
        </div>
      )}
      <ActionButton
        type="link"
        icon={
          knowMoreIsOpen ? (
            <Icons.DropdownUp color="blue" />
          ) : (
            <Icons.Dropdown color="blue" />
          )
        }
        label={t('consumption.shared.know_more_text')}
        onClick={() => setKnowMoreIsOpen(!knowMoreIsOpen)}
        right
        className="flex items-center align-middle text-left mt-14 lg:hidden"
      />
    </div>
  );
};

export default Shared;
