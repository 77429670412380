import React, { FunctionComponent, ReactNode } from 'react';

import Main from './components/Main';
import Footer from './footer/Footer';
import Header from './header/Header';

interface Props {
  title: string;
  children: ReactNode;
  onlyDesktop?: boolean;
}

const PublicLayout: FunctionComponent<Props> = (
  {
    title,
    children,
    onlyDesktop = false,
  }: Props,
) => (
  <Main>
    <Header background="black" onlydesktop={onlyDesktop} withTitle title={title} />
    <main className="flex-1 flex flex-col">{children}</main>
    <Footer onlyDesktop={onlyDesktop} />
  </Main>
);

export default PublicLayout;
