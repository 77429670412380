import _ from 'lodash/fp';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';

import Icons from '../../assets/icons';
import ActionButton from '../../components/buttons/ActionButton';
import InternalLink from '../../components/InternalLink';
import Tag from '../../components/Tag';
import AuthenticatedLayout from '../../layout/AuthenticatedLayout';
import useNavigation from '../../services/navigation';
import { routes } from '../../services/routing';
import { useStoreActions, useStoreState } from '../../store/hooks';
import t from '../../text-strings';
import InfoField from '../../components/text-components/InfoField';
import Title from '../../components/text-components/Title';
import Form from '../../components/forms/Form';
import TextInput from '../../components/forms/TextInput';
import SubmitButton from '../../components/buttons/SubmitButton';
import FormResponse from '../../components/forms/FormResponse';
import { AliasParams } from '../../store/model/contract/types';
import useHandleResponse from '../../services/handle-response';
import formInit from '../../forms/initial-values';
import schemas from '../../forms/schemas';
import Wrap from '../../components/containers/Wrap';
import BreakdownsAlerts from '../../components/alerts/BreakdownsAlerts';
import Caption from '../../components/text-components/Caption';

type RouteParams = {
  id: string;
};

const ContractDetails: FunctionComponent = () => {
  const { contracts, contract } = useStoreState((state) => state.contract);
  const { update, show } = useStoreActions((actions) => actions.contract);
  const { id } = useParams<RouteParams>();
  const [message, handleFormResponse] = useHandleResponse(routes.profile);
  const [isForm, setIsForm] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [clipboard, setClipboard] = useState(false);
  const navigate = useNavigation();

  const handleOnSubmit = async (values: AliasParams): Promise<void> => {
    handleFormResponse(await update(values));
  };

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    isSubmitting,
    setFieldValue,
  } = useFormik({
    initialValues: formInit.alias,
    onSubmit: handleOnSubmit,
    validationSchema: schemas.alias,
  });

  const initializeForm = (): void => {
    setFieldValue('cups', contract.get('cups'));
    setFieldValue('name', contract.get('alias') || '');
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      if (_.isEmpty(id)) {
        navigate(routes.profile);
        return;
      }
      show({ id, contracts });
      setIsLoading(false);
      initializeForm();
    })();
  }, [contract, contracts]);

  const onClickCups = (): void => {
    setClipboard(true);
    navigator.clipboard.writeText(contract.get('cups'));
    setTimeout(() => setClipboard(false), 1500);
  };

  if (isLoading) {
    return null;
  }

  return (
    <AuthenticatedLayout title={contract.title}>
      <div className="relative flex-1 bg-white lg:bg-transparent">
        <div className="mt-6">
          <BreakdownsAlerts alias={contract.get('alias')} />
        </div>
        <Wrap size="wrap-authenticated" className="py-16 lg:py-24">
          <InternalLink
            to={routes.profile}
            label={t('common.navigation.profile')}
            icon={<Icons.Back />}
            className="mb-10 lg:mb-20"
          />
          <Title className="mb-8 lg:mb-12">{t('contract.detalles')}</Title>
          <div className="lg:flex lg:mb-20">
            {isForm ? (
              <Form className="mb-8 lg:w-1/2 lg:mb-0" onSubmit={handleSubmit}>
                <TextInput
                  name="name"
                  value={values.name}
                  placeholder={t('models.contract.attributes.alias')}
                  onChange={handleChange('name')}
                  onBlur={handleBlur('name')}
                  touched={touched.name}
                  errors={errors.name}
                />
                {!_.isEmpty(message) && <FormResponse message={message} />}
                <div className="flex items-center justify-between">
                  <SubmitButton
                    label={t('contract.cambiar')}
                    disabled={isSubmitting}
                  />
                  <ActionButton
                    label={t('contract.cancelar')}
                    onClick={() => setIsForm(!isForm)}
                    type="link"
                  />
                </div>
              </Form>
            ) : (
              <InfoField
                label={t('models.contract.attributes.alias')}
                value={contract.get('alias')}
                className="mb-8 lg:mb-0 lg:w-1/2"
                action={() => setIsForm(!isForm)}
                actionText={t('contract.cambiar')}
                avoidButtonLeftMargin
              />
            )}
            <InfoField
              label={t('models.contract.attributes.estado')}
              // @ts-ignore
              value={(
                <Tag
                  type={_.lowerCase(contract.getState())}
                  label={contract.getState()}
                />
              )}
              className="mb-8 lg:mb-0 lg:w-1/2"
              avoidButtonLeftMargin={false}
            />
          </div>
          <div className="relative mb-20 lg:flex">
            {clipboard && (
              <div className="absolute px-4 py-2 bg-black rounded-sm -top-2 left-22">
                <Caption color="white">
                  <strong>{t('contract.copiado')}</strong>
                </Caption>
              </div>
            )}
            <InfoField
              label={t('models.contract.attributes.cups')}
              value={(
                <ActionButton
                  label={contract.get('cups')}
                  type="link"
                  onClick={onClickCups}
                  icon={<Icons.Copy color="blue" />}
                />
              )}
              className="lg:mb-0 lg:w-1/2"
              avoidButtonLeftMargin={false}
            />
          </div>
          <Title size="small" className="mb-8 lg:mb-12">
            {t('contract.informacion')}
          </Title>
          <div className="lg:flex lg:mb-20">
            <InfoField
              label={t('models.contract.attributes.titular')}
              value={contract.get('responsablePsNombresYApellidos')}
              className="mb-8 lg:mb-0 lg:w-1/2"
              avoidButtonLeftMargin={false}
            />
            <InfoField
              label={t('models.contract.attributes.nif')}
              value={contract.get('cifNif')}
              className="mb-8 lg:mb-0 lg:w-1/2"
              avoidButtonLeftMargin={false}
            />
          </div>
          <div className="lg:flex lg:mb-20">
            <InfoField
              label={t('models.contract.attributes.address')}
              value={contract.address}
              className="mb-8 lg:mb-0 lg:w-1/2"
              avoidButtonLeftMargin={false}
            />
            <InfoField
              label={t('models.contract.attributes.tarifa')}
              value={contract.get('tarifa')}
              className="mb-8 lg:mb-0 lg:w-1/2"
              avoidButtonLeftMargin={false}
            />
          </div>
          <div className="mb-20 lg:flex">
            <InfoField
              label={t('models.contract.attributes.social')}
              value="No"
              className="lg:w-1/2"
              avoidButtonLeftMargin={false}
            />
          </div>
          <Title size="small" className="mb-8 lg:mb-12">
            {t('contract.instalación')}
          </Title>
          <div className="lg:flex lg:mb-20">
            <InfoField
              label={t('models.contract.hora_punta')}
              value={t('models.contract.hora_value', `${contract.highPower()}`)}
              className="mb-8 lg:mb-0 lg:w-1/2"
              avoidButtonLeftMargin={false}
            />
            <InfoField
              label={t('models.contract.hora_valle')}
              value={t('models.contract.hora_value', `${contract.lowPower()}`)}
              className="mb-8 lg:mb-0 lg:w-1/2"
              avoidButtonLeftMargin={false}
            />
          </div>
          <div className="lg:flex lg:mb-20">
            <InfoField
              label={t('models.contract.attributes.tension')}
              value={`${contract.get('tramoTension')}V`}
              className="mb-8 lg:mb-0 lg:w-1/2"
              avoidButtonLeftMargin={false}
            />
            <InfoField
              label={t('models.contract.attributes.instalacion')}
              value={contract.get('tipoInstalacion')}
              className="mb-8 lg:mb-0 lg:w-1/2"
              avoidButtonLeftMargin={false}
            />
          </div>
        </Wrap>
      </div>
    </AuthenticatedLayout>
  );
};

export default ContractDetails;
