import * as yup from 'yup';
import _ from 'lodash/fp';

import t from '../text-strings';
import constants from '../services/constants';

import validations from './validations';

const POSTAL_CODE_LENGHT = 5;
const MOBILE_LENGHT = 9;
const DESCRIPTION_LENGHT = 30;
const SP_USE_OTHER_ID = 55;
const CUPS_USE_OTHER_ID = 130;
const SP_CLIENT_TYPE_ID = 36;
const CUPS_CLIENT_TYPE_ID = 111;
const SC_CLIENT_TYPE_ID = 2;
const G_CLIENT_TYPE_ID = 26;
const SC_YES = 13;
const SC_RUSTIC = 4;
const SC_URBAN = 3;
const G_YES = 33;
const CAU_YES = 23;
const G_RUSTIC = 28;
const G_URBAN = 27;
const SC_POWER_SOURCE_OTHER_ID = 8;
const G_POWER_SOURCE_OTHER_ID = 32;
const CAU_POWER_SOURCE_OTHER_ID = 18;
const CUPS_LENGHT = 20;
const MOBILE_REGEX = /(6|7|8|9)([0-9]){8}$/;

const login = yup.object().shape({
  username: yup
    .string()
    .required(t('common.form.errors.required'))
    .test('validateIdentity', t('common.form.errors.identity'), (value) => validations.validateIdentity(value || '')),
  password: yup.string().required(t('common.form.errors.required')),
});

const resetPassword = yup.object().shape({
  nif: yup
    .string()
    .required(t('common.form.errors.required'))
    .test('validateIdentity', t('common.form.errors.identity'), (value) => validations.validateIdentity(value || '')),
});

const changePassword = yup.object().shape({
  password: yup.string().required(t('common.form.errors.required')),
  repeatPassword: yup
    .string()
    .required(t('common.form.errors.required'))
    .oneOf([yup.ref('password'), null], t('models.user.errors.password')),
});

const register = yup.object().shape({
  name: yup.string().required(t('common.form.errors.required')),
  nif: yup
    .string()
    .required(t('common.form.errors.required'))
    .test('validateIdentity', t('common.form.errors.identity'), (value) => validations.validateIdentity(value || '')),
  password: yup.string().required(t('common.form.errors.required')),
  repeatPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], t('models.user.errors.password'))
    .required(t('common.form.errors.required')),
  userTypeId: yup
    .number()
    .required(t('common.form.errors.required'))
    .min(1, t('common.form.errors.required'))
    .max(3, t('common.form.errors.required')),
  email: yup
    .string()
    .email(t('common.form.errors.email'))
    .required(t('common.form.errors.required')),
  repeatEmail: yup
    .string()
    .oneOf([yup.ref('email'), null], t('models.user.errors.email'))
    .required(t('common.form.errors.required')),
  address: yup.string().required(t('common.form.errors.required')),
  province: yup.string().required(t('common.form.errors.required')),
  locality: yup.string().required(t('common.form.errors.required')),
  pc: yup
    .string()
    .required(t('common.form.errors.required'))
    .length(POSTAL_CODE_LENGHT, t('models.user.errors.codigo_postal'))
    .matches(constants.POSTAL_CODE_REGEX, {
      message: t('models.user.errors.codigo_postal'),
    }),
  movil: yup
    .string()
    .required(t('common.form.errors.required'))
    .length(MOBILE_LENGHT, t('models.user.errors.mobile'))
    .matches(MOBILE_REGEX, t('models.user.errors.mobile')),
  conditions: yup
    .boolean()
    .test(
      'required',
      t('common.form.errors.required'),
      (value) => value === true,
    ),
  idFront: yup
    .mixed()
    .test(
      'required',
      t('common.form.errors.required'),
      (value) => !_.isEmpty(value.name),
    )
    .test('fileSize', t('models.request.errors.to_big', '5MB'), (value) => validations.documentSize(value.size, 5))
    .test('fileType', t('models.request.errors.format'), (value) => validations.isFormatIDSupported(value.type)),
  idBack: yup
    .mixed()
    .test(
      'required',
      t('common.form.errors.required'),
      (value) => !_.isEmpty(value.name),
    )
    .test('fileSize', t('models.request.errors.to_big', '5MB'), (value) => validations.documentSize(value.size, 5))
    .test('fileType', t('models.request.errors.format'), (value) => validations.isFormatIDSupported(value.type)),
});

const editUser = yup.object().shape({
  name: yup.string().required(t('common.form.errors.required')),
  nif: yup
    .string()
    .required(t('common.form.errors.required'))
    .test('validateIdentity', t('common.form.errors.identity'), (value) => validations.validateIdentity(value || '')),
  email: yup
    .string()
    .email(t('common.form.errors.email'))
    .required(t('common.form.errors.required')),
  address: yup.string().required(t('common.form.errors.required')),
  location: yup.string().required(t('common.form.errors.required')),
  pc: yup
    .string()
    .required(t('common.form.errors.required'))
    .length(POSTAL_CODE_LENGHT, t('models.user.errors.codigo_postal'))
    .matches(constants.POSTAL_CODE_REGEX, {
      message: t('models.user.errors.codigo_postal'),
    }),
  movil: yup
    .string()
    .required(t('common.form.errors.required'))
    .length(MOBILE_LENGHT, t('models.user.errors.mobile'))
    .matches(MOBILE_REGEX, t('models.user.errors.mobile')),
});

const authorized = yup.object().shape({
  entitleUserNif: yup
    .string()
    .required(t('common.form.errors.required'))
    .test('validateIdentity', t('common.form.errors.identity'), (value) => validations.validateIdentity(value || '')),
  cups: yup
    .string()
    .required(t('common.form.errors.required'))
    .length(CUPS_LENGHT, t('common.form.errors.limit_cups')),
  idFront: yup
    .mixed()
    .test(
      'required',
      t('common.form.errors.required'),
      (value) => !_.isEmpty(value.name),
    )
    .test('fileSize', t('models.request.errors.to_big', '5MB'), (value) => validations.documentSize(value.size, 5))
    .test('fileType', t('models.request.errors.format'), (value) => validations.isFormatIDSupported(value.type)),
  idBack: yup
    .mixed()
    .test(
      'required',
      t('common.form.errors.required'),
      (value) => !_.isEmpty(value.name),
    )
    .test('fileSize', t('models.request.errors.to_big', '5MB'), (value) => validations.documentSize(value.size, 5))
    .test('fileType', t('models.request.errors.format'), (value) => validations.isFormatIDSupported(value.type)),
  authorization: yup
    .mixed()
    .test(
      'required',
      t('common.form.errors.required'),
      (value) => !_.isEmpty(value.name),
    )
    .test('fileSize', t('models.request.errors.to_big', '5MB'), (value) => validations.documentSize(value.size, 5))
    .test('fileType', t('models.request.errors.format'), (value) => validations.isFormatAuthSupported(value.type)),
});

const alias = yup.object().shape({
  name: yup.string().required(t('common.form.errors.required')),
});

const supply = yup.object().shape({
  nameClient: yup.string().required(t('common.form.errors.required')),
  nifClient: yup
    .string()
    .required(t('common.form.errors.required'))
    .test('validateIdentity', t('common.form.errors.identity'), (value) => validations.validateIdentity(value || '')),
  addressClient: yup.string().required(t('common.form.errors.required')),
  localityClient: yup.string().required(t('common.form.errors.select')),
  pcclient: yup
    .string()
    .required(t('common.form.errors.required'))
    .length(
      POSTAL_CODE_LENGHT,
      t('common.form.errors.lenght', `${POSTAL_CODE_LENGHT}`),
    )
    .matches(constants.POSTAL_CODE_REGEX, {
      message: t('models.user.errors.codigo_postal'),
    }),
  movilClient: yup
    .string()
    .required(t('common.form.errors.required'))
    .length(MOBILE_LENGHT, t('common.form.errors.lenght', `${MOBILE_LENGHT}`))
    .matches(MOBILE_REGEX, t('models.user.errors.mobile')),
  emailClient: yup
    .string()
    .email(t('common.form.errors.email'))
    .required(t('common.form.errors.required')),
  address: yup.string().required(t('common.form.errors.required')),
  locality: yup.string().required(t('common.form.errors.required')),
  pc: yup
    .string()
    .required(t('common.form.errors.required'))
    .length(
      POSTAL_CODE_LENGHT,
      t('common.form.errors.lenght', `${POSTAL_CODE_LENGHT}`),
    )
    .matches(constants.POSTAL_CODE_REGEX, {
      message: t('models.user.errors.codigo_postal'),
    }),
  location: yup
    .string()
    .required(t('common.form.errors.required'))
    .test('locationRegex', t('models.requests.errors.location'), (value) => validations.validateLocation(value || '')),
  powerRequest: yup
    .number()
    .required(t('common.form.errors.required'))
    .positive(t('common.form.errors.positive')),
  clientType: yup.number().required(t('common.form.errors.required')),
  terrainType: yup
    .number()
    .required(t('common.form.errors.required'))
    .positive(t('common.form.errors.select')),
  use: yup
    .number()
    .required(t('common.form.errors.required'))
    .positive(t('common.form.errors.select')),
  useDescription: yup.string().when('use', {
    is: SP_USE_OTHER_ID,
    then: yup
      .string()
      .required(t('common.form.errors.required'))
      .max(
        DESCRIPTION_LENGHT,
        t('common.form.errors.lenght', `${DESCRIPTION_LENGHT}`),
      ),
  }),
  tension: yup
    .number()
    .required(t('common.form.errors.required'))
    .positive(t('common.form.errors.select')),
  instalationType: yup
    .number()
    .required(t('common.form.errors.required'))
    .positive(t('common.form.errors.select')),
  provinceId: yup
    .number()
    .required(t('common.form.errors.required'))
    .positive(t('common.form.errors.select')),
  provinceClientId: yup
    .number()
    .required(t('common.form.errors.required'))
    .positive(t('common.form.errors.select')),
  municipalityId: yup
    .number()
    .required(t('common.form.errors.required'))
    .positive(t('common.form.errors.select')),
  authorization: yup
    .mixed()
    .test('fileSize', t('models.request.errors.to_big', '5MB'), (value) => validations.documentSize(value.size, 5))
    .test('fileType', t('models.request.errors.format'), (value) => validations.isFormatSupported(value.type))
    .when('clientType', {
      is: SP_CLIENT_TYPE_ID,
      then: yup
        .mixed()
        .test(
          'required',
          t('common.form.errors.required'),
          (value) => !_.isEmpty(value.name),
        ),
    }),
  technicalMemory: yup
    .mixed()
    .test('fileSize', t('common.form.errors.too_big', '20MB'), (value) => validations.documentSize(value.size, 20))
    .test('fileType', t('models.request.errors.format'), (value) => validations.isFormatSupported(value.type)),
  technicalDraft: yup
    .mixed()
    .test('fileSize', t('models.request.errors.to_big', '30MB'), (value) => validations.documentSize(value.size, 30))
    .test('fileType', t('models.request.errors.format'), (value) => validations.isFormatSupported(value.type)),
});

const cups = yup.object().shape({
  nameClient: yup.string().required(t('common.form.errors.required')),
  nifClient: yup
    .string()
    .required(t('common.form.errors.required'))
    .test('validateIdentity', t('common.form.errors.identity'), (value) => validations.validateIdentity(value || '')),
  addressClient: yup.string().required(t('common.form.errors.required')),
  localityClient: yup.string().required(t('common.form.errors.select')),
  pcclient: yup
    .string()
    .required(t('common.form.errors.required'))
    .length(
      POSTAL_CODE_LENGHT,
      t('common.form.errors.lenght', `${POSTAL_CODE_LENGHT}`),
    )
    .matches(constants.POSTAL_CODE_REGEX, {
      message: t('models.user.errors.codigo_postal'),
    }),
  movilClient: yup
    .string()
    .required(t('common.form.errors.required'))
    .length(MOBILE_LENGHT, t('common.form.errors.lenght', `${MOBILE_LENGHT}`))
    .matches(MOBILE_REGEX, t('models.user.errors.mobile')),
  emailClient: yup
    .string()
    .email(t('common.form.errors.email'))
    .required(t('common.form.errors.required')),
  address: yup.string().required(t('common.form.errors.required')),
  locality: yup.string().required(t('common.form.errors.required')),
  pc: yup
    .string()
    .required(t('common.form.errors.required'))
    .length(
      POSTAL_CODE_LENGHT,
      t('common.form.errors.lenght', `${POSTAL_CODE_LENGHT}`),
    )
    .matches(constants.POSTAL_CODE_REGEX, {
      message: t('models.user.errors.codigo_postal'),
    }),
  location: yup
    .string()
    .required(t('common.form.errors.required'))
    .test('locationRegex', t('models.requests.errors.location'), (value) => validations.validateLocation(value || '')),
  powerRequest: yup
    .number()
    .required(t('common.form.errors.required'))
    .positive(t('common.form.errors.positive')),
  clientType: yup.number().required(t('common.form.errors.required')),
  terrainType: yup
    .number()
    .required(t('common.form.errors.required'))
    .positive(t('common.form.errors.select')),
  use: yup
    .number()
    .required(t('common.form.errors.required'))
    .positive(t('common.form.errors.select')),
  useDescription: yup.string().when('use', {
    is: CUPS_USE_OTHER_ID,
    then: yup
      .string()
      .required(t('common.form.errors.required'))
      .max(
        DESCRIPTION_LENGHT,
        t('common.form.errors.lenght', `${DESCRIPTION_LENGHT}`),
      ),
  }),
  tension: yup
    .number()
    .required(t('common.form.errors.required'))
    .positive(t('common.form.errors.select')),
  instalationType: yup
    .number()
    .required(t('common.form.errors.required'))
    .positive(t('common.form.errors.select')),
  provinceId: yup
    .number()
    .required(t('common.form.errors.required'))
    .positive(t('common.form.errors.select')),
  provinceClientId: yup
    .number()
    .required(t('common.form.errors.required'))
    .positive(t('common.form.errors.select')),
  municipalityId: yup
    .number()
    .required(t('common.form.errors.required'))
    .positive(t('common.form.errors.select')),
  authorization: yup
    .mixed()
    .test('fileSize', t('models.request.errors.to_big', '5MB'), (value) => validations.documentSize(value.size, 5))
    .test('fileType', t('models.request.errors.format'), (value) => validations.isFormatSupported(value.type))
    .when('clientType', {
      is: CUPS_CLIENT_TYPE_ID,
      then: yup
        .mixed()
        .test(
          'required',
          t('common.form.errors.required'),
          (value) => !_.isEmpty(value.name),
        ),
    }),
  cie: yup
    .mixed()
    .test(
      'required',
      t('common.form.errors.required'),
      (value) => !_.isEmpty(value.name),
    )
    .test('fileSize', t('models.request.errors.to_big', '5MB'), (value) => validations.documentSize(value.size, 5))
    .test('fileType', t('models.request.errors.format'), (value) => validations.isFormatSupported(value.type)),
  firstOccupation: yup
    .mixed()
    .test(
      'required',
      t('common.form.errors.required'),
      (value) => !_.isEmpty(value.name),
    )
    .test('fileSize', t('models.request.errors.to_big', '5MB'), (value) => validations.documentSize(value.size, 5))
    .test('fileType', t('models.request.errors.format'), (value) => validations.isFormatSupported(value.type)),
  deeds: yup
    .mixed()
    .test(
      'required',
      t('common.form.errors.required'),
      (value) => !_.isEmpty(value.name),
    )
    .test('fileSize', t('models.request.errors.to_big', '15MB'), (value) => validations.documentSize(value.size, 15))
    .test('fileType', t('models.request.errors.format'), (value) => validations.isFormatSupported(value.type)),
});

const selfconsumption = yup.object().shape({
  nameClient: yup.string().required(t('common.form.errors.required')),
  nifClient: yup
    .string()
    .required(t('common.form.errors.required'))
    .test('validateIdentity', t('common.form.errors.identity'), (value) => validations.validateIdentity(value || '')),
  addressClient: yup.string().required(t('common.form.errors.required')),
  localityClient: yup.string().required(t('common.form.errors.select')),
  pcclient: yup
    .string()
    .required(t('common.form.errors.required'))
    .length(
      POSTAL_CODE_LENGHT,
      t('common.form.errors.lenght', `${POSTAL_CODE_LENGHT}`),
    )
    .matches(constants.POSTAL_CODE_REGEX, {
      message: t('models.user.errors.codigo_postal'),
    }),
  movilClient: yup
    .string()
    .required(t('common.form.errors.required'))
    .length(MOBILE_LENGHT, t('common.form.errors.lenght', `${MOBILE_LENGHT}`))
    .matches(MOBILE_REGEX, t('models.user.errors.mobile')),
  emailClient: yup
    .string()
    .email(t('common.form.errors.email'))
    .required(t('common.form.errors.required')),
  address: yup.string().required(t('common.form.errors.required')),
  locality: yup.string().required(t('common.form.errors.required')),
  pc: yup
    .string()
    .required(t('common.form.errors.required'))
    .length(
      POSTAL_CODE_LENGHT,
      t('common.form.errors.lenght', `${POSTAL_CODE_LENGHT}`),
    )
    .matches(constants.POSTAL_CODE_REGEX, {
      message: t('models.user.errors.codigo_postal'),
    }),
  location: yup
    .string()
    .required(t('common.form.errors.required'))
    .test('locationRegex', t('models.requests.errors.location'), (value) => validations.validateLocation(value || '')),
  cups: yup
    .string()
    .required(t('common.form.errors.required'))
    .length(CUPS_LENGHT, t('common.form.errors.limit_cups')),
  powerContract: yup
    .number()
    .required(t('common.form.errors.required'))
    .positive(t('common.form.errors.positive')),
  clientType: yup.number().required(t('common.form.errors.required')),
  terrainType: yup
    .number()
    .required(t('common.form.errors.required'))
    .positive(t('common.form.errors.select')),
  powerInstalation: yup
    .number()
    .required(t('common.form.errors.required'))
    .positive(t('common.form.errors.positive')),
  powerSource: yup
    .number()
    .required(t('common.form.errors.required'))
    .positive(t('common.form.errors.select')),
  powerSourceDesc: yup.string().when('powerSource', {
    is: SC_POWER_SOURCE_OTHER_ID,
    then: yup
      .string()
      .required(t('common.form.errors.required'))
      .max(
        DESCRIPTION_LENGHT,
        t('common.form.errors.lenght', `${DESCRIPTION_LENGHT}`),
      ),
  }),
  selfconsumptionType: yup
    .number()
    .required(t('common.form.errors.required'))
    .positive(t('common.form.errors.select')),
  connectionType: yup
    .number()
    .required(t('common.form.errors.required'))
    .positive(t('common.form.errors.select')),
  networked: yup
    .number()
    .required(t('common.form.errors.required'))
    .positive(t('common.form.errors.select')),
  receivedCompensation: yup.number().when('networked', {
    is: SC_YES,
    then: yup
      .number()
      .required(t('common.form.errors.required'))
      .positive(t('common.form.errors.select')),
  }),
  hybridation: yup
    .number()
    .required(t('common.form.errors.required'))
    .positive(t('common.form.errors.select')),
  hybridDescription: yup.string().when('hybridation', {
    is: SC_YES,
    then: yup.string().required(t('common.form.errors.required')),
  }),
  accumulation: yup
    .number()
    .required(t('common.form.errors.required'))
    .positive(t('common.form.errors.select')),
  accumulationDescription: yup.string().when('hybridation', {
    is: SC_YES,
    then: yup.string().required(t('common.form.errors.required')),
  }),
  knot: yup.string().required(t('common.form.errors.required')),
  envImpAssessment: yup
    .number()
    .required(t('common.form.errors.required'))
    .positive(t('common.form.errors.select')),
  guaranteeAmount: yup
    .mixed()
    .when(['networked', 'powerInstalation', 'terrainType'], {
      is: (
        networked: number,
        powerInstalation: number,
        terrainType: number,
      ): boolean => {
        const isNetworked = _.isEqual(networked, SC_YES);
        const greatPower = powerInstalation > 100;
        const isUrban = _.isEqual(terrainType, SC_URBAN);
        const isRustic = _.isEqual(terrainType, SC_RUSTIC);
        const hasTerrainType = isUrban || isRustic;

        return isNetworked && greatPower && hasTerrainType;
      },
      then: yup
        .number()
        .required(t('common.form.errors.required'))
        .positive(t('common.form.errors.positive')),
    }),
  tension: yup
    .number()
    .required(t('common.form.errors.required'))
    .positive(t('common.form.errors.select')),
  provinceId: yup
    .number()
    .required(t('common.form.errors.required'))
    .positive(t('common.form.errors.select')),
  provinceClientId: yup
    .number()
    .required(t('common.form.errors.required'))
    .positive(t('common.form.errors.select')),
  municipalityId: yup
    .number()
    .required(t('common.form.errors.required'))
    .positive(t('common.form.errors.select')),
  authorization: yup
    .mixed()
    .test('fileSize', t('models.request.errors.to_big', '5MB'), (value) => validations.documentSize(value.size, 5))
    .test('fileType', t('models.request.errors.format'), (value) => validations.isFormatSupported(value.type))
    .when('clientType', {
      is: SC_CLIENT_TYPE_ID,
      then: yup
        .mixed()
        .test(
          'required',
          t('common.form.errors.required'),
          (value) => !_.isEmpty(value.name),
        ),
    }),
  warranty: yup.mixed().when(['networked', 'powerInstalation', 'terrainType'], {
    is: (
      networked: number,
      powerInstalation: number,
      terrainType: number,
    ): boolean => {
      const isNetworked = _.isEqual(networked, SC_YES);
      const greatPower = powerInstalation > 100;
      const isUrban = _.isEqual(terrainType, SC_URBAN);
      const isRustic = _.isEqual(terrainType, SC_RUSTIC);
      const hasTerrainType = isUrban || isRustic;

      return isNetworked && greatPower && hasTerrainType;
    },
    then: yup
      .mixed()
      .test(
        'required',
        t('common.form.errors.required'),
        (value) => !_.isEmpty(value.name),
      )
      .test('fileType', t('models.request.errors.format'), (value) => validations.isFormatSupported(value.type)),
  }),
  communicationCompetentAdministration: yup
    .mixed()
    .when(['networked', 'powerInstalation', 'terrainType'], {
      is: (
        networked: number,
        powerInstalation: number,
        terrainType: number,
      ): boolean => {
        const isNetworked = _.isEqual(networked, SC_YES);
        const greatPower = powerInstalation > 100;
        const isUrban = _.isEqual(terrainType, SC_URBAN);
        const isRustic = _.isEqual(terrainType, SC_RUSTIC);
        const hasTerrainType = isUrban || isRustic;

        return isNetworked && greatPower && hasTerrainType;
      },
      then: yup
        .mixed()
        .test(
          'required',
          t('common.form.errors.required'),
          (value) => !_.isEmpty(value.name),
        )
        .test('fileSize', t('models.request.errors.to_big', '5MB'), (value) => validations.documentSize(value.size, 5))
        .test('fileType', t('models.request.errors.format'), (value) => validations.isFormatSupported(value.type)),
    }),
  environmentalImpact: yup.mixed().when('envImpAssessment', {
    is: SC_YES,
    then: yup
      .mixed()
      .test(
        'required',
        t('common.form.errors.required'),
        (value) => !_.isEmpty(value.name),
      )
      .test('fileSize', t('models.request.errors.to_big', '5MB'), (value) => validations.documentSize(value.size, 5))
      .test('fileType', t('models.request.errors.format'), (value) => validations.isFormatSupported(value.type)),
  }),
  technicalMemory: yup.mixed().when(['powerInstalation', 'tension'], {
    is: validations.lowPower,
    then: yup
      .mixed()
      .test(
        'required',
        t('common.form.errors.required'),
        (value) => !_.isEmpty(value.name),
      )
      .test('fileSize', t('models.request.errors.to_big', '20MB'), (value) => validations.documentSize(value.size, 20))
      .test('fileType', t('models.request.errors.format'), (value) => validations.isFormatSupported(value.type)),
  }),
  technicalDraft: yup
    .mixed()
    .test(
      'required',
      t('common.form.errors.required'),
      (value) => !_.isEmpty(value.name),
    )
    .test('fileSize', t('models.request.errors.to_big', '30MB'), (value) => validations.documentSize(value.size, 30))
    .test('fileType', t('models.request.errors.format'), (value) => validations.isFormatSupported(value.type)),
});

const generation = yup.object().shape({
  nameClient: yup.string().required(t('common.form.errors.required')),
  nifClient: yup
    .string()
    .required(t('common.form.errors.required'))
    .test('validateIdentity', t('common.form.errors.identity'), (value) => validations.validateIdentity(value || '')),
  addressClient: yup.string().required(t('common.form.errors.required')),
  localityClient: yup.string().required(t('common.form.errors.select')),
  pcclient: yup
    .string()
    .required(t('common.form.errors.required'))
    .length(
      POSTAL_CODE_LENGHT,
      t('common.form.errors.lenght', `${POSTAL_CODE_LENGHT}`),
    )
    .matches(constants.POSTAL_CODE_REGEX, {
      message: t('models.user.errors.codigo_postal'),
    }),
  movilClient: yup
    .string()
    .required(t('common.form.errors.required'))
    .length(MOBILE_LENGHT, t('common.form.errors.lenght', `${MOBILE_LENGHT}`))
    .matches(MOBILE_REGEX, t('models.user.errors.mobile')),
  emailClient: yup
    .string()
    .email(t('common.form.errors.email'))
    .required(t('common.form.errors.required')),
  location: yup
    .string()
    .required(t('common.form.errors.required'))
    .test('locationRegex', t('models.requests.errors.location'), (value) => validations.validateLocation(value || '')),
  clientType: yup.number().required(t('common.form.errors.required')),
  terrainType: yup
    .number()
    .required(t('common.form.errors.required'))
    .positive(t('common.form.errors.select')),
  powerInstalation: yup
    .number()
    .required(t('common.form.errors.required'))
    .positive(t('common.form.errors.positive')),
  powerSource: yup
    .number()
    .required(t('common.form.errors.required'))
    .positive(t('common.form.errors.select')),
  powerSourceDesc: yup.string().when('powerSource', {
    is: G_POWER_SOURCE_OTHER_ID,
    then: yup
      .string()
      .required(t('common.form.errors.required'))
      .max(
        DESCRIPTION_LENGHT,
        t('common.form.errors.lenght', `${DESCRIPTION_LENGHT}`),
      ),
  }),
  hybridation: yup
    .number()
    .required(t('common.form.errors.required'))
    .positive(t('common.form.errors.select')),
  hybridDescription: yup.string().when('hybridation', {
    is: G_YES,
    then: yup.string().required(t('common.form.errors.required')),
  }),
  accumulation: yup
    .number()
    .required(t('common.form.errors.required'))
    .positive(t('common.form.errors.select')),
  accumulationDescription: yup.string().when('accumulation', {
    is: G_YES,
    then: yup.string().required(t('common.form.errors.required')),
  }),
  knot: yup.string().required(t('common.form.errors.required')),
  envImpAssessment: yup
    .number()
    .required(t('common.form.errors.required'))
    .positive(t('common.form.errors.select')),
  guaranteeAmount: yup
    .mixed()
    .when(['networked', 'powerInstalation', 'terrainType'], {
      is: (
        networked: number,
        powerInstalation: number,
        terrainType: number,
      ): boolean => (_.isEqual(networked, G_YES)
          && powerInstalation > 100
          && _.isEqual(terrainType, G_URBAN))
        || !_.isEqual(terrainType, G_RUSTIC),
      then: yup
        .number()
        .required(t('common.form.errors.required'))
        .positive(t('common.form.errors.positive')),
    }),
  tension: yup
    .number()
    .required(t('common.form.errors.required'))
    .positive(t('common.form.errors.select')),
  provinceClientId: yup
    .number()
    .required(t('common.form.errors.required'))
    .positive(t('common.form.errors.select')),
  authorization: yup
    .mixed()
    .test('fileSize', t('models.request.errors.to_big', '5MB'), (value) => validations.documentSize(value.size, 5))
    .test('fileType', t('models.request.errors.format'), (value) => validations.isFormatSupported(value.type))
    .when('clientType', {
      is: G_CLIENT_TYPE_ID,
      then: yup
        .mixed()
        .test(
          'required',
          t('common.form.errors.required'),
          (value) => !_.isEmpty(value.name),
        ),
    }),
  warranty: yup
    .mixed()
    .test(
      'required',
      t('common.form.errors.required'),
      (value) => !_.isEmpty(value.name),
    )
    .test('fileSize', t('models.request.errors.to_big', '5MB'), (value) => validations.documentSize(value.size, 5))
    .test('fileType', t('models.request.errors.format'), (value) => validations.isFormatSupported(value.type)),
  environmentalImpact: yup.mixed().when('envImpAssessment', {
    is: G_YES,
    then: yup
      .mixed()
      .test(
        'required',
        t('common.form.errors.required'),
        (value) => !_.isEmpty(value.name),
      )
      .test('fileSize', t('models.request.errors.to_big', '5MB'), (value) => validations.documentSize(value.size, 5))
      .test('fileType', t('models.request.errors.format'), (value) => validations.isFormatSupported(value.type)),
  }),
  communicationCompetentAdministration: yup
    .mixed()
    .test(
      'required',
      t('common.form.errors.required'),
      (value) => !_.isEmpty(value.name),
    )
    .test('fileSize', t('models.request.errors.to_big', '5MB'), (value) => validations.documentSize(value.size, 5))
    .test('fileType', t('models.request.errors.format'), (value) => validations.isFormatSupported(value.type)),
  t243: yup.mixed().when(['powerInstalation'], {
    is: validations.power,
    then: yup
      .mixed()
      .test(
        'required',
        t('common.form.errors.required'),
        (value) => !_.isEmpty(value.name),
      )
      .test('fileSize', t('models.request.errors.to_big', '5MB'), (value) => validations.documentSize(value.size, 5))
      .test('fileType', t('models.request.errors.format'), (value) => validations.isFormatSupported(value.type)),
  }),
  technicalDraft: yup
    .mixed()
    .test(
      'required',
      t('common.form.errors.required'),
      (value) => !_.isEmpty(value.name),
    )
    .test('fileSize', t('models.request.errors.to_big', '30MB'), (value) => validations.documentSize(value.size, 30))
    .test('fileType', t('models.request.errors.format'), (value) => validations.isFormatSupported(value.type)),
  generalPlan: yup.mixed().when(['powerInstalation'], {
    is: validations.power,
    then: yup
      .mixed()
      .test(
        'required',
        t('common.form.errors.required'),
        (value) => !_.isEmpty(value.name),
      )
      .test('fileSize', t('models.request.errors.to_big', '5MB'), (value) => validations.documentSize(value.size, 5))
      .test('fileType', t('models.request.errors.format'), (value) => validations.isFormatSupported(value.type)),
  }),
  particularPlan: yup.mixed().when(['powerInstalation'], {
    is: validations.power,
    then: yup
      .mixed()
      .test(
        'required',
        t('common.form.errors.required'),
        (value) => !_.isEmpty(value.name),
      )
      .test('fileSize', t('models.request.errors.to_big', '5MB'), (value) => validations.documentSize(value.size, 5))
      .test('fileType', t('models.request.errors.format'), (value) => validations.isFormatSupported(value.type)),
  }),
  significativeDistances: yup.mixed().when(['powerInstalation'], {
    is: validations.power,
    then: yup
      .mixed()
      .test(
        'required',
        t('common.form.errors.required'),
        (value) => !_.isEmpty(value.name),
      )
      .test('fileSize', t('models.request.errors.to_big', '5MB'), (value) => validations.documentSize(value.size, 5))
      .test('fileType', t('models.request.errors.format'), (value) => validations.isFormatSupported(value.type)),
  }),
  generalImplantationPlan: yup.mixed().when(['powerInstalation'], {
    is: validations.power,
    then: yup
      .mixed()
      .test(
        'required',
        t('common.form.errors.required'),
        (value) => !_.isEmpty(value.name),
      )
      .test('fileSize', t('models.request.errors.to_big', '5MB'), (value) => validations.documentSize(value.size, 5))
      .test('fileType', t('models.request.errors.format'), (value) => validations.isFormatSupported(value.type)),
  }),
  generalImplantationPlan2: yup.mixed().when(['powerInstalation'], {
    is: validations.power,
    then: yup
      .mixed()
      .test(
        'required',
        t('common.form.errors.required'),
        (value) => !_.isEmpty(value.name),
      )
      .test('fileSize', t('models.request.errors.to_big', '5MB'), (value) => validations.documentSize(value.size, 5))
      .test('fileType', t('models.request.errors.format'), (value) => validations.isFormatSupported(value.type)),
  }),
});

const existCups = yup.object().shape({
  streetTypeId: yup
    .number()
    .required(t('common.form.errors.required'))
    .positive(t('common.form.errors.select')),
  cp: yup
    .string()
    .required(t('common.form.errors.required'))
    .length(POSTAL_CODE_LENGHT, t('models.user.errors.codigo_postal'))
    .matches(constants.POSTAL_CODE_REGEX, {
      message: t('models.user.errors.codigo_postal'),
    }),
  street: yup.string().required(t('common.form.errors.required')),
  number: yup.string().required(t('common.form.errors.required')),
});

const cau = yup.object().shape({
  address: yup.string().required(t('common.form.errors.required')),
  locality: yup.string().required(t('common.form.errors.required')),
  pc: yup
    .string()
    .required(t('common.form.errors.required'))
    .length(
      POSTAL_CODE_LENGHT,
      t('common.form.errors.lenght', `${POSTAL_CODE_LENGHT}`),
    )
    .matches(constants.POSTAL_CODE_REGEX, {
      message: t('models.user.errors.codigo_postal'),
    }),
  cups: yup
    .string()
    .test('cauFormat', t('common.form.errors.cups_format'), (value) => validations.isCupsFormatSupported(value || ''))
    .required(t('common.form.errors.required'))
    .length(CUPS_LENGHT, t('common.form.errors.limit_cups')),
  terrainType: yup
    .number()
    .required(t('common.form.errors.required'))
    .positive(t('common.form.errors.select')),
  powerInstalation: yup
    .number()
    .required(t('common.form.errors.required'))
    .positive(t('common.form.errors.positive')),
  powerSource: yup
    .number()
    .required(t('common.form.errors.required'))
    .positive(t('common.form.errors.select')),
  powerSourceDesc: yup.string().when('powerSource', {
    is: CAU_POWER_SOURCE_OTHER_ID,
    then: yup
      .string()
      .required(t('common.form.errors.required'))
      .max(
        DESCRIPTION_LENGHT,
        t('common.form.errors.lenght', `${DESCRIPTION_LENGHT}`),
      ),
  }),
  selfconsumptionType: yup
    .number()
    .required(t('common.form.errors.required'))
    .positive(t('common.form.errors.select')),
  connectionType: yup
    .number()
    .required(t('common.form.errors.required'))
    .positive(t('common.form.errors.select')),
  networked: yup
    .number()
    .required(t('common.form.errors.required'))
    .positive(t('common.form.errors.select')),
  receivedCompensation: yup.number().when('networked', {
    is: CAU_YES,
    then: yup
      .number()
      .required(t('common.form.errors.required'))
      .positive(t('common.form.errors.select')),
  }),
  tension: yup
    .number()
    .required(t('common.form.errors.required'))
    .positive(t('common.form.errors.select')),
  provinceId: yup
    .number()
    .required(t('common.form.errors.required'))
    .positive(t('common.form.errors.select')),
  municipalityId: yup
    .number()
    .required(t('common.form.errors.required'))
    .positive(t('common.form.errors.select')),
});

const contact = yup.object().shape({
  name: yup.string().when('subject', {
    is: (value: string) => validations.notFraud(value),
    then: yup.string().required(t('common.form.errors.required')),
  }),
  subject: yup.string().required(t('common.form.errors.required')),
  descriptionForOthers: yup.string().when('subject', {
    is: 'Otros',
    then: yup
      .string()
      .required(t('common.form.errors.required'))
      .max(
        DESCRIPTION_LENGHT,
        t('common.form.errors.lenght', `${DESCRIPTION_LENGHT}`),
      ),
  }),
  email: yup.string().when('subject', {
    is: (value: string) => validations.notFraud(value),
    then: yup
      .string()
      .email(t('common.form.errors.email'))
      .required(t('common.form.errors.required')),
  }),
  comment: yup.string().when('subject', {
    is: (value: string) => validations.notFraudOrIncidence(value),
    then: yup.string().required(t('common.form.errors.required')),
  }),
  municipality: yup.string().when('subject', {
    is: (value: string) => validations.address(value),
    then: yup.string().required(t('common.form.errors.required')),
  }),
  address: yup.string().when('subject', {
    is: (value: string) => validations.address(value),
    then: yup.string().required(t('common.form.errors.required')),
  }),
  description: yup.string().when('subject', {
    is: (value: string) => validations.address(value),
    then: yup.string().required(t('common.form.errors.required')),
  }),
  conditions: yup
    .boolean()
    .test(
      'required',
      t('common.form.errors.required'),
      (value) => value === true,
    ),
});

const schemas = {
  login,
  resetPassword,
  changePassword,
  register,
  authorized,
  alias,
  supply,
  existCups,
  cups,
  selfconsumption,
  generation,
  cau,
  editUser,
  contact,
};

export default schemas;
