import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

import Icons from '../../../assets/icons';
import { routes } from '../../../services/routing';

const ProfileIcon: FunctionComponent = () => (
  <div className="lg:hidden">
    <Link to={routes.profile} className="block">
      <Icons.User color="yellow" />
    </Link>
  </div>
);

export default ProfileIcon;
