import { Thunk, thunk } from 'easy-peasy';

import { handleRegister } from '../../store-helpers';

import { RaffleParams } from './types';

export interface RaffleModel {
  register: Thunk<RaffleModel, RaffleParams>;
}

export const raffleModel: RaffleModel = {
  register: thunk(async (_actions, params, { injections }) => {
    const { userService } = injections;
    return handleRegister(await userService.registerToRaffle(params));
  }),
};
