import React, { FunctionComponent, useState } from 'react';
import _ from 'lodash/fp';

import Icons from '../../../assets/icons';
import LinkCard from '../../../components/cards/LinkCard';
import Title from '../../../components/text-components/Title';
import Content from '../../../layout/components/Content';
import PublicLayout from '../../../layout/PublicLayout';
import { routes } from '../../../services/routing';
import t from '../../../text-strings';
import images from '../../../assets/images';
import Caption from '../../../components/text-components/Caption';
import Accordion from '../../../components/Accordion';
import { FAQAttributes } from '../../../store/model/contact/types';
import useFAQs from '../../../services/use-faq';
import { SidebarTabProps } from '../../../layout/tabs/SidebarTab';
import useScroll from '../../../services/use-scroll';
import SidebarLayout from '../../../layout/SidebarLayout';

import SelfConsumptionTypes from './componentes/SelfConsumptionTypes';
import KeyConcepts from './componentes/KeyConcepts';

const SelfConsumption: FunctionComponent = () => {
  const isActive = useScroll();
  const [shared, setShared] = useState(false);
  const [surplus, setSurplus] = useState(false);
  const [loading, faqs] = useFAQs(shared ? 'Autoconsumo_compartido' : 'Autoconsumo_individual');

  const toggleShared = (): void => setShared(!shared);
  const toggleSurplus = (): void => setSurplus(!surplus);

  const links: SidebarTabProps[] = [
    {
      id: 'tramites',
      to: '',
      label: t('self.tramites_frecuentes'),
      last: false,
      anchor: true,
      active: isActive('tramites', 'conceptos'),
    },
    {
      id: 'conceptos',
      to: '',
      label: t('self.conceptos_clave.title'),
      last: false,
      anchor: true,
      active: isActive('conceptos', 'tipos'),
    },
    {
      id: 'tipos',
      to: '',
      label: t('self.tipos_autoconsumo'),
      last: false,
      anchor: true,
      active: isActive('tipos', 'faq'),
    },
    {
      id: 'faq',
      to: '',
      label: t('self.preguntas_frecuentes'),
      last: true,
      anchor: true,
      active: isActive('faq'),
    },
  ];

  return (
    <PublicLayout title={t('self.title')}>
      <SidebarLayout
        sidebarTabs={links}
        label={t('self.tramitacion_autoconsumo')}
        to={routes.selfConsumption.process}
        icon={<Icons.Document />}
      >
        <div id="tramites" className="w-full lg:flex lg:flex-col lg:items-end py-16 lg:py-0">
          <Content className="lg:pb-16">
            <Title tag="h1" className="mb-12">{t('self.tramites_frecuentes')}</Title>
            <div className="lg:flex lg:flex-wrap lg:justify-between">
              <LinkCard
                label={t('self.solicitud_autoconsumo')}
                to={routes.requests.selfconsumption}
                icon={<Icons.Bolt color="white" />}
                className="mb-4 lg:w-200 hd:w-224 xl:mb-0"
              />
              <LinkCard
                label={t('self.solicitud_cau')}
                to={routes.requests.cau}
                icon={<Icons.Document color="white" />}
                className="lg:w-200 hd:w-224"
              />
            </div>
          </Content>
        </div>
        <div id="conceptos" className="w-full lg:flex lg:flex-col lg:items-end py-16 lg:py-0">
          <Content className="lg:pb-16">
            <KeyConcepts />
          </Content>
        </div>
        <div id="tipos" className="w-full lg:flex lg:flex-col lg:items-end py-16 lg:py-0">
          <Content className="lg:pb-16">
            <SelfConsumptionTypes
              shared={shared}
              toggleShared={toggleShared}
              surplus={surplus}
              toggleSurplus={toggleSurplus}
            />
          </Content>
        </div>
        <div id="faq" className="bg-white lg:bg-transparent w-full lg:flex lg:flex-col lg:items-end py-16 lg:py-0">
          <Content className="lg:pb-16">
            {!shared && (
            <div
              className="has-bg-image relative h-96 sm:h-128 md:h-144 rounded-base lg:h-152 flex flex-col mb-24 lg:mb-40 px-8 pt-12"
              style={{ backgroundImage: `url(${images.subbanner.mobile})` }}
            >
              <div className="absolute inset-0 bg-overlay-opacity rounded-base flex-1 flex flex-col items-center justify-center">
                <Title tag="h3" color="white" className="mb-8 text-center max-w-256">{t('self.faq.subvenciones')}</Title>
                <Caption color="white" className="text-center mb-12">{t('self.faq.ayudas')}</Caption>
                <a
                  href="https://www.miteco.gob.es/es/ministerio/servicios/ayudas-subvenciones/default.aspx"
                  target="_blank"
                  className="btn-primary"
                  rel="noreferrer"
                >
                  {t('self.saber_mas')}
                </a>
              </div>
            </div>
            )}
            <Title className="mb-12">{t('self.preguntas_frecuentes')}</Title>
            {!loading && (
            <>
              {_.map((faq: FAQAttributes) => (
                <Accordion
                  key={faq.questionId}
                  title={faq.title}
                  text={faq.text}
                />
              ))(faqs)}
            </>
            )}
          </Content>
        </div>
      </SidebarLayout>
    </PublicLayout>
  );
};

export default SelfConsumption;
