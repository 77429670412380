import React, { FunctionComponent } from 'react';

interface CircleLabelProps {
    valueBox: {
      cx: number | string,
      cy: number | string,
    };
    percentage: string;
    energy: string;
  }

const CircleLabel: FunctionComponent<CircleLabelProps> = (
  { valueBox, percentage, energy }: CircleLabelProps,
) => {
  const { cx, cy } = valueBox;
  return (
    <text y={cy} textAnchor="middle" dominantBaseline="central">
      <tspan x={cx} className="font-semibold text-base" alignmentBaseline="middle">{percentage}</tspan>
      <tspan x={cx} className="font-medium text-xs" fill="#A6A6A6" dy="15%">{energy || '0kWh'}</tspan>
    </text>
  );
};

export default CircleLabel;
