import React, { ChangeEvent, FunctionComponent, useState } from 'react';
import _ from 'lodash/fp';

import PublicLayout from '../../layout/PublicLayout';
import t from '../../text-strings';
import Content from '../../layout/components/Content';
import Icons from '../../assets/icons';
import Title from '../../components/text-components/Title';
import Bodycopy from '../../components/text-components/Bodycopy';
import { SidebarTabProps } from '../../layout/tabs/SidebarTab';
import SidebarLayout from '../../layout/SidebarLayout';
import useScroll from '../../services/use-scroll';

type NormativeProps = {
  id: number;
  title: string;
  law: string;
  link: string;
  section: string;
}

const normativeList: NormativeProps[] = [
  {
    id: 1,
    title: 'Regulación de la calidad y suspensión del suministro',
    law: 'Capítulo II, Ley 24/2013, de 26 de diciembre, del Sector Eléctrico',
    link: 'https://www.boe.es/buscar/doc.php?id=BOE-A-2013-13645',
    section: 'Calidad del suministro',
  },
  {
    id: 2,
    title: 'Regulación las actividades de transporte, distribución, comercialización, suministro y procedimientos de autorización de instalaciones de energía eléctrica',
    law: 'Capítulo II, Título VI, Real Decreto 1955/2000, de 1 de diciembre',
    link: 'https://www.boe.es/buscar/act.php?id=BOE-A-2000-24019',
    section: 'Calidad del suministro',
  },
  {
    id: 3,
    title: 'Procedimiento de medida y control de la continuidad del suministro eléctrico',
    law: 'Orden ECO/797/2002, de 22 de marzo',
    link: 'https://www.boe.es/buscar/doc.php?id=BOE-A-2002-7133',
    section: 'Calidad del suministro',
  },
  {
    id: 4,
    title: 'Establecimiento de la tarifa eléctrica a partir de 1 de enero de 2007',
    law: 'REAL DECRETO 1634/2007',
    link: 'https://www.boe.es/buscar/doc.php?id=BOE-A-2006-22961',
    section: 'Calidad del suministro',
  },
  {
    id: 5,
    title: 'Tipificación del delito de defraudación fluido eléctrico',
    law: 'Art. 22. Ley Orgánica 10/1995, de 23 de noviembre',
    link: 'https://www.boe.es/buscar/act.php?id=BOE-A-1995-25444',
    section: 'Fraude',
  },
  {
    id: 6,
    title: 'Detección (tipos, suspensión y rescisión contrato) del fraude',
    law: 'Art. 87 y 90. Real Decreto 1955/2000',
    link: 'https://www.boe.es/buscar/act.php?id=BOE-A-2000-24019',
    section: 'Fraude',
  },
  {
    id: 7,
    title: 'Cálculo de la retribución de la actividad de distribución de energía eléctrica',
    law: 'Art. 40. Real Decreto 1048/2013, de 27 de diciembre',
    link: 'https://www.boe.es/buscar/doc.php?id=BOE-A-2013-13767',
    section: 'Fraude',
  },
  {
    id: 8,
    title: 'Regulación las actividades de transporte, distribución, comercialización, suministro y procedimientos de autorización de instalaciones de energía eléctrica',
    law: 'Art. 87 y 90. Real Decreto 1955/2000, 1 de diciembre',
    link: 'https://www.boe.es/buscar/act.php?id=BOE-A-2000-24019',
    section: 'Nuevos suministros',
  },
  {
    id: 9,
    title: 'Metodología para el cálculo de la retribución de la actividad de distribución de energía eléctrica y liquidación de importe facturado',
    law: 'Art. 40. Real Decreto 1048/2013, de 27 de diciembre',
    link: 'https://www.boe.es/buscar/doc.php?id=BOE-A-2013-13767',
    section: 'Nuevos suministros',
  },
  {
    id: 10,
    title: 'Reglamento unificado de puntos de medida del sistema eléctrico',
    law: 'Real Decreto 1110/2007, de 24 de agosto',
    link: 'https://www.boe.es/buscar/doc.php?id=BOE-A-2007-16478',
    section: 'Nuevos suministros',
  },
  {
    id: 11,
    title: 'Regulación de la calidad y suspensión del suministro',
    law: 'Capítulo II del título VIII. Ley 24/2013, de 26 de diciembre, del Sector Eléctrico',
    link: 'https://www.boe.es/buscar/doc.php?id=BOE-A-2013-13645',
    section: 'Nuevos suministros',
  },
  {
    id: 12,
    title: 'Acceso y conexión a las redes de transporte y distribución de energía eléctrica',
    law: 'Real Decreto 1183/2020, de 29 de diciembre',
    link: 'https://www.boe.es/buscar/act.php?id=BOE-A-2020-17278',
    section: 'Nuevos suministros',
  },
  {
    id: 13,
    title: 'Regulación las actividades de transporte, distribución, comercialización, suministro y procedimientos de autorización de instalaciones de energía eléctrica',
    law: 'Real Decreto 1955/2000, de 1 de diciembre',
    link: 'https://www.boe.es/buscar/act.php?id=BOE-A-2000-24019',
    section: 'Producción de energía',
  },
  {
    id: 14,
    title: 'Metodología para el cálculo de la retribución de la actividad de distribución de energía eléctrica y liquidación de importe facturado',
    law: 'Art. 40. Real Decreto 1048/2013, de 27 de diciembre',
    link: 'https://www.boe.es/buscar/doc.php?id=BOE-A-2013-13767',
    section: 'Producción de energía',
  },
  {
    id: 15,
    title: 'Reglamento unificado de puntos de medida del sistema eléctrico',
    law: 'Real Decreto 1110/2007, de 24 de agosto',
    link: 'https://www.boe.es/buscar/doc.php?id=BOE-A-2007-16478',
    section: 'Producción de energía',
  },
  {
    id: 16,
    title: 'Regulación de la calidad y suspensión del suministro',
    law: 'Capítulo II del título VIII. Ley 24/2013, de 26 de diciembre, del Sector Eléctrico',
    link: 'https://www.boe.es/buscar/doc.php?id=BOE-A-2013-13645',
    section: 'Producción de energía',
  },
  {
    id: 17,
    title: 'Acceso y conexión a las redes de transporte y distribución de energía eléctrica',
    law: 'Real Decreto 1183/2020, de 29 de diciembre',
    link: 'https://www.boe.es/buscar/act.php?id=BOE-A-2020-17278',
    section: 'Producción de energía',
  },
  {
    id: 18,
    title: 'Metodología y condiciones del acceso y de la conexión a las redes de transporte y distribución de las instalaciones de producción de energía eléctrica',
    law: 'Circular 1/2021, de 20 de enero',
    link: 'https://www.boe.es/buscar/doc.php?id=BOE-A-2021-904',
    section: 'Producción de energía',
  },
  {
    id: 19,
    title: 'Condiciones administrativas, técnicas y económicas del autoconsumo de energía eléctrica',
    law: 'Real Decreto 244/2019, de 5 de abril',
    link: 'https://www.boe.es/diario_boe/txt.php?id=BOE-A-2019-5089',
    section: 'Producción de energía',
  },
  {
    id: 20,
    title: 'Defensa y Protección de los Consumidores y Usuarios de Andalucía',
    law: 'Ley 13/2003, de 17 de diciembre',
    link: 'https://www.boe.es/buscar/doc.php?id=BOE-A-2004-884',
    section: 'Reclamaciones',
  },
  {
    id: 21,
    title: 'Regulación las actividades de transporte, distribución, comercialización, suministro y procedimientos de autorización de instalaciones de energía eléctrica',
    law: 'Art.98, Real Decreto 1955/2000, de 1 de diciembre',
    link: 'https://www.boe.es/buscar/act.php?id=BOE-A-2000-24019',
    section: 'Reclamaciones',
  },
  {
    id: 22,
    title: 'Regulación del Sistema Arbitral del Consumo',
    law: 'Real Decreto 231/2008, de 15 de febrero',
    link: 'https://www.boe.es/buscar/doc.php?id=BOE-A-2008-3527',
    section: 'Reclamaciones',
  },
  {
    id: 23,
    title: 'Ley General para la Defensa de los Consumidores y Usuarios y otras leyes complementarias',
    law: 'Real Decreto Legislativo 1/2007, de 16 de noviembre',
    link: 'https://www.boe.es/buscar/act.php?id=BOE-A-2007-20555',
    section: 'Reclamaciones',
  },
  {
    id: 24,
    title: 'Servicio de Inspección Técnica de Equipos de Aplicación de Productos Fitosanitarios',
    law: 'Decreto-Ley 16/2014, de 23 de diciembre',
    link: 'https://www.boe.es/buscar/doc.php?id=BOJA-b-2014-90463',
    section: 'Reclamaciones',
  },
  {
    id: 25,
    title: 'Regulación las hojas de quejas y reclamaciones de las personas consumidoras y usuarias en Andalucía y su tramitación administrativa',
    law: 'Decreto 472/2019, de 28 de mayo',
    link: '',
    section: 'Reclamaciones',
  },
  {
    id: 26,
    title: 'Regulación la edición, distribución, comercialización e impresión de las Hojas de Quejas y Reclamaciones de las personas consumidoras y usuarias en Andalucía',
    law: 'Orden de 9 de febrero de 2015',
    link: '',
    section: 'Reclamaciones',
  },
];

interface NormativeItemProps {
  item: NormativeProps
}

const NormativeItem: FunctionComponent<NormativeItemProps> = ({ item }: NormativeItemProps) => (
  <div className="bg-white w-full p-8 lg:p-12 mb-8">
    <p className="btn mb-4">{item.title}</p>
    <Bodycopy className="mb-12">{item.law}</Bodycopy>
    {!_.isEmpty(item.link) && (
      <a href={item.link} target="_blank" className="btn text-blue flex items-center" rel="noreferrer">
        {t('normative.acceder')}
        {' '}
        <Icons.ArrowUp color="blue" />
      </a>
    )}
  </div>
);

const Normative: FunctionComponent = () => {
  const isActive = useScroll();
  const [currentNormative, setNormative] = useState(normativeList);
  const [search, setSearch] = useState('');

  const links: SidebarTabProps[] = [
    {
      id: 'fraude',
      to: '',
      label: t('normative.sections.fraud'),
      last: false,
      anchor: true,
      active: isActive('fraude', 'reclamaciones'),
    },
    {
      id: 'reclamaciones',
      to: '',
      label: t('normative.sections.claims'),
      last: false,
      anchor: true,
      active: isActive('reclamaciones', 'produccion'),
    },
    {
      id: 'produccion',
      to: '',
      label: t('normative.sections.production'),
      last: false,
      anchor: true,
      active: isActive('produccion', 'suministros'),
    },
    {
      id: 'suministros',
      to: '',
      label: t('normative.sections.supply'),
      last: false,
      anchor: true,
      active: isActive('suministros', 'calidad'),
    },
    {
      id: 'calidad',
      to: '',
      label: t('normative.sections.quality'),
      last: true,
      anchor: true,
      active: isActive('calidad'),
    },
  ];

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    setSearch(value);

    if (_.isEmpty(search)) {
      setNormative(normativeList);
      return;
    }

    const newNormative = _.filter((item: NormativeProps) => (
      item.title.toLowerCase().includes(value.toLowerCase())
      || item.law.toLowerCase().includes(value.toLowerCase())
    ))(normativeList);
    setNormative(newNormative);
  };

  const filterBySection = (section: string): NormativeProps[] => (
    _.filter((item: NormativeProps) => (
      _.isEqual(item.section, section)
    ))(currentNormative)
  );

  return (
    <PublicLayout title={t('normative.title')}>
      <SidebarLayout sidebarTabs={links}>
        <Content className="py-16 lg:py-0">
          <div className="lg:flex mb-16 lg:items-center lg:mb-24">
            <div className="flex w-full max-w-240 border-b border-black py-2 items-center mb-10 lg:mb-0">
              <input
                type="text"
                name="search"
                id="search"
                value={search}
                placeholder={t('contact.buscando')}
                onChange={handleChange}
                className="bg-transparent flex-1 body-copy text-black block py-px placeholder-gray-400"
              />
              <Icons.Search color="blue" />
            </div>
          </div>
          <div id="fraude" className="mb-14 lg:mb-28">
            <Title size="small" className="mb-8 lg:mb-12">{t('normative.sections.fraud')}</Title>
            {_.map((item: NormativeProps) => (
              <NormativeItem key={item.id} item={item} />
            ))(filterBySection(t('normative.sections.fraud')))}
          </div>
          <div id="reclamaciones" className="mb-14 lg:mb-28">
            <Title size="small" className="mb-8 lg:mb-12">{t('normative.sections.claims')}</Title>
            {_.map((item: NormativeProps) => (
              <NormativeItem key={item.id} item={item} />
            ))(filterBySection(t('normative.sections.claims')))}
          </div>
          <div id="produccion" className="mb-14 lg:mb-28">
            <Title size="small" className="mb-8 lg:mb-12">{t('normative.sections.production')}</Title>
            {_.map((item: NormativeProps) => (
              <NormativeItem key={item.id} item={item} />
            ))(filterBySection(t('normative.sections.production')))}
          </div>
          <div id="suministros" className="mb-14 lg:mb-28">
            <Title size="small" className="mb-8 lg:mb-12">{t('normative.sections.supply')}</Title>
            {_.map((item: NormativeProps) => (
              <NormativeItem key={item.id} item={item} />
            ))(filterBySection(t('normative.sections.supply')))}
          </div>
          <div id="calidad" className="mb-14 lg:mb-28">
            <Title size="small" className="mb-8 lg:mb-12">{t('normative.sections.quality')}</Title>
            {_.map((item: NormativeProps) => (
              <NormativeItem key={item.id} item={item} />
            ))(filterBySection(t('normative.sections.quality')))}
          </div>
        </Content>
      </SidebarLayout>
    </PublicLayout>
  );
};

export default Normative;
