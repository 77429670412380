import React, {
  ChangeEvent,
  FocusEvent,
  FunctionComponent,
  HTMLInputTypeAttribute,
} from 'react';
import _ from 'lodash/fp';

export interface Props {
  type: HTMLInputTypeAttribute;
  name: string;
  id?: string;
  value?: string;
  checked?: boolean;
  placeholder?: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  readonly?: boolean;
  disabled?: boolean;
  className?: string;
  required?: boolean;
  step?: string;
  min?: string;
  max?: string;
}

const Input: FunctionComponent<Props> = (
  {
    type,
    name,
    value,
    id,
    checked,
    placeholder,
    onChange,
    onBlur,
    readonly,
    disabled,
    className,
    required,
    step,
    min,
    max,
  }: Props,
) => (
  <input
    type={type}
    name={name}
    id={_.isUndefined(id) ? name : id}
    placeholder={`${placeholder}${required ? '*' : ''}`}
    className={className}
    value={value}
    readOnly={readonly}
    disabled={disabled}
    onChange={onChange}
    onBlur={onBlur}
    checked={checked}
    step={step}
    min={min}
    max={max}
  />
);

export default Input;
