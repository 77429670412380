import { createStore, EasyPeasyConfig, Store } from 'easy-peasy';
import _ from 'lodash/fp';

import * as sessionService from '../api/services/session';
import * as userService from '../api/services/user';
import * as passwordService from '../api/services/passwords';
import * as contractService from '../api/services/contract';
import * as authorizedService from '../api/services/authorized';
import * as distributorService from '../api/services/distributor';
import * as requestService from '../api/services/requests';
import * as contactService from '../api/services/contact';

import model from './model';

const buildStore = (overrideConfig: EasyPeasyConfig = {}): Store => {
  const defaultConfig = {
    injections: {
      sessionService,
      userService,
      passwordService,
      contractService,
      authorizedService,
      distributorService,
      requestService,
      contactService,
    },
  };

  const config = _.defaultsDeep(defaultConfig, overrideConfig);

  if (process.env.NODE_ENV === 'development') {
    return createStore(model, {
      devTools: true,
      ...config,
    });
  }

  return createStore(model, config);
};

export default buildStore;
