import React, {
  ChangeEvent,
  FocusEvent,
  FunctionComponent,
  ReactNode,
} from 'react';
import classnames from 'classnames';
import _ from 'lodash/fp';

import classNames from '../../services/classNames';

import FormGroup from './components/FormGroup';
import InputLabel from './components/InputLabel';
import Input from './components/Input';
import InputError from './components/InputError';

export interface Props {
  name: string;
  value: string;
  placeholder: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  touched?: boolean;
  errors?: string;
  readonly?: boolean;
  disabled?: boolean;
  required?: boolean;
  containerClassName?: string;
  labelClassName?: string;
  inputClassName?: string;
  icon?: ReactNode;
  maxW?: boolean;
}

const TextInput: FunctionComponent<Props> = ({
  name,
  value,
  placeholder,
  onChange,
  onBlur = undefined,
  touched = false,
  errors = '',
  readonly = false,
  disabled = false,
  required = false,
  containerClassName = '',
  labelClassName = '',
  inputClassName = '',
  icon,
  maxW = false,
}: Props) => {
  const labelClass = classnames(classNames('block', labelClassName), {
    'opacity-100': !_.isEmpty(value),
    'opacity-0': _.isEmpty(value),
  });

  return (
    <FormGroup
      className={classNames('text-input', containerClassName)}
      maxW={maxW}
    >
      <InputLabel
        name={name}
        label={placeholder}
        className={labelClass}
        required={required}
      />
      <Input
        type="text"
        name={name}
        placeholder={placeholder}
        className={classNames('text', inputClassName)}
        value={value}
        readonly={readonly}
        disabled={disabled}
        onChange={onChange}
        onBlur={onBlur}
        required={required}
      />
      {!_.isUndefined(icon) && icon}
      <InputError message={touched ? errors : ''} />
    </FormGroup>
  );
};

export default TextInput;
