import React, { FunctionComponent, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import _ from 'lodash/fp';

export interface Props {
  to: string;
  label: string;
  type?: 'primary' | 'secondary';
  icon?: ReactNode;
  fixed?: boolean;
  disabled?: boolean;
}

const LinkButton: FunctionComponent<Props> = (
  {
    to,
    label,
    type = 'primary',
    icon,
    fixed = false,
    disabled = false,
  },
) => {
  const buttonClass = classnames({
    [`btn-${type}-fixed`]: fixed,
    [`btn-${type}`]: !fixed,
    disabled,
  });

  if (disabled) {
    return (
      <div className={buttonClass}>
        {!_.isUndefined(icon) && <span className="icon">{icon}</span>}
        <span className="label">{label}</span>
      </div>
    );
  }

  return (
    <Link to={to} className={buttonClass}>
      {!_.isUndefined(icon) && <span className="icon">{icon}</span>}
      <span className="label">{label}</span>
    </Link>
  );
};

export default LinkButton;
