import { FormikErrors } from 'formik';
import _ from 'lodash/fp';
import React, { ChangeEvent, FunctionComponent } from 'react';

import Icons from '../../../../assets/icons';
import Bodycopy from '../../../../components/text-components/Bodycopy';
import Title from '../../../../components/text-components/Title';
import { TouchedDocs } from '../../../../store/model';
import useAuthSteps from '../../../../store/model/authorized/authorized';
import { AuthorizedForm } from '../../../../store/model/authorized/types';
import t from '../../../../text-strings';
import FormStepsNavigation from '../components/FormStepNavigation';
import FormStepScreen from '../components/FormStepScreen';

/* eslint-disable @typescript-eslint/no-explicit-any */
interface Props {
  values: AuthorizedForm;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<AuthorizedForm>>
  onBlur: (name: 'front' | 'back' | 'authorization') => void;
  touched: TouchedDocs;
  errors: FormikErrors<AuthorizedForm>;
}
/* eslint-enable @typescript-eslint/no-explicit-any */

const IDCard: FunctionComponent<Props> = (
  {
    values,
    setFieldValue,
    onBlur,
    touched,
    errors,
  }: Props,
) => {
  const [step, setStep] = useAuthSteps();

  const handleDocument = (e: ChangeEvent<HTMLInputElement>): void => {
    const { files, name } = e.target;
    if (_.isNil(files)) return;
    setFieldValue(name, files[0]);
  };

  return (
    <FormStepScreen
      onNext={() => setStep('authorization')}
      onBack={() => setStep('supply')}
      current={_.isEqual(step, 'id-card')}
    >
      <FormStepsNavigation blockClassNames={['bg-black', 'bg-black', '', '']} />
      <Title className="mb-12">{t('authorized.id_card')}</Title>
      <Bodycopy className="mb-16 lg:mb-20 max-w-240 w-full">{t('authorized.id_card_subtitle')}</Bodycopy>
      <div className="mb-16 lg:mb-20 cursor-pointer" onClick={(): void => onBlur('front')}>
        <label
          htmlFor="idFront"
          className="flex items-center btn text-blue mb-2"
        >
          <Icons.Attach color="blue" className="mr-4" />
          {_.isEmpty(values.idFront.name)
            ? t('models.authorized.attributes.id_front')
            : t('models.authorized.attributes.id_front_complete')}
        </label>
        <input
          type="file"
          name="idFront"
          id="idFront"
          onChange={handleDocument}
          className="hidden"
          accept="image/*, .pdf"
        />
        {touched.front && (
          <Bodycopy color="red">{errors.idFront}</Bodycopy>
        )}
      </div>
      <div className="mb-16 lg:mb-20 cursor-pointer" onClick={(): void => onBlur('back')}>
        <label htmlFor="idBack" className="flex items-center btn text-blue mb-2">
          <Icons.Attach color="blue" className="mr-4" />
          {_.isEmpty(values.idBack.name)
            ? t('models.authorized.attributes.id_back')
            : t('models.authorized.attributes.id_back_complete')}
        </label>
        <input
          type="file"
          name="idBack"
          id="idBack"
          onChange={handleDocument}
          className="hidden"
          accept="image/*, .pdf"
        />
        {touched.back && (
          <Bodycopy color="red">{errors.idBack}</Bodycopy>
        )}
      </div>
    </FormStepScreen>
  );
};

export default IDCard;
