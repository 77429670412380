import React, { FunctionComponent, useEffect, useState } from 'react';
import classnames from 'classnames';
import _ from 'lodash/fp';

import Icons from '../../assets/icons';
import Caption from '../text-components/Caption';
import date from '../../services/date';
import t from '../../text-strings';

interface Props {
  alias: string;
  startDate: string;
  endDate?: string;
  acceptDate?: string;
}

const CutsAlert: FunctionComponent<Props> = (
  {
    alias,
    startDate,
    endDate,
    acceptDate,
  }: Props,
) => {
  const [open, setOpen] = useState(true);

  const containerClass = classnames(
    'bg-white w-full lg:w-11/12 max-w-screen-content lg:ml-auto p-8 rounded-sm border-l-4 border-red mb-8', {
      hidden: !open,
    },
  );

  const closeAlert = (): void => setOpen(!open);

  const setMessage = (): string => {
    if (!_.isNil(acceptDate)) {
      return t(
        'breakdowns.alerts.corte.pagado_restablecer',
        date.format(acceptDate, 'ddd D/M, HH:mm'),
      );
    }

    if (!_.isNil(endDate)) {
      return t('breakdowns.alerts.corte.pagado', date.format(endDate, 'ddd D/M'));
    }

    if (date.isBrefore(startDate, new Date())) {
      if (date.isSameDay(startDate, new Date())) {
        return t('breakdowns.alerts.corte.hoy_pasado', date.format(startDate, 'HH:mm'));
      }
      return t('breakdowns.alerts.corte.pasado', date.format(startDate, 'ddd D/M'));
    }

    if (date.isSameDay(startDate, new Date())) {
      return t('breakdowns.alerts.corte.hoy_futuro', date.format(startDate, 'HH:mm'));
    }

    return t('breakdowns.alerts.corte.futuro', date.format(startDate, 'ddd D/M'));
  };

  useEffect(() => setOpen(true), [alias]);

  return (
    <div className={containerClass}>
      <div className="flex">
        <div className="flex-1 pt-1 px-4">
          <p className="btn mb-4">{setMessage()}</p>
          <Caption>{alias}</Caption>
        </div>
        <div className="min-w-12" onClick={closeAlert}>
          <Icons.Close color="red" />
        </div>
      </div>
    </div>
  );
};

export default CutsAlert;
