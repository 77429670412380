import React, { FunctionComponent } from 'react';

import Icons from '../assets/icons';

interface WarningMessageProps {
    text: string
}

const OkMessage: FunctionComponent<WarningMessageProps> = (
  { text } : WarningMessageProps,
) => (
  <div className="w-full border rounded-sm bg-green-light flex mb-20">
    <div className=""><Icons.Good height={12} width={12} color="green" className="mt-8 ml-8 mr-4 lg:mr-8" /></div>
    <div className="mr-8 my-8 lg:mr-24">
      <div className="text-sm text-green">{text}</div>
    </div>
  </div>
);

export default OkMessage;
