import React, { FunctionComponent } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

export type SidebarTabProps = {
  label: string;
  id: string;
  anchor: boolean;
  last: boolean;
  to: string;
  active: boolean;
}

interface Props {
  label: string;
  id: string;
  anchor: boolean;
  last: boolean;
  active: boolean;
  to: string;
}

const SidebarTab: FunctionComponent<Props> = (
  {
    label,
    id,
    anchor,
    last,
    active,
    to,
  }: Props,
) => {
  const setPosition = (): number => document.getElementById(id)?.offsetTop || 0;

  const goToSection = (): void => window.scrollTo({ top: setPosition(), behavior: 'smooth' });

  const linkClass = classnames('block w-auto pt-8 lg:pt-0 cursor-pointer', {
    'lg:mb-12': !last,
    'mr-12 lg:mr-0': !last,
  });

  const labelClass = classnames('block mb-6 whitespace-nowrap lg:mb-4', {
    btn: active,
    bodycopy: !active,
  });

  const borderClass = classnames('w-full h-2 block', {
    'bg-gradient': active,
  });

  if (anchor) {
    return (
      <div onClick={goToSection} className={linkClass} title={label}>
        <span className={labelClass}>{label}</span>
        <span className={borderClass} />
      </div>
    );
  }

  return (
    <Link to={to} className={linkClass} title={label}>
      <span className={labelClass}>{label}</span>
      <span className={borderClass} />
    </Link>
  );
};

export default SidebarTab;
