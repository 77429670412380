import React, { FunctionComponent, useEffect, useState } from 'react';
import _ from 'lodash/fp';

import documents, { Downloadable } from '../../../../services/documents';
import { useStoreState } from '../../../../store/hooks';
import { StateDetailsAttributes } from '../../../../store/model/requests/types';
import Title from '../../../../components/text-components/Title';
import Bodycopy from '../../../../components/text-components/Bodycopy';
import t from '../../../../text-strings';
import Icons from '../../../../assets/icons';
import Caption from '../../../../components/text-components/Caption';

interface Props {
  details: StateDetailsAttributes;
  first?: boolean;
}

const HistoryStateCard: FunctionComponent<Props> = ({ details, first = false }: Props) => {
  const { request } = useStoreState((state) => state.requests);
  const [downloadURL, setDownloadURL] = useState<Downloadable>();

  const currentIndex = _.indexOf(details)(request.stateDetails);
  const prevDetails = first
    ? request.stateDetails[currentIndex]
    : request.stateDetails[currentIndex + 1];

  useEffect(() => {
    (async () => {
      if (first) return;
      const downloadable = await documents.downloadHistoryFile(request, prevDetails);
      if (!_.isEmpty(downloadable.filename)) {
        setDownloadURL(downloadable);
      }
    })();
  }, []);

  const {
    acceptButton,
    cancelButton,
    uploadFile,
  } = details;

  const userComment = (
    !acceptButton && !cancelButton && !uploadFile
    && (_.isEqual(details.name, t('history.analizando'))
    || _.isEqual(details.name, t('history.revisando')))
  );

  return (
    <div className="bg-white rounded-sm py-12 px-8 lg:py-16 lg:px-16 mb-8 lg:flex">
      <div className="lg:flex-1">
        <Title size="small" tag="h3" className="lg:mb-2">{details.name}</Title>
        <Caption tag="p" className="text-gray-400 mb-8 lg:mb-12">{details.createdDate}</Caption>
        {!first && !_.isNil(prevDetails.description) && !_.isEmpty(prevDetails.description) && (
        <div className="mb-8 lg:mb-12">
          <p className="btn mb-4">
            {userComment ? t('history.user_comment') : t('history.comment')}
          </p>
          <Bodycopy>{prevDetails.description}</Bodycopy>
        </div>
        )}
        {!_.isUndefined(downloadURL) && (
        <a download={`${downloadURL.filename}.pdf`} href={downloadURL.blobURL} className="flex btn text-blue">
          <Icons.Document color="blue" className="mr-2" />
          {_.isEqual(details.name, t('history.revisando'))
              || _.isEqual(details.name, t('history.propuesta'))
            ? t('history.download_prop')
            : t('history.download_docs')}
        </a>
        )}
      </div>
      <div className="h-px w-full bg-gray-300 my-16 lg:hidden" />
      <div className="flex lg:w-1/2 justify-end">
        <div className="flex w-1/2 lg:w-auto lg:mr-14">
          <Icons.Calendar />
          <div className="ml-2">
            <p className="btn mb-2">{details.daysCurrent}</p>
            <Caption tag="p" className="text-gray-400">En este estado</Caption>
          </div>
        </div>
        <div className="flex w-1/2 lg:w-auto">
          <Icons.Calendar />
          <div className="ml-2">
            <p className="btn mb-2">{details.daysLimit}</p>
            <Caption tag="p" className="text-gray-400">{details.limitDate}</Caption>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HistoryStateCard;
