import React, {
  ChangeEvent, FunctionComponent, useEffect, useState,
} from 'react';
import { useFormik } from 'formik';
import _ from 'lodash/fp';

import { useStoreActions, useStoreState } from '../../../store/hooks';
import useHandleRequestResponse from '../../../services/handle-request-response';
import { FormID, SelfconsumptionParams, SelfconsumptionRequestParams } from '../../../store/model/requests/types';
import {
  clientData,
  documentData,
  especificationData,
  instalationData,
  requestData,
} from '../../../store/model/requests/requests';
import formInit from '../../../forms/initial-values';
import schemas from '../../../forms/schemas';
import constants from '../../../services/constants';
import AuthenticatedLayout from '../../../layout/AuthenticatedLayout';
import Form from '../../../components/forms/Form';
import t from '../../../text-strings';
import validations from '../../../forms/validations';
import FormResponse from '../../../components/forms/FormResponse';
import InternalLink from '../../../components/InternalLink';
import { routes } from '../../../services/routing';
import Icons from '../../../assets/icons';
import Wrap from '../../../components/containers/Wrap';

import ClientDataStep from './components/ClientData';
import RequestDataStep from './components/RequestData';
import InstalationDataStep from './components/InstalationData';
import DocumentDataStep from './components/DocumentData';
import EspecificationDataStep from './components/EspecificationData';
import SideNavLink from './components/SideNavLink';

const TOTAL_STEPS = 5;

const SelfConsumptionRequest: FunctionComponent = () => {
  const { session } = useStoreState((state) => state.session);
  const {
    getloadrequest,
    createselfconsumption,
    setRequestStep,
  } = useStoreActions((actions) => actions.requests);
  const [loading, setLoading] = useState(true);
  const [
    message,
    success,
    sendedPdf,
    handleResponse,
  ] = useHandleRequestResponse<SelfconsumptionParams>();

  const handleOnSubmit = async (values: SelfconsumptionParams): Promise<void> => {
    // @ts-ignore
    const params: SelfconsumptionRequestParams = _.omit([
      'technicalMemory',
      'technicalDraft',
      'authorization',
      'warranty',
      'environmentalImpact',
    ])(values);
    handleResponse(await createselfconsumption(params), values);
  };

  const {
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    isSubmitting,
    isValid,
  } = useFormik({
    initialValues: formInit.selfconsumption(session),
    onSubmit: handleOnSubmit,
    validationSchema: schemas.selfconsumption,
  });

  useEffect(() => {
    (async () => {
      setRequestStep('cliente');
      await getloadrequest({
        distributorId: constants.DISTRIBUTOR_ID,
        formCode: constants.SELFCONSUMPTION_CODE as FormID,
      });
      setLoading(false);
    })();
  }, []);

  if (loading) return null;

  const handleSelect = (e: ChangeEvent<HTMLSelectElement>): void => {
    const { value, name } = e.target;
    if (_.isEmpty(value)) {
      setFieldValue(name, 0);
      return;
    }
    setFieldValue(name, parseInt(value, 10));
  };

  return (
    <AuthenticatedLayout title="" onlyDesktop sidebar={false}>
      <Form onSubmit={handleSubmit} className="lg:flex lg:flex-1">
        <div className="lg:w-7/10">
          <ClientDataStep
            title={t('requests.self_consumption_request')}
            totalSteps={TOTAL_STEPS}
          // @ts-ignore
            values={_.pick(clientData)(values)}
            handleChange={handleChange}
            handleSelect={handleSelect}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
            errors={errors}
            touched={touched}
          />
          <RequestDataStep
            title={t('requests.self_consumption_request')}
            prev="cliente"
            step={2}
            totalSteps={TOTAL_STEPS}
          // @ts-ignore
            values={_.pick(requestData)(values)}
            handleChange={handleChange}
            handleSelect={handleSelect}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
            errors={errors}
            touched={touched}
          />
          <InstalationDataStep
            title={t('requests.self_consumption_request')}
            step={3}
            next="especificaciones"
            totalSteps={TOTAL_STEPS}
          // @ts-ignore
            values={_.pick(instalationData)(values)}
            handleChange={handleChange}
            handleSelect={handleSelect}
            setFieldValue={setFieldValue}
            handleBlur={handleBlur}
            errors={errors}
            touched={touched}
            formCode={constants.SELFCONSUMPTION_CODE}
          />
          <EspecificationDataStep
            title={t('requests.self_consumption_request')}
            step={4}
            totalSteps={TOTAL_STEPS}
            // @ts-ignore
            values={_.pick(especificationData)(values)}
            handleChange={handleChange}
            handleSelect={handleSelect}
            handleBlur={handleBlur}
            errors={errors}
            touched={touched}
          />
          <DocumentDataStep
            title={t('requests.self_consumption_request')}
            prev="instalacion"
            step={5}
            totalSteps={TOTAL_STEPS}
            // @ts-ignore
            values={_.pick(documentData)(values)}
            setFieldValue={setFieldValue}
            handleBlur={handleBlur}
            errors={errors}
            message={message}
            success={success}
            isSubmitting={isSubmitting}
            isValid={isValid && !validations.isCau(
              values.networked || 0,
              values.powerInstalation || 0,
              values.tension || 0,
              values.terrainType,
            )}
            sendedPdf={sendedPdf}
            formCode={constants.SELFCONSUMPTION_CODE}
          />
          {validations.isCau(
            values.networked || 0,
            values.powerInstalation || 0,
            values.tension || 0,
            values.terrainType,
          ) && (
          <Wrap size="wrap-authenticated" className="py-8 lg:hidden">
            <FormResponse message={t('requests.forms.is_cau')} />
            <InternalLink
              label={t('requests.forms.go_to_cau')}
              to={routes.requests.cau}
              icon={<Icons.Go />}
              right
              className="mt-8"
            />
          </Wrap>
          )}
        </div>
        <div className="hidden w-3/10 lg:flex flex-col bg-white relative pt-72 pl-32">
          <div className="bg-white absolute top-0 left-full w-screen-full h-full" />
          <SideNavLink
            label={t('requests.forms.cliente')}
            currentStep="cliente"
          />
          <SideNavLink
            label={t('requests.forms.solicitud')}
            currentStep="solicitud"
          />
          <SideNavLink
            label={t('requests.forms.instalacion')}
            currentStep="instalacion"
          />
          <SideNavLink
            label={t('requests.forms.especificaciones')}
            currentStep="especificaciones"
          />
          <SideNavLink
            label={t('requests.forms.documentacion')}
            currentStep="documentacion"
          />
          {validations.isCau(
            values.networked || 0,
            values.powerInstalation || 0,
            values.tension || 0,
            values.terrainType,
          ) && (
          <>
            <FormResponse message={t('requests.forms.is_cau')} />
            <InternalLink
              label={t('requests.forms.go_to_cau')}
              to={routes.requests.cau}
              icon={<Icons.Go />}
              right
              className="mt-8"
            />
          </>
          )}
        </div>
      </Form>
    </AuthenticatedLayout>
  );
};

export default SelfConsumptionRequest;
