import React, { FunctionComponent, ReactNode } from 'react';
import { Route, Redirect } from 'react-router-dom';

import { routes } from '../services/routing';
import { useStoreState } from '../store/hooks';

interface Props {
  path: string;
  children: ReactNode;
  exact?: boolean;
}

const AuthenticationPage: FunctionComponent<Props> = (
  {
    path,
    children,
    exact = false,
  }: Props,
) => {
  const { isLoggedIn } = useStoreState((state) => state.session);
  return (
    <Route
      exact={exact}
      path={path}
      render={({ location }) => (
        isLoggedIn
          ? <Redirect to={{ pathname: routes.profile, state: { from: location } }} />
          : children
      )}
    />
  );
};

export default AuthenticationPage;
