import React, { FunctionComponent } from 'react';
import _ from 'lodash/fp';

import PublicLayout from '../../../layout/PublicLayout';
import t from '../../../text-strings';
import Content from '../../../layout/components/Content';
import Title from '../../../components/text-components/Title';
import Accordion from '../../../components/Accordion';
import LinkCard from '../../../components/cards/LinkCard';
import { routes } from '../../../services/routing';
import Icons from '../../../assets/icons';
import { FAQAttributes } from '../../../store/model/contact/types';
import useFAQs from '../../../services/use-faq';
import Bodycopy from '../../../components/text-components/Bodycopy';
import InternalLink from '../../../components/InternalLink';
import useScroll from '../../../services/use-scroll';
import { SidebarTabProps } from '../../../layout/tabs/SidebarTab';
import SidebarLayout from '../../../layout/SidebarLayout';

const SelfConsumptionProcess: FunctionComponent = () => {
  const isActive = useScroll();
  const [loading, faqs] = useFAQs('Autoconsumo_tramitaciones');

  const links: SidebarTabProps[] = [
    {
      id: 'tramites',
      to: '',
      label: t('selfProcess.tramites_frecuentes'),
      last: false,
      anchor: true,
      active: isActive('tramites', 'dudas'),
    },
    {
      id: 'dudas',
      to: '',
      label: t('selfProcess.doubts.title'),
      last: false,
      anchor: true,
      active: isActive('dudas', 'faq'),
    },
    {
      id: 'faq',
      to: '',
      label: t('selfProcess.preguntas_frecuentes'),
      last: true,
      anchor: true,
      active: isActive('faq'),
    },
  ];

  return (
    <PublicLayout title={t('selfProcess.title')}>
      <SidebarLayout sidebarTabs={links}>
        <div id="tramites" className="w-full lg:flex lg:flex-col lg:items-end py-16 lg:py-0">
          <Content className="lg:pb-16">
            <Title tag="h1" className="mb-12">{t('selfProcess.tramites_frecuentes')}</Title>
            <div className="lg:flex lg:flex-wrap lg:justify-between">
              <LinkCard
                label={t('selfProcess.solicitud_autoconsumo')}
                to={routes.requests.selfconsumption}
                icon={<Icons.Bolt color="white" />}
                className="mb-4 lg:w-200 hd:w-224 xl:mb-0"
              />
              <LinkCard
                label={t('selfProcess.solicitud_cau')}
                to={routes.requests.cau}
                icon={<Icons.Document color="white" />}
                className="lg:w-200 hd:w-2242"
              />
            </div>
          </Content>
        </div>
        <div id="dudas" className="w-full lg:flex lg:flex-col lg:items-end py-16 lg:py-0">
          <Content className="lg:pb-16">
            <Title className="mb-12">{t('selfProcess.doubts.title')}</Title>
            <Title size="small" tag="h4" className="mb-2">{t('selfProcess.doubts.list.1.title')}</Title>
            <Bodycopy className="mb-12">
              {t('selfProcess.doubts.list.1.description')}
              <br />
              Para saber más haz click &nbsp;
              <InternalLink
                to={routes.selfConsumption.index}
                label="aquí"
                className="text-blue"
              />
            </Bodycopy>
            <Title size="small" tag="h4" className="mb-2">{t('selfProcess.doubts.list.2.title')}</Title>
            <Bodycopy className="mb-12">{t('selfProcess.doubts.list.2.description')}</Bodycopy>
            <Title size="small" tag="h4" className="mb-2">{t('selfProcess.doubts.list.3.title')}</Title>
            <Bodycopy className="mb-12">{t('selfProcess.doubts.list.3.description')}</Bodycopy>
            <Title size="small" tag="h4" className="mb-2">{t('selfProcess.doubts.list.4.title')}</Title>
            <Bodycopy className="mb-12">{t('selfProcess.doubts.list.4.description_1')}</Bodycopy>
            <Bodycopy className="mb-12">{t('selfProcess.doubts.list.4.description_2')}</Bodycopy>
            <Title size="small" tag="h4" className="mb-2">{t('selfProcess.doubts.list.5.title')}</Title>
            <Bodycopy className="mb-12">{t('selfProcess.doubts.list.5.description')}</Bodycopy>
          </Content>
        </div>
        <div id="faq" className="bg-white lg:bg-transparent w-full lg:flex lg:flex-col lg:items-end py-16 lg:py-0">
          <Content className="lg:pb-16">
            <Title className="mb-12">{t('selfProcess.preguntas_frecuentes')}</Title>
            {!loading && (
            <>
              {_.map((faq: FAQAttributes) => (
                <Accordion
                  key={faq.questionId}
                  title={faq.title}
                  text={faq.text}
                />
              ))(faqs)}
            </>
            )}
          </Content>
        </div>
      </SidebarLayout>
    </PublicLayout>
  );
};

export default SelfConsumptionProcess;
