import _ from 'lodash/fp';

import t from '../../../text-strings';

import { BreakdownsAttributes, BreakdownStateAttributes, BreakdownStates } from './types';

export interface Breakdown {
  join: BreakdownStates[];
  joinByAlias: (alias: string) => BreakdownStates[];
}

const defaultBreakdowns: BreakdownsAttributes = {
  cuts: [],
  faults: [],
  scheduledCuts: [],
};

const generateBreakdownStateWithType = (
  type: string,
  breakdowns: BreakdownStateAttributes[],
): BreakdownStates[] => (
  _.map((breakdown: BreakdownStateAttributes) => ({
    ...breakdown,
    type,
  }))(breakdowns)
);

const filterByAlias = (
  alias: string,
  type: string,
  breakdowns: BreakdownStateAttributes[],
): BreakdownStates[] => {
  // @ts-ignore
  const filteredBreakdowns: BreakdownStateAttributes[] = _.filter(['alias', alias])(breakdowns);
  return generateBreakdownStateWithType(type, filteredBreakdowns);
};

const breakdowns = (breakdownAttributes = defaultBreakdowns): Breakdown => ({
  join: [
    ...generateBreakdownStateWithType(t('breakdowns.corte'), breakdownAttributes.cuts),
    ...generateBreakdownStateWithType(t('breakdowns.averia'), breakdownAttributes.faults),
    ...generateBreakdownStateWithType(t('breakdowns.corte_programado'), breakdownAttributes.scheduledCuts),
  ],
  joinByAlias: (alias: string) => [
    ...filterByAlias(alias, t('breakdowns.corte'), breakdownAttributes.cuts),
    ...filterByAlias(alias, t('breakdowns.averia'), breakdownAttributes.faults),
    ...filterByAlias(alias, t('breakdowns.corte_programado'), breakdownAttributes.scheduledCuts),
  ],
});

export default breakdowns;
