import React, { FunctionComponent } from 'react';

import { AppColors } from '../../styles';

interface Props {
  color?: AppColors;
  width?: number;
  height?: number;
  className?: string;
}

const Replace: FunctionComponent<Props> = (
  {
    color = 'black',
    width = 12,
    height = 12,
    className = '',
  }: Props,
) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className={`w-${width} h-${height} text-${color} fill-current ${className}`}>
    <path fillRule="evenodd" clipRule="evenodd" d="M21 5V8.58579L8.58579 21H5C4.70653 21 4.42782 20.9368 4.17675 20.8232L20.8232 4.17675C20.9368 4.42782 21 4.70653 21 5ZM14.5858 21H10L21 10V14.5858L14.5858 21ZM16 21H19C20.1046 21 21 20.1046 21 19V16L16 21ZM20.1919 3.39384C19.8591 3.1464 19.4466 3 19 3H16L3 16V19C3 19.4466 3.1464 19.8591 3.39384 20.1919L20.1919 3.39384ZM10 3H14.5858L3 14.5858V10L10 3ZM8.58579 3H5C3.89543 3 3 3.89543 3 5V8.58579L8.58579 3Z" />
  </svg>
);

export default Replace;
