import _ from 'lodash/fp';
import React, { FunctionComponent } from 'react';

import { routes } from '../../services/routing';
import { Contract } from '../../store/model/contract/contract';
import t from '../../text-strings';
import LinkButton from '../buttons/LinkButton';
import InternalLink from '../InternalLink';
import Tag from '../Tag';
import Caption from '../text-components/Caption';
import Title from '../text-components/Title';

export interface Props {
  contract: Contract;
}

const ContractCard: FunctionComponent<Props> = ({ contract }: Props) => (
  <div className="contract-card">
    <div className="absolute -top-6 right-12">
      {/* @ts-ignore */}
      <Tag label={contract.getState()} type={_.lowerCase(contract.getState())} />
    </div>
    <Title tag="h4" size="small" className="truncate mb-4">{contract.title}</Title>
    <Caption color="gray-400" className="mb-2">
      {t('models.contract.potencia', contract.get('potMaxReconocida'))}
    </Caption>
    <Caption color="gray-400" className="mb-8">
      {t('models.contract.distribuidora', contract.get('distribuidora'))}
    </Caption>
    <Tag label={contract.ownership()} type="regular" />
    <div className="w-full flex justify-between items-center mt-14 lg:mt-16">
      <LinkButton
        label={t('models.contract.consumo')}
        to={`${routes.consumption}?id=${contract.get('idcontrato')}`}
        type="secondary"
      />
      <InternalLink
        to={routes.contract(contract.get('idcontrato'))}
        label={t('models.contract.contrato')}
        className="text-blue"
      />
    </div>
  </div>
);

export default ContractCard;
