import React, { FunctionComponent } from 'react';
import { useLocation } from 'react-router-dom';

import Icons from '../../assets/icons';
import { routes } from '../../services/routing';
import useSelectedURL from '../../services/selected-url';

import Tab from './Tab';

const TabNavigation: FunctionComponent = () => {
  const isSelected = useSelectedURL();
  const location = useLocation();
  const isMyRequests = location.pathname.split('/')[1] === 'mis-solicitudes';
  const isMeasureDetails = location.pathname.split('/')[1] === 'measure';

  return (
    <div className="fixed bottom-0 left-0 z-50 flex w-full h-32 bg-white lg:hidden shadow-blur-sm">
      <div className="flex justify-center flex-1 w-11/12 mx-auto max-w-256">
        <Tab
          to={routes.consumption}
          label="Consumo"
          icon={(
            <Icons.Bolt
              color={isSelected(routes.consumption) ? 'black' : 'gray-400'}
            />
          )}
          isActive={isSelected(routes.consumption)}
        />
        <Tab
          to={routes.counter}
          label="Contador"
          icon={(
            <Icons.Counter
              color={
                isSelected(routes.counter)
                || isSelected(routes.counterSchedule)
                || isMeasureDetails
                  ? 'black'
                  : 'gray-400'
              }
            />
          )}
          isActive={
            isSelected(routes.counter)
            || isSelected(routes.counterSchedule)
            || isMeasureDetails
          }
        />
        <Tab
          to={routes.requests.index}
          label="Solicitudes"
          icon={(
            <Icons.Document
              color={
                isSelected(routes.requests.index) || isMyRequests
                  ? 'black'
                  : 'gray-400'
              }
            />
          )}
          isActive={isSelected(routes.requests.index) || isMyRequests}
        />
        <Tab
          to={routes.breakdowns}
          label="No tengo luz"
          icon={(
            <Icons.Fraud
              color={isSelected(routes.breakdowns) ? 'black' : 'gray-400'}
            />
          )}
          isActive={isSelected(routes.breakdowns)}
        />
      </div>
    </div>
  );
};

export default TabNavigation;
