import { AxiosResponse } from 'axios';

import { AuthorizedParams, RollbackAuthorized } from '../../store/model/authorized/types';
import { axiosPost, setURL } from '../axios';

export const create = async (params: AuthorizedParams): Promise<AxiosResponse> => (
  axiosPost(setURL('/Authorized', '/createauthorized'), params)
);

export const rollbackauthorizeuser = async (params: RollbackAuthorized): Promise<AxiosResponse> => (
  axiosPost(setURL('/Authorized', '/rollbackauthorizeuser'), params)
);
