import { useState } from 'react';
import _ from 'lodash/fp';

import { useStoreActions } from '../store/hooks';
import { AuthorizedParams } from '../store/model/authorized/types';
import azureAPI from '../store/model/azure/azure';
import t from '../text-strings';

import documents from './documents';

const useHandleAuthorized = (): [
  string, boolean, (r: string | number, params: AuthorizedParams) => Promise<void>
] => {
  const { rollback, setStep } = useStoreActions((actions) => actions.authorized);
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(true);

  const handleResponse = async (r: string | number, params: AuthorizedParams): Promise<void> => {
    if (typeof r === 'string') {
      setMessage(r);
      setSuccess(false);
      return;
    }

    const d: File[] = _.flow(
      _.pick(['idFront', 'idBack', 'authorization']),
      _.valuesIn,
    )(params);
    const blob = 'usuarios';
    const azurePath = `autorizacion/${r}/`;
    try {
      setMessage(t('authorized.uploading'));
      for (let index = 0; index < _.size(d); index += 1) {
        const docPath = `${azurePath}${d[index].name}`;
        // eslint-disable-next-line
        await azureAPI().upload({ path: docPath, file: d[index], blob });
      }
      setMessage('');
      setStep('complete');
    } catch (_error) {
      setMessage(t('authorized.uploading_fail'));
      setSuccess(false);
      await documents.deleteAzurePath(azurePath, blob);
      setMessage(t('authorized.undoing'));
      const response = await rollback({
        rowId: r,
        rollbackError: '',
      });
      if (typeof response === 'string') {
        setMessage(response);
      }
    }
  };

  return [message, success, handleResponse];
};

export default useHandleAuthorized;
