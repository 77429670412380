import React, { FunctionComponent } from 'react';

import Icons from '../assets/icons';

interface WarningTextProps {
    text: string,
    description: string,
}

const WarningText: FunctionComponent<WarningTextProps> = (
  { text, description } : WarningTextProps,
) => (
  <div className="w-full border border-orange-light rounded-sm bg-white flex flex-col items-center text-center mb-20 lg:flex-row lg:text-left">
    <div className=""><Icons.Warning height={24} width={24} className="mt-12 lg:m-16" /></div>
    <div className="my-12 mx-24 lg:my-16 lg:ml-0 lg:flex lg:flex-col lg:flex-grow">
      <h3 className="font-semibold lg:text-md">{text}</h3>
      <div className="text-sm mt-4 lg:text-base">{description}</div>
    </div>
  </div>
);

export default WarningText;
