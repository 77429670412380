import React, { FunctionComponent, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import _ from 'lodash/fp';

import Icons from '../../assets/icons';
import Title from '../text-components/Title';

export interface Props {
  label: string;
  to: string;
  icon?: ReactNode;
  className?: string;
  download?: boolean;
  downloadName?: string;
}

const LinkCard: FunctionComponent<Props> = (
  {
    label,
    to,
    icon,
    className = '',
    download = false,
    downloadName = '',
  },
) => {
  const linkCardClass = classnames(className, {
    'link-card-regular': _.isUndefined(icon),
    'link-card-icon': !_.isUndefined(icon),
  });

  if (download) {
    return (
      <a download={downloadName} href={to} className={linkCardClass}>
        {!_.isUndefined(icon) && <div className="icon">{icon}</div>}
        {!_.isUndefined(icon) ? (
          <h3 className="btn label">{label}</h3>
        ) : (
          <Title size="small" className="label">{label}</Title>
        )}
        <div className="arrow"><Icons.Go color="blue" /></div>
      </a>
    );
  }

  return (
    <Link to={to} className={linkCardClass}>
      {!_.isUndefined(icon) && <div className="icon">{icon}</div>}
      {!_.isUndefined(icon) ? (
        <h3 className="btn label">{label}</h3>
      ) : (
        <h3 className="label">{label}</h3>
      )}
      <div className="arrow"><Icons.Go color="blue" /></div>
    </Link>
  );
};

export default LinkCard;
