import React, { FunctionComponent, useState, useEffect } from 'react';
import {
  Label,
  Cell,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import _ from 'lodash/fp';
import classNames from 'classnames';

import Title from '../../../../components/text-components/Title';
import {
  ConsumptionByTypes,
  ConsumptionParams,
} from '../../../../store/model/contract/types';
import t from '../../../../text-strings';
import Icons from '../../../../assets/icons';
import date from '../../../../services/date';
import helpers from '../../../../services/helpers';
import PieArea from '../../../../components/charts/PieArea';
import BarChartTooltip from '../../../../components/charts/BarChartTooltip';
import WarningAdvice from '../../../../components/WarningAdvice';
import ConsumptionTrendLabel from '../../../../components/charts/ConsumptionTrendLabel';
import LineChartPrevYear from '../../../../components/charts/LineChartPrevYear';
import LineChartPrevMonth from '../../../../components/charts/LineChartPrevMonth';
import LineLegendPrevYear from '../../../../components/charts/LineLegendPrevYear';
import LineLegendPrevMonth from '../../../../components/charts/LineLegendPrevMonth';
import { Any } from '../../../../store/model/global';
import ArrowRight from '../../../../assets/icons/ArrowRight';
import ArrowLeft from '../../../../assets/icons/ArrowLeft';
import ActionButton from '../../../../components/buttons/ActionButton';
import Modal from '../../../../components/Modal';
import Bodycopy from '../../../../components/text-components/Bodycopy';
import { useStoreActions, useStoreState } from '../../../../store/hooks';
import SpinnerSmall from '../../../../components/SpinnerSmall';

interface Props {
  isLoading: boolean;
  period: string[];
  energy: Any;
}

interface TotalStat {
  number: number;
  label: string;
  color?: string;
  className?: string;
}

interface ConsumptionType {
  consumptionType: string;
  consumption: number;
}

interface CustomBarProps {
  data: Data[];
  activeIndex: [number, number, number];
  setActiveIndex: React.Dispatch<React.SetStateAction<number[]>>;
}

interface Data {
  name: string;
  ph: number;
  vh: number;
  fh: number;
  lm: number;
  ly: number;
  cu: number;
}

const CustomBarChart: FunctionComponent<CustomBarProps> = ({
  data,
  activeIndex,
  setActiveIndex,
}: CustomBarProps) => {
  const allData = data;

  const [shownData, setShownData] = useState(
    data.slice(activeIndex[1], activeIndex[2]),
  );

  const handleClick = (dataClicked: Data, index: number) => {
    if (activeIndex[0] === index) {
      setActiveIndex([-1, activeIndex[1], activeIndex[2]]);
    } else {
      setActiveIndex([index, activeIndex[1], activeIndex[2]]);
    }
  };

  const isDisabled = (completedData: Any, visibleData: Any) => {
    const prevDisabled = _.isEqual(completedData[0].name, visibleData[0].name);
    const nextDisabled = _.isEqual(
      completedData[completedData.length - 1],
      visibleData[visibleData.length - 1],
    );

    return [prevDisabled, nextDisabled];
  };

  const onMoveRight = () => {
    const window = 10;

    if (activeIndex[0] !== -1) {
      setActiveIndex([-1, activeIndex[1], activeIndex[2]]);
    } else {
      // @ts-ignore
      let startIndex = Math.floor((shownData.length - 1) / 2) + activeIndex[1];
      const startItem = allData[startIndex];
      startIndex = allData.findIndex((obj) => _.isEqual(obj, startItem));
      setActiveIndex([activeIndex[0], startIndex, startIndex + window]);

      setShownData(allData.slice(startIndex, startIndex + window));
    }
  };

  const onMoveLeft = () => {
    const window = 10;

    if (activeIndex[0] !== -1) {
      setActiveIndex([-1, activeIndex[1], activeIndex[2]]);
    } else {
      // @ts-ignore
      let lastIndex = Math.floor((shownData.length - 1) / 2) + activeIndex[2];
      const lastItem = shownData[lastIndex];
      lastIndex = allData.findIndex((obj) => _.isEqual(obj, lastItem));
      lastIndex = lastIndex < window ? window : lastIndex;
      setActiveIndex([activeIndex[0], lastIndex - window, lastIndex]);

      setShownData(allData.slice(lastIndex - window, lastIndex));
    }
  };

  const [prevDisabled, nextDisabled] = isDisabled(allData, shownData);

  return (
    <div className="relative pb-152 lg:pb-144 lg:w-1/2 isolate">
      <button
        onClick={() => onMoveLeft()}
        type="button"
        disabled={prevDisabled}
        className="absolute left-0 z-50 ml-4 top-1/2"
      >
        <ArrowLeft color={prevDisabled ? 'grey' : 'blue'} />
      </button>
      <ResponsiveContainer width="100%" className="absolute inset-0">
        <BarChart
          margin={{
            top: 55,
            right: 15,
            left: 15,
            bottom: 20,
          }}
          data={shownData}
        >
          <XAxis
            xAxisId={0}
            axisLine={false}
            tickLine={false}
            tick={{ fill: '#A6A6A6' }}
            tickFormatter={helpers.parseLabelXWeek}
            dataKey="name"
            dy={10}
            interval={0}
          />
          <XAxis
            xAxisId={1}
            axisLine={false}
            tickLine={false}
            tick={{ fill: '#A6A6A6' }}
            tickFormatter={helpers.parseLabelXDay}
            dataKey="name"
            dy={0}
            interval={0}
          />
          <YAxis
            axisLine={false}
            tickLine={false}
            tick={{ fill: '#A6A6A6' }}
            // eslint-disable-next-line
            tickFormatter={helpers.parseLabelY}
          >
            <Label
              position="insideTopRight"
              fill="#A6A6A6"
              offset={-37}
              fontSize={15}
              fontWeight="500"
              style={{ textAnchor: 'end' }}
            >
              kWh/Días
            </Label>
          </YAxis>
          <Tooltip content={<BarChartTooltip />} cursor={false} />
          <Bar
            dataKey="ph"
            stackId="a"
            onClick={handleClick}
            radius={[20, 20, 20, 20]}
            barSize={7}
          >
            {data.map((entry, index) => (
              <Cell
                cursor="pointer"
                fill={
                  activeIndex[0] === -1
                  || (activeIndex[0] > -1 && index === activeIndex[0])
                    ? '#F9AC39'
                    : '#D4D4D4'
                }
                // eslint-disable-next-line
                key={`cell-${index}`}
              />
            ))}
          </Bar>
          <Bar
            dataKey="vh"
            stackId="a"
            onClick={handleClick}
            radius={[20, 20, 20, 20]}
          >
            {data.map((entry, index) => (
              <Cell
                cursor="pointer"
                fill={
                  activeIndex[0] === -1
                  || (activeIndex[0] > -1 && index === activeIndex[0])
                    ? '#FFDA00'
                    : '#D4D4D4'
                }
                // eslint-disable-next-line
                key={`cell-${index}`}
              />
            ))}
          </Bar>
          <Bar
            dataKey="fh"
            stackId="a"
            onClick={handleClick}
            radius={[20, 20, 20, 20]}
          >
            {data.map((entry, index) => (
              <Cell
                cursor="pointer"
                fill={
                  activeIndex[0] === -1
                  || (activeIndex[0] > -1 && index === activeIndex[0])
                    ? '#FBD59D'
                    : '#D4D4D4'
                }
                // eslint-disable-next-line
                key={`cell-${index}`}
              />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
      <button
        onClick={() => onMoveRight()}
        type="button"
        disabled={nextDisabled}
        className="absolute right-0 mr-4 top-1/2"
      >
        <ArrowRight color={nextDisabled ? 'grey' : 'blue'} />
      </button>
    </div>
  );
};

const buildStackedData = (startPeriod: Any, endPeriod: Any) => {
  const dates = date.getDaysBetweenDates(startPeriod, endPeriod);
  return dates.map((item) => ({
    name: item,
    ph: 0,
    vh: 0,
    fh: 0,
    lm: 0,
    ly: 0,
    cu: 0,
  }));
};

const Energy: FunctionComponent<Props> = ({
  isLoading,
  period,
  energy,
}: Props) => {
  const { contract } = useStoreState((state) => state.contract);
  const [yearlyAnalytics, setYearlyAnalytics] = useState(true);
  const [activeIndex, setActiveIndex] = useState([-1, 0, 10]);
  const stackedData = buildStackedData(period[0], period[1]);
  const { getcsvhourly } = useStoreActions((actions) => actions.contract);

  const activePeriod = date.isBetween(period[0], period[1]);

  const assignConsumptionType = (
    { consumptionType, consumption: c }: ConsumptionType,
    index: number,
  ) => {
    switch (consumptionType) {
      case 'Valle':
        stackedData[index].vh = c;
        break;
      case 'Punta':
        stackedData[index].ph = c;
        break;
      case 'Llano':
        stackedData[index].fh = c;
        break;
      default:
        break;
    }
  };

  energy.consumptionLastYear.consumptionDays.forEach((item: Any) => {
    const day = helpers.dateFromDateISOString(item.date);
    const index = stackedData.findIndex((obj) => obj.name === `${day}`);
    if (index > -1) stackedData[index].ly = item.consumption;
  });

  energy.consumptionLastMonth.consumptionDays.forEach((item: Any) => {
    const day = helpers.dateFromDateISOString(item.date);
    const index = stackedData.findIndex((obj) => obj.name === `${day}`);
    if (index > -1) stackedData[index].lm = item.consumption;
  });

  energy.consumptionCurrent.consumptionDaysComplete.forEach((item: Any) => {
    const day = helpers.dateFromDateISOString(item.date);
    const index = stackedData.findIndex((obj) => obj.name === `${day}`);
    if (index > -1) {
      assignConsumptionType(item, index);
      stackedData[index].cu = item.consumption;
    }
  });

  energy.consumptionCurrent.consumptionByTypes = helpers.sortTypeHourData(
    energy,
  );

  const [consumptionByTypes, setConsumptionByTypes] = useState(
    energy.consumptionCurrent.consumptionByTypes,
  );

  useEffect(() => {
    if (activeIndex[0] === -1) {
      setConsumptionByTypes(energy.consumptionCurrent.consumptionByTypes);
    } else {
      const item = stackedData[activeIndex[0]];
      const totalDay = item.ph + item.vh + item.fh;

      const filteredConsumptionByTypes = [
        {
          typeHour: 'Hr. Punta - €€€',
          typeId: 0,
          typeText: '10-14 / 18-22',
          consumption: `${item.ph}kWh`,
          porcentage: `${(item.ph / totalDay) * 100}`,
        },
        {
          typeHour: 'Hr. Valle - €€',
          typeId: 0,
          typeText: '0-8 / Fin de semana / Festivo nacional',
          consumption: `${item.vh}kWh`,
          porcentage: `${(item.vh / totalDay) * 100}`,
        },
        {
          typeHour: 'Hr. Llano - €',
          typeId: 0,
          typeText: '8-10 / 14-18 / 22-0',
          consumption: `${item.fh}kWh`,
          porcentage: `${(item.fh / totalDay) * 100}`,
        },
      ];

      setConsumptionByTypes(filteredConsumptionByTypes);
    }
  }, [activeIndex]);

  const StatComponent: FunctionComponent<TotalStat> = ({
    number,
    label,
    color,
    className,
  }) => (
    <div className={`mt-12 ${className}`}>
      <div className={`text-xs font-medium text-${color}`}>{label}</div>
      <div className={`text-base font-semibold text-${color} lg:mt-1`}>
        <span className="text-2xl font-normal lg:text-3xl lg:font-semibold">
          {number}
        </span>
        kWh
      </div>
    </div>
  );

  const estilo = {
    width: '100%',
  };
  const StatCardComponent: FunctionComponent = () => (
    <div className="bg-white rounded-sm">
      { !isLoading && (
      <div className="flex justify-center">
        <StatComponent
          number={helpers.roundStatNumber(
            energy.consumptionCurrent.totalConsumption,
          )}
          label={t('consumption.energy.actual')}
          className="sm:mr-16"
        />
        <StatComponent
          number={helpers.roundStatNumber(
            energy.consumptionCurrent.totalConsumptionProvided,
          )}
          label={t('consumption.energy.total_previsto')}
          color="gray-400"
          className="ml-4 lg:ml-0"
        />
      </div>
      ) }
      <div className="hidden h-1 mt-16 mb-16 ml-64 mr-64 bg-gray-300 lg:block" />
      <div className="flex flex-col mt-18 lg:flex-row">
        <div className="lg:w-1/2">
          <PieArea consumptionByTypes={consumptionByTypes} />
        </div>
        <div className="h-1 mt-16 mb-16 ml-10 mr-10 bg-gray-300 lg:hidden" />
        <CustomBarChart
          data={stackedData}
          activeIndex={[activeIndex[0], activeIndex[1], activeIndex[2]]}
          setActiveIndex={setActiveIndex}
        />
      </div>
    </div>
  );

  const showAdvice = (): boolean => {
    // @ts-ignore
    const highHours: ConsumptionByTypes = _.find([
      'typeHour',
      'Hr. Punta - €€€',
    ])(consumptionByTypes);
    if (_.isUndefined(highHours)) return false;
    const highHoursConsumption = helpers.getConsumptionByType(
      highHours.consumption,
    );
    // eslint-disable-next-line
    for (const consumptionByType of consumptionByTypes) {
      const currentConsumption = helpers.getConsumptionByType(
        consumptionByType.consumption,
      );
      if (_.gt(highHoursConsumption, currentConsumption)) {
        return true;
      }
    }
    return false;
  };

  const [isOpen, setIsOpen] = useState(false);

  async function downloadConsumption() {
    const consumptionParams: ConsumptionParams = {
      cup: contract.get('cups'),
      startDate: date.format(period[0], 'YYYY-MM-DD', 'DD/MM/YYYY'),
      endDate: date.format(period[1], 'YYYY-MM-DD', 'DD/MM/YYYY'),
    };

    const res = await getcsvhourly(consumptionParams);

    if (res.status === 200) {
      const link = document.createElement('a');
      link.download = 'documento-consumo.csv';
      link.href = `data:text/csv;charset=utf-8,${encodeURIComponent(res.data)}`;
      link.click();
    }
  }

  const toggleAnalytics = (): void => setYearlyAnalytics(!yearlyAnalytics);
  const toggleModal = (): void => setIsOpen(!isOpen);
  const [estimated, setEstimated] = useState(false);
  const toggleEstimated = (): void => setEstimated(!estimated);
  const curMonth = (): boolean => {
    const now = new Date();
    const dateFormatted = `${now.getDate().toString().padStart(2, '0')}/
    ${(now.getMonth() + 1).toString().padStart(2, '0')}/
    ${now.getFullYear().toString().padStart(4, '0')}`;
    return date.getMonthName(period[1]) === date.getMonthName(dateFormatted);
  };

  return (
    <>
      {showAdvice() && (
        <WarningAdvice
          text={t('consumption.card.title')}
          description={t('consumption.card.description')}
        />
      )}

      <div className="lg:border-b lg:border-gray-400">
        <Title tag="h2" size="small" className="mb-12">
          {t('consumption.energy.detalle')}
        </Title>
        {isLoading && <div style={estilo}><SpinnerSmall /></div>}
        {!isLoading && (
          <>
            <div className="min-h-200 mb-22">
              <StatCardComponent />
              <div className="flex flex-col justify-between mt-10 lg:flex-row">
                {curMonth() && (
                  <div className="mb-14">
                    <ActionButton
                      type="link"
                      label={t('consumption.estimado')}
                      onClick={toggleEstimated}
                      icon={<Icons.Info color="blue" />}
                    />
                    <br />
                    { activePeriod
                    && (
                      <div className="mb-14">
                        <ActionButton
                          type="link"
                          label="¿Por qué no puedo ver el consumo de ciertos días?"
                          onClick={toggleModal}
                          className="text-left"
                        />
                      </div>
                    )}
                    <Modal isOpen={estimated} toggleOpen={toggleEstimated}>
                      <p className="mb-8 btn">{t('consumption.estimado_title')}</p>
                      <Bodycopy className="mb-4">
                        {t('consumption.estimado_content')}
                      </Bodycopy>
                    </Modal>
                    <Modal isOpen={isOpen} toggleOpen={toggleModal}>
                      <p className="mb-8 btn">Consumo</p>
                      <Bodycopy className="mb-4">
                        Durante el mes en curso, hay días en que puede ser que
                        no aparezca tu lectura real ya que no podemos acceder al
                        contador telemáticamente.
                      </Bodycopy>
                    </Modal>
                  </div>
                )}
                <div>
                  <ActionButton
                    type="link"
                    label="Descargar consumo"
                    icon={<Icons.Document color="blue" />}
                    onClick={() => downloadConsumption()}
                    className="text-right"
                  />
                </div>
              </div>
            </div>
            <Title tag="h2" size="small" className="mb-12">
              {t('consumption.energy.analisis')}
            </Title>
            <div className="mb-40 min-h-200">
              <div className="flex border border-black rounded-sm lg:hidden">
                <div
                  onClick={toggleAnalytics}
                  className={classNames(
                    'w-1/2 m-2 rounded-sm',
                    yearlyAnalytics
                      ? 'text-white bg-black'
                      : 'text-black bg-transparent',
                  )}
                >
                  <div className="flex justify-between ml-8 mr-8 text-lg">
                    <h2 className="mt-8 text-sm">
                      {helpers.cleanPercentage(
                        `${energy.consumptionLastYear.percentage}`,
                      )}
                    </h2>
                    <div className="mt-8">
                      {energy.consumptionLastYear?.arrowDirection ? (
                        <Icons.Growth
                          color={yearlyAnalytics ? 'white' : 'black'}
                        />
                      ) : (
                        <Icons.Decay
                          color={yearlyAnalytics ? 'white' : 'black'}
                        />
                      )}
                    </div>
                  </div>
                  <div className="w-3/4 mt-2 mb-8 ml-8 text-xs">
                    {energy.consumptionLastYear?.arrowDirection
                      ? t('consumption.energy.more_last_year')
                      : t('consumption.energy.less_last_year')}
                  </div>
                </div>
                <div
                  onClick={toggleAnalytics}
                  className={classNames(
                    'w-1/2 m-2 rounded-sm',
                    yearlyAnalytics ? 'text-black' : 'text-white bg-black',
                  )}
                >
                  <div className="flex justify-between ml-8 mr-8 text-lg">
                    <h2 className="mt-8 text-sm">
                      {helpers.cleanPercentage(
                        `${energy.consumptionLastMonth.percentage}`,
                      )}
                    </h2>
                    <div className="mt-8">
                      {energy.consumptionLastMonth?.arrowDirection ? (
                        <Icons.Growth
                          color={yearlyAnalytics ? 'black' : 'white'}
                        />
                      ) : (
                        <Icons.Decay
                          color={yearlyAnalytics ? 'black' : 'white'}
                        />
                      )}
                    </div>
                  </div>
                  <div className="w-3/4 mt-2 mb-8 ml-8 text-xs">
                    {energy.consumptionLastMonth?.arrowDirection
                      ? t('consumption.energy.more_last_month')
                      : t('consumption.energy.less_last_month')}
                  </div>
                </div>
              </div>
              {yearlyAnalytics && (
                <>
                  <div className="mt-14 lg:hidden">
                    <LineChartPrevYear data={stackedData} />
                    <LineLegendPrevYear
                      energy={energy}
                      startPeriod={period[0]}
                      endPeriod={period[1]}
                    />
                  </div>
                </>
              )}
              {!yearlyAnalytics && (
                <>
                  <div className="mt-14 lg:hidden">
                    <LineChartPrevMonth data={stackedData} />
                    <LineLegendPrevMonth
                      energy={energy}
                      startPeriod={period[0]}
                      endPeriod={period[1]}
                    />
                  </div>
                </>
              )}
              <div className="hidden lg:flex">
                <div className="w-1/2 pr-8">
                  <ConsumptionTrendLabel
                    consumption={energy.consumptionLastYear}
                  />
                  <LineChartPrevYear data={stackedData} />
                  <LineLegendPrevYear
                    energy={energy}
                    startPeriod={period[0]}
                    endPeriod={period[1]}
                  />
                </div>
                <div className="w-1/2 pl-8">
                  <ConsumptionTrendLabel
                    consumption={energy.consumptionLastMonth}
                  />
                  <LineChartPrevMonth data={stackedData} />
                  <LineLegendPrevMonth
                    energy={energy}
                    startPeriod={period[0]}
                    endPeriod={period[1]}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Energy;
