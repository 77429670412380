import React, { FunctionComponent, ReactNode } from 'react';
import classnames from 'classnames';
import _ from 'lodash/fp';

import Bodycopy from './text-components/Bodycopy';

export type ListData = {
  position?: number;
  title?: string | ReactNode;
  description?: string | ReactNode;
}

export interface Props {
  position?: number;
  title?: string | ReactNode;
  description?: string | ReactNode;
  vertical?: boolean;
}

const ListItem: FunctionComponent<Props> = (
  {
    position = undefined,
    title = '',
    description = '',
    vertical = false,
  }: Props,
) => {
  const hasPosition = !_.isUndefined(position);
  const listClass = classnames('flex max-w-168 items-baseline mb-12', {
    'lg:flex-col lg:items-start': vertical,
  });

  const positionClass = classnames(
    'min-w-12 w-12 h-12 flex items-center justify-center rounded-full mr-4', {
      'lg:mr-0 lg:mb-8': vertical,
      'border border-black': hasPosition,
    },
  );

  return (
    <li className={listClass}>
      <div className={positionClass}>
        <span className="btn block">{hasPosition ? position : '*'}</span>
      </div>
      <div>
        {!_.isEmpty(title) && <p className="btn mb-2">{title}</p>}
        {!_.isEmpty(description) && <Bodycopy>{description}</Bodycopy>}

      </div>
    </li>
  );
};

export default ListItem;
