import React, {
  ChangeEvent,
  FunctionComponent,
  useEffect,
  useState,
} from 'react';
import _ from 'lodash/fp';

import Icons from '../../../assets/icons';
import LinkButton from '../../../components/buttons/LinkButton';
import MobileTopNav from '../../../components/MobileTopNav';
import Bodycopy from '../../../components/text-components/Bodycopy';
import Title from '../../../components/text-components/Title';
import PublicLayout from '../../../layout/PublicLayout';
import useNavigation from '../../../services/navigation';
import { routes } from '../../../services/routing';
import t from '../../../text-strings';
import formInit from '../../../forms/initial-values';
import supplyGuide, { helperMethods, radios } from '../../../forms/supply-guide';
import RadioButtonsInput from '../../../components/forms/RadioButtonsInput';
import InternalLink from '../../../components/InternalLink';

import IconsRow from './components/IconsRow';
import Marketer from './completions/Marketer';
import CallUs from './completions/CallUs';
import NoCupsRusticSupply from './completions/NoCupsRusticSupply';
import NoCupsUrbanSupply from './completions/NoCupsUrbanSupply';
import NewCups from './completions/NewCups';
import InactiveRusticSupply from './completions/InactiveRusticSupply';
import InactiveUrbanNewCups from './completions/InactiveUrbanNewCups';
import InactiveUrbanSupply from './completions/InactiveUrbanSupply';

// @ts-ignore
const isHelperActive = _.flow(
  _.pick(helperMethods),
  _.valuesIn,
  _.compact,
  _.size,
  _.gt(_, 0),
);

const SupplyGuide: FunctionComponent = () => {
  const navigate = useNavigation();
  const [guide, setGuide] = useState(formInit.supplyGuide);
  const [helperActive, setHelperActive] = useState(false);
  const active = supplyGuide(guide);

  useEffect(() => {
    // @ts-ignore
    setHelperActive(isHelperActive(active));
  }, [guide]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const newGuide = { ...guide };
    const { name, value } = e.target;

    const isSpecial = e.target.id === 'No tengo claro si tengo CUPS' && e.target.value === '3';

    if (isSpecial) {
      window.location.replace(routes.supply.cupsGuesser);
      return;
    }

    // @ts-ignore
    newGuide[`${name}`] = parseInt(value, 10);
    setGuide({ ...newGuide });
  };

  const closeHelper = (): void => setHelperActive(false);

  return (
    <PublicLayout title={t('supply.title')} onlyDesktop>
      <MobileTopNav
        title={t('supply.title')}
        onClick={() => navigate(routes.supply.index)}
      />
      <div className="hidden lg:block wrap pt-20 mb-10">
        <InternalLink
          label={t('supply.title')}
          to={routes.supply.index}
          icon={<Icons.Back />}
        />
      </div>
      <div className="wrap lg:flex">
        <div className="mb-8 lg:mb-0 lg:max-w-160 w-full">
          <Title tag="h1" className="lg:mb-28">{t('supply.asistente')}</Title>
          <LinkButton
            label={t('supply.acceder')}
            to={routes.requests.index}
            icon={<Icons.Bolt />}
            fixed
          />
        </div>
        <div className="pb-40 lg:pb-20 lg:flex-1">
          <Bodycopy className="mb-12 lg:mb-16 max-w-256">{t('supply.asistente_descripcion')}</Bodycopy>
          <IconsRow />
          <div>
            <RadioButtonsInput
              name="cups"
              value={guide.cups}
              placeholder={t('supply.dispones_cups')}
              radios={radios.cups}
              onChange={handleChange}
            >
              <Title tag="h4" size="small" className="ml-8">{t('supply.dispones_cups')}</Title>
              <Bodycopy className="m-8">{t('supply.cups_helper_description')}</Bodycopy>
              <InternalLink
                to={routes.supply.cupsGuesser}
                label={t('supply.cups_helper_link')}
                className="text-blue ml-8"
              />
            </RadioButtonsInput>
            {active.operative && (
              <RadioButtonsInput
                name="operative"
                value={guide.operative}
                placeholder={t('supply.operatividad')}
                radios={radios.operative}
                onChange={handleChange}
              />
            )}
            {active.soil && (
              <RadioButtonsInput
                name="soil"
                value={guide.soil}
                placeholder={t('supply.tipo_suelo')}
                radios={radios.soil}
                onChange={handleChange}
              />
            )}
            {active.power && (
              <RadioButtonsInput
                name="power"
                value={guide.power}
                placeholder={t('supply.potencia')}
                radios={radios.power}
                onChange={handleChange}
              />
            )}
            <Marketer
              isOpen={helperActive && active.marketer}
              close={closeHelper}
            />
            <CallUs
              isOpen={helperActive && active.callUs}
              close={closeHelper}
            />
            <NoCupsRusticSupply
              isOpen={helperActive && active.noCupsRusticSupply}
              close={closeHelper}
            />
            <NoCupsUrbanSupply
              isOpen={helperActive && active.noCupsUrbanSupply}
              close={closeHelper}
            />
            <NewCups
              isOpen={helperActive && active.newCups}
              close={closeHelper}
            />
            <InactiveRusticSupply
              isOpen={helperActive && active.inactiveRusticSupply}
              close={closeHelper}
            />
            <InactiveUrbanNewCups
              isOpen={helperActive && active.inactiveUrbanNewCups}
              close={closeHelper}
            />
            <InactiveUrbanSupply
              isOpen={helperActive && active.inactiveUrbanSupply}
              close={closeHelper}
            />
          </div>
        </div>
      </div>
    </PublicLayout>
  );
};

export default SupplyGuide;
