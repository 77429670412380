import React, { FunctionComponent } from 'react';

import images from '../../../../assets/images';
import Image from '../../../../components/Image';
import Tag from '../../../../components/Tag';
import Bodycopy from '../../../../components/text-components/Bodycopy';
import Title from '../../../../components/text-components/Title';
import t from '../../../../text-strings';
import ConsumptionCard from '../componentes/ConsumptionCard';
import Savings from '../componentes/Savings';

const IndividualSurplus: FunctionComponent = () => (
  <div className="pt-12 lg:pt-14 pb-28 lg:pb-40 w-full">
    <div className="flex justify-end mb-10">
      <Tag
        label={t('self.individual.con_excedentes.tag')}
        type="mantenimiento"
      />
    </div>
    <Bodycopy className="mb-12 hidden lg:block">{t('self.individual.con_excedentes.fig_caption')}</Bodycopy>
    <Image
      src={images.autoconsumo.individualExcedentes}
      className="mb-10 lg:mb-48 mx-auto"
    />
    <Bodycopy className="mb-24 lg:hidden">{t('self.individual.con_excedentes.fig_caption')}</Bodycopy>
    <div className="md:flex md:mb-36">
      <div className="md:w-1/3">
        <Title size="small" className="mb-4">{t('self.autoconsumo.compensacion')}</Title>
        <Bodycopy className="mb-4">{t('self.individual.con_excedentes.compensacion')}</Bodycopy>
        <Bodycopy className="mb-12 md:mb-0">{t('self.individual.con_excedentes.compensacion2')}</Bodycopy>
      </div>
      <div className="md:w-2/3 md:flex md:flex-col md:items-end">
        <Image src={images.autoconsumo.individualConExcedentesCompensacion} className="max-w-224 w-full hidden lg:block" />
        <Image src={images.autoconsumo.individualConExcedentesCompensacionMVL} className="max-w-224 w-full lg:hidden" />
      </div>
    </div>
    <Savings />
    <ConsumptionCard
      mobile={images.autoconsumo.individualConExcedentesFacturaMVL}
      desktop={images.autoconsumo.individualConExcedentesFactura}
    />
  </div>
);

export default IndividualSurplus;
