import { FormikErrors, FormikTouched } from 'formik';
import React, { ChangeEvent, FunctionComponent } from 'react';
import _ from 'lodash/fp';

import {
  CauParams,
  CupsParams,
  GenerationParams,
  InstalationData,
  LoadRequestItemAttributes,
  RequestStep,
  SelfconsumptionParams,
  SupplyParams,
} from '../../../../store/model/requests/types';
import { useStoreState } from '../../../../store/hooks';
import Bodycopy from '../../../../components/text-components/Bodycopy';
import t from '../../../../text-strings';
import TextInput from '../../../../components/forms/TextInput';
import SelectInput from '../../../../components/forms/SelectInput';
import NumberInput from '../../../../components/forms/NumberInput';
import SubmitButton from '../../../../components/buttons/SubmitButton';
import FormResponse from '../../../../components/forms/FormResponse';
import InternalLink from '../../../../components/InternalLink';
import { routes } from '../../../../services/routing';
import Icons from '../../../../assets/icons';
import TextArea from '../../../../components/forms/TextArea';

import StepPage from './StepPage';

interface Props {
  title: string;
  step: number
  next?: RequestStep;
  totalSteps: number;
  values: InstalationData;
  handleChange: {
    // eslint-disable-next-line
    (e: React.ChangeEvent<any>): void;
    // eslint-disable-next-line
    <T_1 = string | React.ChangeEvent<any>>(field: T_1): T_1 extends React.ChangeEvent<any> 
      ? void
      // eslint-disable-next-line
      : (e: string | React.ChangeEvent<any>) => void;
  };
  handleBlur: {
    // eslint-disable-next-line
    (e: React.FocusEvent<any, Element>): void;
    // eslint-disable-next-line
    <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
  };
  handleSelect: (e: ChangeEvent<HTMLSelectElement>) => void;
  // eslint-disable-next-line
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<void> | Promise<FormikErrors<SupplyParams | SelfconsumptionParams | CauParams | GenerationParams | CupsParams>>;
  // eslint-disable-next-line
  errors: FormikErrors<SupplyParams | SelfconsumptionParams | CauParams | GenerationParams | CupsParams>;
  // eslint-disable-next-line
  touched: FormikTouched<SupplyParams | SelfconsumptionParams | CauParams | GenerationParams | CupsParams>;
  success?: boolean;
  message?: string;
  isSubmitting?: boolean;
  isValid?: boolean;
  sendedPdf?: string;
  formCode?: string;
}

const OTHERS = 'Otros';
const OTHER = 'Otra';
const YES = 'Sí';

const InstalationDataStep: FunctionComponent<Props> = (
  {
    title,
    step,
    next,
    totalSteps,
    values,
    handleChange,
    handleBlur,
    handleSelect,
    setFieldValue,
    errors,
    touched,
    success = true,
    message = '',
    isSubmitting = false,
    isValid = true,
    sendedPdf = '',
    formCode = '',
  }: Props,
) => {
  const { loadrequest } = useStoreState((state) => state.requests);

  const getOthers = _.flow(
    _.find(['value', OTHERS]),
    _.get('selectorId'),
  );

  const getOther = _.flow(
    _.find(['value', OTHER]),
    _.get('selectorId'),
  );

  const getYes = _.flow(
    _.find(['value', YES]),
    _.get('selectorId'),
  );

  const tensionSelectables: LoadRequestItemAttributes[] = [
    {
      selectorId: 0.23,
      value: '0,23',
      formCode,
      section: '',
    },
    {
      selectorId: 0.4,
      value: '0,4',
      formCode,
      section: '',
    },
    {
      selectorId: 20,
      value: '20',
      formCode,
      section: '',
    },
    {
      selectorId: 66,
      value: '66',
      formCode,
      section: '',
    },
    {
      selectorId: 132,
      value: '132',
      formCode,
      section: '',
    },
  ];

  const handleTension = (e: ChangeEvent<HTMLSelectElement>): void => {
    const { value, name } = e.target;
    if (_.isEmpty(value)) {
      setFieldValue(name, 0);
      return;
    }
    setFieldValue(name, parseFloat(value));
  };

  return (
    <StepPage
      title={title}
      next={next}
      prev="solicitud"
      totalSteps={totalSteps}
      current="instalacion"
      step={step}
    >
      <Bodycopy className="mb-8">
        {t('requests.forms.instalacion_descripcion')}
      </Bodycopy>
      {!_.isUndefined(values.powerRequest) && (
        <NumberInput
          name="powerRequest"
          value={_.isEqual(values.powerRequest, 0) ? '' : `${values.powerRequest}`}
          placeholder={t('models.requests.instalation.power_request')}
          onChange={handleChange('powerRequest')}
          onBlur={handleBlur('powerRequest')}
          touched={touched.powerRequest}
          errors={errors.powerRequest}
          required
        />
      )}
      <SelectInput
        name="tension"
        value={values.tension}
        placeholder={t(`models.requests.instalation.tension.${formCode}`)}
        selectables={tensionSelectables}
        onChange={handleTension}
        onBlur={handleBlur('tension')}
        touched={touched.tension}
        errors={errors.tension}
        required
      />
      {!_.isUndefined(loadrequest.Use) && (
        <>
          {!_.isUndefined(values.use) && (
          <SelectInput
            name="use"
            value={values.use}
            placeholder={t('models.requests.instalation.use')}
            selectables={loadrequest.Use}
            onChange={handleSelect}
            onBlur={handleBlur('use')}
            touched={touched.use}
            errors={errors.use}
            required
          />
          )}
          {!_.isUndefined(values.useDescription)
          && _.isEqual(values.use, getOthers(loadrequest.Use)) && (
          <TextInput
            name="useDescription"
            value={values.useDescription}
            placeholder={t('models.requests.instalation.use_description')}
            onChange={handleChange('useDescription')}
            onBlur={handleBlur('useDescription')}
            touched={touched.useDescription}
            errors={errors.useDescription}
            required
          />
          )}
        </>
      )}
      {!_.isUndefined(values.instalationType) && !_.isUndefined(loadrequest.InstalationType) && (
        <SelectInput
          name="instalationType"
          value={values.instalationType}
          selectables={loadrequest.InstalationType}
          placeholder={t('models.requests.instalation.instalation_type')}
          onChange={handleSelect}
          onBlur={handleBlur('instalationType')}
          touched={touched.instalationType}
          errors={errors.instalationType}
          required
        />
      )}
      {!_.isUndefined(values.powerInstalation) && (
        <NumberInput
          name="powerInstalation"
          value={_.isEqual(values.powerInstalation, 0) ? '' : `${values.powerInstalation}`}
          placeholder={t(`models.requests.instalation.power_instalation.${formCode}`)}
          onChange={handleChange('powerInstalation')}
          onBlur={handleBlur('powerInstalation')}
          touched={touched.powerInstalation}
          errors={errors.powerInstalation}
          required
        />
      )}
      {!_.isUndefined(loadrequest.PowerSource) && (
        <>
          {!_.isUndefined(values.powerSource) && (
          <SelectInput
            name="powerSource"
            value={values.powerSource}
            placeholder={t('models.requests.instalation.power_source')}
            selectables={loadrequest.PowerSource}
            onChange={handleSelect}
            onBlur={handleBlur('powerSource')}
            touched={touched.powerSource}
            errors={errors.powerSource}
            required
          />
          )}
          {!_.isUndefined(values.powerSourceDesc)
            && _.isEqual(values.powerSource, getOther(loadrequest.PowerSource)) && (
            <TextInput
              name="powerSourceDesc"
              value={values.powerSourceDesc}
              placeholder={t('models.requests.instalation.power_source_description')}
              onChange={handleChange('powerSourceDesc')}
              onBlur={handleBlur('powerSourceDesc')}
              touched={touched.powerSourceDesc}
              errors={errors.powerSourceDesc}
              required
            />
          )}
        </>
      )}
      {!_.isUndefined(values.selfconsumptionType)
      && !_.isUndefined(loadrequest.SelfconsumptionType) && (
        <SelectInput
          name="selfconsumptionType"
          value={values.selfconsumptionType}
          selectables={loadrequest.SelfconsumptionType}
          placeholder={t('models.requests.instalation.selfconsumption_type')}
          onChange={handleSelect}
          onBlur={handleBlur('selfconsumptionType')}
          touched={touched.selfconsumptionType}
          errors={errors.selfconsumptionType}
          required
        />
      )}
      {!_.isUndefined(values.connectionType) && !_.isUndefined(loadrequest.ConnectionType) && (
        <SelectInput
          name="connectionType"
          value={values.connectionType}
          selectables={loadrequest.ConnectionType}
          placeholder={t('models.requests.instalation.connection_type')}
          onChange={handleSelect}
          onBlur={handleBlur('connectionType')}
          touched={touched.connectionType}
          errors={errors.connectionType}
          required
        />
      )}
      {!_.isUndefined(values.networked) && !_.isUndefined(loadrequest.SiNo) && (
        <>
          <SelectInput
            name="networked"
            value={values.networked}
            selectables={loadrequest.SiNo}
            placeholder={t('models.requests.instalation.networked')}
            onChange={handleSelect}
            onBlur={handleBlur('networked')}
            touched={touched.networked}
            errors={errors.networked}
            required
          />
          {!_.isUndefined(values.receivedCompensation) && !_.isUndefined(loadrequest.SiNo)
          && _.isEqual(values.networked, getYes(loadrequest.SiNo)) && (
            <SelectInput
              name="receivedCompensation"
              value={values.receivedCompensation}
              selectables={loadrequest.SiNo}
              placeholder={t('models.requests.instalation.received_compensation')}
              onChange={handleSelect}
              onBlur={handleBlur('receivedCompensation')}
              touched={touched.receivedCompensation}
              errors={errors.receivedCompensation}
              required
            />
          )}
        </>
      )}
      <TextArea
        name="observations"
        value={values.observations}
        placeholder={t('models.requests.instalation.observations')}
        onChange={handleChange('observations')}
        onBlur={handleBlur('observations')}
        touched={touched.observations}
        errors={errors.observations}
        inputClassName="min-h-56"
      />
      {_.isEqual(totalSteps, step) && (
        <div>
          {!success && !_.isEmpty(message) && (
            <FormResponse message={message} className="my-8" />
          )}
          {_.isEmpty(sendedPdf) && (
            <SubmitButton
              label={t('requests.enviar_solicitud')}
              fixed
              disabled={isSubmitting || !isValid}
            />
          )}
          {success && !_.isEmpty(message) && (
            <Bodycopy className="my-8 font-bold">
              {message}
            </Bodycopy>
          )}
          {success && _.isEmpty(message) && !_.isEmpty(sendedPdf) && (
            <div className="flex flex-col lg:flex-row items-center">
              <InternalLink
                label={t('requests.volver')}
                to={routes.requests.index}
                icon={<Icons.Back />}
              />
              <a download={`mi-solicitud-${formCode}.pdf`} href={sendedPdf} className="btn text-blue mt-4 lg:mt-0 lg:ml-2">
                {t(`requests.forms.download.${formCode}`)}
              </a>
            </div>
          )}
        </div>
      )}
    </StepPage>
  );
};

export default InstalationDataStep;
