import React, { FunctionComponent } from 'react';

import Caption from '../../../../components/text-components/Caption';

import CircledNumber from './CircledNumber';

interface Props {
  number: number;
  name: string;
  stateName: string;
}

const ActiveState: FunctionComponent<Props> = (
  {
    number,
    name,
    stateName,
  }: Props,
) => (
  <div className="lg:flex-2">
    <div className="flex items-center lg:mb-8">
      <CircledNumber number={number} current />
      <div className="ml-8 flex-1 lg:hidden">
        <p className="btn mb-2">{name}</p>
        <a href="#buttons" className="block">
          <Caption color="blue" className="hover:underline">{stateName}</Caption>
        </a>
      </div>
      <div className="hidden lg:flex flex-1 flex-col justify-center mx-8">
        <div className="w-full h-px bg-gradient" />
      </div>
    </div>
    <div className="flex my-4 lg:flex-col">
      <div className="w-22 flex justify-center">
        <div className="w-px bg-gradient h-16 lg:hidden" />
      </div>
      <div className="hidden lg:block mb-4">
        <p className="btn mb-2">{name}</p>
        <a href="#current_state" className="block">
          <Caption color="blue" className="hover:underline">{stateName}</Caption>
        </a>
      </div>
    </div>
  </div>
);

export default ActiveState;
