import axios, { AxiosResponse } from 'axios';
import _ from 'lodash/fp';

type ApiModel =
  | '/Authorized'
  | '/Contact'
  | '/Contract'
  | '/Distributor'
  | '/Login'
  | '/Request'
  | '/CT'
  | '/Base';

export const BASE_URL = process.env.REACT_APP_BASE_URL || 'https://portalclientesdso.azurewebsites.net/api';

const client = axios.create({ baseURL: BASE_URL });

client.defaults.headers.common['Accept-Language'] = 'es-ES';

const joinPairs = _.join('=');

export const queryParams = _.flow(_.toPairs, _.map(joinPairs), _.join('&'));

export const setURL = (
  base: ApiModel,
  endpoint: string,
  queryparams?: string,
): string => _.join('')([base, endpoint, queryparams]);

// eslint-disable-next-line
export const axiosPost = async (
  endpoint: string,
  params?: unknown,
): Promise<AxiosResponse> => {
  try {
    return await client.post(endpoint, { ...(params as any) });
  } catch (error) {
    // @ts-ignore
    return error.response;
  }
};

// eslint-disable-next-line
export const axiosPut = async (
  endpoint: string,
  params?: unknown,
): Promise<AxiosResponse> => {
  try {
    return await client.put(endpoint, { ...(params as any) });
  } catch (error) {
    // @ts-ignore
    return error.response;
  }
};

// eslint-disable-next-line
export const axiosGet = async (
  endpoint: string,
  params?: unknown,
): Promise<AxiosResponse> => {
  try {
    return await client.get(endpoint, { data: { ...(params as any) } });
  } catch (error) {
    // @ts-ignore
    return error.response;
  }
};

// eslint-disable-next-line
export const axiosDelete = async (
  endpoint: string,
  params?: unknown,
): Promise<AxiosResponse> => {
  try {
    return await client.delete(endpoint, { data: { ...(params as any) } });
  } catch (error) {
    // @ts-ignore
    return error.response;
  }
};

export default client;
