import _ from 'lodash/fp';
import React, {
  FunctionComponent,
  useEffect,
  useState,
  SetStateAction,
} from 'react';
// @ts-ignore
// eslint-disable-next-line
import DatePicker, { registerLocale } from "react-datepicker";
// eslint-disable-next-line
import es from "date-fns/locale/es";
// eslint-disable-next-line
import "react-datepicker/dist/react-datepicker.css";
import './calendarRange.scss';

import Icons from '../../../assets/icons';
import BreakdownsAlerts from '../../../components/alerts/BreakdownsAlerts';
import ActionButton from '../../../components/buttons/ActionButton';
import Wrap from '../../../components/containers/Wrap';
import InternalLink from '../../../components/InternalLink';
import Modal from '../../../components/Modal';
import Select from '../../../components/Select';
import Bodycopy from '../../../components/text-components/Bodycopy';
import Title from '../../../components/text-components/Title';
import Toggle from '../../../components/Toggle';
import AuthenticatedLayout from '../../../layout/AuthenticatedLayout';
import { ConsumptionParams } from '../../../store/model/contract/types';
import useQuery from '../../../services/query';
import { routes } from '../../../services/routing';
import { useStoreActions, useStoreState } from '../../../store/hooks';
import { Contract } from '../../../store/model/contract/contract';
import t from '../../../text-strings';
import date from '../../../services/date';
import Spinner from '../../../components/Spinner';

import DistributionNetwork from './components/DistributionNetwork';
import Energy from './components/Energy';
import Power from './components/Power';
import useConsumption, { usePeriod } from './use-consumption';

const TIME = 'T00:00:00.000Z';

registerLocale('es', es);

const Consumption: FunctionComponent = () => {
  const { getconsumption } = useStoreActions((actions) => actions.contract);
  const { energy } = useStoreState((state) => state.contract);
  const query = useQuery();
  const { contracts, contract } = useStoreState((state) => state.contract);
  const { show } = useStoreActions((actions) => actions.contract);
  const [isLoading, setIsLoading] = useState(true);
  const [hasDateRangeExceeded, setDateRangeExceeded] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [power, setPower] = useState(false);
  const consumption = useConsumption();
  const [selectedPeriod, setSelectedPeriod] = usePeriod(consumption.periods[0]);
  const idContrato = query.get('id') || '';
  const powerSection = query.get('power') || '';
  const isSelected = !_.isEmpty(contract.get('idcontrato'));
  const isId = !_.isEmpty(idContrato);
  const [dateRange, setDateRange] = useState([
    date.startOfMonth(),
    date.nowDate(),
  ]);
  const [startDate, endDate] = dateRange;

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      if (!_.isEmpty(contracts)) {
        if (isId) {
          show({ id: idContrato, contracts });
          setIsLoading(_.isEmpty(contracts));
          return;
        }
        if (!isSelected) {
          show({ id: contracts[0].get('idcontrato'), contracts });
          setIsLoading(_.isEmpty(contracts));
          return;
        }
      }

      if (_.isEqual(powerSection, 'true')) {
        setPower(true);
      }
      setIsLoading(false);
    })();
  }, [contracts]);

  useEffect(() => {
    const start = date.format(dateRange[0], 'DD/MM/yyyy');

    if (!dateRange[1]) return;

    const end = date.format(dateRange[1], 'DD/MM/yyyy');
    const difference = date.differenceInDays(start, end);

    if (difference > 60) {
      setDateRangeExceeded(true);
      setDateRange([date.startOfMonth(), date.nowDate()]);
      return;
    }

    setSelectedPeriod([start, end]);
  }, dateRange);

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      const consumptionParams: ConsumptionParams = {
        cup: contract.get('cups'),
        startDate: `${consumption.buildAPIDate(selectedPeriod[0])}${TIME}`,
        endDate: `${consumption.buildAPIDate(selectedPeriod[1])}${TIME}`,
      };

      await getconsumption(consumptionParams);
      setIsLoading(false);
    })();
  }, [contract, selectedPeriod]);

  const handleContractChange = (id: string): void => {
    show({ id, contracts });
  };

  const toggleModal = (): void => setIsOpen(!isOpen);
  const toggleModalDate = (): void => setDateRangeExceeded(!hasDateRangeExceeded);
  const togglePower = (): void => setPower(!power);

  if (isLoading) return (<Spinner />);

  const autoConsumption = energy.consumptionCurrent.totalAutoConsumption;
  const hasAutoconsumption = autoConsumption > 0;

  if (!isLoading && _.isEmpty(contracts)) {
    return (
      <AuthenticatedLayout title={t('common.navigation.mi_consumo')}>
        <Wrap size="wrap-authenticated" className="py-12">
          <Title tag="h1" className="mb-12">
            {t('common.navigation.mi_consumo')}
          </Title>
          <Bodycopy>{t('consumption.no_contracts')}</Bodycopy>
        </Wrap>
      </AuthenticatedLayout>
    );
  }

  return (
    <AuthenticatedLayout title={t('common.navigation.mi_consumo')}>
      <div className="relative py-12 mb-6 bg-white">
        <div className="absolute top-0 hidden h-full bg-white left-full w-screen-full lg:block" />
        <Wrap
          size="wrap-authenticated"
          className="flex flex-wrap lg:items-center"
        >
          <Title
            size="small"
            tag="h4"
            className="w-1/2 mb-4 lg:mb-0 lg:w-auto lg:mr-10"
          >
            {t('consumption.detalles')}
          </Title>
          <div className="flex justify-end w-1/2 mb-4 lg:mb-0 lg:order-3 lg:flex-1">
            <InternalLink
              label={t('consumption.ver')}
              to={routes.contract(contract.get('idcontrato'))}
              className="text-blue"
            />
          </div>
          <Select selected={contract.title} type="select-underline">
            {_.map((contractItem: Contract) => (
              <div
                key={contractItem.get('idcontrato')}
                onClick={() => handleContractChange(contractItem.get('idcontrato'))}
              >
                <Bodycopy className="truncate">{contractItem.title}</Bodycopy>
              </div>
            ))(contracts)}
          </Select>
        </Wrap>
      </div>
      <BreakdownsAlerts alias={contract.get('alias')} />
      <Wrap size="wrap-authenticated" className="pt-10">
        <div className="flex flex-wrap lg:mb-16">
          <Title tag="h1" className="w-full mb-12 lg:w-1/2 lg:order-1">
            {t('common.navigation.mi_consumo')}
          </Title>
          <div className="w-full mb-12 lg:w-1/2 lg:flex lg:justify-end lg:mb-0 lg:order-4">
            <ActionButton
              type="link"
              label={t('consumption.diferencia')}
              onClick={toggleModal}
              icon={<Icons.Help color="blue" />}
              right
            />
            <Modal isOpen={isOpen} toggleOpen={toggleModal}>
              <p className="mb-8 btn">{t('consumption.potencia_energia')}</p>
              <Bodycopy className="mb-4">
                {t('consumption.paragraph_1')}
              </Bodycopy>
              <Bodycopy className="mb-4">
                {t('consumption.paragraph_2')}
              </Bodycopy>
              <Bodycopy className="mb-4">
                {t('consumption.paragraph_3')}
              </Bodycopy>
            </Modal>
          </div>
          <div className="w-full mb-12 lg:w-1/2 lg:flex lg:justify-end lg:order-2">
            <Toggle
              variants={[
                hasAutoconsumption
                  ? t('consumption.red_distribucion')
                  : t('consumption.energia'),
                t('consumption.potencia'),
              ]}
              selected={power}
              onToggle={togglePower}
            />
          </div>
          <div className="w-full mb-16 lg:mb-0 lg:w-1/2 lg:order-3">
            {!power && (
              <div className="flex flex-col xs:flex-row">
                <div className="flex justify-items-start">
                  <Icons.Calendar color="blue" className="mr-4" />
                </div>
                <div className="flex items-center w-auto border-b border-black">
                  <p className="flex items-center pr-2 overflow-auto text-sm">
                    Periodo del
                    {' '}
                  </p>
                  <DatePicker
                    selected={startDate}
                    onChange={(update: SetStateAction<Date[]>) => setDateRange(update)}
                    startDate={startDate}
                    endDate={endDate}
                    monthsShown={2}
                    dateFormat="dd/MM/yyyy"
                    selectsRange
                    withPortal
                    locale="es"
                    calendarStartDay={1}
                    calendarClassName="calendarRange"
                    fixedHeight
                  />
                  <Icons.Dropdown color="blue" />
                </div>
              </div>
            )}
          </div>
        </div>
        {hasDateRangeExceeded && (
          <Modal isOpen={hasDateRangeExceeded} toggleOpen={toggleModalDate}>
            <div className="px-8">
              <Title size="small" tag="h4" className="mb-8">
                Período a consultar
              </Title>
              <Bodycopy className="mb-10">
                El período no puede ser superior a 60 días
              </Bodycopy>
              <div className="justify-start hidden lg:flex">
                <ActionButton
                  label="Continuar"
                  onClick={() => setDateRangeExceeded(false)}
                  className="mr-16"
                />
              </div>
            </div>
          </Modal>
        )}
        {!isLoading && power && <Power contract={contract} />}
        {!isLoading && !power && hasAutoconsumption && (
          <DistributionNetwork
            isLoading={isLoading}
            period={selectedPeriod}
            energy={energy}
            cups={contract.get('cups')}
          />
        )}
        {!power && !hasAutoconsumption && (
          <Energy
            isLoading={isLoading}
            period={selectedPeriod}
            energy={energy}
          />
        )}
      </Wrap>
    </AuthenticatedLayout>
  );
};

export default Consumption;
