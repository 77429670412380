import React, { FunctionComponent } from 'react';

// import { AppColors } from '../../styles';

interface Props {
  // color?: AppColors;
  width?: number;
  height?: number;
  // className?: string;
}

const Twitter: FunctionComponent<Props> = (
  {
    // color = 'black',
    width = 28,
    height = 28,
    // className = '',
  }: Props,
) => (
  <svg width={width} height={height} viewBox="0 0 175 175" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1685_49)">
      <mask id="mask0_1685_49" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="0" y="0" width={width} height={height}>
        <path d="M175 0H0V175H175V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_1685_49)">
        <path fillRule="evenodd" clipRule="evenodd" d="M74.6999 90.3L36.458 43.75H66.7485L90.3551 72.5212L115.575 43.8796H132.257L98.4211 82.3521L138.541 131.25H108.341L82.7805 100.136L55.4912 131.164H38.7185L74.6999 90.3ZM112.743 122.625L55.0311 52.3749H62.3431L119.983 122.625H112.743Z" fill="#130E1E" />
      </g>
    </g>
  </svg>

);

export default Twitter;
