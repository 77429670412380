export const paths = {
  landing: '/',
  raffle: '/sorteo',
  supply: {
    index: '/suministro-electrico',
    guide: '/suministro-electrico/guide',
    faq: '/suministro-electrico/preguntas-frecuentes',
    supplyProcess: '/suministro-electrico/proceso-nuevo-suministro',
    cupsProcess: '/suministro-electrico/proceso-nuevo-cups',
    cupsGuesser: '/suministro-electrico/mi-cups',
  },
  selfConsumption: {
    index: '/autoconsumo',
    process: '/autoconsumo/tramitacion-autoconsumo',
    information: '/autoconsumo/tramitacion-autoconsumo/informacion',
  },
  generation: '/generacion',
  nodalCapacities: '/capacidades-nodales',
  contact: '/contacto',
  callUs: '/llamanos',
  book: '/agendar-llamada',
  contactForm: '/enviar-mensaje',
  normative: '/normativa',
  legalWarning: '/aviso-legal',
  cookiePolicy: '/politica-cookies',
  privacyPolicy: '/politica-privacidad',
  qualityPolicies: '/politica-calidad',
  login: '/acceso',
  register: '/registro',
  registered: '/registro-completo',
  validation: '/validar-usuario',
  password: {
    recovery: '/recuperar-contraseña',
    confirmation: '/cambio-contraseña',
  },
  consumption: '/mi-consumo',
  tipsToSave: '/consejos-para-ahorrar',
  requests: {
    index: '/mis-solicitudes',
    all: '/mis-solicitudes/all',
    supply: '/solicitud-punto-de-suministro',
    generation: '/solicitud-de-generacion',
    cups: '/solicitud-de-cups',
    cau: '/solicitud-de-cau',
    selfconsumption: '/solicitud-de-autoconsumo',
    history: '/mis-solicitudes/:id',
  },
  breakdowns: '/averias-y-cortes',
  notifications: '/notificaciones',
  profile: '/mi-perfil',
  editProfile: '/editar-perfil',
  authorized: {
    create: '/añadir-cuenta-autorizada',
    created: '/cuenta-autorizada-añadida',
  },
  contract: '/contract/:id',
  counter: '/mi-contador',
  measure: '/measure/:id',
  counterSchedule: '/programar-medicion',
};

export const routes = {
  landing: '/',
  raffle: '/sorteo',
  supply: {
    index: '/suministro-electrico',
    guide: '/suministro-electrico/guide',
    faq: '/suministro-electrico/preguntas-frecuentes',
    supplyProcess: '/suministro-electrico/proceso-nuevo-suministro',
    cupsProcess: '/suministro-electrico/proceso-nuevo-cups',
    cupsGuesser: '/suministro-electrico/mi-cups',
  },
  selfConsumption: {
    index: '/autoconsumo',
    process: '/autoconsumo/tramitacion-autoconsumo',
    information: '/autoconsumo/tramitacion-autoconsumo/informacion',
  },
  generation: '/generacion',
  nodalCapacities: '/capacidades-nodales',
  contact: '/contacto',
  callUs: '/llamanos',
  book: '/agendar-llamada',
  contactForm: '/enviar-mensaje',
  normative: '/normativa',
  legalWarning: '/aviso-legal',
  cookiePolicy: '/politica-cookies',
  privacyPolicy: '/politica-privacidad',
  qualityPolicies: '/politica-calidad',
  login: '/acceso',
  register: '/registro',
  registered: '/registro-completo',
  validation: '/validar-usuario',
  password: {
    recovery: '/recuperar-contraseña',
    confirmation: '/cambio-contraseña',
  },
  consumption: '/mi-consumo',
  tipsToSave: '/consejos-para-ahorrar',
  requests: {
    index: '/mis-solicitudes',
    all: '/mis-solicitudes/all',
    supply: '/solicitud-punto-de-suministro',
    cups: '/solicitud-de-cups',
    cau: '/solicitud-de-cau',
    generation: '/solicitud-de-generacion',
    selfconsumption: '/solicitud-de-autoconsumo',
    history: (id: string): string => `/mis-solicitudes/${id}`,
  },
  breakdowns: '/averias-y-cortes',
  notifications: '/notificaciones',
  profile: '/mi-perfil',
  editProfile: '/editar-perfil',
  authorized: {
    create: '/añadir-cuenta-autorizada',
    created: '/cuenta-autorizada-añadida',
  },
  contract: (id: string): string => `/contract/${id}`,
  counter: '/mi-contador',
  measure: (id: string): string => `/measure/${id}`,
  counterSchedule: '/programar-medicion',
};
