import { FormikErrors, FormikTouched } from 'formik';
import _ from 'lodash/fp';
import React, { FunctionComponent, useState } from 'react';
import { Properties } from 'csstype';

import IDHelper from '../../../../components/forms/IDHelper';
import TextInput from '../../../../components/forms/TextInput';
import Bodycopy from '../../../../components/text-components/Bodycopy';
import Title from '../../../../components/text-components/Title';
import { UserForm } from '../../../../forms/initial-values';
import useSteps from '../../../../store/model/register/register';
import t from '../../../../text-strings';
import FormStepScreen from '../components/FormStepScreen';
import FormStepsNavigation from '../components/FormStepsNavigation';
import Toggle from '../../../../components/Toggle';

/* eslint-disable @typescript-eslint/no-explicit-any */
interface Props {
  values: UserForm;
  onChange: {
    (e: React.ChangeEvent<any>): void;
    <T_1 = string | React.ChangeEvent<any>>(
      field: T_1
    ): T_1 extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
  onBlur: {
    (e: React.FocusEvent<any, Element>): void;
    <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
  };
  touched: FormikTouched<UserForm>;
  errors: FormikErrors<UserForm>;
}
/* eslint-enable @typescript-eslint/no-explicit-any */

const YourPersonalData: FunctionComponent<Props> = ({
  values,
  onChange,
  onBlur,
  touched,
  errors,
}: Props) => {
  const [step, setStep] = useSteps();

  const [dniIntl, setDNIIntl] = useState(false);
  const toggleDNI = (): void => setDNIIntl(!dniIntl);

  const separator = {
    marginBottom: '2rem',
  } as Properties<string | number, string>;

  return (
    <FormStepScreen
      onNext={() => setStep('id-card')}
      onBack={() => setStep('permissions')}
      current={_.isEqual(step, 'personal-data')}
    >
      <FormStepsNavigation blockClassNames={['bg-black', 'bg-black', '', '']} />
      <Title className="mb-4 lg:mb-8">{t('register.hablanos')}</Title>
      <Bodycopy className="mb-4 lg:mb-8 max-w-240 w-full">
        {t('register.hablanos_subtitle')}
      </Bodycopy>
      <TextInput
        name="name"
        placeholder={t('models.user.attributes.name')}
        value={values.name}
        onChange={onChange('name')}
        onBlur={onBlur('name')}
        touched={touched.name}
        errors={errors.name}
        required
      />
      <Toggle
        variants={[t('register.nif_spa'), t('register.other_nif')]}
        selected={dniIntl}
        onToggle={toggleDNI}
      />
      <span style={separator}>
        &nbsp;
      </span>
      {!dniIntl && (
        <TextInput
          name="nif"
          placeholder={t('models.user.attributes.nif')}
          value={values.nif.toUpperCase()}
          onChange={onChange('nif')}
          onBlur={onBlur('nif')}
          touched={touched.nif}
          errors={errors.nif}
          required
          icon={<IDHelper />}
        />
      )}
      {dniIntl && (
        <TextInput
          name="nif"
          placeholder={t('models.user.attributes.other_nif')}
          value={values.nif.toUpperCase()}
          onChange={onChange('nif')}
          onBlur={onBlur('nif')}
          touched={touched.nif}
          errors={errors.nif}
          required
        />
      )}
      <TextInput
        name="movil"
        placeholder={t('models.user.attributes.movil')}
        value={values.movil}
        onChange={onChange('movil')}
        onBlur={onBlur('movil')}
        touched={touched.movil}
        errors={errors.movil}
        required
      />
    </FormStepScreen>
  );
};

export default YourPersonalData;
