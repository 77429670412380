import React, { FunctionComponent, ReactNode } from 'react';

import { Colors } from '../../styles';
import classNames from '../../services/classNames';

export interface Props {
  children: ReactNode;
  tag?: 'h5' | 'h6';
  color?: Colors;
  className?: string;
}

const Subtitle: FunctionComponent<Props> = (
  {
    children,
    tag: Tag = 'h5',
    color = 'black',
    className = '',
  }: Props,
) => (
  <Tag className={classNames(`subtitle text-${color}`, className)}>
    {children}
  </Tag>
);

export default Subtitle;
