import React, { FunctionComponent } from 'react';

import { AppColors } from '../../styles';

interface Props {
  color?: AppColors | string;
  width?: number;
  height?: number;
  className?: string;
}

const ArrowRight: FunctionComponent<Props> = (
  {
    color = '#1F61FF',
    width = 8,
    height = 8,
    className = '',
  }: Props,
) => (
  <svg viewBox="0 0 8 12" xmlns="http://www.w3.org/2000/svg" className={`w-${width} h-${height} ${className}`}>
    <path
      d="M6.70994 2.12002L2.82994 6.00002L6.70994 9.88002C7.09994 10.27 7.09994 10.9 6.70994 11.29C6.31994 11.68 5.68994 11.68 5.29994 11.29L0.709942 6.70002C0.319942 6.31002 0.319942 5.68002 0.709942 5.29002L5.29994 0.700021C5.68994 0.31002 6.31994 0.31002 6.70994 0.700021C7.08994 1.09002 7.09994 1.73002 6.70994 2.12002Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill={color}
    />
  </svg>
);

export default ArrowRight;
