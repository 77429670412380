import { useLayoutEffect, useState } from 'react';
import _ from 'lodash/fp';

const useScroll = (): ((current: string, next?: string) => boolean) => {
  const [position, setPosition] = useState(0);

  const onScroll = (): void => setPosition(window.scrollY);

  useLayoutEffect(() => {
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const isActiveLink = (current: string, next?: string): boolean => {
    const contentPosition = document.getElementById('content')?.offsetTop;
    // @ts-ignore
    const currentPosition = document.getElementById(current)?.offsetTop - contentPosition;
    const nextPosition = _.isUndefined(next)
      ? window.document.body.offsetHeight
      // @ts-ignore
      : document.getElementById(next)?.offsetTop - contentPosition;
    // @ts-ignore
    return position >= currentPosition && position < nextPosition;
  };

  return isActiveLink;
};

export default useScroll;
