import React, { ChangeEvent, FunctionComponent, useState } from 'react';
import { useFormik } from 'formik';
import _ from 'lodash/fp';
import { Link } from 'react-router-dom';

import Wrap from '../../components/containers/Wrap';
import PublicLayout from '../../layout/PublicLayout';
import { useStoreActions, useStoreState } from '../../store/hooks';
import { ContactParams } from '../../store/model/contact/types';
import t from '../../text-strings';
import formInit from '../../forms/initial-values';
import schemas from '../../forms/schemas';
import Title from '../../components/text-components/Title';
import InternalLink from '../../components/InternalLink';
import { routes } from '../../services/routing';
import Icons from '../../assets/icons';
import Form from '../../components/forms/Form';
import SubmitButton from '../../components/buttons/SubmitButton';
import TextInput from '../../components/forms/TextInput';
import TextArea from '../../components/forms/TextArea';
import FormResponse from '../../components/forms/FormResponse';
import documents from '../../services/documents';
import { isValidResponse } from '../../store/store-helpers';
import Bodycopy from '../../components/text-components/Bodycopy';
import InputSelect from '../../components/forms/InputSelect';
import useQuery from '../../services/query';
import RadioButtonsInput from '../../components/forms/RadioButtonsInput';
import { Contract } from '../../store/model/contract/contract';
import MobileTopNav from '../../components/MobileTopNav';
import useNavigation from '../../services/navigation';
import CheckboxInput from '../../components/forms/CheckboxInput';

const selectables: string[] = [
  'Estado de solicitud de suministro / autoconsumo',
  'Estado de solicitud CUPS / CAU',
  'Incidencias en la red o situación peligrosa',
  'Notificar un fraude',
  'Quejas y reclamaciones',
  'Otros',
];

const setSelect = (select: string): string => {
  if (_.isEmpty(select)) return '';

  if (_.isEqual(select, 'incidencia')) return 'Incidencias en la red o situación peligrosa';
  if (_.isEqual(select, 'fraude')) return 'Notificar un fraude';

  return '';
};

const ContactForm: FunctionComponent = () => {
  const { sendcontact } = useStoreActions((actions) => actions.contact);
  const { session } = useStoreState((state) => state.session);
  const { municipalities } = useStoreState((state) => state.distributors);
  const { contracts } = useStoreState((state) => state.contract);
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(true);
  const query = useQuery();
  const select = query.get('select') || '';
  const navigate = useNavigation();
  const municipalitySelectables = _.map('name')(municipalities);
  const contractSelectables = _.map('address')(contracts);
  const hasContracts = !_.isEmpty(contracts);
  const oneContract = _.isEqual(_.size(contracts), 1);

  const handleOnSubmit = async (params: ContactParams): Promise<void> => {
    const newParams: ContactParams = {
      name: params.name,
      email: params.email,
      comment: params.comment,
      subject: params.subject,
      descriptionForOthers: params.descriptionForOthers,
      file: params.file,
      fileName: params.fileName,
      distributorId: params.distributorId,
      municipality: params.municipality,
      address: params.address,
      description: params.description,
    };
    const response = await sendcontact(newParams);
    if (isValidResponse(response)) {
      setMessage(response.data);
      return;
    }
    setMessage(response.data.message);
    setSuccess(false);
  };

  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    setFieldValue,
    errors,
    touched,
    isValid,
    isSubmitting,
  } = useFormik({
    initialValues: formInit.contact(session, setSelect(select)),
    onSubmit: handleOnSubmit,
    validationSchema: schemas.contact,
  });

  const handleFile = async (e: ChangeEvent<HTMLInputElement>): Promise<void> => {
    const { files } = e.target;
    if (_.isNil(files)) {
      return;
    }
    const file = files[0];
    setFieldValue('fileName', file.name);
    setFieldValue('file', await documents.saveB64Document(file));
  };

  const handleSubject = (e: ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    setFieldValue('subject', value);
    if (_.isEqual(value, selectables[3])) {
      setFieldValue('name', '');
      setFieldValue('email', '');
      setFieldValue('comment', '');
      setFieldValue('descriptionForOthers', '');
      return;
    }

    if (!_.isEqual(value, selectables[3])) {
      setFieldValue('name', session.get('name'));
      setFieldValue('email', session.get('email'));
    }
  };

  const handleMunicipality = (e: ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    setFieldValue('municipality', value);
  };

  const handleOwn = (e: ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    setFieldValue('own', parseInt(value, 10));
    if (oneContract) {
      setFieldValue('municipality', contracts[0].get('municipioCups'));
      setFieldValue('address', contracts[0].address);
    }
  };

  const handleContract = (e: ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    // @ts-ignore
    const contract: Contract = _.find(['address', value])(contracts);
    setFieldValue('addressContract', value);
    setFieldValue('municipality', contract.get('municipioCups'));
    setFieldValue('address', contract.address);
  };

  const handleConditions = (e: ChangeEvent<HTMLInputElement>): void => {
    const { checked } = e.target;
    setFieldValue('conditions', checked);
  };

  return (
    <PublicLayout title={t('contact.habla')} onlyDesktop>
      <MobileTopNav
        title={t('contact.habla')}
        onClick={() => navigate(routes.contact)}
      />
      <div className="pt-20 mb-20 wrap hidden lg:block">
        <InternalLink
          to={routes.contact}
          label={t('contact.habla')}
          icon={<Icons.Back />}
          className="mb-16"
        />
      </div>
      <Wrap size="wrap" className="lg:flex pb-20 lg:pb-40">
        <div className="mb-12 lg:mb-0 lg:w-192">
          <Title>{t('contact.enviar')}</Title>
        </div>
        <div className="lg:flex-1 lg:ml-16">
          <Form onSubmit={handleSubmit}>
            {!_.isEqual(values.subject, selectables[3]) && (
              <div className="mb-32">
                <Title size="small" className="mb-6">{t('contact.datos')}</Title>
                <TextInput
                  name="name"
                  value={values.name}
                  placeholder={t('contact.name')}
                  onChange={handleChange('name')}
                  onBlur={handleBlur('name')}
                  errors={errors.name}
                  touched={touched.name}
                  required
                />
                <TextInput
                  name="email"
                  value={values.email}
                  placeholder={t('contact.email')}
                  onChange={handleChange('email')}
                  onBlur={handleBlur('email')}
                  errors={errors.email}
                  touched={touched.email}
                  required
                />
              </div>
            )}
            <div className="mb-32">
              <Title size="small" className="mb-6">{t('contact.hablemos')}</Title>
              <InputSelect
                name="subject"
                value={values.subject}
                selectables={selectables}
                placeholder={t('contact.subject')}
                onChange={handleSubject}
                onBlur={handleBlur('subject')}
                touched={touched.subject}
                errors={errors.subject}
                required
              />
              {_.isEqual(values.subject, selectables[5]) && (
              <TextInput
                name="descriptionForOthers"
                value={values.descriptionForOthers}
                placeholder={t('contact.descriptionForOthers')}
                onChange={handleChange('descriptionForOthers')}
                onBlur={handleBlur('descriptionForOthers')}
                errors={errors.descriptionForOthers}
                touched={touched.descriptionForOthers}
                required
              />
              )}
              {(!_.isEqual(values.subject, selectables[3])
              && !_.isEqual(values.subject, selectables[2])) && (
              <TextArea
                name="comment"
                value={values.comment}
                placeholder={t('contact.comment')}
                onChange={handleChange('comment')}
                onBlur={handleBlur('comment')}
                errors={errors.comment}
                touched={touched.comment}
                required
              />
              )}
              {_.isEqual(values.subject, selectables[2]) && hasContracts && (
              <>
                <Title size="small" className="mb-16 mt-32">{t('contact.own')}</Title>
                <RadioButtonsInput
                  name="own"
                  value={values.own || 0}
                  placeholder=""
                  radios={[
                    {
                      selectorId: 1,
                      value: t('contact.si'),
                    },
                    {
                      selectorId: 2,
                      value: t('contact.no'),
                    },
                  ]}
                  onChange={handleOwn}
                  onBlur={handleBlur('own')}
                  touched={touched.own}
                  errors={errors.own}
                  containerClassName="mb-28"
                />
                {_.isEqual(values.own, 2) && (
                  <div>
                    <Title size="small" className="mb-6">{t('contact.detalles')}</Title>
                    <InputSelect
                      name="municipality"
                      value={values.municipality}
                      selectables={municipalitySelectables}
                      placeholder={t('contact.municipality')}
                      onChange={handleMunicipality}
                      onBlur={handleBlur('municipality')}
                      touched={touched.municipality}
                      errors={errors.municipality}
                      required
                    />
                    <TextInput
                      name="address"
                      value={values.address}
                      placeholder={t('contact.address')}
                      onChange={handleChange('address')}
                      onBlur={handleBlur('address')}
                      errors={errors.address}
                      touched={touched.address}
                      required
                    />
                    <TextInput
                      name="description"
                      value={values.description}
                      placeholder={t('contact.description')}
                      onChange={handleChange('description')}
                      onBlur={handleBlur('description')}
                      errors={errors.description}
                      touched={touched.description}
                      required
                    />
                  </div>
                )}
                {_.isEqual(values.own, 1) && !oneContract && (
                <div>
                  <Title size="small" className="mb-6">{t('contact.detalles')}</Title>
                  <InputSelect
                    name="addressContract"
                    value={values.addressContract || ''}
                    selectables={contractSelectables}
                    placeholder={t('contact.addressContract')}
                    onChange={handleContract}
                    onBlur={handleBlur('addressContract')}
                    touched={touched.addressContract}
                    errors={errors.addressContract}
                    required
                  />
                  <TextInput
                    name="description"
                    value={values.description}
                    placeholder={t('contact.description')}
                    onChange={handleChange('description')}
                    onBlur={handleBlur('description')}
                    errors={errors.description}
                    touched={touched.description}
                    required
                  />
                </div>
                )}
                {_.isEqual(values.own, 1) && oneContract && (
                <TextInput
                  name="description"
                  value={values.description}
                  placeholder={t('contact.description')}
                  onChange={handleChange('description')}
                  onBlur={handleBlur('description')}
                  errors={errors.description}
                  touched={touched.description}
                  required
                />
                )}
              </>
              )}
            </div>
            {_.isEqual(values.subject, selectables[2]) && !hasContracts && (
              <div className="mb-32">
                <Title size="small" className="mb-6">{t('contact.detalles')}</Title>
                <InputSelect
                  name="municipality"
                  value={values.municipality}
                  selectables={municipalitySelectables}
                  placeholder={t('contact.municipality')}
                  onChange={handleMunicipality}
                  onBlur={handleBlur('municipality')}
                  touched={touched.municipality}
                  errors={errors.municipality}
                  required
                />
                <TextInput
                  name="address"
                  value={values.address}
                  placeholder={t('contact.address')}
                  onChange={handleChange('address')}
                  onBlur={handleBlur('address')}
                  errors={errors.address}
                  touched={touched.address}
                  required
                />
                <TextInput
                  name="description"
                  value={values.description}
                  placeholder={t('contact.description')}
                  onChange={handleChange('description')}
                  onBlur={handleBlur('description')}
                  errors={errors.description}
                  touched={touched.description}
                  required
                />
              </div>
            )}
            {_.isEqual(values.subject, selectables[3]) && (
              <div className="mb-32">
                <Title size="small" className="mb-6">{t('contact.detalles')}</Title>
                <InputSelect
                  name="municipality"
                  value={values.municipality}
                  selectables={municipalitySelectables}
                  placeholder={t('contact.municipality')}
                  onChange={handleMunicipality}
                  onBlur={handleBlur('municipality')}
                  touched={touched.municipality}
                  errors={errors.municipality}
                  required
                />
                <TextInput
                  name="address"
                  value={values.address}
                  placeholder={t('contact.address')}
                  onChange={handleChange('address')}
                  onBlur={handleBlur('address')}
                  errors={errors.address}
                  touched={touched.address}
                  required
                />
                <TextInput
                  name="description"
                  value={values.description}
                  placeholder={t('contact.description')}
                  onChange={handleChange('description')}
                  onBlur={handleBlur('description')}
                  errors={errors.description}
                  touched={touched.description}
                  required
                />
              </div>
            )}
            {!_.isEqual(values.subject, selectables[3]) && (
              <div className="btn text-blue flex mb-32">
                <Icons.Attach color="blue" />
                <label htmlFor="fileName" className="ml-2">
                  {t('contact.file')}
                </label>
                <input
                  type="file"
                  name="fileName"
                  id="fileName"
                  onChange={handleFile}
                  onBlur={handleBlur('fileName')}
                  className="hidden"
                  accept=".pdf, .jpg, .png"
                />
              </div>
            )}
            <CheckboxInput
              name="conditions"
              value={values.conditions || false}
              onChange={handleConditions}
              onBlur={handleBlur('conditions')}
              touched={touched.conditions}
              errors={errors.conditions}
              containerClassName="mb-16"
            >
              {t('register.conditions')}
              <Link to={routes.privacyPolicy} target="_blank" className="text-blue">
                {t('register.privacy')}
              </Link>
            </CheckboxInput>
            {success && !_.isEmpty(message) && (
              <div className="rounded-sm bg-green-light p-10 flex items-center mb-16 max-w-240 w-full">
                <Icons.Good color="green" className="mr-6" />
                <Bodycopy className="text-green">{message}</Bodycopy>
              </div>
            )}
            {!success && !_.isEmpty(message) && (
            <FormResponse message={message} className="mb-8" />
            )}
            <SubmitButton
              label={t('contact.enviar')}
              disabled={!isValid || isSubmitting}
            />
          </Form>
        </div>
      </Wrap>
    </PublicLayout>
  );
};

export default ContactForm;
