import React, { FunctionComponent } from 'react';

import classNames from '../services/classNames';

type TagType = 'regular' | 'activa' | 'suspendida' | 'desconectado' | 'mantenimiento';

export interface Props {
  label: string;
  type: TagType | string;
  onClick?: () => void;
  className?: string;
}

const Tag: FunctionComponent<Props> = ({
  label, type, onClick, className = '',
}: Props) => (
  <div className={classNames(`tag-${type}`, className)} onClick={onClick}>
    {label}
  </div>
);

export default Tag;
