import { FormikErrors, FormikTouched } from 'formik';
import _ from 'lodash/fp';
import React, { ChangeEvent, FunctionComponent } from 'react';

import NumberInput from '../../../../components/forms/NumberInput';
import SelectInput from '../../../../components/forms/SelectInput';
import TextInput from '../../../../components/forms/TextInput';
import { useStoreState } from '../../../../store/hooks';
import {
  EspecificationsData,
  GenerationParams,
  SelfconsumptionParams,
} from '../../../../store/model/requests/types';
import t from '../../../../text-strings';

import StepPage from './StepPage';

interface Props {
  title: string;
  step: number
  totalSteps: number;
  values: EspecificationsData;
  handleChange: {
    // eslint-disable-next-line
    (e: React.ChangeEvent<any>): void;
    // eslint-disable-next-line
    <T_1 = string | React.ChangeEvent<any>>(field: T_1): T_1 extends React.ChangeEvent<any> 
      ? void
      // eslint-disable-next-line
      : (e: string | React.ChangeEvent<any>) => void;
  };
  handleBlur: {
    // eslint-disable-next-line
    (e: React.FocusEvent<any, Element>): void;
    // eslint-disable-next-line
    <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
  };
  handleSelect: (e: ChangeEvent<HTMLSelectElement>) => void;
  errors: FormikErrors<SelfconsumptionParams | GenerationParams>;
  // eslint-disable-next-line
  touched: FormikTouched<SelfconsumptionParams | GenerationParams>;
}

const YES = 'Sí';

const EspecificationDataStep: FunctionComponent<Props> = (
  {
    title,
    step,
    totalSteps,
    values,
    handleChange,
    handleBlur,
    handleSelect,
    errors,
    touched,
  }: Props,
) => {
  const { loadrequest } = useStoreState((state) => state.requests);

  const getYes = _.flow(
    _.find(['value', YES]),
    _.get('selectorId'),
  );

  return (
    <StepPage
      title={title}
      current="especificaciones"
      next="documentacion"
      prev="instalacion"
      step={step}
      totalSteps={totalSteps}
    >
      {!_.isUndefined(loadrequest.SiNo) && (
        <>
          <SelectInput
            name="hybridation"
            value={values.hybridation}
            placeholder={t('models.requests.especification.hybridation')}
            selectables={loadrequest.SiNo}
            onChange={handleSelect}
            onBlur={handleBlur('hybridation')}
            touched={touched.hybridation}
            errors={errors.hybridation}
            required
          />
          {_.isEqual(values.hybridation, getYes(loadrequest.SiNo)) && (
            <TextInput
              name="hybridDescription"
              value={values.hybridDescription}
              placeholder={t('models.requests.especification.hybrid_description')}
              onChange={handleChange('hybridDescription')}
              onBlur={handleBlur('hybridDescription')}
              touched={touched.hybridDescription}
              errors={errors.hybridDescription}
              required
            />
          )}
        </>
      )}
      {!_.isUndefined(loadrequest.SiNo) && (
        <>
          <SelectInput
            name="accumulation"
            value={values.accumulation}
            placeholder={t('models.requests.especification.accumulation')}
            selectables={loadrequest.SiNo}
            onChange={handleSelect}
            onBlur={handleBlur('accumulation')}
            touched={touched.accumulation}
            errors={errors.accumulation}
            required
          />
          {_.isEqual(values.accumulation, getYes(loadrequest.SiNo)) && (
            <TextInput
              name="accumulationDescription"
              value={values.accumulationDescription}
              placeholder={t('models.requests.especification.accumulation_description')}
              onChange={handleChange('accumulationDescription')}
              onBlur={handleBlur('accumulationDescription')}
              touched={touched.accumulationDescription}
              errors={errors.accumulationDescription}
              required
            />
          )}
        </>
      )}
      {!_.isUndefined(loadrequest.SiNo) && (
        <SelectInput
          name="envImpAssessment"
          value={values.envImpAssessment}
          placeholder={t('models.requests.especification.env_imp_assesment')}
          selectables={loadrequest.SiNo}
          onChange={handleSelect}
          onBlur={handleBlur('envImpAssessment')}
          touched={touched.envImpAssessment}
          errors={errors.envImpAssessment}
          required
        />
      )}
      <NumberInput
        name="guaranteeAmount"
        value={_.isEqual(values.guaranteeAmount, 0) ? '' : `${values.guaranteeAmount}`}
        placeholder={t('models.requests.especification.guarantee_amount')}
        onChange={handleChange('guaranteeAmount')}
        onBlur={handleBlur('guaranteeAmount')}
        touched={touched.guaranteeAmount}
        errors={errors.guaranteeAmount}
        decimals="0.01"
      />
      <TextInput
        name="knot"
        value={values.knot}
        placeholder={t('models.requests.especification.knot')}
        onChange={handleChange('knot')}
        onBlur={handleBlur('knot')}
        touched={touched.knot}
        errors={errors.knot}
        required
      />
    </StepPage>
  );
};

export default EspecificationDataStep;
