import React, { FunctionComponent } from 'react';
import _ from 'lodash/fp';

import PublicLayout from '../../../layout/PublicLayout';
import { routes } from '../../../services/routing';
import t from '../../../text-strings';
import Title from '../../../components/text-components/Title';
import LinkCard from '../../../components/cards/LinkCard';
import Icons from '../../../assets/icons';
import images from '../../../assets/images';
import LinkButton from '../../../components/buttons/LinkButton';
import Accordion from '../../../components/Accordion';
import Content from '../../../layout/components/Content';
import { FAQAttributes } from '../../../store/model/contact/types';
import useFAQs from '../../../services/use-faq';
import SidebarLayout from '../../../layout/SidebarLayout';
import { SidebarTabProps } from '../../../layout/tabs/SidebarTab';
import useScroll from '../../../services/use-scroll';

const Supply: FunctionComponent = () => {
  const [loading, faqs] = useFAQs('Solicitud_nuevo_suministro');
  const isActive = useScroll();

  const links: SidebarTabProps[] = [
    {
      id: 'tramites-frecuentes',
      to: '',
      label: t('supply.tramites_frecuentes'),
      last: false,
      anchor: true,
      active: isActive('tramites-frecuentes', 'altas'),
    },
    {
      id: 'altas',
      to: '',
      label: t('supply.nuevas_altas'),
      last: false,
      anchor: true,
      active: isActive('altas', 'faq'),
    },
    {
      id: 'faq',
      to: '',
      label: t('supply.preguntas_frecuentes'),
      last: true,
      anchor: true,
      active: isActive('faq'),
    },
  ];

  return (
    <PublicLayout title={t('supply.title')}>
      <SidebarLayout sidebarTabs={links}>
        <div id="tramites-frecuentes" className="w-full lg:flex lg:flex-col lg:items-end pt-16 lg:pt-0">
          <Content className="">
            <Title tag="h1" className="mb-12 lg:mb-14">{t('supply.tramites_frecuentes')}</Title>
            <div className="lg:flex lg:flex-wrap lg:justify-between">
              <LinkCard
                label={t('supply.nuevo_punto_suministro')}
                icon={<Icons.Bolt color="white" />}
                to={routes.supply.supplyProcess}
                className="mb-4 lg:w-200 hd:w-224 xl:mb-0"
              />
              <LinkCard
                label={t('supply.solicitud')}
                icon={<Icons.AddDocument color="white" />}
                to={routes.supply.cupsProcess}
                className="lg:w-200 hd:w-224"
              />
            </div>
          </Content>
        </div>
        <div id="altas" className="w-full lg:flex lg:flex-col lg:items-end pt-16">
          <Content className="">
            <div
              className="has-bg-image relative h-96 sm:h-128 md:h-144 rounded-base lg:h-152 flex flex-col"
              style={{ backgroundImage: `url(${images.subbanner.mobile})` }}
            >
              <div className="absolute inset-0 bg-overlay-opacity rounded-base flex-1 flex flex-col items-center justify-center">
                <Icons.Help color="yellow" />
                <Title tag="h3" color="white" className="my-12 text-center">{t('supply.no_sabes')}</Title>
                <LinkButton
                  to={routes.supply.guide}
                  label={t('supply.asistente')}
                />
              </div>
            </div>
          </Content>
        </div>
        <div id="faq" className="w-full lg:flex lg:flex-col lg:items-end pt-16 mb-28">
          <Content className="">
            <Title tag="h3" className="mb-12">{t('supply.preguntas_frecuentes')}</Title>
            {!loading && (
              <>
                {_.map((faq: FAQAttributes) => (
                  <Accordion
                    key={faq.questionId}
                    title={faq.title}
                    text={faq.text}
                  />
                ))(faqs)}
              </>
            )}
          </Content>
        </div>
      </SidebarLayout>
    </PublicLayout>
  );
};

export default Supply;
