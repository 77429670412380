import React, { FunctionComponent, ReactNode } from 'react';

import classNames from '../../services/classNames';

interface Props {
  children: ReactNode;
  className?: string;
  innerClassName?: string;
}

const HorizontalList: FunctionComponent<Props> = (
  {
    children,
    className = '',
    innerClassName = '',
  }: Props,
) => (
  <div className={classNames('overflow-x-visible', className)}>
    <div className={classNames('flex overflow-x-scroll w-full lg:overflow-x-visible', innerClassName)}>
      {children}
    </div>
  </div>
);

export default HorizontalList;
