import React, { FunctionComponent, ReactNode, useState } from 'react';
import classnames from 'classnames';
import _ from 'lodash/fp';

import classNames from '../services/classNames';
import Icons from '../assets/icons';

import Bodycopy from './text-components/Bodycopy';

export interface Props {
  type: 'select-underline' | 'select-complete' | 'select-icon';
  selected: string;
  children: ReactNode;
  className?: string;
  icon?: ReactNode;
}

const Select: FunctionComponent<Props> = (
  {
    type,
    selected,
    children,
    className = '',
    icon,
  }: Props,
) => {
  const [open, setOpen] = useState(false);
  const toggleOpen = (): void => setOpen(!open);

  const dropdownClass = classnames('dropdown', {
    block: open,
    hidden: !open,
  });

  return (
    <div className={classNames(`${type}`, className)} onClick={toggleOpen}>
      <div className="select">
        {!_.isUndefined(icon) && icon}
        <Bodycopy className="truncate">{selected}</Bodycopy>
        <Icons.Dropdown color="blue" />
      </div>
      <div className={dropdownClass}>
        {children}
      </div>
    </div>
  );
};

export default Select;
