import AddDocument from './icons/AddDocument';
import ArrowUp from './icons/ArrowUp';
import Attach from './icons/Attach';
import Back from './icons/Back';
import Bad from './icons/Bad';
import Bolt from './icons/Bolt';
import Calendar from './icons/Calendar';
import Counter from './icons/Counter';
import Check from './icons/Check';
import Clock from './icons/Clock';
import Close from './icons/Close';
import Copy from './icons/Copy';
import Document from './icons/Document';
import Dropdown from './icons/Dropdown';
import DropdownUp from './icons/Dropdown-up';
import Edit from './icons/Edit';
import Facebook from './icons/Facebook';
import Filter from './icons/Filter';
import Fraud from './icons/Fraud';
import Go from './icons/Go';
import Good from './icons/Good';
import Growth from './icons/Growth';
import Help from './icons/Help';
import Home from './icons/Home';
import Info from './icons/Info';
import Instagram from './icons/Instagram';
import LinkedIn from './icons/LinkedIn';
import Menu from './icons/Menu';
import Message from './icons/Message';
import Minus from './icons/Minus';
import Money from './icons/Money';
import Notifications from './icons/Notifications';
import People from './icons/People';
import Phone from './icons/Phone';
import Pin from './icons/Pin';
import Plus from './icons/Plus';
import Replace from './icons/Replace';
import Search from './icons/Search';
import Settings from './icons/Settings';
import Support from './icons/Support';
import Tool from './icons/Tool';
import Twitter from './icons/Twitter';
import User from './icons/User';
import Warning from './icons/Warning';
import Decay from './icons/Decay';
import Youtube from './icons/Youtube';
import AngleRight from './icons/AngleRight';
import Pencil from './icons/Pencil';
import Trash from './icons/Trash';

const Icons = {
  AddDocument,
  AngleRight,
  ArrowUp,
  Attach,
  Back,
  Bad,
  Bolt,
  Calendar,
  Counter,
  Check,
  Clock,
  Close,
  Copy,
  Decay,
  Document,
  DropdownUp,
  Dropdown,
  Edit,
  Facebook,
  Filter,
  Fraud,
  Go,
  Good,
  Growth,
  Help,
  Home,
  Info,
  Instagram,
  LinkedIn,
  Menu,
  Message,
  Minus,
  Money,
  Notifications,
  Pencil,
  People,
  Phone,
  Pin,
  Plus,
  Replace,
  Search,
  Settings,
  Support,
  Trash,
  Tool,
  Twitter,
  User,
  Warning,
  Youtube,
};

export default Icons;
