import _ from 'lodash/fp';
import React, { FunctionComponent, useState } from 'react';
import { Link } from 'react-router-dom';

import Icons from '../../../assets/icons';
import ListItem, { ListData } from '../../../components/ListItem';
import Bodycopy from '../../../components/text-components/Bodycopy';
import Title from '../../../components/text-components/Title';
import PublicLayout from '../../../layout/PublicLayout';
import t from '../../../text-strings';
import Content from '../../../layout/components/Content';
import Modal from '../../../components/Modal';
import InternalLink from '../../../components/InternalLink';
import { routes } from '../../../services/routing';
import useScroll from '../../../services/use-scroll';
import { SidebarTabProps } from '../../../layout/tabs/SidebarTab';
import SidebarLayout from '../../../layout/SidebarLayout';

import IconCircled from './components/IconCircled';
import Calculator from './components/Calculator';
import processDocumentList from './data';

const SupplyProcess: FunctionComponent = () => {
  const [isCups, setIsCups] = useState(false);
  const isActive = useScroll();

  const toggleCups = (): void => setIsCups(!isCups);

  const links: SidebarTabProps[] = [
    {
      id: 'nuevo-suministro',
      to: '',
      label: t('supply.proceso_suministro.nuevo_suministro'),
      last: false,
      anchor: true,
      active: isActive('nuevo-suministro', 'documentacion'),
    },
    {
      id: 'documentacion',
      to: '',
      label: t('supply.procesos.doc'),
      last: false,
      anchor: true,
      active: isActive('documentacion', 'costes'),
    },
    {
      id: 'costes',
      to: '',
      label: t('supply.procesos.costes'),
      last: true,
      anchor: true,
      active: isActive('costes'),
    },
  ];

  const processList: ListData[] = [
    {
      position: 1,
      title: t('supply.proceso_suministro.listado.1.title'),
      description: t('supply.proceso_suministro.listado.1.description'),
    },
    {
      position: 2,
      title: t('supply.proceso_suministro.listado.2.title'),
      description: t('supply.proceso_suministro.listado.2.description'),
    },
    {
      position: 3,
      title: (
        <>
          Solicita el &nbsp;
          <span onClick={toggleCups} className="text-blue">
            {t('supply.proceso_suministro.listado.3.title')}
          </span>
        </>
      ),
      description: (
        <>
          {t('supply.proceso_suministro.listado.3.description')}
          <Link to={routes.requests.cups} className="text-blue"><strong>&nbsp;aquí</strong></Link>
        </>
      ),
    },
    {
      position: 4,
      title: t('supply.proceso_suministro.listado.4.title'),
    },
    {
      position: 5,
      title: t('supply.proceso_suministro.listado.5.title'),
    },
    {
      position: 6,
      title: t('supply.proceso_suministro.listado.6.title'),
      description: (
        <>
          {t('supply.proceso_suministro.listado.6.description')}
          <strong>&nbsp;entre entre 5 y 15 días.</strong>
        </>

      ),
    },
  ];

  return (
    <PublicLayout title={t('supply.title')} onlyDesktop>
      <SidebarLayout
        sidebarTabs={links}
        to={routes.requests.supply}
        label={t('supply.nuevo_punto_suministro')}
        icon={<Icons.Bolt />}
      >
        <div id="nuevo-suministro" className="w-full py-16 lg:flex lg:flex-col lg:items-end lg:py-0">
          <Content className="border-gray-200 lg:border-b lg:pb-16">
            <InternalLink
              to={routes.supply.index}
              label={t('supply.title')}
              icon={<Icons.Back />}
              className="mb-16"
            />
            <IconCircled>
              <Icons.AddDocument />
            </IconCircled>
            <div className="xl:flex xl:justify-between">
              <div className="mb-12 xl:mb-0 xl:w-5/12 xl:max-w-192">
                <Title tag="h1" className="mb-4">{t('supply.proceso_suministro.title')}</Title>
                <div className="flex">
                  <Bodycopy className="mr-16">{t('supply.proceso_suministro.potencias')}</Bodycopy>
                  <Bodycopy>{t('supply.proceso_suministro.urbano')}</Bodycopy>
                </div>
              </div>
              <ul className="lg:grid lg:grid-cols-2 xl:w-1/2 xl:max-w-224 lg:gap-x-16">
                {_.map((listItem: ListData) => (
                  <ListItem
                    key={listItem.position}
                    position={listItem.position}
                    title={listItem.title}
                    description={listItem.description}
                    vertical
                  />
                ))(processList)}
              </ul>
            </div>
          </Content>
        </div>

        <div id="documentacion" className="w-full py-16 bg-white lg:bg-transparent lg:flex lg:flex-col lg:items-end lg:py-0">
          <Content className="border-gray-200 lg:border-b lg:pb-16 lg:pt-32">
            <IconCircled bgColor="white">
              <Icons.Document />
            </IconCircled>
            <div id="documentacion" className="xl:flex xl:justify-between">
              <div className="mb-12 xl:mb-0 xl:w-5/12 xl:max-w-192">
                <Title tag="h1">{t('supply.procesos.documentacion')}</Title>
              </div>
              <ul className="lg:grid lg:grid-cols-2 lg:gap-x-16 xl:w-1/2 xl:max-w-224">
                {_.map((listItem: ListData) => (
                  <ListItem
                    key={listItem.position}
                    position={listItem.position}
                    title={listItem.title}
                    description={listItem.description}
                    vertical
                  />
                ))(processDocumentList)}
              </ul>
            </div>
          </Content>
        </div>
        <div id="costes" className="w-full py-16 lg:flex lg:flex-col lg:items-end mb-28 lg:py-0 xl:mb-56">
          <Content className="lg:pb-16 lg:pt-32">
            <Calculator />
            <Modal isOpen={isCups} toggleOpen={toggleCups}>
              <Title>{t('supply.cups.que')}</Title>
              <Bodycopy>{t('supply.cups.descripcion')}</Bodycopy>
            </Modal>
          </Content>
        </div>
      </SidebarLayout>
    </PublicLayout>
  );
};

export default SupplyProcess;
