import React, { FunctionComponent } from 'react';

import helpers from '../../services/helpers';
import { Any } from '../../store/model/global';

const BarChartTooltip: FunctionComponent = ({ active, payload }: Any) => {
  if (active && payload && payload.length) {
    const [flatData, valleyData, peakData, autoData] = payload;

    return (
      <div className="border border-black rounded-sm flex flex-col bg-white p-6">
        <div className="flex items-center mb-2">
          <p className="text-xs font-bold">
            {`Día ${helpers.parseDateTooltip(
              payload[0].payload.name,
            )}`}
          </p>
        </div>
        <div className="flex items-center mb-2">
          <div className="bg-orange-lighter min-h-6 min-w-6 h-6 w-6 rounded mr-4" />
          <p className="text-xs">
            {`${helpers.toStringDecimal(
              flatData.value,
              2,
            )} kWh Llana`}
          </p>
        </div>
        <div className="flex items-center mb-2">
          <div className="bg-yellow min-h-6 min-w-6 h-6 w-6 rounded mr-4" />
          <p className="text-xs">
            {`${helpers.toStringDecimal(
              valleyData.value,
              2,
            )} kWh Valle`}
          </p>
        </div>
        <div className="flex items-center">
          <div className="bg-orange-light min-h-6 min-w-6 h-6 w-6 rounded mr-4" />
          <p className="text-xs">
            {`${helpers.toStringDecimal(
              peakData.value,
              2,
            )} kWh Punta`}
          </p>
        </div>
        {autoData && (
          <div className="flex items-center mt-2">
            <div className="bg-black min-h-6 min-w-6 h-6 w-6 rounded mr-4" />
            <p className="text-xs">
              {`${helpers.toStringDecimal(
                autoData.value,
                2,
              )} kWh Vertido`}
            </p>
          </div>
        )}
      </div>
    );
  }

  return null;
};

export default BarChartTooltip;
