import {
  action,
  Action,
  computed,
  Computed,
  thunk,
  Thunk,
} from 'easy-peasy';
import _ from 'lodash/fp';

import client from '../../../api/axios';
import cookieStorage from '../../../services/cookies';
import { handleAxiosResponse } from '../../store-helpers';

import { SessionAttributes, SessionParams } from './types';
import session, { expiracyDate, Session } from './session';

export interface SessionModel {
  session: Session;
  expiracy: boolean;

  authenticate: Thunk<SessionModel, SessionParams>;

  init: Action<SessionModel, SessionAttributes>;
  restore: Action<SessionModel>;
  setExpiracy: Action<SessionModel, boolean>;
  logout: Action<SessionModel>;

  isLoggedIn: Computed<SessionModel, boolean>;
}

const sessionModel: SessionModel = {
  session: session(),
  expiracy: false,

  authenticate: thunk(async (actions, params, { injections }) => {
    const { sessionService } = injections;
    actions.setExpiracy(params.sessionNoExpired);
    return handleAxiosResponse(await sessionService.authenticate(params), actions.init);
  }),

  init: action((state, params) => {
    cookieStorage.setCookie('session', params, expiracyDate(state.expiracy));
    client.defaults.headers.common.Authorization = params.token;
    state.session = session(params);
  }),

  restore: action((state) => {
    const sessionAttributes: SessionAttributes = cookieStorage.getCookie('session');
    if (!_.isUndefined(sessionAttributes)) {
      client.defaults.headers.common.Authorization = sessionAttributes.token;
      state.session = session(sessionAttributes);
    }
  }),

  setExpiracy: action((state, params) => {
    state.expiracy = params;
  }),

  logout: action((state) => {
    cookieStorage.removeCookie('session');
    state.session = session();
  }),

  isLoggedIn: computed((state) => !_.isEmpty(state.session.get('token'))),
};

export default sessionModel;
