import React, { FunctionComponent } from 'react';
import _ from 'lodash/fp';

import navLinks, { NavLinkProps } from '../navigation';
import t from '../../../text-strings';
import LinkButton from '../../../components/buttons/LinkButton';
import { routes } from '../../../services/routing';

import NavLink from './NavLink';

const DesktopMenu: FunctionComponent = () => (
  <div className="hidden lg:flex items-center">
    <ul className="flex items-center">
      {_.map((navLink: NavLinkProps) => (
        !_.isEqual(navLink.label, t('common.navigation.home')) && (
          <NavLink
            key={navLink.label}
            to={navLink.to}
            label={navLink.label}
            subLinks={navLink.subLinks}
          />
        )
      ))(navLinks)}
    </ul>
    <LinkButton
      to={routes.profile}
      label={t('common.area_privada')}
    />
  </div>
);

export default DesktopMenu;
