import React, { FunctionComponent } from 'react';

import images from '../../../../assets/images';
import Image from '../../../../components/Image';
import Bodycopy from '../../../../components/text-components/Bodycopy';
import Caption from '../../../../components/text-components/Caption';
import Title from '../../../../components/text-components/Title';
import t from '../../../../text-strings';

const Savings: FunctionComponent = () => (
  <div className="md:flex md:mb-40">
    <div className="md:w-1/3">
      <Title size="small" className="mb-4">{t('self.autoconsumo.ahorro')}</Title>
      <Bodycopy className="mb-12">{t('self.autoconsumo.ahorro_cantidad')}</Bodycopy>
    </div>
    <div className="md:w-2/3 md:flex md:flex-col md:items-end">
      <Image src={images.autoconsumo.ahorro} className="w-224 mb-8 hidden lg:block" />
      <Image src={images.autoconsumo.ahorroMVL} className="w-224 mb-8 lg:hidden" />
      <div className="flex w-full mb-8 max-w-224">
        <div className="flex items-baseline mr-16">
          <div className="w-6 h-6 bg-gradient rounded-full mr-2" />
          <Caption>{t('self.autoconsumo.con_autoconsumo')}</Caption>
        </div>
        <div className="flex items-baseline">
          <div className="w-6 h-6 bg-gray-300 rounded-full mr-2" />
          <Caption>{t('self.autoconsumo.sin_autoconsumo')}</Caption>
        </div>
      </div>
      <Caption color="gray-500" className="max-w-224 mb-12 md:mb-0">
        {t('self.autoconsumo.caption_grafico')}
      </Caption>
    </div>
  </div>
);

export default Savings;
