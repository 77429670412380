import React, { FunctionComponent, ReactNode } from 'react';

import { AppColors } from '../../styles';
import classNames from '../../services/classNames';

export interface Props {
  children: ReactNode;
  tag?: 'p' | 'span';
  color?: AppColors;
  className?: string;
}

const Caption: FunctionComponent<Props> = (
  {
    children,
    tag: Tag = 'p',
    color = 'black',
    className = '',
  }: Props,
) => (
  <Tag className={classNames(`caption text-${color}`, className)}>
    {children}
  </Tag>
);

export default Caption;
