import React, { FunctionComponent } from 'react';
import classnames from 'classnames';

import Title from '../../../../components/text-components/Title';

interface Props {
  current: boolean;
  number: number;
}

const CircledNumber: FunctionComponent<Props> = ({ current, number }: Props) => {
  const circleClass = classnames('w-22 h-22 lg:w-28 lg:h-28 flex items-center justify-center rounded-full', {
    'bg-gradient': current,
    'bg-gray-400': !current,
  });

  return (
    <div className={circleClass}>
      <div className="bg-white w-20 h-20 lg:w-24 lg:h-24 flex items-center justify-center rounded-full">
        <Title size="small" tag="h4" className="font-bold">{number}</Title>
      </div>
    </div>
  );
};

export default CircledNumber;
