import _ from 'lodash/fp';
import React, { FunctionComponent, ReactNode } from 'react';
import { Link } from 'react-router-dom';

import Icons from '../../../../assets/icons';
import Title from '../../../../components/text-components/Title';
import { routes } from '../../../../services/routing';
import { useStoreActions } from '../../../../store/hooks';
import { RequestStep } from '../../../../store/model/requests/types';

interface Props {
  title: string;
  prev?: RequestStep;
  step: number;
  totalSteps: number;
}

const StepHeader: FunctionComponent<Props> = (
  {
    title,
    prev,
    step,
    totalSteps,
  }: Props,
) => {
  const { setRequestStep } = useStoreActions((actions) => actions.requests);

  const steps: ReactNode[] = [];
  for (let i = 1; i <= totalSteps; i += 1) {
    steps.push((
      <div
        key={i}
        className={
          `rounded-sm h-4 w-1/${totalSteps} 
          ${_.lte(i, step) ? 'bg-black' : 'border border-black'} 
          ${_.isEqual(i, totalSteps) ? '' : 'mr-2'}`
        }
      />
    ));
  }

  return (
    <div>
      <div className="min-h-12 mb-4 py-8 lg:hidden relative">
        {!_.isUndefined(prev) ? (
          <div className="cursor-pointer w-12 h-12 absolute top-8 left-0" onClick={(): void => setRequestStep(prev)}>
            <Icons.Back />
          </div>
        ) : (
          <Link to={routes.requests.index} className="w-12 h-12 absolute top-8 left-0">
            <Icons.Back />
          </Link>
        )}
        <div>
          <Title size="small" tag="h4" className="text-center">{title}</Title>
        </div>
      </div>
      <div className="flex items-center max-w-240 mb-16 lg:pt-20">
        {steps}
      </div>
    </div>
  );
};

export default StepHeader;
