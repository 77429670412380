import React, { FunctionComponent } from 'react';

import Icons from '../../../assets/icons';

interface Props {
  open: boolean;
  toggleOpen: () => void;
}

const MenuBars: FunctionComponent<Props> = ({ open, toggleOpen }: Props) => (
  <div onClick={toggleOpen} className="lg:hidden">
    {open ? <Icons.Close color="yellow" /> : <Icons.Menu color="yellow" />}
  </div>
);

export default MenuBars;
