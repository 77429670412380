import { useFormik } from 'formik';
import _ from 'lodash/fp';
import React, {
  ChangeEvent,
  FunctionComponent,
  useEffect,
  useState,
} from 'react';

import Form from '../../../components/forms/Form';
import formInit from '../../../forms/initial-values';
import schemas from '../../../forms/schemas';
import AuthenticatedLayout from '../../../layout/AuthenticatedLayout';
import constants from '../../../services/constants';
import { useStoreActions, useStoreState } from '../../../store/hooks';
import {
  clientData,
  documentData,
  especificationData,
  instalationData,
  requestData,
} from '../../../store/model/requests/requests';
import {
  FormID,
  GenerationParams,
  GenerationRequestParams,
} from '../../../store/model/requests/types';
import t from '../../../text-strings';
import useHandleRequestResponse from '../../../services/handle-request-response';

import SideNavLink from './components/SideNavLink';
import ClientDataStep from './components/ClientData';
import InstalationDataStep from './components/InstalationData';
import RequestDataStep from './components/RequestData';
import DocumentDataStep from './components/DocumentData';
import EspecificationDataStep from './components/EspecificationData';

const TOTAL_STEPS = 5;

const GenerationRequest: FunctionComponent = () => {
  const { session } = useStoreState((state) => state.session);
  const {
    getloadrequest,
    creategenerationrequest,
    setRequestStep,
  } = useStoreActions((actions) => actions.requests);
  const [loading, setLoading] = useState(true);
  const [
    message,
    success,
    sendedPdf,
    handleResponse,
  ] = useHandleRequestResponse<GenerationParams>();

  const handleOnSubmit = async (values: GenerationParams): Promise<void> => {
    // @ts-ignore
    const params: GenerationRequestParams = _.omit([
      'locality',
      'municipalityId',
      'technicalDraft',
      'authorization',
      'warranty',
      'environmentalImpact',
      'communicationCompetentAdministration',
      't243',
      'generalPlan',
      'particularPlan',
      'significativeDistances',
      'generalImplantationPlan',
      'generalImplantationPlan2',
    ])(values);
    handleResponse(await creategenerationrequest(params), values);
  };

  const {
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    isSubmitting,
    isValid,
  } = useFormik({
    initialValues: formInit.generation(session),
    onSubmit: handleOnSubmit,
    validationSchema: schemas.generation,
  });

  useEffect(() => {
    (async () => {
      setRequestStep('cliente');
      await getloadrequest({
        distributorId: constants.DISTRIBUTOR_ID,
        formCode: constants.GENERATION_CODE as FormID,
      });
      setLoading(false);
    })();
  }, []);

  if (loading) {
    return null;
  }

  const handleSelect = (e: ChangeEvent<HTMLSelectElement>): void => {
    const { value, name } = e.target;
    setFieldValue(name, parseInt(value, 10));
  };

  return (
    <AuthenticatedLayout title="" onlyDesktop sidebar={false}>
      <Form onSubmit={handleSubmit} className="lg:flex lg:flex-1">
        <div className="lg:w-7/10">
          <ClientDataStep
            title={t('requests.generation_request')}
            totalSteps={TOTAL_STEPS}
            // @ts-ignore
            values={_.pick(clientData)(values)}
            handleChange={handleChange}
            handleSelect={handleSelect}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
            errors={errors}
            touched={touched}
          />
          <RequestDataStep
            title={t('requests.generation_request')}
            prev="cliente"
            step={2}
            totalSteps={TOTAL_STEPS}
            // @ts-ignore
            values={_.pick(requestData)(values)}
            handleChange={handleChange}
            handleSelect={handleSelect}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
            errors={errors}
            touched={touched}
          />
          <InstalationDataStep
            title={t('requests.generation_request')}
            step={3}
            next="especificaciones"
            totalSteps={TOTAL_STEPS}
            // @ts-ignore
            values={_.pick(instalationData)(values)}
            handleChange={handleChange}
            handleSelect={handleSelect}
            setFieldValue={setFieldValue}
            handleBlur={handleBlur}
            errors={errors}
            touched={touched}
            formCode={constants.GENERATION_CODE}
          />
          <EspecificationDataStep
            title={t('requests.generation_request')}
            step={4}
            totalSteps={TOTAL_STEPS}
            // @ts-ignore
            values={_.pick(especificationData)(values)}
            handleChange={handleChange}
            handleSelect={handleSelect}
            handleBlur={handleBlur}
            errors={errors}
            touched={touched}
          />
          <DocumentDataStep
            title={t('requests.generation_request')}
            prev="especificaciones"
            step={5}
            totalSteps={TOTAL_STEPS}
            // @ts-ignore
            values={_.pick(documentData)(values)}
            setFieldValue={setFieldValue}
            handleBlur={handleBlur}
            errors={errors}
            message={message}
            success={success}
            isSubmitting={isSubmitting}
            isValid={isValid}
            sendedPdf={sendedPdf}
            formCode={constants.GENERATION_CODE}
          />
        </div>
        <div className="relative flex-col hidden pl-32 bg-white w-3/10 lg:flex pt-72">
          <div className="absolute top-0 h-full bg-white left-full w-screen-full" />
          <SideNavLink
            label={t('requests.forms.cliente')}
            currentStep="cliente"
          />
          <SideNavLink
            label={t('requests.forms.solicitud')}
            currentStep="solicitud"
          />
          <SideNavLink
            label={t('requests.forms.instalacion')}
            currentStep="instalacion"
          />
          <SideNavLink
            label={t('requests.forms.especificaciones')}
            currentStep="especificaciones"
          />
          <SideNavLink
            label={t('requests.forms.documentacion')}
            currentStep="documentacion"
          />
        </div>
      </Form>
    </AuthenticatedLayout>
  );
};

export default GenerationRequest;
