import React, { FunctionComponent, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import _ from 'lodash/fp';

import classNames from '../services/classNames';

export interface Props {
  to: string;
  label: string;
  icon?: ReactNode;
  right?: boolean;
  className?: string;
}

const InternalLink: FunctionComponent<Props> = (
  {
    to,
    label,
    icon,
    right = false,
    className = '',
  }: Props,
) => {
  const linkClass = classnames(classNames('inline-flex btn items-center', className), {
    'flex-row-reverse': right,
  });
  const iconClass = classnames({ 'mr-6': !right, 'ml-6': right });
  return (
    <Link to={to} title={label} className={classNames(linkClass, className)}>
      {!_.isUndefined(icon) && <span className={iconClass}>{icon}</span>}
      <span>{label}</span>
    </Link>
  );
};

export default InternalLink;
