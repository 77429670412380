import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  Label,
  Cell,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
  // eslint-disable-next-line
} from "recharts";
import _ from 'lodash/fp';

import Title from '../../../../components/text-components/Title';
import { ConsumptionParams } from '../../../../store/model/contract/types';
import BarChartTooltip from '../../../../components/charts/BarChartTooltip';
import helpers from '../../../../services/helpers';
import t from '../../../../text-strings';
import date from '../../../../services/date';
import WarningMessage from '../../../../components/WarningMessage';
import PieArea from '../../../../components/charts/PieArea';
import ArrowRight from '../../../../assets/icons/ArrowRight';
import ArrowLeft from '../../../../assets/icons/ArrowLeft';
import { useStoreActions, useStoreState } from '../../../../store/hooks';
import { Any } from '../../../../store/model/global';
import OkMessage from '../../../../components/OkMessage';
import BarLegendPrevYear from '../../../../components/charts/BarLegendPrevYear';
import BarChartPrevYear from '../../../../components/charts/BarChartPrevYear';
import WarningText from '../../../../components/WarningText';
import OkText from '../../../../components/OkText';
import ActionButton from '../../../../components/buttons/ActionButton';
import Modal from '../../../../components/Modal';
import Bodycopy from '../../../../components/text-components/Bodycopy';
import Icons from '../../../../assets/icons';

interface Props {
  isLoading: boolean;
  period: string[];
  energy: Any;
  cups: Any;
}

interface ConsumptionType {
  consumptionType: string;
  consumption: number;
}

interface Data {
  name: string;
  ph: number;
  vh: number;
  fh: number;
  lm: number;
  ly: number;
  cu: number;
}

interface CustomBarProps {
  data: Data[];
  activeIndex: [number, number, number];
  setActiveIndex: React.Dispatch<React.SetStateAction<number[]>>;
}

const TIME = 'T00:00:00.000Z';

const DistributionNetwork: FunctionComponent<Props> = ({
  isLoading,
  period,
  energy,
  cups,
}: Props) => {
  const { getPrevYearConsumption } = useStoreActions(
    (actions) => actions.contract,
  );
  const { prevYearEnergy } = useStoreState((state) => state.contract);
  const [activeIndex, setActiveIndex] = useState([-1, 0, 10]);
  const [hasOkMessage, setHasOkMessage] = useState(false);
  const [hasAdviceMessage, setHasAdviceMessage] = useState(false);
  const autoConsumption = energy.consumptionCurrent.totalAutoConsumption;
  const consumption = energy.consumptionCurrent.totalConsumption;
  const year = parseInt(date.now().split('/')[2], 10) - 1;
  const { getcsvhourly } = useStoreActions((actions) => actions.contract);

  const activePeriod = date.isBetween(period[0], period[1]);

  useEffect(() => {
    (async () => {
      const consumptionParams: ConsumptionParams = {
        cup: cups,
        startDate: `${year}-01-01${TIME}`,
        endDate: `${year}-12-31${TIME}`,
      };
      await getPrevYearConsumption(consumptionParams);
    })();
  }, [energy]);

  useEffect(() => {
    if (autoConsumption < consumption / 2) {
      setHasOkMessage(true);
    } else {
      setHasAdviceMessage(true);
    }
  }, [energy]);

  const dates = date.getDaysBetweenDates(period[0], period[1]);
  const stackedData = dates.map((item) => ({
    name: item,
    ph: 0,
    vh: 0,
    fh: 0,
    lm: 0,
    ly: 0,
    cu: 0,
    au: 0,
  }));

  const groupedYearData = [
    {
      name: `${year}-01`,
      energy: 0,
      autoconsumption: 0,
    },
    {
      name: `${year}-02`,
      energy: 0,
      autoconsumption: 0,
    },
    {
      name: `${year}-03`,
      energy: 0,
      autoconsumption: 0,
    },
    {
      name: `${year}-04`,
      energy: 0,
      autoconsumption: 0,
    },
    {
      name: `${year}-05`,
      energy: 0,
      autoconsumption: 0,
    },
    {
      name: `${year}-06`,
      energy: 0,
      autoconsumption: 0,
    },
    {
      name: `${year}-07`,
      energy: 0,
      autoconsumption: 0,
    },
    {
      name: `${year}-08`,
      energy: 0,
      autoconsumption: 0,
    },
    {
      name: `${year}-09`,
      energy: 0,
      autoconsumption: 0,
    },
    {
      name: `${year}-10`,
      energy: 0,
      autoconsumption: 0,
    },
    {
      name: `${year}-11`,
      energy: 0,
      autoconsumption: 0,
    },
    {
      name: `${year}-12`,
      energy: 0,
      autoconsumption: 0,
    },
  ];

  prevYearEnergy.consumptionCurrent.consumptionDaysComplete.forEach(
    (item: Any) => {
      const month = item.date.substring(0, 7);
      const index = groupedYearData.findIndex((obj) => obj.name === `${month}`);
      if (index > -1) groupedYearData[index].energy += item.consumption;
      if (index > -1) groupedYearData[index].autoconsumption += item.autoConsumption;
    },
  );

  const assignConsumptionType = (
    { consumptionType, consumption: c }: ConsumptionType,
    index: number,
  ) => {
    switch (consumptionType) {
      case 'Valle':
        stackedData[index].vh = c;
        break;
      case 'Punta':
        stackedData[index].ph = c;
        break;
      case 'Llano':
        stackedData[index].fh = c;
        break;
      default:
        break;
    }
  };

  energy.consumptionLastYear.consumptionDays.forEach((item: Any) => {
    const day = helpers.dateFromDateISOString(item.date);
    const index = stackedData.findIndex((obj) => obj.name === `${day}`);
    if (index > -1) stackedData[index].ly = item.consumption;
  });

  energy.consumptionLastMonth.consumptionDays.forEach((item: Any) => {
    const day = helpers.dateFromDateISOString(item.date);
    const index = stackedData.findIndex((obj) => obj.name === `${day}`);
    if (index > -1) stackedData[index].lm = item.consumption;
  });

  energy.consumptionCurrent.consumptionDaysComplete.forEach((item: Any) => {
    const day = helpers.dateFromDateISOString(item.date);
    const index = stackedData.findIndex((obj) => obj.name === `${day}`);
    if (index > -1) {
      assignConsumptionType(item, index);
      stackedData[index].au = item.autoConsumption;
      stackedData[index].cu = item.consumption;
    }
  });

  energy.consumptionCurrent.consumptionByTypes = helpers.sortTypeHourData(
    energy,
  );

  const [consumptionByTypes, setConsumptionByTypes] = useState(
    energy.consumptionCurrent.consumptionByTypes,
  );

  async function downloadConsumption() {
    const consumptionParams: ConsumptionParams = {
      cup: cups,
      startDate: date.format(period[0], 'YYYY-MM-DD', 'DD/MM/YYYY'),
      endDate: date.format(period[1], 'YYYY-MM-DD', 'DD/MM/YYYY'),
    };

    const res = await getcsvhourly(consumptionParams);

    if (res.status === 200) {
      const link = document.createElement('a');
      link.download = 'documento-autoconsumo.csv';
      link.href = `data:text/csv;charset=utf-8,${encodeURIComponent(res.data)}`;
      link.click();
    }
  }

  useEffect(() => {
    if (activeIndex[0] === -1) {
      setConsumptionByTypes(energy.consumptionCurrent.consumptionByTypes);
    } else {
      const item = stackedData[activeIndex[0] + activeIndex[1]];
      const totalDay = item.ph + item.vh + item.fh;

      const filteredConsumptionByTypes = [
        {
          typeHour: 'Hr. Punta - €€€',
          typeId: 0,
          typeText: '10-14 / 18-22',
          consumption: `${item.ph}kWh`,
          porcentage: `${(item.ph / totalDay) * 100}`,
        },
        {
          typeHour: 'Hr. Valle - €€',
          typeId: 0,
          typeText: '0-8 / Fin de semana / Festivo nacional',
          consumption: `${item.vh}kWh`,
          porcentage: `${(item.vh / totalDay) * 100}`,
        },
        {
          typeHour: 'Hr. Llano - €',
          typeId: 0,
          typeText: '8-10 / 14-18 / 22-0',
          consumption: `${item.fh}kWh`,
          porcentage: `${(item.fh / totalDay) * 100}`,
        },
      ];

      setConsumptionByTypes(filteredConsumptionByTypes);
    }
  }, [activeIndex]);

  const CustomBarChart: FunctionComponent<CustomBarProps> = (
    // eslint-disable-next-line
    { data, activeIndex, setActiveIndex }: CustomBarProps
  ) => {
    const allData = data;
    const [shownData, setShownData] = useState(
      data.slice(activeIndex[1], activeIndex[2]),
    );

    const handleClick = (dataClicked: Data, index: number) => {
      if (activeIndex[0] === index) {
        setActiveIndex([-1, activeIndex[1], activeIndex[2]]);
      } else {
        setActiveIndex([index, activeIndex[1], activeIndex[2]]);
      }
    };

    const isDisabled = (completedData: Any, visibleData: Any) => {
      const prevDisabled = _.isEqual(
        completedData[0].name,
        visibleData[0].name,
      );
      const nextDisabled = _.isEqual(
        completedData[completedData.length - 1],
        visibleData[visibleData.length - 1],
      );

      return [prevDisabled, nextDisabled];
    };

    // @ts-ignore
    const onMoveRight = () => {
      let window = 10;

      if (activeIndex[0] !== -1) {
        setActiveIndex([-1, activeIndex[1], activeIndex[2]]);
      } else {
        // @ts-ignore
        let startIndex = Math.floor((shownData.length - 1) / 2) + activeIndex[1];

        const startItem = allData[startIndex];

        startIndex = allData.findIndex((obj) => _.isEqual(obj, startItem));

        const delta = allData.length - startIndex;
        window = delta < window ? delta : 10;

        setActiveIndex([activeIndex[0], startIndex, startIndex + window]);
        setShownData(allData.slice(startIndex, startIndex + window));
      }
    };

    const onMoveLeft = () => {
      const window = 10;

      if (activeIndex[0] !== -1) {
        setActiveIndex([-1, activeIndex[1], activeIndex[2]]);
      } else {
        // @ts-ignore
        let lastIndex = Math.floor((shownData.length - 1) / 2) + activeIndex[2];
        const lastItem = shownData[lastIndex];
        lastIndex = allData.findIndex((obj) => _.isEqual(obj, lastItem));
        lastIndex = lastIndex < window ? window : lastIndex;
        setActiveIndex([activeIndex[0], lastIndex - window, lastIndex]);

        setShownData(allData.slice(lastIndex - window, lastIndex));
      }
    };

    const [prevDisabled, nextDisabled] = isDisabled(allData, shownData);

    return (
      <div className="relative w-auto lg:ml-8 pb-152 lg:pb-144 isolate">
        <button
          onClick={() => onMoveLeft()}
          type="button"
          disabled={prevDisabled}
          className="absolute left-0 z-50 ml-4 top-1/2"
        >
          <ArrowLeft color={prevDisabled ? 'grey' : 'blue'} />
        </button>
        <ResponsiveContainer width="100%" className="absolute inset-0">
          <BarChart
            margin={{
              top: 55,
              right: 15,
              left: 15,
              bottom: 20,
            }}
            data={shownData}
          >
            <XAxis
              xAxisId={0}
              axisLine={false}
              tickLine={false}
              tick={{ fill: '#A6A6A6' }}
              tickFormatter={helpers.parseLabelXWeek}
              dataKey="name"
              dy={10}
              interval={0}
            />
            <XAxis
              xAxisId={1}
              axisLine={false}
              tickLine={false}
              tick={{ fill: '#A6A6A6' }}
              tickFormatter={helpers.parseLabelXDay}
              dataKey="name"
              dy={0}
              interval={0}
            />
            <YAxis
              axisLine={false}
              tickLine={false}
              tick={{ fill: '#A6A6A6' }}
              // eslint-disable-next-line
              tickFormatter={helpers.parseLabelY}
            >
              <Label
                position="insideTopRight"
                fill="#A6A6A6"
                offset={-37}
                fontSize={15}
                fontWeight="500"
                style={{ textAnchor: 'end' }}
              >
                kWh/Días
              </Label>
            </YAxis>
            <Tooltip content={<BarChartTooltip />} cursor={false} />
            <Bar
              dataKey="ph"
              stackId="a"
              onClick={handleClick}
              radius={[20, 20, 20, 20]}
              barSize={7}
            >
              {data.map((entry, index) => (
                <Cell
                  cursor="pointer"
                  fill={
                    activeIndex[0] === -1
                    || (activeIndex[0] > -1 && index === activeIndex[0])
                      ? '#F9AC39'
                      : '#D4D4D4'
                  }
                  // eslint-disable-next-line
                  key={`cell-${index}`}
                />
              ))}
            </Bar>
            <Bar
              dataKey="vh"
              stackId="a"
              onClick={handleClick}
              radius={[20, 20, 20, 20]}
            >
              {data.map((entry, index) => (
                <Cell
                  cursor="pointer"
                  fill={
                    activeIndex[0] === -1
                    || (activeIndex[0] > -1 && index === activeIndex[0])
                      ? '#FFDA00'
                      : '#D4D4D4'
                  }
                  // eslint-disable-next-line
                  key={`cell-${index}`}
                />
              ))}
            </Bar>
            <Bar
              dataKey="fh"
              stackId="a"
              onClick={handleClick}
              radius={[20, 20, 20, 20]}
            >
              {data.map((entry, index) => (
                <Cell
                  cursor="pointer"
                  fill={
                    activeIndex[0] === -1
                    || (activeIndex[0] > -1 && index === activeIndex[0])
                      ? '#FBD59D'
                      : '#D4D4D4'
                  }
                  // eslint-disable-next-line
                  key={`cell-${index}`}
                />
              ))}
            </Bar>
            <Bar
              dataKey="au"
              onClick={handleClick}
              radius={[20, 20, 20, 20]}
              barSize={7}
            >
              {data.map((entry, index) => (
                <Cell
                  cursor="pointer"
                  fill={
                    activeIndex[0] === -1
                    || (activeIndex[0] > -1 && index === activeIndex[0])
                      ? '#000000'
                      : '#D4D4D4'
                  }
                  // eslint-disable-next-line
                  key={`cell-${index}`}
                />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
        <button
          onClick={() => onMoveRight()}
          type="button"
          disabled={nextDisabled}
          className="absolute right-0 mr-4 top-1/2"
        >
          <ArrowRight color={nextDisabled ? 'grey' : 'blue'} />
        </button>
      </div>
    );
  };

  const StatCardComponent: FunctionComponent = () => (
    <div className="bg-white rounded-sm">
      <div className="flex justify-center gap-18 lg:justify-start">
        <div className="flex lg:w-1/2 lg:ml-18">
          <div className="sm:mr-16 lg:mr-24">
            <div className="mt-12 lg:font-semibold lg:text-3xl">
              <h1 className="text-xs font-medium lg:font-semibold">
                Consumo de la red
              </h1>
              <h1 className="text-base lg:mt-1">
                <span className="text-2xl lg:text-3xl">
                  {helpers.roundStatNumber(
                    energy.consumptionCurrent.totalConsumption,
                  )}
                </span>
                kWh
              </h1>
            </div>
            <div className="flex flex-col justify-start mt-4 text-xs lg:hidden">
              <p className="text-xs text-gray-400">Mensual previsto</p>
              <p className="text-xs text-gray-400">
                {helpers.roundStatNumber(
                  energy.consumptionCurrent.totalConsumptionProvided,
                )}
                {' '}
                kWh
              </p>
            </div>
          </div>
          <div className="hidden sm:mr-16 lg:block">
            <div className="mt-12 lg:font-semibold lg:text-3xl lg:text-gray-400">
              <h1 className="text-xs font-medium lg:font-semibold">
                Mensual previsto
              </h1>
              <h1 className="text-base lg:mt-1">
                <span className="text-2xl lg:text-3xl">
                  {helpers.roundStatNumber(
                    energy.consumptionCurrent.totalConsumptionProvided,
                  )}
                </span>
                kWh
              </h1>
            </div>
          </div>
        </div>
        <div className="flex">
          <div className="sm:mr-16 lg:mr-24">
            <div className="mt-12 lg:font-semibold lg:text-3xl">
              <h1 className="text-xs font-medium lg:font-semibold">
                Vertido a la red
              </h1>
              <h1 className="text-base lg:mt-1">
                <span className="text-2xl lg:text-3xl">
                  {helpers.roundStatNumber(
                    energy.consumptionCurrent.totalAutoConsumption,
                  )}
                </span>
                kWh
              </h1>
            </div>
            <div className="flex flex-col justify-start mt-4 text-xs lg:hidden">
              <p className="text-xs text-gray-400">Mensual previsto</p>
              <p className="text-xs text-gray-400">
                {helpers.roundStatNumber(
                  energy.consumptionCurrent.totalAutoConsumptionProvided,
                )}
                {' '}
                kWh
              </p>
            </div>
          </div>
          <div className="hidden sm:mr-16 lg:block lg:text-gray-400">
            <div className="mt-12 lg:font-semibold lg:text-3xl">
              <h1 className="text-xs font-medium lg:font-semibold">
                Mensual previsto
              </h1>
              <h1 className="text-base lg:mt-1">
                <span className="text-2xl lg:text-3xl">
                  {helpers.roundStatNumber(
                    energy.consumptionCurrent.totalAutoConsumptionProvided,
                  )}
                </span>
                kWh
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden h-1 mt-16 mb-16 bg-gray-300 lg:block ml-18 mr-18" />
      <div className="flex flex-col mt-18 lg:flex-row">
        <div className="lg:w-1/2">
          <PieArea consumptionByTypes={consumptionByTypes} />
        </div>
        <div className="h-1 mt-16 mb-16 ml-10 mr-10 bg-gray-300 lg:hidden" />
        <div className="flex flex-col lg:w-1/2">
          <CustomBarChart
            data={stackedData}
            activeIndex={[activeIndex[0], activeIndex[1], activeIndex[2]]}
            setActiveIndex={setActiveIndex}
          />
          <div className="flex mt-2 ml-36 mb-14">
            <div className="z-20 flex w-6 h-6 mt-2 rounded bg-orange-lighter min-h-6 min-w-6" />
            <div className="z-10 flex w-6 h-6 mt-2 -ml-2 rounded bg-orange-light min-h-6 min-w-6" />
            <div className="z-0 flex w-6 h-6 mt-2 mr-4 -ml-2 rounded bg-yellow min-h-6 min-w-6" />
            <div className="mr-12 text-sm">Energía consumida</div>
            <div className="flex w-6 h-6 mt-2 mr-4 bg-black rounded min-h-6 min-w-6" />
            <div className="flex flex-col">
              <div className="mr-1 text-sm">Energía vertida</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const [isOpen, setIsOpen] = useState(false);
  const toggleModal = (): void => setIsOpen(!isOpen);

  return (
    <>
      {hasOkMessage && (
        <OkText
          text="¡Estás obteniendo un buen rendimiento de tu instalación!"
          description="Sigue leyendo para saber más"
        />
      )}
      {hasAdviceMessage && (
        <WarningText
          text="Podrías sacar un mayor rendimiento de la energía que produces"
          description="Sigue leyendo para saber más"
        />
      )}

      <div className="lg:border-b lg:border-gray-400">
        <Title tag="h2" size="small" className="mb-12">
          {t('consumption.energy.detalle')}
        </Title>
        {isLoading && (
          <Title tag="h4" size="small" className="mb-8 text-center">
            {t('consumption.loading')}
          </Title>
        )}
        {!isLoading && (
          <>
            <div className="mb-32 min-h-200">
              <StatCardComponent />
              <div className="flex flex-col justify-between mt-10 lg:flex-row">
                {activePeriod && (
                  <div className="mb-14">
                    <ActionButton
                      type="link"
                      label="¿Por qué no puedo ver el consumo de ciertos días?"
                      onClick={toggleModal}
                      className="text-left"
                    />
                    <Modal isOpen={isOpen} toggleOpen={toggleModal}>
                      <p className="mb-8 btn">Consumo</p>
                      <Bodycopy className="mb-4">
                        Durante el mes en curso, hay días en que puede ser que
                        no aparezca tu lectura real ya que no podemos acceder al
                        contador telemáticamente.
                      </Bodycopy>
                    </Modal>
                  </div>
                )}
                <div>
                  <ActionButton
                    type="link"
                    label="Descargar consumo"
                    icon={<Icons.Document color="blue" />}
                    onClick={() => downloadConsumption()}
                    className="text-right"
                  />
                </div>
              </div>
            </div>
            <Title tag="h2" size="small" className="mb-12">
              Análisis de tu consumo y vertido de energía
            </Title>
            <div className="mb-40 min-h-200">
              <p className="w-3/4">Balance en el último año</p>
              <div className="w-full mt-14">
                <BarChartPrevYear data={groupedYearData} />
                <BarLegendPrevYear
                  powerConsumed={
                    prevYearEnergy.consumptionCurrent.totalConsumption
                  }
                  powerProvided={
                    prevYearEnergy.consumptionCurrent.totalAutoConsumption
                  }
                />
              </div>
              {hasAdviceMessage && (
                <div className="mt-20">
                  <WarningMessage text={t('consumption.energy.warning_auto')} />
                </div>
              )}
              {hasOkMessage && (
                <div className="mt-20">
                  <OkMessage text={t('consumption.energy.okay_auto')} />
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default DistributionNetwork;
