/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import _ from 'lodash/fp';

const NIF_REGEX = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKE]$/i;
const NIE_REGEX = /^[XYZ][0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKE]$/i;
const CIF_REGEX = /^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/;
const CUPS_REGEX = /^ES[0-9]{16}[a-zA-Z]{2}[0-9]{0,1}[a-zA-Z]{0,1}$/;
const MB = 1024 * 1024;
const SUPPORTED_FORMATS = ['application/pdf'];
const SUPPORTED_ID_FORMATS = [
  'application/pdf',
  'image/jpeg',
  'image/png',
  'image/svg+xml',
  'image/tiff',
  'image/webp',
];

const SUPPORTED_AUTH_FORMATS = [
  'application/pdf',
  'image/jpeg',
  'image/png',
  'image/svg+xml',
  'image/tiff',
  'image/webp',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];

const LOCATION_LENGHT = 20;
const KW = 100;
const TENSION = 1;
const SC_NO = 14;
const SC_KW = 15;
const SC_TENSION = 1;
const SC_URBAN = 3;

interface Validations {
  validateIdentity: (value: string) => boolean;
  documentSize: (size: number, max: number) => boolean;
  isFormatSupported: (type: string) => boolean;
  isFormatIDSupported: (type: string) => boolean;
  isFormatAuthSupported: (type: string) => boolean;
  validateLocation: (location: string) => boolean;
  lowPower: (powerInstalation: number, tension: number) => boolean;
  power: (powerInstalation: number) => boolean;
  notFraud: (subject: string) => boolean;
  address: (subject: string) => boolean;
  notFraudOrIncidence: (subject: string) => boolean;
  isCupsFormatSupported: (subject: string) => boolean;
  isCau: (
    networked: number,
    powerInstalation: number,
    tension: number,
    terrain: number
  ) => boolean;
}

const validateIdentity = (_value: string): boolean => (
  true // (NIF_REGEX.test(value) || NIE_REGEX.test(value) || CIF_REGEX.test(value))
);

const documentSize = (size: number, max: number): boolean => size <= max * MB;

const isFormatSupported = (type: string): boolean => SUPPORTED_FORMATS.includes(type);

const isFormatIDSupported = (type: string): boolean => SUPPORTED_ID_FORMATS.includes(type);

const isFormatAuthSupported = (type: string): boolean => SUPPORTED_AUTH_FORMATS.includes(type);

const isCupsFormatSupported = (type: string): boolean => CUPS_REGEX.test(type);

const validateLocation = (location: string): boolean => {
  const positionWeight = [13, 15, 12, 5, 4, 17, 9, 21, 3, 7, 1];
  const letterDC = 'MQWERTYUIOPASDFGHJKLBZX';

  if (_.lt(_.size(location), LOCATION_LENGHT)) return false;

  const cadastralreference = location.toUpperCase();
  const firstDC = (
    cadastralreference.substring(0, 7) + cadastralreference.substring(14, 18)
  ).toUpperCase();
  const secondDC = (
    cadastralreference.substring(7, 14) + cadastralreference.substring(14, 18)
  ).toUpperCase();
  const chainsDC = [firstDC, secondDC];
  let calculatedDC = '';

  chainsDC.forEach((chain) => {
    let digitaddition = 0;

    chain.split('').forEach((char, position) => {
      let charValue = char;

      if (char >= 'A' && char <= 'N') {
        // @ts-ignore
        charValue = char.charCodeAt(0) - 64;
      } else if (char === 'Ñ') {
        // @ts-ignore
        charValue = 15;
      } else if (char > 'N') {
        // @ts-ignore
        charValue = char.charCodeAt(0) - 63;
      }
      // @ts-ignore
      digitaddition = (digitaddition + charValue * positionWeight[position]) % 23;
    });

    calculatedDC += letterDC.charAt(digitaddition);
  });

  if (calculatedDC !== cadastralreference.substring(18, 20)) return false;

  return true;
};

const lowPower = (powerInstalation: number, tension: number): boolean => (
  powerInstalation <= KW && tension >= TENSION
);

const power = (powerInstalation: number): boolean => powerInstalation >= 5000;

const isCau = (
  networked: number,
  powerInstalation: number,
  tension: number,
  terrain: number,
): boolean => (
  _.isEqual(networked, SC_NO) || (
    powerInstalation < SC_KW
    && powerInstalation > 0
    && tension < SC_TENSION
    && tension > 0 && _.isEqual(SC_URBAN, terrain)
  )
);

const notFraud = (subject: string): boolean => (
  !_.isEqual(subject, 'Notificar un fraude')
);

const notFraudOrIncidence = (subject: string): boolean => (
  !_.isEqual(subject, 'Notificar un fraude') && !_.isEqual(subject, 'Incidencias en la red o situación peligrosa')
);

const address = (subject: string): boolean => (
  _.isEqual(subject, 'Notificar un fraude')
  || _.isEqual(subject, 'Incidencias en la red o situación peligrosa')
);

const validations: Validations = {
  validateIdentity,
  validateLocation,
  documentSize,
  isFormatIDSupported,
  isFormatSupported,
  isCupsFormatSupported,
  lowPower,
  power,
  isCau,
  isFormatAuthSupported,
  notFraudOrIncidence,
  notFraud,
  address,
};

export default validations;
