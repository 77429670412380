import _ from 'lodash/fp';
import { FormikErrors, FormikTouched } from 'formik';

import { TouchedDocs } from '../store/model';
import { AuthorizedForm } from '../store/model/authorized/types';

import { UserForm } from './initial-values';

const COMPLETE_STEP = 1;

interface Control {
  permissions: boolean;
  data: boolean;
  supply: boolean;
  access: boolean;
  idCard: boolean;
}

interface Authorized {
  suply: boolean;
  idCard: boolean;
  authorization: boolean;
}

const PERMISSIONS = ['userTypeId'];

const PERSONAL_DATA = ['name', 'nif', 'movil'];

const SUPPLY_POINT = ['address', 'province', 'pc'];

const ACCESS_DATA = ['email', 'repeatEmail', 'password', 'repeatPassword'];

const ID_CARD = ['idFront', 'idBack'];

const AUTH_SUPPLY = ['entitleUserNif', 'cups'];

const AUTH_DOC = ['authorization'];

const isComplete = _.flow(_.valuesIn, _.compact, _.size);

const hasError = _.flow(
  _.compact,
  _.size,
  // @ts-ignore
  _.gt(_, 0),
);

const permissionsComplete = _.flow(
  _.pick(PERMISSIONS),
  isComplete,
  // @ts-ignore
  _.divide(_, _.size(PERMISSIONS)),
  _.isEqual(COMPLETE_STEP),
);

const dataComplete = _.flow(
  _.pick(PERSONAL_DATA),
  isComplete,
  // @ts-ignore
  _.divide(_, _.size(PERSONAL_DATA)),
  _.isEqual(COMPLETE_STEP),
);

const supplyComplete = _.flow(
  _.pick(SUPPLY_POINT),
  isComplete,
  // @ts-ignore
  _.divide(_, _.size(SUPPLY_POINT)),
  _.isEqual(COMPLETE_STEP),
);

const accessComplete = _.flow(
  _.pick(ACCESS_DATA),
  isComplete,
  // @ts-ignore
  _.divide(_, _.size(ACCESS_DATA)),
  _.isEqual(COMPLETE_STEP),
);

const idCardComplete = _.flow(
  _.pick(ID_CARD),
  _.valuesIn,
  _.map((file: File) => file.name),
  _.compact,
  _.size,
  // @ts-ignore
  _.divide(_, _.size(ID_CARD)),
  _.isEqual(COMPLETE_STEP),
);

const authSupplyComplete = _.flow(
  _.pick(AUTH_SUPPLY),
  isComplete,
  // @ts-ignore
  _.divide(_, _.size(AUTH_SUPPLY)),
  _.isEqual(COMPLETE_STEP),
);

const authDocComplete = _.flow(
  _.pick(AUTH_DOC),
  _.valuesIn,
  _.map((file: File) => file.name),
  _.compact,
  _.size,
  // @ts-ignore
  _.divide(_, _.size(AUTH_DOC)),
  _.isEqual(COMPLETE_STEP),
);

const isCompleted = (values: UserForm): Control => ({
  permissions: permissionsComplete(values),
  data: dataComplete(values),
  supply: supplyComplete(values),
  access: accessComplete(values),
  idCard: idCardComplete(values),
});

const hasErrors = (
  errors: FormikErrors<UserForm>,
  touched: FormikTouched<UserForm>,
  docs: TouchedDocs,
): Control => ({
  // @ts-ignore
  permissions: hasError([touched.userTypeId && !_.isEmpty(errors.userTypeId)]),
  // @ts-ignore
  data: hasError([
    touched.name && !_.isEmpty(errors.name),
    touched.nif && !_.isEmpty(errors.nif),
    touched.movil && !_.isEmpty(errors.movil),
  ]),
  // @ts-ignore
  supply: hasError([
    touched.address && !_.isEmpty(errors.address),
    touched.province && !_.isEmpty(errors.province),
    touched.pc && !_.isEmpty(errors.pc),
  ]),
  // @ts-ignore
  access: hasError([
    touched.email && !_.isEmpty(errors.email),
    touched.repeatEmail && !_.isEmpty(errors.repeatEmail),
    touched.password && !_.isEmpty(errors.password),
    touched.repeatPassword && !_.isEmpty(errors.repeatPassword),
  ]),
  // @ts-ignore
  idCard: hasError([
    docs.front && !_.isEmpty(errors.idFront),
    docs.back && !_.isEmpty(errors.idBack),
  ]),
});

const isAuthCompleted = (values: AuthorizedForm): Authorized => ({
  suply: authSupplyComplete(values),
  idCard: idCardComplete(values),
  authorization: authDocComplete(values),
});

const hasAuthErrors = (
  errors: FormikErrors<AuthorizedForm>,
  touched: FormikTouched<AuthorizedForm>,
  docs: TouchedDocs,
): Authorized => ({
  // @ts-ignore
  suply: hasError([
    touched.entitleUserNif && !_.isEmpty(errors.entitleUserNif),
    touched.cups && !_.isEmpty(errors.cups),
  ]),
  // @ts-ignore
  idCard: hasError([
    docs.front && !_.isEmpty(errors.idFront),
    docs.back && !_.isEmpty(errors.idBack),
  ]),
  // @ts-ignore
  authorization: hasError([
    docs.authorization && !_.isEmpty(errors.authorization),
  ]),
});

const helpers = {
  isCompleted,
  hasErrors,
  isAuthCompleted,
  hasAuthErrors,
};

export default helpers;
