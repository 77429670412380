import React, { FunctionComponent } from 'react';
import _ from 'lodash/fp';

interface Props {
  message: string;
}

const InputError: FunctionComponent<Props> = ({ message }: Props) => (
  <>
    {!_.isEmpty(message) && (
    <p className="caption text-red">{message}</p>
    )}
  </>
);

export default InputError;
