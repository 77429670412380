import React, { FunctionComponent } from 'react';
import {
  ResponsiveContainer, LineChart, XAxis, YAxis, Label, Line, Tooltip,
} from 'recharts';

import helpers from '../../services/helpers';
import { Any } from '../../store/model/global';

import LineChartTooltip from './LineChartTooltip';

interface LineChartPrevYearProps {
    data: Any
}

const LineChartPrevYear: FunctionComponent<LineChartPrevYearProps> = (
  { data }: LineChartPrevYearProps,
) => (
  <div className="relative pb-144 rounded-sm bg-white">
    <ResponsiveContainer width="100%" className="absolute inset-0">
      <LineChart
        data={data}
        margin={{
          top: 55,
          right: 20,
          left: 20,
          bottom: 20,
        }}
      >
        <XAxis
          xAxisId={0}
          dataKey="name"
          axisLine={false}
          tickLine={false}
          tickFormatter={helpers.parseLabelXWeek}
          tick={{ fill: '#A6A6A6' }}
          dy={10}
          interval={2}
        />
        <XAxis
          xAxisId={1}
          axisLine={false}
          tickLine={false}
          tick={{ fill: '#A6A6A6' }}
          tickFormatter={helpers.parseLabelXDay}
          dataKey="name"
          dy={0}
          interval={2}
        />
        <YAxis
          axisLine={false}
          tickLine={false}
            // eslint-disable-next-line
            tickFormatter={helpers.parseLabelY}
          tick={{ fill: '#A6A6A6' }}
        >
          <Label position="insideTopRight" fill="#A6A6A6" offset={-37} fontSize={15} fontWeight="500" style={{ textAnchor: 'end' }}>kWh/Días</Label>
        </YAxis>
        <Tooltip content={<LineChartTooltip />} cursor={false} />
        <Line
          type="monotone"
          dataKey="ly"
          stroke="#000000"
          dot={{ stroke: '#000000', strokeWidth: 1 }}
          activeDot={{ stroke: '#000000', strokeWidth: 1 }}
          strokeWidth={3}
        />
        <Line
          type="monotone"
          dataKey="cu"
          stroke="#FFDA00"
          dot={{ stroke: '#FFDA00', strokeWidth: 1 }}
          activeDot={{ stroke: '#FFDA00', strokeWidth: 1 }}
          strokeWidth={3}
        />
      </LineChart>
    </ResponsiveContainer>
  </div>
);
export default LineChartPrevYear;
