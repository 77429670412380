import { routes } from '../../services/routing';
import t from '../../text-strings';

export type LinkProps = {
  to: string;
  label: string;
}

export type NavLinkProps = LinkProps & {
  subLinks?: LinkProps[];
}

const navLinks: NavLinkProps[] = [
  {
    to: routes.landing,
    label: t('common.navigation.home'),
  },
  {
    to: routes.breakdowns,
    label: t('common.navigation.averias_cortes'),
  },
  {
    to: routes.supply.index,
    label: t('common.navigation.conexion_red'),
    subLinks: [
      {
        to: routes.supply.index,
        label: t('common.navigation.suministro_electrico'),
      },
      {
        to: routes.selfConsumption.index,
        label: t('common.navigation.autoconsumo'),
      },
      {
        to: routes.generation,
        label: t('common.navigation.generacion_energia'),
      },
      {
        to: routes.nodalCapacities,
        label: t('common.navigation.capacidades_nodales'),
      },
    ],
  },
  {
    to: routes.landing,
    label: t('common.navigation.ayuda'),
    subLinks: [
      {
        to: routes.contact,
        label: t('common.navigation.contacto'),
      },
      {
        to: routes.normative,
        label: t('common.navigation.normativa'),
      },
    ],
  },
];

export default navLinks;
