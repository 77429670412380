import React, { FunctionComponent } from 'react';

import Icons from '../../../assets/icons';
import LinkCard from '../../../components/cards/LinkCard';
import Wrap from '../../../components/containers/Wrap';
import InternalLink from '../../../components/InternalLink';
import Title from '../../../components/text-components/Title';
import AuthenticatedLayout from '../../../layout/AuthenticatedLayout';
import { routes } from '../../../services/routing';
import t from '../../../text-strings';

import RequestHistory from './components/RequestHistory';

const SupplyLinks: FunctionComponent = () => (
  <div>
    <LinkCard
      label={t('requests.supply_point_request')}
      to={routes.requests.supply}
      icon={<Icons.Bolt color="white" />}
      className="mb-8"
    />
    <LinkCard
      label={t('requests.cups_request')}
      to={routes.requests.cups}
      icon={<Icons.AddDocument color="white" />}
      className="mb-12"
    />
    <InternalLink
      to={routes.supply.index}
      label={t('requests.saber_mas')}
      icon={<Icons.Document color="blue" />}
      className="text-blue"
    />
  </div>
);

const SelfLinks: FunctionComponent = () => (
  <div>
    <LinkCard
      label={t('requests.self_consumption_request')}
      to={routes.requests.selfconsumption}
      icon={<Icons.Bolt color="white" />}
      className="mb-8"
    />
    <LinkCard
      label={t('requests.cau_request')}
      to={routes.requests.cau}
      icon={<Icons.AddDocument color="white" />}
      className="mb-12"
    />
    <InternalLink
      to={routes.selfConsumption.process}
      label={t('requests.saber_mas')}
      icon={<Icons.Document color="blue" />}
      className="text-blue"
    />
  </div>
);

const Requests: FunctionComponent = () => (
  <AuthenticatedLayout title={t('common.navigation.mis_solicitudes')}>
    <RequestHistory />
    <Wrap size="wrap-authenticated">
      <div className="flex flex-col mb-20 lg:flex-row lg:justify-between">
        <div className="pr-1 mb-20 lg:w-9/20 lg:mb-0">
          <Title tag="h3" className="mb-12">
            {t('requests.supply')}
          </Title>
          <SupplyLinks />
        </div>
        <div className="pl-1 lg:w-9/20">
          <Title tag="h3" className="mb-12">
            {t('requests.self_consumption')}
          </Title>
          <SelfLinks />
        </div>
      </div>
      <div className="flex flex-col mb-20 lg:flex-row lg:justify-between">
        <div className="mb-20 lg:w-9/20 lg:pr-1 lg:mb-0 lg:flex lg:flex-col lg:justify-between">
          <Title tag="h3" className="mb-12">
            {t('requests.generacion')}
          </Title>
          <LinkCard
            label={t('requests.generation_request')}
            to={routes.requests.generation}
            icon={<Icons.Bolt color="white" />}
          />
        </div>
        <div className="lg:w-9/20 lg:pl-1 lg:flex lg:flex-col lg:justify-between">
          <Title tag="h3" className="mb-12">
            {t('requests.nodales')}
          </Title>
          <LinkCard
            label={t('requests.access_nodales')}
            to={routes.nodalCapacities}
            icon={<Icons.Settings color="white" />}
          />
        </div>
      </div>
    </Wrap>
  </AuthenticatedLayout>
);

export default Requests;
