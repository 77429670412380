import { StoreProvider } from 'easy-peasy';
import _ from 'lodash/fp';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import Routing from './pages/Routing';
import buildStore from './store';
import { useStoreActions, useStoreState } from './store/hooks';

const store = buildStore();

const Session: FunctionComponent = () => {
  const { session } = useStoreState((state) => state.session);
  const { restore } = useStoreActions((actions) => actions.session);
  const { getMunicipalities } = useStoreActions((actions) => actions.distributors);
  const { municipalities } = useStoreState((state) => state.distributors);
  const {
    index,
    getstatecontracts,
    getstreettypes,
  } = useStoreActions((actions) => actions.contract);
  const [isLoading, setIsLoading] = useState(true);
  const token: string = session.get('token');

  useEffect(() => {
    (async () => {
      await getMunicipalities();
      if (_.isEmpty(token)) restore();
      if (_.isEmpty(municipalities)) await getMunicipalities();
      if (!_.isEmpty(token)) {
        await index(session.get('nif'));
        await getstatecontracts({ nif: session.get('nif') });
      }
      await getstreettypes();
      setIsLoading(false);
    })();
  }, [token]);

  if (isLoading) return null;

  return (
    <Routing />
  );
};

const App: FunctionComponent = () => (
  <StoreProvider store={store}>
    <Router>
      <Session />
    </Router>
  </StoreProvider>
);

export default App;
