import _ from 'lodash/fp';

import { Any } from '../store/model/global';

import date from './date';

const percentageToNumber = (percentage: string): number => (percentage ? Number(percentage.replace('%', '').replace(',', '.')) : 0);

const roundNumber = (number: number, decimals: number) : number => {
  const rounder = 10 ** decimals;
  return Math.round((number + Number.EPSILON) * rounder) / rounder;
};

const cleanPercentage = (percentage = '', decimals = 0): string => {
  let cleanNumber = parseFloat(percentage.replace('%', '').replace(',', '.'));
  cleanNumber = cleanNumber > 0 ? cleanNumber : 0;
  cleanNumber = roundNumber(cleanNumber, decimals);

  return `${cleanNumber}%`;
};

const roundPieEnery = (data: string): string => {
  if (!data) return data;

  const units = data.slice(-3);
  const value = data.slice(0, -3);
  const numberedValue = parseFloat(value.replace(',', '.').replace(' ', ''));

  return `${roundNumber(numberedValue, 1)}${units}`;
};

const parseLabelY = (label:string): string => {
  const numberedLabel = Number(label);

  if (numberedLabel === 0) return label;

  if (numberedLabel < 1) return `${roundNumber(numberedLabel, 1)}`;

  return `${roundNumber(numberedLabel, 0)}`;
};

const parseDateTooltip = (label:string): string => {
  const dateFormatted = date.format(label, 'DD MMMM', 'DD-MM-YYYY');

  return `${dateFormatted}`;
};

const parseMonthTooltip = (label: string): string => {
  const dateFormatted = date.format(label, 'MMMM YYYY', 'YYYY-MM');

  return dateFormatted;
};

const parseLabelXDay = (label:string): string => {
  const day = date.format(label, 'D', 'DD-MM-YYYY');

  return `${day}`;
};

const parseLabelXMonth = (label:string): string => {
  const month = date.format(label, 'MMM', 'DD-MM-YYYY');
  const formattedMonth = month.charAt(0).toUpperCase();

  return `${formattedMonth}`;
};

const parseLabelXWeek = (label:string): string => {
  const dayOfWeek = date.format(label, 'ddd', 'DD-MM-YYYY');
  const firstChar = dayOfWeek.charAt(0).toUpperCase();

  if (dayOfWeek === 'mié.') return 'X';

  return `${firstChar}`;
};

const toStringDecimal = (value: number, decimal: number): string => value.toFixed(decimal);

const titleString = (word: string): string => (
  word ? word[0].toUpperCase() + word.substr(1).toLowerCase() : word
);

const dayFromDateISOString = (dateInput: string): string|undefined => dateInput.split('-', 3).pop()?.split('T')[0];

const dateFromDateISOString = (dateInput: string): string|undefined => {
  const day = dateInput.split('-', 3).pop()?.split('T')[0];
  const month = dateInput.split('-', 2).pop();
  const year = dateInput.split('-', 1);

  return `${day}-${month}-${year}`;
};

const roundStatNumber = (data: number) : number => {
  if (data < 1) return roundNumber(data, 3);

  return roundNumber(data, 1);
};

const sortTypeHourData = (object: Any): Any => {
  const aux = object.consumptionCurrent.consumptionByTypes;
  return [
    aux.find((item: Any) => item?.typeHour.includes('Punta')),
    aux.find((item: Any) => item?.typeHour.includes('Valle')),
    aux.find((item: Any) => item?.typeHour.includes('Llano')),
  ];
};

const getConsumptionByType = _.flow(
  _.replace('kWh', ''),
  _.replace(',', '.'),
  parseFloat,
);

const helpers = {
  percentageToNumber,
  roundNumber,
  cleanPercentage,
  roundPieEnery,
  parseLabelXDay,
  parseLabelXWeek,
  parseLabelXMonth,
  parseDateTooltip,
  parseMonthTooltip,
  parseLabelY,
  toStringDecimal,
  titleString,
  dayFromDateISOString,
  roundStatNumber,
  sortTypeHourData,
  getConsumptionByType,
  dateFromDateISOString,
};

export default helpers;
