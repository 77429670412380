import { AxiosResponse } from 'axios';

import {
  GenerationRequestParams,
  CauParams,
  LoadRequestParams,
  RequestHistoryParams,
  RollbackRequestParams,
  SelfconsumptionRequestParams,
  SupplyRequestParams,
  CupsRequestParams,
  ChangeRequestParams,
  RollbackChangeStateRequestParams,
  OpenRequests,
} from '../../store/model/requests/types';
import {
  axiosGet, setURL, queryParams, axiosPost,
} from '../axios';

export const getrequesthistory = async (
  params: RequestHistoryParams,
): Promise<AxiosResponse> => axiosGet(setURL('/Request', '/getrequesthistory?', queryParams(params)));

export const getloadrequest = async (
  params: LoadRequestParams,
): Promise<AxiosResponse> => axiosGet(setURL('/Request', '/getloadrequest?', queryParams(params)));

export const getopenrequests = async (
  params: OpenRequests,
): Promise<AxiosResponse> => axiosGet(setURL('/Request', '/getopenrequests?', queryParams(params)));

export const getallrequests = async (
  params: OpenRequests,
): Promise<AxiosResponse> => axiosGet(setURL('/Request', '/getallrequests?', queryParams(params)));

export const getrequestfilter = async (
  params: OpenRequests,
): Promise<AxiosResponse> => axiosGet(setURL('/Request', '/getrequestfilter?', queryParams(params)));

export const createsupplypointrequest = async (
  params: SupplyRequestParams,
): Promise<AxiosResponse> => axiosPost(setURL('/Request', '/createsupplypointrequest'), params);

export const creategenerationrequest = async (
  params: GenerationRequestParams,
): Promise<AxiosResponse> => axiosPost(setURL('/Request', '/creategenerationrequest'), params);

export const createcupsrequest = async (
  params: CupsRequestParams,
): Promise<AxiosResponse> => axiosPost(setURL('/Request', '/createcupsrequest'), params);

export const createcaurequest = async (
  params: CauParams,
): Promise<AxiosResponse> => axiosPost(setURL('/Request', '/createcaurequest'), params);

export const createselfconsumption = async (
  params: SelfconsumptionRequestParams,
): Promise<AxiosResponse> => axiosPost(setURL('/Request', '/createselfconsumption'), params);

export const rollbackcreaterequest = async (
  params: RollbackRequestParams,
): Promise<AxiosResponse> => axiosPost(setURL('/Request', '/rollbackcreaterequest'), params);

export const changestaterequest = async (
  params: ChangeRequestParams,
): Promise<AxiosResponse> => axiosPost(setURL('/Request', '/changestaterequest'), params);

export const rollbackchangestaterequest = async (
  params: RollbackChangeStateRequestParams,
): Promise<AxiosResponse> => axiosPost(setURL('/Request', '/rollbackchangestaterequest'), params);
