import _ from 'lodash/fp';

import common from './common.json';
import landing from './landing.json';
import login from './login.json';
import register from './register.json';
import registered from './registered.json';
import models from './models.json';
import passwords from './passwords.json';
import profile from './profile.json';
import authorized from './authorized.json';
import contract from './contract.json';
import supply from './electrical-supply.json';
import self from './self-consumption.json';
import selfProcess from './self-consumption-process.json';
import selfInfo from './self-consumption-info.json';
import generation from './generation.json';
import nodalCapacities from './nodal-capatities.json';
import consumption from './consumption.json';
import tipsToSave from './tips-to-save.json';
import requests from './requests.json';
import history from './history.json';
import breakdowns from './breakdowns.json';
import contact from './contact.json';
import normative from './normative.json';

const textStrings = {
  common,
  landing,
  login,
  register,
  registered,
  models,
  passwords,
  profile,
  authorized,
  contract,
  supply,
  self,
  selfProcess,
  selfInfo,
  generation,
  nodalCapacities,
  consumption,
  tipsToSave,
  requests,
  history,
  breakdowns,
  contact,
  normative,
};

const t = (textString: string, replacement?: string): string => {
  const text = _.get(textString)(textStrings);
  if (_.isUndefined(replacement)) return text;

  return _.replace('%s', replacement)(text);
};

export default t;
