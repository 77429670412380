import { useFormik } from 'formik';
import React, { ChangeEvent, FunctionComponent } from 'react';
import { useLocation } from 'react-router-dom';

import Title from '../../components/text-components/Title';
import AuthenticationLayout from '../../layout/AuthenticationLayout';
import { routes } from '../../services/routing';
import t from '../../text-strings';
import Form from '../../components/forms/Form';
import TextInput from '../../components/forms/TextInput';
import PasswordInput from '../../components/forms/PasswordInput';
import SubmitButton from '../../components/buttons/SubmitButton';
import CheckboxInput from '../../components/forms/CheckboxInput';
import Bodycopy from '../../components/text-components/Bodycopy';
import InternalLink from '../../components/InternalLink';
import { useStoreActions } from '../../store/hooks';
import FormResponse from '../../components/forms/FormResponse';
import { SessionParams } from '../../store/model/session/types';
import useNavigation from '../../services/navigation';
import formInit from '../../forms/initial-values';
import schemas from '../../forms/schemas';
import useHandleResponse from '../../services/handle-response';
import MobileTopNav from '../../components/MobileTopNav';

const Login: FunctionComponent = () => {
  const { authenticate } = useStoreActions((actions) => actions.session);
  const location = useLocation();
  // @ts-ignore
  const { from } = location.state || { from: { pathname: routes.profile } };
  const [message, handleResponse] = useHandleResponse(from);
  const navigate = useNavigation();

  const handleOnSubmit = async (values: SessionParams): Promise<void> => {
    handleResponse(await authenticate(values));
  };

  const {
    handleSubmit,
    values,
    handleChange,
    handleBlur,
    touched,
    errors,
    isSubmitting,
    isValid,
    setFieldValue,
  } = useFormik({
    initialValues: formInit.login,
    onSubmit: handleOnSubmit,
    validationSchema: schemas.login,
  });

  return (
    <AuthenticationLayout>
      <MobileTopNav onClick={() => navigate(routes.landing)} title={t('login.acceder')} />
      <div className="flex-1 wrap lg:flex mb-28 lg:mb-0">
        <div className="lg:w-7/10">
          <Form onSubmit={handleSubmit} className="">
            <Title tag="h2" className="mb-8 lg:mb-12 lg:mt-12">{t('login.bienvenido')}</Title>
            <TextInput
              name="username"
              value={values.username}
              placeholder={t('login.username')}
              onChange={handleChange('username')}
              onBlur={handleBlur('username')}
              touched={touched.username}
              errors={errors.username}
              containerClassName="mb-1"
            />
            <PasswordInput
              name="password"
              value={values.password}
              placeholder={t('login.password')}
              onChange={handleChange('password')}
              onBlur={handleBlur('password')}
              touched={touched.password}
              errors={errors.password}
              containerClassName="mb-1"
            />
            <CheckboxInput
              name="sessionNoExpired"
              value={values.sessionNoExpired}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setFieldValue('sessionNoExpired', e.target.checked);
              }}
            >
              <Bodycopy tag="span">{t('login.session_no_expired')}</Bodycopy>
            </CheckboxInput>
            <FormResponse message={message} className="mb-12" />
            <SubmitButton
              label={t('login.acceder')}
              disabled={isSubmitting || !isValid}
              fixed
            />
          </Form>
        </div>
        <div className="lg:bg-white lg:w-1/3 relative">
          <div className="bg-white absolute top-0 left-full w-screen-full h-full hidden lg:block" />
          <div className="flex flex-col items-center pt-12 lg:items-start lg:max-w-240 lg:pl-32 lg:pt-80">
            <InternalLink
              to={routes.register}
              label={t('login.no_cuenta')}
              className="mb-8 underline lg:no-underline lg:hover:underline text-blue lg:text-black"
            />
            <InternalLink
              to={routes.password.recovery}
              label={t('login.password_olvidado')}
              className="underline lg:no-underline lg:hover:underline text-blue lg:text-black"
            />
          </div>
        </div>
      </div>
    </AuthenticationLayout>
  );
};

export default Login;
