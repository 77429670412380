import { useFormik } from 'formik';
import React, { FunctionComponent } from 'react';
import _ from 'lodash/fp';

import { useStoreActions } from '../../store/hooks';
import t from '../../text-strings';
import AuthenticationLayout from '../../layout/AuthenticationLayout';
import { routes } from '../../services/routing';
import Form from '../../components/forms/Form';
import FormResponse from '../../components/forms/FormResponse';
import SubmitButton from '../../components/buttons/SubmitButton';
import useQuery from '../../services/query';
import PasswordInput from '../../components/forms/PasswordInput';
import useNavigation from '../../services/navigation';
import formInit, { ChangePasswordForm } from '../../forms/initial-values';
import schemas from '../../forms/schemas';
import { ChangePasswordParams } from '../../store/model/passwords/types';
import useHandleResponse from '../../services/handle-response';
import MobileTopNav from '../../components/MobileTopNav';

const PasswordChange: FunctionComponent = () => {
  const { change } = useStoreActions((actions) => actions.passwords);
  const [message, handleResponse] = useHandleResponse(routes.login);
  const navigate = useNavigation();
  const query = useQuery();
  const token: string = query.get('codigo') as string | '';

  const handleOnSubmit = async (values: ChangePasswordForm): Promise<void> => {
    // @ts-ignore
    const changeParams: ChangePasswordParams = _.omit(['repeatPassword'])(values);
    handleResponse(await change(changeParams));
  };

  const {
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    errors,
    values,
    isSubmitting,
    isValid,
  } = useFormik({
    initialValues: formInit.changePassword(token),
    onSubmit: handleOnSubmit,
    validationSchema: schemas.changePassword,
  });

  return (
    <AuthenticationLayout>
      <MobileTopNav onClick={() => navigate(routes.login)} title={t('passwords.change_title')} />
      <Form onSubmit={handleSubmit} className="wrap-thin pt-12">
        <PasswordInput
          name="password"
          value={values.password}
          placeholder={t('models.user.attributes.password')}
          onChange={handleChange('password')}
          onBlur={handleBlur('password')}
          touched={touched.password}
          errors={errors.password}
        />
        <PasswordInput
          name="repeatPassword"
          value={values.repeatPassword}
          placeholder={t('models.user.attributes.repetir_password')}
          onChange={handleChange('repeatPassword')}
          onBlur={handleBlur('repeatPassword')}
          touched={touched.repeatPassword}
          errors={errors.repeatPassword}
        />
        <FormResponse message={message} className="mb-2" />
        <SubmitButton
          label={t('passwords.recovery')}
          disabled={isSubmitting || !isValid}
          fixed
        />
      </Form>
    </AuthenticationLayout>
  );
};

export default PasswordChange;
