import _ from 'lodash';
import React, { FunctionComponent } from 'react';

import date from '../../services/date';
import { BreakdownStates } from '../../store/model/contract/types';
import t from '../../text-strings';
import Tag from '../Tag';
import Caption from '../text-components/Caption';
import Title from '../text-components/Title';

interface Props {
  state: BreakdownStates;
}

const BreakdownCard: FunctionComponent<Props> = ({ state }: Props) => (
  <div className="w-full max-w-144 min-w-144 rounded-sm bg-white p-8 mr-8 lg:mb-8">
    <Title size="small" className="mb-4">{state.alias}</Title>
    {
      state.type === t('breakdowns.corte')
      && <Caption className="mb-4 text-gray-400">{`${t('breakdowns.contacte_comercializadora')} ${state.marketer ?? ''}`}</Caption>
    }
    <Caption className="mb-4 text-gray-400">{state.cups}</Caption>
    <div className="mb-2 flex">
      <Caption className="text-gray-400">
        {
          state.type === t('breakdowns.corte')
            ? `${t('breakdowns.fecha_prevista')} ${date.format(state.startDate, 'ddd D/M')}`
            : date.format(state.startDate, 'ddd D/M, HH:mm')
        }
      </Caption>
      {!_.isNil(state.endDate) && _.isNil(state.acceptEndDate) && (
        <>
          &nbsp;
          {date.isSameDay(state.startDate, state.endDate) && (
            <Caption className="text-gray-400">
              {date.format(state.endDate, '- HH:mm')}
            </Caption>
          )}
        </>
      )}
      {!_.isNil(state.endDate) && !_.isNil(state.acceptEndDate) && (
        <>
        &nbsp;
          {
          date.isSameDay(state.startDate, state.acceptEndDate) && (
            <Caption className="text-gray-400">
              {date.format(state.endDate, '- HH:mm')}
            </Caption>
          )
          }
        </>
      )}
    </div>
    <div className="mb-8">
      {!_.isNil(state.endDate) && !_.isNil(state.acceptEndDate) && (
      <Caption>{t('breakdowns.pagado', date.format(state.endDate, 'ddd D/M'))}</Caption>
      )}
    </div>
    <Tag type="regular" label={state.type} className="" />
  </div>
);

export default BreakdownCard;
