import _ from 'lodash/fp';
import React, {
  FunctionComponent,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import classnames from 'classnames';

import LinkButton from '../components/buttons/LinkButton';

import SidebarTab, { SidebarTabProps } from './tabs/SidebarTab';

interface Props {
  sidebarTabs: SidebarTabProps[];
  children: ReactNode;
  to?: string;
  label?: string;
  icon?: ReactNode;
}

const LG_SCREEN = 1024;

const SidebarLayout: FunctionComponent<Props> = (
  {
    sidebarTabs,
    children,
    to,
    label,
    icon,
  }: Props,
) => {
  const [isFixed, setIsFixed] = useState(false);
  const windowWidth = window.innerWidth;

  const onScrollHeader = (): void => {
    const position = window.scrollY;
    const header = document.getElementById('site-header');
    const headerHeight = header?.offsetHeight || 0;
    if (windowWidth < LG_SCREEN) {
      setIsFixed(!!_.gte(position, headerHeight));
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', onScrollHeader);
    onScrollHeader();

    return () => window.removeEventListener('scroll', onScrollHeader);
  }, []);

  const sidebarClass = classnames('lg:w-1/4 left-0 w-full lg:fixed top-0 lg:top-auto lg:left-auto', {
    'fixed z-30': isFixed,
  });

  const contentClass = classnames('lg:flex lg:wrap lg:py-36 lg:items-start lg:relative lg:justify-between', {
    'mt-28 lg:mt-0': isFixed,
  });

  return (
    <div id="content" className={contentClass}>
      <div id="sidebar" className={sidebarClass}>
        <div className="bg-white w-full max-w-full lg:bg-transparent p-1 lg:p-0 lg:mb-28">
          <div className="overflow-x-visible lg:flex">
            <div className="flex overflow-x-scroll w-full px-1/24 lg:flex-col lg:overflow-hidden lg:bg-white lg:rounded-sm lg:p-16 lg:max-w-128 sm:justify-center lg:items-start">
              {_.map((sidebarTab: SidebarTabProps) => (
                <SidebarTab
                  key={sidebarTab.id}
                  id={sidebarTab.id}
                  to={sidebarTab.to}
                  label={sidebarTab.label}
                  active={sidebarTab.active}
                  last={sidebarTab.last}
                  anchor={sidebarTab.anchor}
                />
              ))(sidebarTabs)}
            </div>
          </div>
        </div>
        {!_.isUndefined(to) && !_.isUndefined(label) && (
          <LinkButton
            to={to}
            label={label}
            type="primary"
            icon={icon}
            fixed
          />
        )}
      </div>
      <div className="flex flex-col lg:w-3/4 lg:items-end lg:ml-auto">
        {children}
      </div>
    </div>
  );
};

export default SidebarLayout;
