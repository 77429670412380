import React, { FunctionComponent } from 'react';

import { AppColors } from '../../styles';

interface Props {
  color?: AppColors;
  width?: number;
  height?: number;
  className?: string;
}

const Instagram: FunctionComponent<Props> = (
  {
    color = 'black',
    width = 12,
    height = 12,
    className = '',
  }: Props,
) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className={`w-${width} h-${height} text-${color} fill-current ${className}`}>
    <path d="M17.3766 0H6.62295C2.97106 0 0 2.9712 0 6.62309V17.3768C0 21.0288 2.97106 23.9999 6.62295 23.9999H17.3766C21.0288 23.9999 23.9999 21.0287 23.9999 17.3768V6.62309C24 2.9712 21.0288 0 17.3766 0ZM21.8706 17.3768C21.8706 19.8547 19.8547 21.8705 17.3768 21.8705H6.62295C4.1452 21.8706 2.12938 19.8547 2.12938 17.3768V6.62309C2.12938 4.14534 4.1452 2.12938 6.62295 2.12938H17.3766C19.8545 2.12938 21.8705 4.14534 21.8705 6.62309V17.3768H21.8706Z" />
    <path d="M11.9998 5.81616C8.58983 5.81616 5.81567 8.59032 5.81567 12.0003C5.81567 15.4102 8.58983 18.1842 11.9998 18.1842C15.4098 18.1842 18.184 15.4102 18.184 12.0003C18.184 8.59032 15.4098 5.81616 11.9998 5.81616ZM11.9998 16.0547C9.76412 16.0547 7.94506 14.2359 7.94506 12.0002C7.94506 9.76432 9.76398 7.9454 11.9998 7.9454C14.2357 7.9454 16.0546 9.76432 16.0546 12.0002C16.0546 14.2359 14.2355 16.0547 11.9998 16.0547Z" />
    <path d="M18.4431 4.0105C18.0329 4.0105 17.6299 4.17659 17.3401 4.46761C17.049 4.7572 16.8816 5.16037 16.8816 5.57205C16.8816 5.98245 17.0491 6.38547 17.3401 6.67649C17.6297 6.96608 18.0329 7.13359 18.4431 7.13359C18.8548 7.13359 19.2566 6.96608 19.5476 6.67649C19.8386 6.38547 20.0047 5.98231 20.0047 5.57205C20.0047 5.16037 19.8386 4.7572 19.5476 4.46761C19.258 4.17659 18.8548 4.0105 18.4431 4.0105Z" />
  </svg>
);

export default Instagram;
