import React, { FunctionComponent } from 'react';

import { AppColors } from '../../styles';

interface Props {
  color?: AppColors;
  width?: number;
  height?: number;
  className?: string;
}

const AngleRight: FunctionComponent<Props> = (
  {
    color = 'black',
    width = 12,
    height = 12,
    className = '',
  }: Props,
) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className={`w-${width} h-${height} text-${color} fill-current ${className}`}>
    <path d="M1.28957 2.11953L5.16957 5.99953L1.28957 9.87953C0.89957 10.2695 0.89957 10.8995 1.28957 11.2895C1.67957 11.6795 2.30957 11.6795 2.69957 11.2895L7.28957 6.69953C7.67957 6.30953 7.67957 5.67953 7.28957 5.28953L2.69957 0.699532C2.30957 0.309532 1.67957 0.309532 1.28957 0.699532C0.90957 1.08953 0.89957 1.72953 1.28957 2.11953Z" />
  </svg>
);

export default AngleRight;
