import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

import images from '../../../assets/images';
import Image from '../../../components/Image';
import { routes } from '../../../services/routing';
import t from '../../../text-strings';

const WebMap: FunctionComponent = () => (
  <div className="flex lg:flex-row-reverse mb-20">
    <div className="w-1/2">
      <Link to={routes.contact} className="btn block lg:text-right mb-4 lg:mb-6">
        {t('common.navigation.contacto')}
      </Link>
      <Link to={routes.normative} className="btn block lg:text-right">
        {t('common.navigation.normativa')}
      </Link>
    </div>
    <div className="w-1/2">
      <Image src={images.logo.black} alt={t('common.logo_alt')} className="h-10 ml-auto lg:ml-0" />
    </div>
  </div>
);

export default WebMap;
