import React, { FunctionComponent } from 'react';
import _ from 'lodash/fp';

import classNames from '../../../../services/classNames';
import Caption from '../../../../components/text-components/Caption';
import t from '../../../../text-strings';

interface Props {
  blockClassNames: string[];
}

const generateKey = (): string => `${Math.random() * 100}`;

const FormStepsNavigation: FunctionComponent<Props> = ({ blockClassNames }: Props) => (
  <>
    <div className="flex items-center mb-16 lg:mb-20">
      {_.map((blockClassName: string) => (
        <div
          key={generateKey()}
          className={classNames('w-32 h-4 rounded-sm border border-black mr-4', blockClassName)}
        />
      ))(blockClassNames)}
    </div>
    <Caption className="hidden xl:block mb-8">{t('authorized.title')}</Caption>
  </>
);

export default FormStepsNavigation;
