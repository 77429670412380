import classNames from 'classnames';
import React, { FunctionComponent, ReactNode } from 'react';

import Icons from '../../assets/icons';
import Bodycopy from '../text-components/Bodycopy';

export interface Props {
  message: string | ReactNode;
  className?: string;
}

const InfoAlert: FunctionComponent<Props> = ({ message, className = '' }: Props) => (
  <div className={classNames('bg-blue-light p-8 flex rounded-sm', className)}>
    <Icons.Info color="blue" />
    <Bodycopy color="blue" className="flex-1 ml-6">
      {message}
    </Bodycopy>
  </div>
);

export default InfoAlert;
