import React, { FunctionComponent } from 'react';

import Title from '../../components/text-components/Title';
import AuthenticatedLayout from '../../layout/AuthenticatedLayout';
import t from '../../text-strings';

const Notifications: FunctionComponent = () => (
  <AuthenticatedLayout title={t('common.navigation.notificaciones')}>
    <div className="wrap-authenticated">
      <Title size="small" tag="h1">{t('common.navigation.notificaciones')}</Title>
    </div>
  </AuthenticatedLayout>
);

export default Notifications;
