import React, { FunctionComponent } from 'react';

import Title from '../../../../components/text-components/Title';
import t from '../../../../text-strings';
import Toggle from '../../../../components/Toggle';
import IndividualSurplus from '../types/IndividualSurplus';
import IndividualWithoutSurplus from '../types/IndividualWithoutSurplus';
import Shared from '../types/Shared';

interface Props {
  shared: boolean;
  surplus: boolean;
  toggleShared: () => void;
  toggleSurplus: () => void
}

const SelfConsumptionTypes: FunctionComponent<Props> = (
  {
    shared,
    surplus,
    toggleShared,
    toggleSurplus,
  }: Props,
) => {
  const individualNoSurplus = !shared && surplus;
  const individualSurplus = !shared && !surplus;
  const sharedNoSurplus = shared && !surplus;
  const sharedSurplus = shared && surplus;

  return (
    <div className="lg:mb-40 lg:border-b border-gray-400">
      <div className="lg:flex justify-between items-center mb-12 lg:mb-16">
        <Title className="mb-12 lg:mb-0 lg:w-full">{t('self.tipos_autoconsumo')}</Title>
        <Toggle
          variants={['Individual', 'Compartido']}
          selected={shared}
          onToggle={toggleShared}
        />
      </div>
      {!shared && (
        <Toggle
          variants={['Con excedentes', 'Sin excedentes']}
          selected={surplus}
          onToggle={toggleSurplus}
          underline
        />
      )}
      {individualNoSurplus && <IndividualWithoutSurplus />}
      {individualSurplus && <IndividualSurplus />}
      {(sharedNoSurplus || sharedSurplus) && (
        <Shared />
      )}
    </div>
  );
};

export default SelfConsumptionTypes;
