import React, { FunctionComponent } from 'react';

import date from '../../services/date';
import helpers from '../../services/helpers';
import { Any } from '../../store/model/global';

interface LineLegendPrevYearProps {
    energy: Any;
    startPeriod: Any;
    endPeriod: Any;
}

const LineLegendPrevYear: FunctionComponent<LineLegendPrevYearProps> = (
  { energy, startPeriod, endPeriod }: LineLegendPrevYearProps,
) => (
  <div className="mt-8 flex items-center">
    <div className="flex">
      <div className="bg-black min-h-6 min-w-6 h-6 w-6 rounded mr-4 flex mt-2" />
      <div className="flex flex-col">
        <div className="text-sm">
          {`${helpers.titleString(date.getMonthName(startPeriod))} / ${date.getMonthName(endPeriod)} ${Number(date.getYear(startPeriod)) - 1}`}
        </div>
        <div className="text-sm text-gray-400">
          {`${helpers.roundStatNumber(energy.consumptionLastYear.totalConsumption)}kWh`}
        </div>
      </div>
    </div>
    <div className="flex ml-18">
      <div className="bg-yellow min-h-6 min-w-6 h-6 w-6 rounded mr-4 flex mt-2" />
      <div className="flex flex-col">
        <div className="text-sm">
          {`${helpers.titleString(date.getMonthName(startPeriod))} / ${date.getMonthName(endPeriod)} ${date.getYear(startPeriod)}`}
        </div>
        <div className="text-sm text-gray-400">
          {`${helpers.roundStatNumber(energy.consumptionCurrent.totalConsumption)}kWh`}
        </div>
      </div>
    </div>
  </div>
);

export default LineLegendPrevYear;
