import { FormikErrors } from 'formik';
import _ from 'lodash/fp';
import React, { FunctionComponent, ChangeEvent } from 'react';

import SubmitButton from '../../../../components/buttons/SubmitButton';
import FileInput from '../../../../components/forms/FileInput';
import FormResponse from '../../../../components/forms/FormResponse';
import InternalLink from '../../../../components/InternalLink';
import Bodycopy from '../../../../components/text-components/Bodycopy';
import { routes } from '../../../../services/routing';
import {
  CupsParams,
  DocumentData,
  GenerationParams,
  RequestStep,
  SelfconsumptionParams,
  SupplyParams,
} from '../../../../store/model/requests/types';
import t from '../../../../text-strings';
import Icons from '../../../../assets/icons';
import { cacheStorage } from '../../../../store/model';

import StepPage from './StepPage';

interface Props {
  title: string;
  prev: RequestStep;
  step: number
  totalSteps: number;
  values: DocumentData;
  // eslint-disable-next-line
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<void> | Promise<FormikErrors<SupplyParams | SelfconsumptionParams | GenerationParams | CupsParams>>;
  handleBlur: {
    // eslint-disable-next-line
    (e: React.FocusEvent<any, Element>): void;
    // eslint-disable-next-line
    <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
  };
  // eslint-disable-next-line
  errors: FormikErrors<SupplyParams | SelfconsumptionParams | GenerationParams | CupsParams>;
  success: boolean;
  message: string;
  isSubmitting: boolean;
  isValid: boolean;
  sendedPdf: string;
  formCode: string;
}

const DocumentDataStep: FunctionComponent<Props> = (
  {
    title,
    prev,
    step,
    totalSteps,
    values,
    setFieldValue,
    handleBlur,
    errors,
    success,
    message,
    isSubmitting,
    isValid,
    sendedPdf,
    formCode,
  }: Props,
) => {
  const saveDocument = (e: ChangeEvent<HTMLInputElement>, name: string): void => {
    const { files } = e.target;
    if (!_.gt(files?.length, 0) || _.isNil(files)) {
      return;
    }
    setFieldValue(name, files[0]);
  };
  const areYouOwner = () => {
    const valuesYes: number[] = [1, 25, 35, 110];
    return cacheStorage.AreYouOwnerValue
      ? valuesYes.includes(cacheStorage.AreYouOwnerValue) : false;
  };

  return (
    <StepPage
      title={title}
      current="documentacion"
      prev={prev}
      step={step}
      totalSteps={totalSteps}
    >
      <Bodycopy className="mb-8">
        {t('requests.forms.pdf')}
      </Bodycopy>

      {!_.isUndefined(values.technicalMemory) && (
        <FileInput
          name="technicalMemory"
          value={values.technicalMemory.name}
          placeholder={t('models.requests.documentation.technical_memory')}
          onChange={(e: ChangeEvent<HTMLInputElement>): void => saveDocument(e, 'technicalMemory')}
          onBlur={handleBlur('technicalMemory')}
          errors={errors.technicalMemory}
        />
      )}
      {!_.isUndefined(values.technicalDraft) && (
        <FileInput
          name="technicalDraft"
          value={values.technicalDraft.name}
          placeholder={t('models.requests.documentation.technical_draft')}
          onChange={(e: ChangeEvent<HTMLInputElement>): void => saveDocument(e, 'technicalDraft')}
          onBlur={handleBlur('technicalDraft')}
          errors={errors.technicalDraft}
        />
      )}
      {!_.isUndefined(values.cie) && (
        <FileInput
          name="cie"
          value={values.cie.name}
          placeholder={t('models.requests.documentation.cie')}
          onChange={(e: ChangeEvent<HTMLInputElement>): void => saveDocument(e, 'cie')}
          onBlur={handleBlur('cie')}
          errors={errors.cie}
        />
      )}
      {
        !_.isUndefined(values.firstOccupation) && (
        <FileInput
          name="firstOccupation"
          value={values.firstOccupation.name}
          placeholder={t('models.requests.documentation.first_occupation')}
          onChange={(e: ChangeEvent<HTMLInputElement>): void => saveDocument(e, 'firstOccupation')}
          onBlur={handleBlur('firstOccupation')}
          errors={errors.firstOccupation}
        />
        )
        }
      {!_.isUndefined(values.deeds) && (
        <FileInput
          name="deeds"
          value={values.deeds.name}
          placeholder={t('models.requests.documentation.deeds')}
          onChange={(e: ChangeEvent<HTMLInputElement>): void => saveDocument(e, 'deeds')}
          onBlur={handleBlur('deeds')}
          errors={errors.deeds}
        />
      )}
      {!_.isUndefined(values.warranty) && (
        <FileInput
          name="warranty"
          value={values.warranty.name}
          placeholder={t('models.requests.documentation.warranty')}
          onChange={(e: ChangeEvent<HTMLInputElement>): void => saveDocument(e, 'warranty')}
          onBlur={handleBlur('warranty')}
          errors={errors.warranty}
        />
      )}
      {!_.isUndefined(values.environmentalImpact) && (
        <FileInput
          name="environmentalImpact"
          value={values.environmentalImpact.name}
          placeholder={t('models.requests.documentation.environmental_impact')}
          onChange={(e: ChangeEvent<HTMLInputElement>): void => saveDocument(e, 'environmentalImpact')}
          onBlur={handleBlur('environmentalImpact')}
          errors={errors.environmentalImpact}
        />
      )}
      {!_.isUndefined(values.communicationCompetentAdministration) && (
        <FileInput
          name="communicationCompetentAdministration"
          value={values.communicationCompetentAdministration.name}
          placeholder={t('models.requests.documentation.communication_competent_administration')}
          onChange={(e: ChangeEvent<HTMLInputElement>): void => saveDocument(e, 'communicationCompetentAdministration')}
          onBlur={handleBlur('communicationCompetentAdministration')}
          errors={errors.communicationCompetentAdministration}
        />
      )}
      {!_.isUndefined(values.t243) && (
        <FileInput
          name="t243"
          value={values.t243.name}
          placeholder={t('models.requests.documentation.t243')}
          onChange={(e: ChangeEvent<HTMLInputElement>): void => saveDocument(e, 't243')}
          onBlur={handleBlur('t243')}
          errors={errors.t243}
        />
      )}
      {!_.isUndefined(values.generalPlan) && (
        <FileInput
          name="generalPlan"
          value={values.generalPlan.name}
          placeholder={t('models.requests.documentation.generalPlan')}
          onChange={(e: ChangeEvent<HTMLInputElement>): void => saveDocument(e, 'generalPlan')}
          onBlur={handleBlur('generalPlan')}
          errors={errors.generalPlan}
        />
      )}
      {!_.isUndefined(values.particularPlan) && (
        <FileInput
          name="particularPlan"
          value={values.particularPlan.name}
          placeholder={t('models.requests.documentation.particularPlan')}
          onChange={(e: ChangeEvent<HTMLInputElement>): void => saveDocument(e, 'particularPlan')}
          onBlur={handleBlur('particularPlan')}
          errors={errors.particularPlan}
        />
      )}
      {!_.isUndefined(values.significativeDistances) && (
        <FileInput
          name="significativeDistances"
          value={values.significativeDistances.name}
          placeholder={t('models.requests.documentation.significativeDistances')}
          onChange={(e: ChangeEvent<HTMLInputElement>): void => saveDocument(e, 'significativeDistances')}
          onBlur={handleBlur('significativeDistances')}
          errors={errors.significativeDistances}
        />
      )}
      {!_.isUndefined(values.generalImplantationPlan) && (
        <FileInput
          name="generalImplantationPlan"
          value={values.generalImplantationPlan.name}
          placeholder={t('models.requests.documentation.generalImplantationPlan')}
          onChange={(e: ChangeEvent<HTMLInputElement>): void => saveDocument(e, 'generalImplantationPlan')}
          onBlur={handleBlur('generalImplantationPlan')}
          errors={errors.generalImplantationPlan}
        />
      )}
      {!_.isUndefined(values.generalImplantationPlan2) && (
        <FileInput
          name="generalImplantationPlan2"
          value={values.generalImplantationPlan2.name}
          placeholder={t('models.requests.documentation.generalImplantationPlan2')}
          onChange={(e: ChangeEvent<HTMLInputElement>): void => saveDocument(e, 'generalImplantationPlan2')}
          onBlur={handleBlur('generalImplantationPlan2')}
          errors={errors.generalImplantationPlan2}
        />
      )}
      {!_.isUndefined(values.authorization) && !areYouOwner() && (
        <FileInput
          name="authorization"
          value={values.authorization.name}
          placeholder={t('models.requests.documentation.authorization')}
          onChange={(e: ChangeEvent<HTMLInputElement>): void => saveDocument(e, 'authorization')}
          onBlur={handleBlur('authorization')}
          // @ts-ignore
          errors={errors.authorization}
        />
      )}
      {_.isEqual(totalSteps, step) && (
        <div>
          {!success && (
            <FormResponse message={message} className="my-4" />
          )}
          {_.isEmpty(sendedPdf) && (
            <SubmitButton
              label={t('requests.enviar_solicitud')}
              fixed
              disabled={isSubmitting || !isValid}
            />
          )}
          {success && (
            <Bodycopy className="font-bold my-4">
              {message}
            </Bodycopy>
          )}
          {success && _.isEmpty(message) && !_.isEmpty(sendedPdf) && (
            <div className="flex flex-col lg:flex-row items-center">
              <InternalLink
                label={t('requests.volver')}
                to={routes.requests.index}
                icon={<Icons.Back />}
              />
              <a download={`mi-solicitud-${formCode}.pdf`} href={sendedPdf} className="btn text-blue mt-4 lg:mt-0 lg:ml-2">
                {t(`requests.forms.download.${formCode}`)}
              </a>
            </div>
          )}
        </div>
      )}
    </StepPage>
  );
};

export default DocumentDataStep;
