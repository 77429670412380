import React, { FunctionComponent, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

interface Props {
  to: string;
  label: string;
  icon: ReactNode;
  isActive: boolean;
}

const SidebarLink: FunctionComponent<Props> = (
  {
    to,
    label,
    icon,
    isActive,
  }: Props,
) => {
  const activeClass = classnames('w-10 h-1 mt-4', { 'bg-gradient': isActive });
  const labelActiveClass = classnames({ btn: isActive, bodycopy: !isActive });

  return (
    <Link to={to} className="flex mb-12">
      <div className="min-w-12 flex flex-col items-center mr-12">
        {icon}
        <div className={activeClass} />
      </div>
      <p className={labelActiveClass}>{label}</p>
    </Link>
  );
};

export default SidebarLink;
