import React, {
  SetStateAction, useEffect, useState, forwardRef, ReactElement,
} from 'react';
// @ts-ignore
import DatePicker from 'react-datepicker';
// @ts-ignore
import TimePicker from 'react-time-picker';
import { useHistory } from 'react-router-dom';

import Icons from '../../assets/icons';
import Wrap from '../../components/containers/Wrap';
import InternalLink from '../../components/InternalLink';
import Bodycopy from '../../components/text-components/Bodycopy';
import AuthenticatedLayout from '../../layout/AuthenticatedLayout';
import { routes } from '../../services/routing';
import ActionButton from '../../components/buttons/ActionButton';
import { useStoreActions, useStoreState } from '../../store/hooks';
import date from '../../services/date';
import useNavigation from '../../services/navigation';
import Title from '../../components/text-components/Title';

interface Measure {
  comments: string;
  createdDate: string;
  cups: string;
  endMeasureDate: string;
  finished: boolean;
  interval: number;
  meterMeasureScheduledId: number;
  startMeasureDate: string;
  userId: number;
}

const getTimeFromDateString = (dateString: string): string => {
  const time = dateString.split('T')[1];
  return time.slice(0, 5);
};

const CounterSchedule = (): ReactElement => {
  const { contract } = useStoreState((state) => state.contract);
  const navigate = useNavigation();
  const history = useHistory();
  const isEditing = history.location?.state;
  const [editObject] = useState<Measure>(
    // @ts-ignore
    isEditing ? history.location?.state : null,
  );

  const [dateInit, setDateInit] = useState<Date>(
    isEditing ? new Date(editObject.startMeasureDate) : new Date(),
  );
  const [timeInit, setTimeInit] = useState(
    isEditing ? getTimeFromDateString(editObject.startMeasureDate) : '',
  );
  const [dateEnd, setDateEnd] = useState<Date>(
    isEditing ? new Date(editObject.endMeasureDate) : new Date(),
  );
  const [timeEnd, setTimeEnd] = useState(
    isEditing ? getTimeFromDateString(editObject.endMeasureDate) : '',
  );
  const [activeButton, setActiveButton] = useState(false);

  const { createscheduledmeasure, updatescheduledmeasure } = useStoreActions(
    (actions) => actions.contract,
  );
  const [invalidRange, setInvalidRange] = useState(false);

  const parseDateLabel = (dateString: Date, timeString: string) => {
    const parsedLabel = `${date.format(
      dateString,
      'YYYY-MM-DD',
    )}T${timeString}`;

    return parsedLabel;
  };

  useEffect(() => {
    (async () => {
      const initParsedLabel = parseDateLabel(dateInit, timeInit);
      const endParsedLabel = parseDateLabel(dateEnd, timeEnd);

      const correctDates = date.isBrefore(initParsedLabel, endParsedLabel);
      const correctInitDatetime = !date.isBrefore(initParsedLabel, new Date());

      const lessThanEightDays = date.difference(dateInit, dateEnd, 'days') + 1 <= 7;

      setInvalidRange(!lessThanEightDays);

      setActiveButton(correctDates && correctInitDatetime && lessThanEightDays);
    })();
  }, [dateInit, timeInit, dateEnd, timeEnd]);

  async function scheduleMeasure() {
    const cups: string = await contract.get('cups');

    const params = {
      cups,
      startMeasureDate: parseDateLabel(dateInit, timeInit),
      endMeasureDate: parseDateLabel(dateEnd, timeEnd),
      interval: 0,
    };

    await createscheduledmeasure(params);
    navigate(routes.counter);
  }

  async function editScheduleMeasure() {
    editObject.startMeasureDate = parseDateLabel(dateInit, timeInit);
    editObject.endMeasureDate = parseDateLabel(dateEnd, timeEnd);

    await updatescheduledmeasure(editObject);
    navigate(routes.counter);
  }

  /* eslint-disable */
  // @ts-ignore
  const CalendarInput = forwardRef(({ value, onClick }, ref) => (
    // @ts-ignore
    <button
      type="button"
      className="flex items-center w-full cursor-pointer"
      onClick={onClick}
      // @ts-ignore
      ref={ref}
    >
      <Icons.Calendar color="blue" className="mr-4" />
      <div className="flex justify-between w-full border-b border-black">
        <Bodycopy className="mr-6">{value}</Bodycopy>
        <Icons.Dropdown color="blue" />
      </div>
    </button>
  ));
  /* eslint-enable */

  return (
    <AuthenticatedLayout title="Programar medición">
      <Wrap size="wrap-authenticated" className="pt-8 pb-16 lg:pt-16 lg:pb-32">
        <InternalLink
          label="Programar medición"
          to={routes.counter}
          icon={<Icons.Back />}
          className="lg:hidden"
        />
        <InternalLink
          label="Contador"
          to={routes.counter}
          icon={<Icons.Back />}
          className="hidden lg:flex"
        />
        <Title tag="h2" className="hidden mt-24 mb-4 lg:flex">
          Programar medición
        </Title>
        <Bodycopy className="mt-12 mb-8 lg:mt-12">
          Puedes hacer mediciones de hasta 7 días de duración
        </Bodycopy>
        <Bodycopy>Selecciona la fecha y la hora de inicio y fin</Bodycopy>
        <div className="mt-12 lg:flex lg:mt-32 lg:space-x-40">
          <div className="flex flex-col lg:w-full">
            <p className="font-semibold">Inicio de la medición</p>
            <div className="px-8 py-8 mt-8 bg-white rounded-sm">
              <div className="mb-12">
                <div className="w-full">
                  <DatePicker
                    selected={dateInit}
                    onChange={(update: SetStateAction<Date>) => setDateInit(update)}
                    startDate={dateInit}
                    monthsShown={2}
                    dateFormat="dd/MM/yyyy"
                    withPortal
                    locale="es"
                    customInput={<CalendarInput />}
                    calendarStartDay={1}
                    placeholderText="Seleccionar fecha"
                    fixedHeight
                  />
                </div>
              </div>
              <div className="flex items-center">
                <Icons.Clock color="blue" className="mr-4" />
                <TimePicker
                  disableClock
                  value={timeInit}
                  onChange={setTimeInit}
                  isClearable
                  format="HH:mm"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col lg:w-full">
            <p className="font-semibold">Fin de la medición</p>
            <div className="px-8 py-8 mt-8 bg-white rounded-sm">
              <div className="mb-12">
                <div className="w-full">
                  <DatePicker
                    selected={dateEnd}
                    onChange={(update: SetStateAction<Date>) => setDateEnd(update)}
                    startDate={dateEnd}
                    monthsShown={2}
                    dateFormat="dd/MM/yyyy"
                    withPortal
                    locale="es"
                    customInput={<CalendarInput />}
                    calendarStartDay={1}
                    placeholderText="Seleccionar fecha"
                    fixedHeight
                  />
                </div>
              </div>
              <div className="flex items-center">
                <Icons.Clock color="blue" className="mr-4" />
                <TimePicker
                  disableClock
                  value={timeEnd}
                  onChange={setTimeEnd}
                  isClearable
                  format="HH:mm"
                />
              </div>
            </div>
          </div>
        </div>
        {invalidRange && (
          <div className="flex p-8 mt-16 mb-12 rounded-sm lg:mt-20 lg:mb-16 bg-orange-lightest">
            <Icons.Warning color="orange" className="mr-6" />
            <div className="flex items-center">
              <Bodycopy color="orange">
                No puedes programar mediciones de más de 7 días
              </Bodycopy>
            </div>
          </div>
        )}
        <div className="flex justify-center lg:justify-start">
          <ActionButton
            label="Programar medición"
            type="secondary"
            disabled={!activeButton}
            onClick={() => (isEditing ? editScheduleMeasure() : scheduleMeasure())}
            className={invalidRange ? 'mt-0' : 'mt-24'}
          />
        </div>
      </Wrap>
    </AuthenticatedLayout>
  );
};

export default CounterSchedule;
