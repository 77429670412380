import { FormikErrors } from 'formik';
import _ from 'lodash/fp';
import React, { ChangeEvent, FunctionComponent } from 'react';

import Icons from '../../../../assets/icons';
import SubmitButton from '../../../../components/buttons/SubmitButton';
import Modal from '../../../../components/Modal';
import Bodycopy from '../../../../components/text-components/Bodycopy';
import Title from '../../../../components/text-components/Title';
import { TouchedDocs } from '../../../../store/model';
import useAuthSteps from '../../../../store/model/authorized/authorized';
import { AuthorizedForm } from '../../../../store/model/authorized/types';
import t from '../../../../text-strings';
import FormStepsNavigation from '../components/FormStepNavigation';
import FormStepScreen from '../components/FormStepScreen';

/* eslint-disable @typescript-eslint/no-explicit-any */
interface Props {
  values: AuthorizedForm;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<AuthorizedForm>>
  onBlur: (name: 'front' | 'back' | 'authorization') => void;
  touched: TouchedDocs;
  errors: FormikErrors<AuthorizedForm>;
  message: string;
  success: boolean;
  disabled: boolean;
}
/* eslint-enable @typescript-eslint/no-explicit-any */

const Authorization: FunctionComponent<Props> = (
  {
    values,
    setFieldValue,
    onBlur,
    touched,
    errors,
    message,
    disabled,
    success,
  }: Props,
) => {
  const [step, setStep] = useAuthSteps();

  const handleDocument = (e: ChangeEvent<HTMLInputElement>): void => {
    const { files, name } = e.target;
    if (_.isNil(files)) return;
    setFieldValue(name, files[0]);
  };

  const docURI = process.env.REACT_APP_AUTHORIZATION || 'https://portalclientesdso.blob.core.windows.net/usuarios/autorizacion/ModeloAutorizacion.docx';
  const docSas = `${docURI}${(process.env.REACT_APP_AZURE_SAS || '?sv=2020-10-02&ss=btqf&srt=sco&st=2022-03-08T11%3A49%3A29Z&se=2122-03-09T11%3A49%3A00Z&sp=rwdxl&sig=jVB53YCGrhdze6wOYrC87iiuyaterUnC9wR6YfF%2FMUo%3D')}`;

  return (
    <FormStepScreen
      onNext={() => setStep('supply')}
      onBack={() => setStep('id-card')}
      current={_.isEqual(step, 'authorization')}
      finish
    >
      <FormStepsNavigation blockClassNames={['bg-black', 'bg-black', 'bg-black', '']} />
      <Title className="mb-12">{t('authorized.auth')}</Title>
      <Bodycopy className="mb-16 lg:mb-20 max-w-240 w-full">{t('authorized.auth_subtitle')}</Bodycopy>
      <a
        href={docSas}
        target="_blank"
        rel="noreferrer"
        className="text-blue inline-flex btn lg:mb-20 mb-18"
      >
        <span className="mr-4"><Icons.Document color="blue" /></span>
        <span>{t('authorized.download')}</span>
      </a>
      <div className="mb-16 lg:mb-20 cursor-pointer" onClick={(): void => onBlur('authorization')}>
        <label htmlFor="authorization" className="flex items-center btn text-blue mb-2">
          <Icons.Attach color="blue" className="mr-4" />
          {_.isEmpty(values.authorization.name)
            ? t('models.authorized.attributes.auth_doc')
            : t('models.authorized.attributes.auth_doc_complete')}
        </label>
        <input
          type="file"
          name="authorization"
          id="authorization"
          onChange={handleDocument}
          className="hidden"
          accept="image/*, .pdf, .docx"
        />
        {touched.authorization && (
          <Bodycopy color="red">{errors.authorization}</Bodycopy>
        )}
      </div>
      <Modal isOpen={!_.isEmpty(message) && success} unclosable>
        <Icons.Info color="blue" className="mb-6" />
        <Bodycopy>{message}</Bodycopy>
      </Modal>
      <Modal
        isOpen={!_.isEmpty(message) && !success}
        toggleOpen={(): void => window.location.reload()}
      >
        <Icons.Bad color="red" className="mb-6" />
        <Bodycopy color="red">{message}</Bodycopy>
      </Modal>
      <SubmitButton
        label={t('authorized.terminar')}
        disabled={disabled}
        fixed
      />
    </FormStepScreen>
  );
};

export default Authorization;
