import React, { FunctionComponent, ReactNode } from 'react';

import Main from './components/Main';
import Header from './header/Header';
import TabNavigation from './tabs/TabNavigation';
import Sidebar from './sidebar/Sidebar';

export interface Props {
  children: ReactNode;
  title: string;
  onlyDesktop?: boolean;
  sidebar?: boolean;
  headerMobile?: boolean;
}

const AuthenticatedLayout: FunctionComponent<Props> = ({
  children,
  title,
  onlyDesktop = false,
  sidebar = true,
  headerMobile = true,
}: Props) => (
  <Main>
    <div className={`${!headerMobile ? 'hidden lg:block' : 'block'}`}>
      <Header
        background="black"
        title={title}
        withTitle
        onlydesktop={onlyDesktop}
      />
    </div>

    <main className="flex flex-col flex-1 pb-32 lg:pb-0">
      <div className="flex flex-1 lg:wrap">
        {sidebar && <Sidebar />}
        <div
          className={`w-full flex-1 flex flex-col ${sidebar ? 'lg:w-4/5' : ''}`}
        >
          {children}
        </div>
      </div>
    </main>
    {!onlyDesktop && <TabNavigation />}
  </Main>
);

export default AuthenticatedLayout;
