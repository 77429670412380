import React, { FunctionComponent } from 'react';

import { AppColors } from '../../styles';

interface Props {
  color?: AppColors;
  width?: number;
  height?: number;
  className?: string;
}

const Check: FunctionComponent<Props> = (
  {
    color = 'black',
    width = 12,
    height = 12,
    className = '',
  }: Props,
) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className={`w-${width} h-${height} text-${color} stroke-current ${className}`}>
    <path d="M20 6L9 17L4 12" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default Check;
