import React, { FunctionComponent, ReactNode } from 'react';

import Main from './components/Main';
import Footer from './footer/Footer';
import Header from './header/Header';

export interface Props {
  children: ReactNode;
}

const LandingLayout: FunctionComponent<Props> = ({ children }: Props) => (
  <Main>
    <Header background="transparent" thin />
    <main className="flex-1 flex flex-col">{children}</main>
    <Footer thin />
  </Main>
);

export default LandingLayout;
