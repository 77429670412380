import { LoadRequestAttributes, RequestAttributes, StateDetailsAttributes } from './types';

export const defaultLoadRequest: LoadRequestAttributes = {
  ClientType: [],
  InstalationType: [],
  Municipalities: [],
  ProvinceId: [],
  TerrainType: [],
  Use: [],
  ConnectionType: [],
  PowerSource: [],
  SelfconsumptionType: [],
  SiNo: [],
};

const defaultStateDetails: StateDetailsAttributes = {
  historialId: 0,
  icon: '',
  name: '',
  createdDate: '',
  dayCreatedDate: '',
  monthYearCreatedDate: '',
  limitDate: '',
  daysCurrent: '',
  description: '',
  stateCode: '',
  acceptButton: false,
  cancelButton: false,
  moreInfoButton: false,
  uploadFile: false,
  abbreviatedCheck: false,
  requestButton: false,
  current: false,
  self: false,
  finalState: false,
  stateCodeName: '',
  stateId: 0,
  showConditions: false,
  daysLimit: '',
};

export const defaultRequest: RequestAttributes = {
  requestId: 0,
  formName: '',
  formCode: '',
  code: '',
  comments: null,
  clientName: '',
  power: '',
  totalDays: '',
  dateRequest: '',
  distributorId: 0,
  blob: '',
  year: '',
  month: '',
  day: '',
  distributorName: '',
  abbreviated: false,
  stateFlows: [],
  stateDetails: [defaultStateDetails],
  conditions: [],
};

export const clientData: string[] = [
  'clientType',
  'nameClient',
  'nifClient',
  'addressClient',
  'localityClient',
  'pcclient',
  'movilPrefixClient',
  'movilClient',
  'emailClient',
  'provinceClientId',
];

export const requestData: string[] = [
  'address',
  'locality',
  'pc',
  'location',
  'terrainType',
  'provinceId',
  'municipalityId',
  'cups',
  'powerContract',
];

export const instalationData: string[] = [
  'powerRequest',
  'tension',
  'use',
  'useDescription',
  'instalationType',
  'powerInstalation',
  'powerSource',
  'powerSourceDesc',
  'selfconsumptionType',
  'connectionType',
  'networked',
  'receivedCompensation',
  'observations',
];

export const especificationData: string[] = [
  'hybridation',
  'accumulation',
  'hybridDescription',
  'accumulationDescription',
  'guaranteeAmount',
  'knot',
  'envImpAssessment',
];

export const documentData: string[] = [
  'technicalMemory',
  'technicalDraft',
  'authorization',
  'cie',
  'firstOccupation',
  'deeds',
  'warranty',
  'environmentalImpact',
  'communicationCompetentAdministration',
  't243',
  'generalPlan',
  'particularPlan',
  'significativeDistances',
  'generalImplantationPlan',
  'generalImplantationPlan2',
];
