import React, { FunctionComponent } from 'react';
import classnames from 'classnames';
import _ from 'lodash/fp';

import Icons from '../../../../assets/icons';
import { FormStep } from '../../../../store/model/register/types';
import useSteps from '../../../../store/model/register/register';

interface Props {
  label: string;
  currentStep: FormStep;
  isComplete: boolean;
  hasErrors: boolean;
}

const SideNavLink: FunctionComponent<Props> = (
  {
    label,
    currentStep,
    isComplete,
    hasErrors,
  }: Props,
) => {
  const [step, setStep] = useSteps();

  const isCurrentStep = _.isEqual(currentStep, step);

  const navLinkClass = classnames('flex items-center', {
    bodycopy: !isCurrentStep && !isComplete,
    btn: isCurrentStep || isComplete,
  });

  const labelClass = classnames({ 'mr-4': isComplete || hasErrors });

  return (
    <div onClick={() => setStep(currentStep)} className="mb-8 cursor-pointer">
      <p className={navLinkClass}>
        <span className={labelClass}>{label}</span>
        {isComplete && !hasErrors && <Icons.Good color="green" />}
        {hasErrors && <Icons.Bad color="red" />}
      </p>
    </div>
  );
};

export default SideNavLink;
