import {
  action, Action, thunk, Thunk,
} from 'easy-peasy';
import _ from 'lodash/fp';

import { handleAxiosResponse } from '../../store-helpers';

import {
  AliasParams,
  ConsumptionParams,
  ContractAttributes,
  EnergyParams,
  ShowParams,
  PowerParams,
  DistributorParams,
  ExistCupsParams,
  StateParams,
  BreakdownsAttributes,
  StreetType,
  MeasureParams,
} from './types';
import contract, {
  Contract,
  defaultEnergyParams,
  defaultPowerParams,
} from './contract';
import breakdowns, { Breakdown } from './breakdowns';

export interface ContractModel {
  contracts: Contract[];
  contract: Contract;
  energy: EnergyParams;
  prevYearEnergy: EnergyParams;
  power: PowerParams;
  breakdowns: Breakdown;
  period: string[];
  streets: StreetType[];

  index: Thunk<ContractModel, string>;
  show: Thunk<ContractModel, ShowParams>;
  update: Thunk<ContractModel, AliasParams>;

  getconsumption: Thunk<ContractModel, ConsumptionParams>;
  getPrevYearConsumption: Thunk<ContractModel, ConsumptionParams>;
  getpower: Thunk<ContractModel, ConsumptionParams>;
  getctdata: Thunk<ContractModel, string>;
  getexistcups: Thunk<ContractModel, ExistCupsParams>;
  getexistdistributor: Thunk<ContractModel, DistributorParams>;
  getstatecontracts: Thunk<ContractModel, StateParams>;
  getstreettypes: Thunk<ContractModel>;
  getcsvhourly: Thunk<ContractModel, ConsumptionParams>;
  getpdfmeasurecertificate: Thunk<ContractModel, ConsumptionParams>;

  getmeasuresbycups: Thunk<ContractModel, string>;
  createscheduledmeasure: Thunk<ContractModel, MeasureParams>;
  getscheduledsbycups: Thunk<ContractModel, string>;
  deletescheduledmeasure: Thunk<ContractModel, string>;
  reconnecticp: Thunk<ContractModel, string>;
  takemeasure: Thunk<ContractModel, any>;
  getscheduledmeasureinfo: Thunk<ContractModel, number>;
  updatescheduledmeasure: Thunk<any, any>;

  init: Action<ContractModel, ContractAttributes[]>;
  initContract: Action<ContractModel, Contract>;
  initEnergy: Action<ContractModel, EnergyParams>;
  initPrevYearEnergy: Action<ContractModel, EnergyParams>;
  initPower: Action<ContractModel, PowerParams>;
  initBreakdowns: Action<ContractModel, BreakdownsAttributes>;
  initStreets: Action<ContractModel, StreetType[]>;

  setPeriod: Action<ContractModel, string[]>;
}

const contractModel: ContractModel = {
  contracts: [],
  contract: contract(),
  energy: defaultEnergyParams,
  prevYearEnergy: defaultEnergyParams,
  power: defaultPowerParams,
  breakdowns: breakdowns(),
  period: [],
  streets: [],

  init: action((state, params) => {
    const contracts = _.map(contract)(params);
    state.contracts = [...contracts];
  }),

  initContract: action((state, params) => {
    state.contract = params;
  }),

  initEnergy: action((state, params) => {
    state.energy = params;
  }),

  initPrevYearEnergy: action((state, params) => {
    state.prevYearEnergy = params;
  }),

  initPower: action((state, params) => {
    state.power = params;
  }),

  initBreakdowns: action((state, params) => {
    state.breakdowns = breakdowns(params);
  }),

  initStreets: action((state, params) => {
    state.streets = params;
  }),

  index: thunk(async (actions, params, { injections }) => {
    const { contractService } = injections;
    return handleAxiosResponse(
      await contractService.getcontracts(params),
      actions.init,
    );
  }),

  show: thunk((actions, params) => {
    const { id, contracts } = params;
    if (_.isEmpty(contracts)) return;
    // @ts-ignore
    const contractByID = _.find((c: Contract) => _.isEqual(c.get('idcontrato'), id))(contracts);
    actions.initContract(contractByID || contract());
  }),

  update: thunk(async (_actions, params, { injections }) => {
    const { contractService } = injections;
    return handleAxiosResponse(await contractService.createalias(params));
  }),

  getconsumption: thunk(async (actions, params, { injections }) => {
    const { contractService } = injections;
    return handleAxiosResponse(
      await contractService.getconsumption(params),
      actions.initEnergy,
    );
  }),

  getPrevYearConsumption: thunk(async (actions, params, { injections }) => {
    const { contractService } = injections;
    return handleAxiosResponse(
      await contractService.getconsumption(params),
      actions.initPrevYearEnergy,
    );
  }),

  getpower: thunk(async (actions, params, { injections }) => {
    const { contractService } = injections;
    return handleAxiosResponse(
      await contractService.getpower(params),
      actions.initPower,
    );
  }),

  getctdata: thunk(async (_actions, params, { injections }) => {
    const { contractService } = injections;
    const response = await contractService.getctdata({ cups: params });
    return response;
  }),

  getexistcups: thunk(async (_actions, params, { injections }) => {
    const { contractService } = injections;
    const response = await contractService.getexistcups(params);
    return response.data;
  }),

  getexistdistributor: thunk(async (_actions, params, { injections }) => {
    const { contractService } = injections;
    const response = await contractService.getexistdistributor(params);
    return response.data;
  }),

  getstatecontracts: thunk(async (actions, params, { injections }) => {
    const { contractService } = injections;
    return handleAxiosResponse(
      await contractService.getstatecontracts(params),
      actions.initBreakdowns,
    );
  }),

  getstreettypes: thunk(async (actions, _params, { injections }) => {
    const { contractService } = injections;
    return handleAxiosResponse(
      await contractService.getstreettypes(),
      actions.initStreets,
    );
  }),

  getcsvhourly: thunk(async (actions, params, { injections }) => {
    const { contractService } = injections;
    const response = await contractService.getcsvhourly(params);
    return response;
  }),

  getscheduledmeasureinfo: thunk(async (actions, params, { injections }) => {
    const { contractService } = injections;
    const response = await contractService.getscheduledmeasureinfo(params);
    return response;
  }),

  getpdfmeasurecertificate: thunk(async (actions, params, { injections }) => {
    const { contractService } = injections;
    const response = await contractService.getpdfmeasurecertificate(params);
    return response;
  }),

  getmeasuresbycups: thunk(async (actions, params, { injections }) => {
    const { contractService } = injections;
    const response = await contractService.getmeasuresbycups(params);
    return response;
  }),

  createscheduledmeasure: thunk(async (actions, params, { injections }) => {
    const { contractService } = injections;
    return handleAxiosResponse(
      await contractService.createscheduledmeasure(params),
    );
  }),

  getscheduledsbycups: thunk(async (actions, params, { injections }) => {
    const { contractService } = injections;
    const response = await contractService.getscheduledsbycups(params);
    return response;
  }),

  deletescheduledmeasure: thunk(async (actions, params, { injections }) => {
    const { contractService } = injections;
    const response = await contractService.deletescheduledmeasure(params);
    return response;
  }),

  takemeasure: thunk(async (actions, params, { injections }) => {
    const { contractService } = injections;
    const response = await contractService.takemeasure(params);
    return response;
  }),

  updatescheduledmeasure: thunk(async (actions, params, { injections }) => {
    const { contractService } = injections;
    const response = await contractService.updatescheduledmeasure(params);
    return response;
  }),

  reconnecticp: thunk(async (actions, params, { injections }) => {
    const { contractService } = injections;
    const response = await contractService.reconnecticp(params);
    return response;
  }),

  setPeriod: action((state, params) => {
    state.period = params;
  }),
};

export default contractModel;
