import React, { FunctionComponent } from 'react';

import Bodycopy from '../../components/text-components/Bodycopy';
import Title from '../../components/text-components/Title';
import AuthenticationLayout from '../../layout/AuthenticationLayout';
import useNavigation from '../../services/navigation';
import { routes } from '../../services/routing';
import t from '../../text-strings';
import MobileTopNav from '../../components/MobileTopNav';

const Registered: FunctionComponent = () => {
  const navigate = useNavigation();
  return (
    <AuthenticationLayout>
      <MobileTopNav onClick={() => navigate(routes.login)} title={t('registered.title')} />
      <div className="wrap-thin pt-80">
        <Title size="large" className="text-center mb-8 lg:mb-12">{t('registered.complete_title')}</Title>
        <Bodycopy className="text-center max-w-prose mx-auto">{t('registered.complete_description')}</Bodycopy>
      </div>
    </AuthenticationLayout>
  );
};

export default Registered;
