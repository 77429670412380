import { Municipality } from './types';

const defaultMunicipality: Municipality = {
  distributorId: 0,
  identity: '',
  municipalityId: 0,
  name: '',
  provinceId: 0,
};

export default defaultMunicipality;
