import React, { FunctionComponent, ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface Props {
  children: ReactNode;
}

const Main: FunctionComponent<Props> = ({ children }: Props) => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return <div id="main-wrapper">{children}</div>;
};
export default Main;
