import React, { FunctionComponent, useState } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import _ from 'lodash/fp';

import t from '../../../../text-strings';
import constants from '../../../../services/constants';
import Form from '../../../../components/forms/Form';
import Input from '../../../../components/forms/components/Input';
import SubmitButton from '../../../../components/buttons/SubmitButton';
import InputError from '../../../../components/forms/components/InputError';
import Bodycopy from '../../../../components/text-components/Bodycopy';
import { useStoreActions } from '../../../../store/hooks';

const BannerForm: FunctionComponent = () => {
  const { getexistdistributor } = useStoreActions((actions) => actions.contract);
  const [message, setMessage] = useState('');

  const handleOnSubmit = async (values: {cp: string}): Promise<void> => {
    const response = await getexistdistributor(values);
    setMessage(_.isEmpty(response) ? t('landing.banner_form.error') : response);
  };

  const schema = yup.object().shape({
    cp: yup.string()
      .required(t('common.form.errors.required'))
      .length(5, t('models.user.errors.codigo_postal'))
      .matches(constants.POSTAL_CODE_REGEX, t('models.user.errors.codigo_postal')),
  });

  const {
    handleSubmit,
    values,
    handleChange,
    handleBlur,
    touched,
    errors,
    isSubmitting,
    isValid,
  } = useFormik({
    initialValues: {
      cp: '',
    },
    onSubmit: handleOnSubmit,
    validationSchema: schema,
  });

  return (
    <Form onSubmit={handleSubmit} className="">
      <div className="w-full max-w-240">
        <div className="flex items-center border border-white rounded-sm pl-4 pr-2 justify-between sm:pl-8 sm:pr-4">
          <Input
            type="text"
            name="cp"
            value={values.cp}
            placeholder={t('landing.banner_form.placeholder')}
            onChange={handleChange('cp')}
            onBlur={handleBlur('cp')}
            className="bodycopy text-white placeholder-white bg-transparent block py-8 flex-1"
          />
          <SubmitButton
            label={t('landing.banner_form.button_label')}
            disabled={isSubmitting || !isValid}
          />
        </div>
        {touched && !_.isEmpty(errors.cp) && (
          <div className="p-4 sm:p-8 bg-white rounded-sm">
            <InputError message={errors.cp || ''} />
          </div>
        )}

        {!_.isEmpty(message) && (
          <Bodycopy className="p-4 sm:p-8 bg-white rounded-sm">
            {message}
          </Bodycopy>
        )}
      </div>
    </Form>
  );
};

export default BannerForm;
