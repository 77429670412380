import React, { FunctionComponent } from 'react';
import classnames from 'classnames';

import Icons from '../../../../assets/icons';
import Caption from '../../../../components/text-components/Caption';
import date from '../../../../services/date';
import t from '../../../../text-strings';

interface Props {
  name: string;
  stateName: string;
  createdDate: string;
  good?: boolean;
}

const FinalState: FunctionComponent<Props> = (
  {
    name,
    stateName,
    createdDate,
    good = false,
  }: Props,
) => {
  const circleClass = classnames(
    'w-22 h-22 lg:w-28 lg:h-28 flex items-center justify-center rounded-full', {
      'bg-red-light': !good,
      'bg-green-light': good,
    },
  );

  const hLineClass = classnames('w-full h-px', {
    'bg-red': !good,
    'bg-green': good,
  });

  const vLineClass = classnames('w-px', {
    'bg-red': !good,
    'bg-green': good,
  });

  return (
    <div className="lg:flex-2">
      <div className="flex items-center lg:mb-8">
        <div className={circleClass}>
          <div className="bg-white w-20 h-20 lg:w-24 lg:h-24 flex items-center justify-center rounded-full">
            {good
              ? <Icons.Good width={8} height={8} color="green" />
              : <Icons.Close width={8} height={8} color="red" /> }
          </div>
        </div>
        <div className="ml-8 flex-1 lg:hidden">
          <p className="btn mb-2">{name}</p>
          <a href="#buttons" className="block">
            <Caption color={good ? 'blue' : 'red'} className="hover:underline">{stateName}</Caption>
          </a>
        </div>
        <div className="hidden lg:flex flex-1 flex-col justify-center mx-8">
          <div className={hLineClass} />
        </div>
      </div>
      <div className="flex my-4 lg:flex-col">
        <div className="w-22 flex justify-center">
          <div className={vLineClass} />
        </div>
        <div className="hidden lg:block mb-4">
          <p className="btn mb-2">{name}</p>
          <a href="#current_state" className="block">
            <Caption color={good ? 'blue' : 'red'} className="hover:underline">{stateName}</Caption>
          </a>
        </div>
        <div>
          <div className="flex items-center mb-8">
            <Icons.Calendar width={8} height={8} className="mr-4" />
            <Caption color="gray-500">{t('history.days_state', date.daysUntilToday(createdDate))}</Caption>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinalState;
