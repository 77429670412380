import React, { ChangeEvent, FunctionComponent } from 'react';
import { FormikErrors, FormikTouched } from 'formik';
import _ from 'lodash/fp';

import {
  ClientData,
  CupsParams,
  GenerationParams,
  SelfconsumptionParams,
  SupplyParams,
} from '../../../../store/model/requests/types';
import t from '../../../../text-strings';
import { useStoreState } from '../../../../store/hooks';
import RadioButtonsInput, {
  RadioProps,
} from '../../../../components/forms/RadioButtonsInput';
import TextInput from '../../../../components/forms/TextInput';
import InfoField from '../../../../components/text-components/InfoField';
import SelectInput from '../../../../components/forms/SelectInput';
import Title from '../../../../components/text-components/Title';
import IDHelper from '../../../../components/forms/IDHelper';
import { cacheStorage } from '../../../../store/model';

import StepPage from './StepPage';

interface Props {
  title: string;
  totalSteps: number;
  values: ClientData;
  handleChange: {
    // eslint-disable-next-line
    (e: React.ChangeEvent<any>): void;
    // eslint-disable-next-line
    <T_1 = string | React.ChangeEvent<any>>(
      field: T_1
    ): T_1 extends React.ChangeEvent<any>
      ? void
      : // eslint-disable-next-line
        (e: string | React.ChangeEvent<any>) => void;
  };
  handleBlur: {
    // eslint-disable-next-line
    (e: React.FocusEvent<any, Element>): void;
    // eslint-disable-next-line
    <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
  };
  handleSelect: (e: ChangeEvent<HTMLSelectElement>) => void;
  // eslint-disable-next-line
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) =>
    | Promise<void>
    | Promise<
        FormikErrors<
          SupplyParams | SelfconsumptionParams | GenerationParams | CupsParams
        >
      >;
  errors: FormikErrors<
    SupplyParams | SelfconsumptionParams | GenerationParams | CupsParams
  >;
  touched: FormikTouched<
    SupplyParams | SelfconsumptionParams | GenerationParams | CupsParams
  >;
}

const TITULAR = 'Si';

const ClientDataStep: FunctionComponent<Props> = ({
  title,
  totalSteps,
  values,
  handleChange,
  handleBlur,
  handleSelect,
  setFieldValue,
  errors,
  touched,
}: Props) => {
  const { session } = useStoreState((state) => state.session);
  const { loadrequest } = useStoreState((state) => state.requests);
  const titularID = _.flow(
    _.find(['value', TITULAR]),
    _.get('selectorId'),
  )(loadrequest.ClientType);

  const isTitular = _.isEqual(titularID, values.clientType);

  const onChangeClientType = (e: ChangeEvent<HTMLInputElement>): void => {
    const clientType = parseInt(e.target.value, 10);
    const titular = _.isEqual(clientType, titularID);
    cacheStorage.AreYouOwnerValue = parseInt(e.target.value, 10);

    setFieldValue('nameClient', titular ? session.get('name') : '');
    setFieldValue('nifClient', titular ? session.get('nif') : '');
    setFieldValue('addressClient', titular ? session.get('address') : '');
    setFieldValue('localityClient', titular ? session.get('locality') : '');
    setFieldValue('pcclient', titular ? session.get('pc') : '');
    setFieldValue('movilClient', titular ? session.mobile : '');
    setFieldValue('emailClient', titular ? session.get('email') : '');
    setFieldValue(
      'provinceClientId',
      titular ? session.provinceId(loadrequest.ProvinceId) : '',
    );
    setFieldValue('clientType', clientType);
  };

  return (
    <StepPage
      title={title}
      next="solicitud"
      step={1}
      totalSteps={totalSteps}
      current="cliente"
    >
      <RadioButtonsInput
        name="clientType"
        value={values.clientType}
        placeholder={t('models.requests.client.are_you_owner')}
        radios={loadrequest.ClientType as RadioProps[]}
        onChange={onChangeClientType}
        onBlur={handleBlur('clientType')}
        errors={errors.clientType}
        touched={touched.clientType}
        containerClassName="mb-16"
      />
      <Title size="small" className="mb-6">
        {t('requests.datos_personales')}
      </Title>
      {isTitular ? (
        <InfoField
          label={t('models.requests.client.name_client')}
          value={values.nameClient}
          className="mb-8"
          avoidButtonLeftMargin={false}
        />
      ) : (
        <TextInput
          name="nameClient"
          value={values.nameClient}
          placeholder={t('models.requests.client.name_client')}
          onChange={handleChange('nameClient')}
          onBlur={handleBlur('nameClient')}
          touched={touched.nameClient}
          errors={errors.nameClient}
          required
        />
      )}
      {isTitular ? (
        <InfoField
          label={t('models.requests.client.nif_client')}
          value={values.nifClient}
          className="mb-8"
          avoidButtonLeftMargin={false}
        />
      ) : (
        <TextInput
          name="nifClient"
          value={values.nifClient}
          placeholder={t('models.requests.client.nif_client')}
          onChange={handleChange('nifClient')}
          onBlur={handleBlur('nifClient')}
          touched={touched.nifClient}
          errors={errors.nifClient}
          icon={<IDHelper />}
          required
        />
      )}
      {isTitular ? (
        <InfoField
          label={t('models.requests.client.email_client')}
          value={values.emailClient}
          className="mb-8"
          avoidButtonLeftMargin={false}
        />
      ) : (
        <TextInput
          name="emailClient"
          value={values.emailClient}
          placeholder={t('models.requests.client.email_client')}
          onChange={handleChange('emailClient')}
          onBlur={handleBlur('emailClient')}
          touched={touched.emailClient}
          errors={errors.emailClient}
          required
        />
      )}
      {isTitular ? (
        <InfoField
          label={t('models.requests.client.movil_client')}
          value={values.movilClient}
          className="mb-8"
          avoidButtonLeftMargin={false}
        />
      ) : (
        <TextInput
          name="movilClient"
          value={values.movilClient}
          placeholder={t('models.requests.client.movil_client')}
          onChange={handleChange('movilClient')}
          onBlur={handleBlur('movilClient')}
          touched={touched.movilClient}
          errors={errors.movilClient}
          required
        />
      )}
      <Title size="small" className="mt-32 mb-6">
        {t('requests.tu_direccion')}
      </Title>
      {isTitular ? (
        <InfoField
          label={t('models.requests.client.address_client')}
          value={values.addressClient}
          className="mb-8"
          avoidButtonLeftMargin={false}
        />
      ) : (
        <TextInput
          name="addressClient"
          value={values.addressClient}
          placeholder={t('models.requests.client.address_client')}
          onChange={handleChange('addressClient')}
          onBlur={handleBlur('addressClient')}
          touched={touched.addressClient}
          errors={errors.addressClient}
          required
        />
      )}
      {isTitular ? (
        <InfoField
          label={t('models.requests.client.locality_client')}
          value={values.localityClient}
          className="mb-8"
          avoidButtonLeftMargin={false}
        />
      ) : (
        <TextInput
          name="localityClient"
          value={values.localityClient}
          placeholder={t('models.requests.client.locality_client')}
          onChange={handleChange('localityClient')}
          onBlur={handleBlur('localityClient')}
          touched={touched.localityClient}
          errors={errors.localityClient}
          required
        />
      )}
      {isTitular ? (
        <InfoField
          label={t('models.requests.client.pc_client')}
          value={values.pcclient}
          className="mb-8"
          avoidButtonLeftMargin={false}
        />
      ) : (
        <TextInput
          name="pcclient"
          value={values.pcclient}
          placeholder={t('models.requests.client.pc_client')}
          onChange={handleChange('pcclient')}
          onBlur={handleBlur('pcclient')}
          touched={touched.pcclient}
          errors={errors.pcclient}
          required
        />
      )}
      {isTitular ? (
        <InfoField
          label={t('models.requests.client.province_client')}
          value={session.get('province')}
          className="mb-8"
          avoidButtonLeftMargin={false}
        />
      ) : (
        <SelectInput
          name="provinceClientId"
          value={values.provinceClientId}
          selectables={loadrequest.ProvinceId}
          placeholder={t('models.requests.client.province_client')}
          onChange={handleSelect}
          onBlur={handleBlur('provinceClientId')}
          touched={touched.provinceClientId}
          errors={errors.provinceClientId}
          required
        />
      )}
    </StepPage>
  );
};

export default ClientDataStep;
