import React, { FunctionComponent } from 'react';

const Spinner: FunctionComponent = () => {
  const spinnerStyle: React.CSSProperties = {
    color: '#fff',
    fontFamily: 'FKCuervaGrotesk',
    display: 'inline-grid',
    alignItems: 'center',
    position: 'relative',
    fontSize: '200px',
    top: 'calc(50vh - 100px)',
    width: '180px',
    height: '220px',
    animation: 'spin 1s ease-in-out infinite',
    margin: '0 auto',
  };

  const bgStyle: React.CSSProperties = {
    zIndex: 10000,
    background: 'rgb(20, 16, 27)',
    width: '100%',
    height: '100vh',
    margin: '0 auto',
    padding: '0',
    position: 'absolute',
    top: '0px',
    left: '0px',
    textAlign: 'center',
    transition: '1s all linear',
  };

  return (
    <div style={bgStyle}>
      <div style={spinnerStyle}>
        *
      </div>
    </div>
  );
};

export default Spinner;
