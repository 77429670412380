import React, { FunctionComponent } from 'react';

import Icons from '../../../assets/icons';
import LinkButton from '../../../components/buttons/LinkButton';
import { routes } from '../../../services/routing';
import { useStoreState } from '../../../store/hooks';
import t from '../../../text-strings';

import PrivateLink from './PrivateLink';

const PrivateArea: FunctionComponent = () => {
  const { isLoggedIn } = useStoreState((state) => state.session);

  if (isLoggedIn) {
    return (
      <div className="overflow-x-visible">
        <div className="flex w-full max-w-full mb-32 overflow-x-scroll">
          <PrivateLink
            to={routes.consumption}
            label={t('common.navigation.mi_consumo')}
            icon={<Icons.Bolt />}
          />
          <PrivateLink
            to={routes.counter}
            label={t('common.navigation.mi_contador')}
            icon={<Icons.Counter />}
          />
          <PrivateLink
            to={routes.requests.index}
            label={t('common.navigation.mis_solicitudes')}
            icon={<Icons.Document />}
          />
          <PrivateLink
            to={routes.breakdowns}
            label={t('common.navigation.no_tengo_luz')}
            icon={<Icons.Fraud />}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="flex justify-between">
      <LinkButton label={t('common.navigation.acceder')} to={routes.login} />
      <LinkButton
        label={t('common.navigation.registrate')}
        to={routes.register}
        type="secondary"
      />
    </div>
  );
};

export default PrivateArea;
