import { ActionCreator } from 'easy-peasy';

import { useStoreActions, useStoreState } from '../../hooks';

import { FormStep } from './types';

const useSteps = (): [FormStep, ActionCreator<FormStep>] => {
  const { step } = useStoreState((state) => state.register);
  const { setStep } = useStoreActions((actions) => actions.register);

  return [step, setStep];
};

export default useSteps;
