import _ from 'lodash/fp';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Icons from '../../../assets/icons';
import LinkCard from '../../../components/cards/LinkCard';
import Wrap from '../../../components/containers/Wrap';
import InternalLink from '../../../components/InternalLink';
import Caption from '../../../components/text-components/Caption';
import Title from '../../../components/text-components/Title';
import AuthenticatedLayout from '../../../layout/AuthenticatedLayout';
import constants from '../../../services/constants';
import documents from '../../../services/documents';
import { routes } from '../../../services/routing';
import { useStoreState } from '../../../store/hooks';
import t from '../../../text-strings';
import { StateDetailsAttributes } from '../../../store/model/requests/types';

import CanceledState from './components/CanceledState';
import SendAppointment from './components/SendAppointment';
import useRequestHistory from './useHistory';
import SupplyPointFlow from './components/SupplyPointFlow';
import CauFlow from './components/CauFlow';
import CupsFlow from './components/CupsFlow';
import SelfConsumptionFlow from './components/SelfConsumptionFlow';
import GenerationFlow from './components/GenrationFlow';
import CurrentStateCard from './components/CurrentStateCard';
import HistoryStateCard from './components/HistoryStateCard';

type RouteParams = {
  id: string;
}

const History: FunctionComponent = () => {
  const { request } = useStoreState((state) => state.requests);
  const { id } = useParams<RouteParams>();
  const [loading, setLoading] = useState(true);
  const [downloadURL, setDownloadURL] = useState({ filename: '', blobURL: '' });
  const requestHistory = useRequestHistory(request, id);

  useEffect(() => {
    (async () => {
      if (_.isEqual(request.requestId, 0) || !_.isEqual(id, request.code)) {
        await requestHistory.update();
      }
      if (!_.isEqual(request.requestId, 0)) {
        setDownloadURL({ ...await documents.downloadFiles(request) });
      }
      setLoading(false);
    })();
  }, [request]);

  const { name: currentState } = requestHistory.details;

  if (loading) return null;

  return (
    <AuthenticatedLayout title={t('history.estado_de_mi_solicitud')}>
      <div className="flex-1 flex flex-col">
        <div className="bg-white pt-16 lg:pt-24 lg:bg-transparent">
          <Wrap size="wrap-authenticated">
            <InternalLink
              label={t('history.mis_solicitudes')}
              to={routes.requests.index}
              icon={<Icons.Back />}
              className="mb-10 lg:mb-20"
            />
            <Title tag="h1" className="mb-12">
              {request.formName}
            </Title>
            <div className="mb-12 lg:mb-18 lg:flex items-center">
              <SendAppointment requestCode={request.code} distributorId={request.distributorId} />
              <LinkCard
                label={t('history.documentacion')}
                to={downloadURL.blobURL}
                icon={<Icons.Document color="white" />}
                className="lg:ml-18 shadow-blur"
                download
                downloadName={downloadURL.filename}
              />
            </div>
          </Wrap>
        </div>
        <Wrap size="wrap-authenticated" className="flex-1 py-16 lg:py-18">
          <div className="flex flex-wrap lg:flex-nowrap mb-8 lg:mb-12">
            <div className="flex-1 mb-8 lg:mb-0">
              <Title size="small" tag="h2">
                {request.distributorName}
              </Title>
            </div>
            <Caption className="w-1/2 flex items-center mb-8 lg:mb-0 lg:w-auto lg:mr-8">
              <Icons.Bolt height={8} width={8} className="mr-4" />
              {request.power}
            </Caption>
            <Caption className="w-1/2 flex items-center mb-8 lg:mb-0 lg:w-auto lg:mr-8">
              <Icons.Growth height={8} width={8} className="mr-4" />
              {request.code}
            </Caption>
            <Caption className="w-1/2 flex items-center mb-8 lg:mb-0 lg:w-auto lg:mr-8">
              <Icons.Calendar height={8} width={8} className="mr-4" />
              {request.totalDays}
            </Caption>
          </div>
          {(_.isEqual(currentState, t('history.cancelada'))
          || _.isEqual(currentState, t('history.cancelada_gestor'))) && (
            <div className="bg-white rounded-sm py-12 px-8 lg:py-16 lg:px-16 lg:flex mb-16">
              <CanceledState
                name={t('history.cancelada')}
                stateName={currentState}
              />
            </div>
          )}
          {!_.isEqual(currentState, t('history.cancelada')) && !_.isEqual(currentState, t('history.cancelada_gestor')) && (
            <div className="bg-white rounded-sm py-12 px-8 lg:py-16 lg:px-16 lg:flex mb-16">
              {_.isEqual(request.formCode, constants.SUPPLY_CODE) && (
                <SupplyPointFlow request={request} id={id} />
              )}
              {_.isEqual(request.formCode, constants.CAU_CODE) && (
                <CauFlow request={request} id={id} />
              )}
              {_.isEqual(request.formCode, constants.CUPS_CODE) && (
                <CupsFlow request={request} id={id} />
              )}
              {_.isEqual(request.formCode, constants.SELFCONSUMPTION_CODE) && (
                <SelfConsumptionFlow request={request} id={id} />
              )}
              {_.isEqual(request.formCode, constants.GENERATION_CODE) && (
                <GenerationFlow request={request} id={id} />
              )}
            </div>
          )}
          <CurrentStateCard />
          {_.gt(_.size(request.stateDetails), 1) && (
            <div>
              <Title size="small" tag="h3" className="mb-8">{t('history.historical')}</Title>
              {_.map((details: StateDetailsAttributes) => (
                <HistoryStateCard
                  key={details.historialId}
                  details={details}
                />
              ))(_.slice(1, _.size(request.stateDetails) - 1)(request.stateDetails))}
            </div>
          )}
          {_.gt(_.size(request.stateDetails), 1) && (
            <HistoryStateCard
              // @ts-ignore
              details={_.last(request.stateDetails)}
              first
            />
          )}
        </Wrap>
      </div>
    </AuthenticatedLayout>
  );
};

export default History;
