import { AxiosResponse } from 'axios';

import { LegalParams } from '../../store/model/distributors/types';
import { axiosGet, setURL, queryParams } from '../axios';

// eslint-disable-next-line
export const municipalities = async (): Promise<AxiosResponse> => (
  axiosGet(setURL('/Distributor', '/getmunicipalitiesdistributor'))
);

export const getlegalinfo = async (params: LegalParams): Promise<AxiosResponse> => (
  axiosGet(setURL('/Distributor', '/getlegalinfo?', queryParams(params)))
);
