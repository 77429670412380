import React, {
  useEffect, useState, FunctionComponent, ReactElement,
} from 'react';
import { useParams } from 'react-router-dom';
import { RotatingLines } from 'react-loader-spinner';
import {
  ResponsiveContainer,
  LineChart,
  XAxis,
  YAxis,
  Line,
  Tooltip,
} from 'recharts';

import { Any } from '../../store/model/global';
import Icons from '../../assets/icons';
import ActionButton from '../../components/buttons/ActionButton';
import Wrap from '../../components/containers/Wrap';
import InternalLink from '../../components/InternalLink';
import Modal from '../../components/Modal';
import Bodycopy from '../../components/text-components/Bodycopy';
import Title from '../../components/text-components/Title';
import AuthenticatedLayout from '../../layout/AuthenticatedLayout';
import date from '../../services/date';
import { routes } from '../../services/routing';
import { useStoreActions, useStoreState } from '../../store/hooks';
import pdfLib from '../../services/pdf';

type RouteParams = {
  id: string;
};

type Reading = {
  init?: number | null;
  end?: number | null;
};

const parseLabelY = (label: string): string => `${label}kW`;

const CustomTooltip: FunctionComponent = ({ active, payload }: Any) => {
  if (active && payload && payload.length) {
    return (
      <div className="flex flex-col p-6 bg-white border border-black rounded-sm">
        <div className="flex items-center">
          <p className="text-xs">Sin datos</p>
        </div>
      </div>
    );
  }

  return null;
};

const parseDateLabel = (dateString: string) => {
  const parsedLabel = `${date.format(dateString, 'DD.MM.YYYY - HH:mm')}h`;

  return parsedLabel;
};

const MeasureDetails = (): ReactElement => {
  const { contract } = useStoreState((state) => state.contract);
  const { id } = useParams<RouteParams>();
  const { getpdfmeasurecertificate, getscheduledmeasureinfo } = useStoreActions(
    (actions) => actions.contract,
  );
  const [power, setPower] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [initalDateRead, setInitalDateRead] = useState('Sin datos');
  const [finalDateRead, setFinalDateRead] = useState('Sin datos');

  const [measureData, setMeasureData] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [lectura, setLectura] = useState<Reading>({
    init: null,
    end: null,
  });
  const showChart = false;
  const toggleModal = (): void => setIsOpen(!isOpen);
  const [dictionaryTramos, setDictionaryTramos] = useState(null);

  const chartData: any = [
    { date: '00:00', c: 0 },
    { date: '00:15', c: 0 },
    { date: '00:30', c: 0 },
    { date: '00:45', c: 0 },
    { date: '01:00', c: 0 },
    { date: '01:15', c: 0 },
    { date: '01:30', c: 0 },
    { date: '01:45', c: 0 },
    { date: '02:00', c: 0 },
    { date: '02:15', c: 0 },
    { date: '02:30', c: 0 },
    { date: '02:45', c: 0 },
    { date: '03:00', c: 0 },
    { date: '03:15', c: 0 },
    { date: '03:30', c: 0 },
    { date: '03:45', c: 0 },
    { date: '04:00', c: 0 },
  ];

  useEffect(() => {
    (async () => {
      setPower(contract.highPower());

      const response = await getscheduledmeasureinfo(Number(id));
      const { data } = response;
      setMeasureData(data);

      /* Calculate max power */

      const lastMeasurePosition = data.measures.length - 1 < 0 ? 0 : data.measures.length - 1;
      setInitalDateRead(parseDateLabel(data.measures[0].measureDate));
      setFinalDateRead(
        parseDateLabel(data.measures[lastMeasurePosition].measureDate),
      );

      setMeasureData(response.data);
      const dictionary: Map<number, number> = new Map();

      // Tramos 1, 2 y 3
      for (let i = 1; i <= 3; i += 1) {
        const tramo = response.data.measures.filter((m: any) => m.tramo.id === i);
        let suma = 0;
        tramo.forEach((t: any) => {
          suma += response.data.consumos[t.measureDate] ?? 0;
        });
        dictionary.set(i, suma);
      }
      setDictionaryTramos(dictionary as any);
      setLectura({
        init: response.data.initialCTRead,
        end: response.data.finalCTRead,
      });
    })();
  }, []);

  async function downloadConsumption() {
    setIsLoading(true);
    const params = {
      // @ts-ignore
      cup: measureData.scheduled.cups,

      startDate: date.format(
        // @ts-ignore
        measureData.scheduled.startMeasureDate,
        'yyyy-MM-DD',
      ),
      // @ts-ignore
      endDate: date.format(measureData.scheduled.endMeasureDate, 'yyyy-MM-DD'),
    };

    const response = await getpdfmeasurecertificate(params);

    setIsLoading(false);
    if (response.status === 200) {
      const distributorObj = response.data.distributor;
      const documento = Uint8Array.from(atob(response.data.html), (c) => c.charCodeAt(0));
      const documentoSellado = await pdfLib.stampDocument(
        documento,
        response.data.distributorIconPngBase64,
        distributorObj.code,
        distributorObj.identity,
        distributorObj.name,
        distributorObj.name,
        distributorObj.longName,
        false,
      );

      const url = window.URL.createObjectURL(
        new Blob([documentoSellado], { type: 'application/pdf' }),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'detalle-de-lectura.pdf');
      document.body.appendChild(link);
      link.click();
    }
  }
  return (
    <AuthenticatedLayout title="Detalle lectura">
      <Wrap size="wrap-authenticated" className="py-16 lg:flex lg:flex-col">
        <InternalLink
          label="Contador"
          to={routes.counter}
          icon={<Icons.Back />}
        />
        <Title tag="h2" className="mt-16">
          Detalle lectura
        </Title>
        {measureData && (
          <div className="w-full">
            <p className="mt-8 mb-20 text-sm lg:mb-22">
              Del
              <span className="font-bold">
                {/* @ts-ignore */}
                {` ${parseDateLabel(measureData.scheduled.startMeasureDate)}`}
              </span>
              {' '}
              a
              {' '}
              <span className="font-bold">
                {/* @ts-ignore */}
                {parseDateLabel(measureData.scheduled.endMeasureDate)}
              </span>
            </p>
            <div className="lg:flex">
              <p className="w-full mb-4 text-sm font-semibold lg:text-md">
                Potencia consumida
              </p>
              <p className="hidden w-full pl-16 mb-4 text-sm font-semibold lg:flex lg:text-md">
                Lectura
              </p>
            </div>
            <div className="flex lg:hidden">
              <p className="mr-2 text-sm">
                ¡Recuerda! tu potencia contratada es de
                {' '}
                {power}
                kW
              </p>
              <ActionButton
                type="link"
                label=""
                onClick={toggleModal}
                icon={<Icons.Help color="blue" height={8} width={8} />}
              />
            </div>
            <Modal isOpen={isOpen} toggleOpen={toggleModal}>
              <p className="mb-8 btn">Potencia contratada</p>
              <Bodycopy className="mb-4">
                Es el número máximo de kW que puedes consumir en un momento
                determinado. Lo contratas a través de tu comercializadora.
              </Bodycopy>
              <Bodycopy className="mb-4">
                A mayor potencia contratada, mayor será tu factura por lo que te
                recomendamos que la ajustes en función de tu consumo.
              </Bodycopy>
            </Modal>
            <div className="w-full lg:flex lg:flex-row space-x-18">
              <div className="flex w-full px-6 py-6 mt-8 bg-white rounded-sm lg:py-16 lg:px-16 lg:space-x-8">
                <div className="w-1/2">
                  <p className="text-xs lg:text-sm">Máxima</p>
                  <div className="flex mb-6 lg:items-baseline">
                    <p className="mr-4 text-lg font-semibold lg:text-2xl">
                      {/* @ts-ignore */}
                      {measureData.maxPower > 0
                        ? (measureData as any).maxPower.toFixed(2)
                        : (measureData as any).maxPower}
                      {' '}
                      <span className="text-base font-normal">kW</span>
                    </p>
                    <Icons.Growth color="red" />
                  </div>
                  <p className="text-gray-500 text-xs font-medium lg:text-base">
                    {
                      parseDateLabel(
                        (measureData as any).measures.find(
                          (m: any) => m.measurePower === (measureData as any).maxPower,
                        ).measureDate,
                      )
                    }
                  </p>
                </div>
                <div>
                  <p className="text-xs lg:text-sm">Mínima</p>
                  <div className="flex mb-6 lg:flex lg:items-baseline">
                    <p className="mr-4 text-lg font-semibold lg:text-2xl">
                      {/* @ts-ignore */}
                      {measureData.minPower > 0
                        ? (measureData as any).minPower.toFixed(2)
                        : (measureData as any).minPower}
                      {' '}
                      <span className="text-base font-normal">kW</span>
                    </p>
                    <Icons.Decay color="green" />
                  </div>
                  <p className="text-xs font-medium text-gray-500 lg:text-base lg:font-normal">
                    {
                      parseDateLabel(
                        (measureData as any).measures.find(
                          (m: any) => m.measurePower === (measureData as any).minPower,
                        ).measureDate,
                      )
                    }
                  </p>
                </div>
              </div>
              <div className="hidden w-full mt-8 bg-white rounded-sm lg:flex lg:py-16 lg:px-16">
                <div className="flex flex-col justify-between w-full">
                  <p className="text-xs lg:text-sm">Inicial</p>
                  <div className="flex mb-6 lg:flex lg:items-baseline">
                    {(lectura.end ?? 0) > 0
                      ? (
                        <p className="mr-4 text-lg font-semibold lg:text-2xl">
                          {/* @ts-ignore */}
                          {`${lectura.init}`}
                          {' '}
                          <span className="text-base font-normal">kWh</span>
                        </p>
                      )
                      : (
                        <p className="mr-4 font-light text-gray-400 text-md">
                          Sin datos
                        </p>
                      )}
                  </div>
                  <p className="text-xs font-medium text-gray-500 lg:text-base lg:font-normal">
                    {parseDateLabel((measureData as any).measures[0].measureDate)}
                  </p>
                </div>
                <div className="flex flex-col justify-between w-full">
                  <p className="text-xs lg:text-sm">Final</p>
                  <div className="flex mb-6 lg:flex lg:items-baseline">
                    {(lectura.end ?? 0) > 0
                      ? (
                        <p className="mr-4 text-lg font-semibold lg:text-2xl">
                          {/* @ts-ignore */}
                          {`${lectura.end}`}
                          {' '}
                          <span className="text-base font-normal">kWh</span>
                        </p>
                      )
                      : (
                        <p className="mr-4 font-light text-gray-400 text-md">
                          Sin datos
                        </p>
                      )}
                  </div>
                  <p className="text-xs font-medium text-gray-500 lg:text-base lg:font-normal">
                    {parseDateLabel((measureData as any)
                      .measures[(measureData as any).measures.length - 1].measureDate)}
                  </p>
                </div>
              </div>
            </div>
            <div className="hidden lg:flex lg:mt-12">
              <p className="mr-2 text-sm">
                ¡Recuerda! tu potencia contratada es de
                {' '}
                {power}
                kW
              </p>
              <ActionButton
                type="link"
                label=""
                onClick={toggleModal}
                icon={<Icons.Help color="blue" height={8} width={8} />}
              />
            </div>
            <p className="mt-20 text-sm font-semibold lg:hidden">Lectura</p>
            <div className="flex px-6 py-6 mt-8 bg-white rounded-sm lg:hidden">
              <div className="w-1/2">
                <p className="text-xs">Inicial</p>
                <div className="flex mb-6">
                  {
                    (
                      ((lectura.init ?? 0) > 0)
                        ? (
                          <p className="mr-4 text-lg font-semibold">
                            {/* @ts-ignore */}
                            {`${lectura.init}`}
                            {' '}
                            <span className="text-base font-normal">kWh</span>
                          </p>
                        )
                        : (
                          <p className="mr-4 font-light text-gray-400 text-md">
                            Sin datos
                          </p>
                        )
                    )
                  }
                </div>
                <p className="text-xs font-medium text-gray-500">
                  {initalDateRead}
                </p>
              </div>
              <div>
                <p className="text-xs">Final</p>
                <div className="flex mb-6">
                  {
                    (lectura.end ?? 0) > 0
                      ? (
                        <p className="mr-4 text-lg font-semibold">
                          {/* @ts-ignore */}
                          {`${lectura.end}`}
                          {' '}
                          <span className="text-base font-normal">kWh</span>
                        </p>
                      )
                      : (
                        <p className="mr-4 font-light text-gray-400 text-md">
                          Sin datos
                        </p>
                      )
                  }
                </div>
                <p className="text-xs font-medium text-gray-500">
                  {finalDateRead}
                </p>
              </div>
            </div>
            <p className="mt-20 text-sm font-semibold lg:text-md lg:font-semibold">
              Consumo
            </p>
            <div className="flex bg-white rounded-sm p-8 mt-8 w-full justify-center">
              <div className="w-1/3">
                <p className="font-medium text-xs">H. Punta</p>
                <p className="font-semibold text-lg">
                  {(dictionaryTramos as Map<number, number> | null)?.get(3) ?? 0}
                  {' '}
                  <span className="lg:text-md font-normal">kWh</span>
                </p>
              </div>
              <div className="w-1/3">
                <p className="font-medium text-xs">H. Llana</p>
                <p className="font-semibold text-lg">
                  {(dictionaryTramos as Map<number, number> | null)?.get(2) ?? 0}
                  {' '}
                  <span className="lg:text-md font-normal">kWh</span>
                </p>
              </div>
              <div className="w-1/3">
                <p className="font-medium text-xs">H. Valle</p>
                <p className="font-semibold text-lg">
                  {(dictionaryTramos as Map<number, number> | null)?.get(1) ?? 0}
                  {' '}
                  <span className="lg:text-md font-normal">kWh</span>
                </p>
              </div>
            </div>
            {showChart && (
              <div className="relative pt-8 bg-white rounded-sm pb-144">
                <ResponsiveContainer width="100%" className="absolute inset-0">
                  <LineChart
                    data={chartData}
                    margin={{
                      top: 55,
                      right: 50,
                      left: 20,
                      bottom: 20,
                    }}
                  >
                    <XAxis
                      xAxisId={0}
                      dataKey="date"
                      axisLine={false}
                      tickLine={false}
                      // tickFormatter={helpers.parseLabelXWeek}
                      tick={{ fill: '#A6A6A6' }}
                      dy={10}
                      dx={20}
                      interval={3}
                    />
                    <YAxis
                      axisLine={false}
                      tickLine={false}
                      // eslint-disable-next-line
                      tickFormatter={parseLabelY}
                      tick={{ fill: '#A6A6A6' }}
                      dx={-10}
                    />
                    <Tooltip content={<CustomTooltip />} cursor={false} />
                    <Line
                      type="monotone"
                      dataKey="c"
                      stroke="#FFDA00"
                      dot={{ stroke: '#FFDA00', strokeWidth: 1 }}
                      activeDot={{ stroke: '#FFDA00', strokeWidth: 1 }}
                      strokeWidth={3}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            )}
            <div>
              {!isLoading && (
                <ActionButton
                  type="link"
                  label="Descargar detalle de lectura"
                  icon={<Icons.Document color="blue" />}
                  onClick={() => downloadConsumption()}
                  className="text-right mt-18"
                />
              )}
              {isLoading && (
                <div className="flex items-center mt-16 lg:mt-18">
                  <RotatingLines
                    strokeColor="black"
                    strokeWidth="2"
                    animationDuration="1.5"
                    width="24"
                    visible
                  />
                  <p className="ml-8 text-xs font-medium text-gray-500">
                    Estamos generando el documento. En breve comenzará la
                    descarga
                  </p>
                </div>
              )}
            </div>
          </div>
        )}
      </Wrap>
    </AuthenticatedLayout>
  );
};

export default MeasureDetails;
