import React, { FunctionComponent, ReactNode } from 'react';
import classnames from 'classnames';
import _ from 'lodash/fp';

import Icons from '../../assets/icons';
import Title from '../text-components/Title';

export interface Props {
  label: string;
  onClick: () => void;
  icon?: ReactNode;
  className?: string;
}

const ActionCard: FunctionComponent<Props> = (
  {
    label,
    onClick,
    icon,
    className = '',
  },
) => {
  const linkCardClass = classnames(className, {
    'link-card-regular': _.isUndefined(icon),
    'link-card-icon': !_.isUndefined(icon),
  });

  return (
    <button onClick={onClick} className={linkCardClass} type="button">
      {!_.isUndefined(icon) && <div className="icon">{icon}</div>}
      {!_.isUndefined(icon) ? (
        <h3 className="btn label">{label}</h3>
      ) : (
        <Title size="small" className="label">{label}</Title>
      )}
      <div className="arrow"><Icons.Go color="blue" /></div>
    </button>
  );
};

export default ActionCard;
