import React, { FunctionComponent, ReactNode } from 'react';
import { Route } from 'react-router-dom';

interface Props {
  path: string;
  children: ReactNode;
  exact?: boolean;
}

const PublicPage: FunctionComponent<Props> = ({ path, children, exact = false }: Props) => (
  <Route exact={exact} path={path}>{children}</Route>
);

export default PublicPage;
