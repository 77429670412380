import React, { FunctionComponent } from 'react';
import _ from 'lodash/fp';

import classNames from '../../../../services/classNames';

interface Props {
  blockClassNames: string[];
}

const generateKey = (): string => `${Math.random() * 100}`;

const FormStepsNavigation: FunctionComponent<Props> = ({ blockClassNames }: Props) => (
  <div className="flex items-center mb-16 lg:mb-40">
    {_.map((blockClassName: string) => (
      <div
        key={generateKey()}
        className={classNames('w-32 h-4 rounded-sm border border-black mr-4', blockClassName)}
      />
    ))(blockClassNames)}
  </div>
);

export default FormStepsNavigation;
