import _ from 'lodash/fp';
import React, { ChangeEvent, FunctionComponent, useState } from 'react';

import Icons from '../../../../assets/icons';
import ActionButton from '../../../../components/buttons/ActionButton';
import ActionCard from '../../../../components/cards/ActionCard';
import TextArea from '../../../../components/forms/TextArea';
import Modal from '../../../../components/Modal';
import Bodycopy from '../../../../components/text-components/Bodycopy';
import Title from '../../../../components/text-components/Title';
import { useStoreActions, useStoreState } from '../../../../store/hooks';
import t from '../../../../text-strings';

interface Props {
  requestCode: string;
  distributorId: number;
}

const SendAppointment: FunctionComponent<Props> = ({ requestCode, distributorId }: Props) => {
  const { session } = useStoreState((state) => state.session);
  const { sendappointment } = useStoreActions((actions) => actions.contact);
  const [isAppointment, setIsAppointment] = useState(false);
  const [successAppointment, setSuccessAppointment] = useState(false);
  const [failAppointment, setFailAppointment] = useState(false);
  const [subject, setSubject] = useState('');
  const [error, setError] = useState('');

  const sendAppointment = async (): Promise<void> => {
    if (_.isEmpty(subject)) {
      setError(t('common.form.errors.required'));
      return;
    }
    const response = await sendappointment({
      name: session.get('name'),
      email: session.get('email'),
      comment: requestCode,
      subject,
      descriptionForOthers: '',
      file: '',
      fileName: '',
      distributorId,
      municipality: '',
      address: '',
      description: '',
    });
    setIsAppointment(!isAppointment);
    if (typeof response === 'string') {
      setFailAppointment(!failAppointment);
      return;
    }
    setSuccessAppointment(!successAppointment);
  };

  const handleSubject = (e: ChangeEvent<HTMLTextAreaElement>): void => {
    const { value } = e.target;
    if (!_.isEmpty(value)) setError('');
    setSubject(value);
  };

  const toggleAppointment = (): void => setIsAppointment(!isAppointment);
  const toggleSuccessAppointment = (): void => setSuccessAppointment(!successAppointment);
  const toggleFailAppointment = (): void => setFailAppointment(!failAppointment);

  const onCancel = (): void => {
    setError('');
    toggleAppointment();
  };

  return (
    <>
      <ActionCard
        label={t('history.gestor')}
        onClick={onCancel}
        icon={<Icons.Calendar color="white" />}
        className="mb-4 lg:mr-18 shadow-blur"
      />
      <Modal isOpen={isAppointment} toggleOpen={toggleAppointment}>
        <div className="py-6 px-8">
          <Title size="small" tag="h4" className="mb-8">{t('history.send_appointment.title')}</Title>
          <Bodycopy className="mb-10">{t('history.send_appointment.description')}</Bodycopy>
          <TextArea
            name="subject"
            value={subject}
            placeholder={t('history.send_appointment.subject')}
            onChange={handleSubject}
            errors={error}
            touched
            required
            containerClassName="mb-16"
          />
          <div className="flex justify-end">
            <ActionButton
              label={t('history.send_appointment.send')}
              onClick={() => sendAppointment()}
              className="mr-8"
            />
            <ActionButton
              label={t('history.send_appointment.cancel')}
              onClick={onCancel}
              type="secondary"
            />
          </div>
        </div>
      </Modal>
      <Modal isOpen={successAppointment} toggleOpen={toggleSuccessAppointment}>
        <Title size="small" tag="h4" className="mb-4">{t('history.send_appointment.success_title')}</Title>
        <Bodycopy>{t('history.send_appointment.success_description')}</Bodycopy>
      </Modal>
      <Modal isOpen={failAppointment} toggleOpen={toggleFailAppointment}>
        <Title size="small" tag="h4" className="mb-4">{t('history.send_appointment.title')}</Title>
        <Bodycopy>{t('history.send_appointment.description')}</Bodycopy>
      </Modal>
    </>
  );
};

export default SendAppointment;
