import React, { FunctionComponent } from 'react';

import Caption from '../../../../components/text-components/Caption';
import t from '../../../../text-strings';
import Image from '../../../../components/Image';
import Bodycopy from '../../../../components/text-components/Bodycopy';

interface Props {
  mobile: string;
  desktop: string;
}

const ConsumptionCard: FunctionComponent<Props> = ({ mobile, desktop }: Props) => (
  <div className="md:flex">
    <div className="md:w-1/3">
      <p className="btn mb-4">{t('self.factura.ejemplo')}</p>
      <Bodycopy className="mb-8 md:mb-0">{t('self.factura.individual.sin_excedentes.fecha')}</Bodycopy>
    </div>
    <div className="md:w-2/3 md:flex md:flex-col md:items-end">
      <Image src={mobile} className="lg:hidden mb-8 max-w-224 w-full" />
      <Image src={desktop} className="hidden lg:block mb-10 max-w-224 w-full" />
      <div className="flex w-full mb-8 max-w-224">
        <div className="flex items-baseline mr-16">
          <div className="w-6 h-6 bg-green rounded-full mr-2" />
          <Caption>{t('self.autoconsumo.con_autoconsumo')}</Caption>
        </div>
        <div className="flex items-baseline">
          <div className="w-6 h-6 bg-red rounded-full mr-2" />
          <Caption>{t('self.autoconsumo.sin_autoconsumo')}</Caption>
        </div>
      </div>
      <Caption color="gray-500" className="max-w-224">{t('self.autoconsumo.caption_grafico')}</Caption>
    </div>
  </div>
);

export default ConsumptionCard;
