import { thunk, Thunk } from 'easy-peasy';

import { handleAxiosResponse } from '../../store-helpers';

import { ChangePasswordParams, ResetPasswordParams } from './types';

export interface PasswordModel {
  reset: Thunk<PasswordModel, ResetPasswordParams>;
  change: Thunk<PasswordModel, ChangePasswordParams>;
}

const passwordModel: PasswordModel = {
  reset: thunk(async (_actions, params, { injections }) => {
    const { passwordService } = injections;
    return handleAxiosResponse(await passwordService.reset(params));
  }),

  change: thunk(async (_actions, params, { injections }) => {
    const { passwordService } = injections;
    return handleAxiosResponse(await passwordService.change(params));
  }),
};

export default passwordModel;
