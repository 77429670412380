import React, { FunctionComponent } from 'react';
import _ from 'lodash/fp';
import classnames from 'classnames';

import Icons from '../../assets/icons';
import classNames from '../../services/classNames';
import Bodycopy from '../text-components/Bodycopy';

import FormGroup from './components/FormGroup';

export interface Props {
  message: string;
  className?: string;
}

const FormResponse: FunctionComponent<Props> = ({ message, className = '' }: Props) => {
  const formResponseClass = classnames(classNames('form-response', className), {
    'opacity-0': _.isEmpty(message),
    'opacity-100': !_.isEmpty(message),
  });
  return (
    <FormGroup className={formResponseClass}>
      <Icons.Bad color="red" className="min-w-12" />
      <Bodycopy color="red" className="ml-4 lg:ml-6 mt-1 lg:mt-px">
        {message}
      </Bodycopy>
    </FormGroup>
  );
};

export default FormResponse;
