import React, { FunctionComponent, useState } from 'react';
import _ from 'lodash/fp';

import Icons from '../../../assets/icons';
import Wrap from '../../../components/containers/Wrap';
import ListItem from '../../../components/ListItem';
import Title from '../../../components/text-components/Title';
import Bodycopy from '../../../components/text-components/Bodycopy';
import InternalLink from '../../../components/InternalLink';
import AuthenticatedLayout from '../../../layout/AuthenticatedLayout';
import { routes } from '../../../services/routing';
import t from '../../../text-strings';
import CircledIcon from '../../../components/CircledIcon';
import ActionButton from '../../../components/buttons/ActionButton';
import Modal from '../../../components/Modal';
import Tag from '../../../components/Tag';

interface LineItem {
  startHour?: string,
  endHour?: string,
  color: string,
  width: string
}

interface ChartLabelItem {
  label: string
  price: string
  color: string
}

const LineItemComponent: FunctionComponent<LineItem> = ({
  startHour, width, endHour, color,
}) => (
  <div className={`${width}`}>
    <div className={`h-4 bg-${color} rounded`} />
    <div className="flex flex-row justify-between mt-2">
      <div className="text-xs text-gray-400">
        {startHour}
      </div>
      <div className="text-xs text-gray-400">
        {endHour}
      </div>
    </div>
  </div>
);

const FirstLineChart: FunctionComponent = () => (
  <div>
    <div className="mb-4 text-xs">
      {t('tipsToSave.week_days')}
    </div>
    <div className="flex flex-row items-center space-x-1">
      <LineItemComponent startHour="00" endHour="8" width="w-1/3" color="orange-lighter" />
      <LineItemComponent endHour="10" width="w-1/12" color="yellow" />
      <LineItemComponent endHour="14" width="w-1/6" color="orange-light" />
      <LineItemComponent endHour="18" width="w-1/6" color="yellow" />
      <LineItemComponent endHour="22" width="w-1/6" color="orange-light" />
      <LineItemComponent endHour="00" width="w-1/12" color="yellow" />
    </div>
  </div>
);

const SecondLineChart: FunctionComponent = () => (
  <div>
    <div className="mb-4 text-xs">
      {t('tipsToSave.weekend')}
    </div>
    <LineItemComponent startHour="00" endHour="00" width="w-full" color="orange-lighter" />
  </div>
);

const TimeChartComponent: FunctionComponent = () => (
  <div className="flex flex-grow items-center justify-center w-full">
    <div className="flex flex-col justify-center rounded-sm border border-gray-300 w-full bg-white">
      <div className="m-8 space-y-8">
        <FirstLineChart />
        <SecondLineChart />
      </div>
    </div>
  </div>
);

const ChartLabel: FunctionComponent<ChartLabelItem> = ({ label, price, color }) => (
  <div className="flex mt-8">
    <div className={`bg-${color} min-w-6 h-6 w-6 rounded mr-2`} />
    <div>
      <div className="text-xs">
        {label}
      </div>
      <div className="text-xs mt-1 text-gray-400">
        {price}
      </div>
    </div>
  </div>
);

const FirstSectionComponent: FunctionComponent = () => (
  <Wrap
    size="wrap-authenticated"
    className="py-16 lg:flex lg:flex-col lg:justify-start space-y-20"
  >
    <div className="flex flex-col lg:flex-row">
      <div className="lg:w-1/2 lg:flex lg:flex-row">
        <CircledIcon icon={<Icons.Bolt />} />
        <Title className="mt-4 lg:mt-0 lg:ml-4">{t('tipsToSave.review_power_title')}</Title>
      </div>
      <div className="mt-2 lg:w-1/2 lg:pl-8 xl:pl-0">
        <Bodycopy className="mb-4">{t('tipsToSave.power_label')}</Bodycopy>
        <InternalLink
          label={t('tipsToSave.review_power_title')}
          to={`${routes.consumption}?power=true`}
          className="text-blue"
        />
      </div>
    </div>
  </Wrap>
);

const SecondSectionComponent: FunctionComponent = () => {
  const [open, setOpen] = useState(false);
  const toggleOpen = (): void => setOpen(!open);
  const domesticAppliances = [
    {
      id: 1,
      name: 'Lavadora',
    },
    {
      id: 2,
      name: 'Horno',
    },
    {
      id: 3,
      name: 'Lavavajillas',
    },
    {
      id: 4,
      name: 'Secadora',
    },
    {
      id: 5,
      name: 'Plancha',
    },
    {
      id: 6,
      name: 'Televisor',
    },
  ];
  return (
    <Wrap
      size="wrap-authenticated"
      className="py-16 flex-grow lg:bg-transparent lg:flex lg:justify-between"
    >
      <div className="flex flex-col lg:flex-row lg:justify-between">
        <div className="mb-6 lg:flex lg:flex-row lg:w-1/2">
          <CircledIcon icon={<Icons.Clock />} />
          <div>
            <Title className="mt-4 lg:mt-0 lg:ml-4">{t('tipsToSave.saving_title')}</Title>
            <ActionButton
              type="link"
              label={t('tipsToSave.domestic_appliances')}
              onClick={toggleOpen}
              className="text-blue mt-12 hidden lg:block"
            />
          </div>
        </div>
        <div className="lg:w-1/2 lg:pl-8 xl:pl-0">
          <TimeChartComponent />
          <div className="flex space-x-22 lg:space-x-16">
            <ChartLabel label={t('tipsToSave.peak_hour')} price="€€€" color="orange-light" />
            <ChartLabel label={t('tipsToSave.valley_hour')} price="€€" color="yellow" />
            <ChartLabel label={t('tipsToSave.flat_hour')} price="€" color="orange-lighter" />
          </div>
        </div>
        <InternalLink
          label={t('tipsToSave.domestic_appliances')}
          to={routes.consumption}
          className="text-blue mt-12 lg:hidden"
        />
      </div>
      <Modal isOpen={open} toggleOpen={toggleOpen}>
        <Bodycopy className="mb-4"><strong>{t('tipsToSave.domestic_appliances')}</strong></Bodycopy>
        {_.map((domestic: {id: number, name: string}) => (
          <Tag
            key={domestic.id}
            label={domestic.name}
            type="regular"
            className="mr-4 mb-4"
          />
        ))(domesticAppliances)}
      </Modal>
    </Wrap>
  );
};

const ThirdSectionComponent: FunctionComponent = () => (
  <Wrap
    size="wrap-authenticated"
    className="py-16 lg:flex lg:flex-col lg:justify-start space-y-20"
  >
    <div className="flex flex-col lg:flex-row">
      <div className="lg:w-1/2 lg:flex lg:flex-row">
        <CircledIcon icon={<Icons.Good />} />
        <Title className="mt-4 lg:mt-0 lg:ml-4">{t('tipsToSave.change_habits_title')}</Title>
      </div>
      <div className="mt-8 space-y-6 lg:w-1/2 lg:pl-8 xl:pl-0 lg:space-y-0 lg:mt-0 lg:grid lg:grid-cols-2 lg:gap-8">
        <ListItem position={1} description={t('tipsToSave.habit_one')} vertical />
        <ListItem position={2} description={t('tipsToSave.habit_two')} vertical />
        <ListItem position={3} description={t('tipsToSave.habit_three')} vertical />
        <ListItem position={4} description={t('tipsToSave.habit_four')} vertical />
      </div>
    </div>
  </Wrap>
);

const TipsToSave: FunctionComponent = () => (
  <AuthenticatedLayout title={t('common.navigation.mi_consumo')}>
    <Wrap
      size="wrap-authenticated"
      className="lg:flex lg:justify-between"
    >
      <InternalLink
        to={routes.consumption}
        label={t('tipsToSave.back_label')}
        icon={<Icons.Back />}
        className="mt-8 lg:mt-24 lg:mb-20"
      />
    </Wrap>
    <FirstSectionComponent />
    <div className="bg-white lg:bg-transparent">
      <SecondSectionComponent />
    </div>
    <ThirdSectionComponent />
  </AuthenticatedLayout>
);

export default TipsToSave;
