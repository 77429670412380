import React, { FunctionComponent } from 'react';

import Wrap from '../../components/containers/Wrap';
import InternalLink from '../../components/InternalLink';
import Bodycopy from '../../components/text-components/Bodycopy';
import Title from '../../components/text-components/Title';
import AuthenticatedLayout from '../../layout/AuthenticatedLayout';
import { routes } from '../../services/routing';
import t from '../../text-strings';

const AuthorizedCreated: FunctionComponent = () => (
  <AuthenticatedLayout title={t('authorized.title')}>
    <Wrap size="wrap-authenticated" className="py-16 lg:pt-24">
      <Title tag="h2" className="mb-12">{t('authorized.info_enviada')}</Title>
      <Bodycopy className="max-w-240 mb-24">{t('authorized.info_enviada_description')}</Bodycopy>
      <InternalLink
        to={routes.profile}
        label={t('authorized.volver')}
        className="text-blue"
      />
    </Wrap>
  </AuthenticatedLayout>
);

export default AuthorizedCreated;
