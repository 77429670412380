import React, { FunctionComponent } from 'react';

import Icons from '../../../../assets/icons';
import ListItem from '../../../../components/ListItem';
import Modal from '../../../../components/Modal';
import Bodycopy from '../../../../components/text-components/Bodycopy';
import t from '../../../../text-strings';

interface Props {
  isOpen: boolean;
  close: () => void;
}

const Marketer: FunctionComponent<Props> = ({ isOpen, close }: Props) => (
  <Modal isOpen={isOpen} toggleOpen={close}>
    <div className="mb-16">
      <Icons.Info color="blue" />
    </div>
    <Bodycopy className="mb-12">{t('supply.marketer.description')}</Bodycopy>
    <ul>
      <ListItem
        position={1}
        title={t('supply.marketer.item1.title')}
        description={t('supply.marketer.item1.description')}
      />
      <ListItem
        position={2}
        title={t('supply.marketer.item2.title')}
      />
      <ListItem
        position={3}
        title={t('supply.marketer.item3.title')}
        description={t('supply.marketer.item3.description')}
      />
    </ul>
  </Modal>
);

export default Marketer;
