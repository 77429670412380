import { useStoreActions, useStoreState } from '../../hooks';

import { AuthorizedStep } from './types';

const useAuthSteps = (): [AuthorizedStep, (newStep: AuthorizedStep) => void] => {
  const { step } = useStoreState((state) => state.authorized);
  const { setStep } = useStoreActions((actions) => actions.authorized);

  const changeStep = (newStep: AuthorizedStep): void => {
    setStep(newStep);
  };

  return [step, changeStep];
};

export default useAuthSteps;
