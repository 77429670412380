import React, { FunctionComponent, ReactNode } from 'react';
import classnames from 'classnames';
import _ from 'lodash/fp';

import { RequestStep } from '../../../../store/model/requests/types';
import Wrap from '../../../../components/containers/Wrap';
import { useStoreActions, useStoreState } from '../../../../store/hooks';
import ActionButton from '../../../../components/buttons/ActionButton';
import t from '../../../../text-strings';
import Title from '../../../../components/text-components/Title';

import StepHeader from './StepHeader';

interface Props {
  children: ReactNode;
  title: string;
  current: RequestStep;
  next?: RequestStep;
  prev?: RequestStep;
  step: number;
  totalSteps: number;
  disabled?: boolean;
}

const StepPage: FunctionComponent<Props> = (
  {
    children,
    title,
    current,
    next,
    prev,
    step,
    totalSteps,
    disabled = false,
  }: Props,
) => {
  const { requestStep } = useStoreState((state) => state.requests);
  const { setRequestStep } = useStoreActions((actions) => actions.requests);
  const isCurrent = _.isEqual(requestStep, current);

  const containerClass = classnames('lg:mb-20', {
    block: isCurrent,
    hidden: !isCurrent,
  });

  return (
    <Wrap size="wrap-authenticated" className={containerClass}>
      <StepHeader
        title={title}
        prev={prev}
        step={step}
        totalSteps={totalSteps}
      />
      <Title className="mb-12">{t(`requests.forms.${requestStep}`)}</Title>
      {children}
      {!_.isUndefined(next) && (
        <div>
          <ActionButton
            label={t('requests.forms.siguiente')}
            onClick={(): void => setRequestStep(next)}
            fixed
            disabled={disabled}
          />
        </div>
      )}
    </Wrap>
  );
};

export default StepPage;
