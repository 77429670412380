import Cookies from 'universal-cookie';

const cookies = new Cookies();

const setCookie = <T>(name: string, value: T, expires: Date): void => (
  cookies.set(name, value, {
    expires,
    path: '/',
    sameSite: true,
  })
);

const getCookie = <T>(name: string): T => (
  cookies.get(name)
);

const removeCookie = (name: string): void => (
  cookies.remove(name, { path: '/', sameSite: true })
);

const cookieStorage = {
  setCookie,
  getCookie,
  removeCookie,
};

export default cookieStorage;
