import { useState } from 'react';
import _ from 'lodash/fp';

import { UserParams } from '../store/model/user/types';
import azureAPI from '../store/model/azure/azure';
import { useStoreActions } from '../store/hooks';
import t from '../text-strings';

import { routes } from './routing';
import useNavigation from './navigation';
import documents from './documents';

const useRegisterResponse = (): [
  string,
  boolean,
  (r: string | UserParams, d: File[]) => Promise<void>
] => {
  const { rollbackcreateuser } = useStoreActions((actions) => actions.user);
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(true);
  const navigate = useNavigation();

  const handleResponse = async (
    r: string | UserParams,
    d: File[],
  ): Promise<void> => {
    if (typeof r === 'string') {
      setMessage(r);
      setSuccess(false);
      return;
    }

    const blob = 'usuarios';
    const azurePath = `dni/${r.nif.toUpperCase()}/`;

    try {
      setMessage(t('register.uploading'));
      for (let index = 0; index < _.size(d); index += 1) {
        const docPath = `${azurePath}${d[index].name}`;
        // eslint-disable-next-line
        await azureAPI().upload({ path: docPath, file: d[index], blob });
      }
      navigate(routes.registered);
    } catch (error) {
      setMessage(t('register.uploading_fail'));
      setSuccess(false);
      await documents.deleteAzurePath(azurePath, blob);
      setMessage(t('register.undoing'));
      const response = await rollbackcreateuser({
        userId: r.id,
        rollbackError: JSON.stringify(error),
      });
      if (typeof response === 'string') {
        setMessage(response);
      }
    }
  };

  return [message, success, handleResponse];
};

export default useRegisterResponse;
