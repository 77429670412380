import React, { FunctionComponent } from 'react';
import _ from 'lodash/fp';

import { useStoreState } from '../../store/hooks';
import { BreakdownStates } from '../../store/model/contract/types';
import t from '../../text-strings';

import ScheduledCutsAlert from './ScheduledCutsAlert';
import CutsAlert from './CutsAlert';
import BreakdownAlert from './BreakdownAlert';

interface Props {
  alias: string;
}

const BreakdownsAlerts: FunctionComponent<Props> = ({ alias }: Props) => {
  const { breakdowns } = useStoreState((state) => state.contract);

  const messages = _.map((breakdownState: BreakdownStates) => {
    if (_.isEqual(breakdownState.type, t('breakdowns.corte_programado'))) {
      return (
        <ScheduledCutsAlert
          key={`${breakdownState.startDate}_${breakdownState.type}`}
          alias={breakdownState.alias}
          startDate={breakdownState.startDate}
          // @ts-ignore
          endDate={breakdownState.endDate}
        />
      );
    }

    if (_.isEqual(breakdownState.type, t('breakdowns.corte'))) {
      return (
        <CutsAlert
          key={`${breakdownState.type}_${breakdownState.startDate}`}
          alias={breakdownState.alias}
          startDate={breakdownState.startDate}
          endDate={breakdownState.endDate}
          acceptDate={breakdownState.acceptEndDate}
        />
      );
    }

    return (
      <BreakdownAlert
        key={`${breakdownState.type}_${breakdownState.startDate}`}
        alias={breakdownState.alias}
        startDate={breakdownState.startDate}
      />
    );
  })(breakdowns.joinByAlias(alias));

  return (
    <>
      {messages}
    </>
  );
};

export default BreakdownsAlerts;
