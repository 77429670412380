import {
  action, Action, thunk, Thunk,
} from 'easy-peasy';

import { handleAuthorized, handleAxiosResponse } from '../../store-helpers';

import { AuthorizedParams, AuthorizedStep, RollbackAuthorized } from './types';

export interface AuthorizedModel {
  step: AuthorizedStep;

  setStep: Action<AuthorizedModel, AuthorizedStep>;

  create: Thunk<AuthorizedModel, AuthorizedParams>;
  rollback: Thunk<AuthorizedModel, RollbackAuthorized>;
}

const authorizedModel: AuthorizedModel = {
  step: 'supply',

  setStep: action((state, params) => {
    state.step = params;
  }),

  create: thunk(async (_actions, params, { injections }) => {
    const { authorizedService } = injections;
    return handleAuthorized(await authorizedService.create(params));
  }),

  rollback: thunk(async (_actions, params, { injections }) => {
    const { authorizedService } = injections;
    return handleAxiosResponse(await authorizedService.rollbackauthorizeuser(params));
  }),
};

export default authorizedModel;
