import React, { FormEvent, FunctionComponent, ReactNode } from 'react';

import classNames from '../../services/classNames';

interface Props {
  children: ReactNode;
  onSubmit: (e?: FormEvent<HTMLFormElement> | undefined) => void;
  className?: string;
}

const Form: FunctionComponent<Props> = ({ children, onSubmit, className = '' }: Props) => (
  <form onSubmit={onSubmit} className={classNames('block', className)}>
    {children}
  </form>
);

export default Form;
