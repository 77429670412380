import _ from 'lodash/fp';

import constants from '../services/constants';
import { AuthorizedForm } from '../store/model/authorized/types';
import { ContactParams } from '../store/model/contact/types';
import { AliasParams, ExistCupsParams } from '../store/model/contract/types';
import {
  ChangePasswordParams,
  ResetPasswordParams,
} from '../store/model/passwords/types';
import {
  CauParams,
  CupsParams,
  GenerationParams,
  SelfconsumptionParams,
  SupplyParams,
} from '../store/model/requests/types';
import { Session } from '../store/model/session/session';
import { SessionParams } from '../store/model/session/types';
import { EditUserParams, UserParams } from '../store/model/user/types';

import { SupplyGuide } from './supply-guide';

const SELFCONSUMPTION_FORM_ID = 1;
const CAU_FORM_ID = 2;
const SUPPLY_FORM_ID = 3;
const GENERATION_FORM_ID = 4;
const CUPS_FORM_ID = 5;

const EMPTY_FILE = new File([], '', { type: 'application/pdf' });

export type ChangePasswordForm = ChangePasswordParams & {
  repeatPassword: string;
};

export type UserForm = UserParams & {
  idFront: File;
  idBack: File;
  repeatEmail: string;
  repeatPassword: string;
  conditions: boolean;
};

const login: SessionParams = {
  username: '',
  password: '',
  sessionNoExpired: false,
  distributorId: constants.DISTRIBUTOR_ID,
};

const resetPassword: ResetPasswordParams = {
  nif: '',
  distributorId: constants.DISTRIBUTOR_ID,
};

const changePassword = (token: string): ChangePasswordForm => ({
  password: '',
  repeatPassword: '',
  distributorId: constants.DISTRIBUTOR_ID,
  token,
});

const user: UserForm = {
  id: 0,
  name: '',
  nif: '',
  password: '',
  userTypeId: 0,
  email: '',
  address: '',
  locality: '',
  province: '',
  provinceId: 1,
  pc: '',
  movilPrefix: '',
  movil: '',
  keyValidation: '',
  distributorId: constants.DISTRIBUTOR_ID,
  idFront: EMPTY_FILE,
  idBack: EMPTY_FILE,
  repeatEmail: '',
  repeatPassword: '',
  conditions: false,
};

const editUser = (session: Session): EditUserParams => ({
  nif: session.get('nif'),
  distributorId: constants.DISTRIBUTOR_ID,
  name: session.get('name'),
  address: session.get('address'),
  location: session.get('locality'),
  provinceId: 1,
  pc: session.get('pc'),
  email: session.get('email'),
  movil: session.get('movil'),
  movilPrefix: '',
  password: '',
  notifyByEmail: session.get('notifyByEmail'),
  notifyNonPaymentCuts: session.get('notifyNonPaymentCuts'),
  notifyFaults: session.get('notifyFaults'),
  notifyScheduledCuts: session.get('notifyScheduledCuts'),
});

const authorized: AuthorizedForm = {
  entitleUserNif: '',
  cups: '',
  idFront: EMPTY_FILE,
  idBack: EMPTY_FILE,
  authorization: EMPTY_FILE,
};

const alias: AliasParams = {
  name: '',
  cups: '',
};

const supplyGuide: SupplyGuide = {
  cups: 0,
  operative: 0,
  soil: 0,
  power: 0,
};

const supply = (session: Session): SupplyParams => ({
  supplyPointRequestId: 0,
  userId: session.get('id'),
  nameClient: '',
  nifClient: '',
  addressClient: '',
  localityClient: '',
  pcclient: '',
  movilPrefixClient: '',
  movilClient: '',
  emailClient: '',
  distributorId: 0,
  address: '',
  locality: '',
  pc: '',
  location: '',
  powerRequest: 0,
  clientType: 0,
  terrainType: 0,
  code: constants.SUPPLY_CODE,
  formId: SUPPLY_FORM_ID,
  use: 0,
  useDescription: '',
  formCode: constants.SUPPLY_CODE,
  tension: 0,
  instalationType: 0,
  provinceId: 78,
  provinceClientId: 0,
  observations: '',
  municipalityId: 0,
  technicalMemory: EMPTY_FILE,
  technicalDraft: EMPTY_FILE,
  authorization: EMPTY_FILE,
});

const cups = (session: Session): CupsParams => ({
  cupsrequestId: 0,
  userId: session.get('id'),
  nameClient: '',
  nifClient: '',
  addressClient: '',
  localityClient: '',
  pcclient: '',
  movilPrefixClient: '',
  movilClient: '',
  emailClient: '',
  distributorId: 0,
  address: '',
  locality: '',
  pc: '',
  location: '',
  powerRequest: 0,
  clientType: 0,
  terrainType: 0,
  code: constants.CUPS_CODE,
  formId: CUPS_FORM_ID,
  use: 0,
  useDescription: '',
  formCode: constants.CUPS_CODE,
  tension: 0,
  instalationType: 0,
  provinceId: 78,
  provinceClientId: 0,
  comments: '',
  observations: '',
  municipalityId: 0,
  cie: EMPTY_FILE,
  firstOccupation: EMPTY_FILE,
  deeds: EMPTY_FILE,
  authorization: EMPTY_FILE,
});

const selfconsumption = (session: Session): SelfconsumptionParams => ({
  requestId: 0,
  userId: session.get('id'),
  nameClient: '',
  nifClient: '',
  addressClient: '',
  localityClient: '',
  pcclient: '',
  movilPrefixClient: '',
  movilClient: '',
  emailClient: '',
  distributorId: 0,
  address: '',
  locality: '',
  pc: '',
  location: '',
  cups: '',
  powerContract: 0,
  clientType: 0,
  terrainType: 0,
  code: constants.SELFCONSUMPTION_CODE,
  formId: SELFCONSUMPTION_FORM_ID,
  powerInstalation: 0,
  powerSource: 0,
  powerSourceDesc: '',
  selfconsumptionType: 0,
  connectionType: 0,
  networked: 0,
  receivedCompensation: 0,
  hybridation: 0,
  hybridDescription: '',
  accumulation: 0,
  accumulationDescription: '',
  guaranteeAmount: 0,
  knot: '',
  envImpAssessment: 0,
  formCode: constants.SELFCONSUMPTION_CODE,
  tension: 0,
  provinceId: 78,
  provinceClientId: 0,
  observations: '',
  municipalityId: 0,
  technicalMemory: EMPTY_FILE,
  technicalDraft: EMPTY_FILE,
  authorization: EMPTY_FILE,
  warranty: EMPTY_FILE,
  environmentalImpact: EMPTY_FILE,
  communicationCompetentAdministration: EMPTY_FILE,
});

const generation = (session: Session): GenerationParams => ({
  generationRequestId: 0,
  userId: session.get('id'),
  nameClient: '',
  nifClient: '',
  addressClient: '',
  localityClient: '',
  pcclient: '',
  movilPrefixClient: '',
  movilClient: '',
  emailClient: '',
  distributorId: 0,
  location: '',
  locality: '',
  municipalityId: 0,
  clientType: 0,
  terrainType: 0,
  code: constants.GENERATION_CODE,
  formId: GENERATION_FORM_ID,
  powerInstalation: 0,
  powerSource: 0,
  powerSourceDesc: '',
  hybridation: 0,
  hybridDescription: '',
  accumulation: 0,
  accumulationDescription: '',
  guaranteeAmount: 0,
  knot: '',
  envImpAssessment: 0,
  formCode: constants.GENERATION_CODE,
  tension: 0,
  provinceClientId: 0,
  observations: '',
  internal: true,
  technicalDraft: EMPTY_FILE,
  authorization: EMPTY_FILE,
  warranty: EMPTY_FILE,
  environmentalImpact: EMPTY_FILE,
  communicationCompetentAdministration: EMPTY_FILE,
  t243: EMPTY_FILE,
  generalPlan: EMPTY_FILE,
  particularPlan: EMPTY_FILE,
  significativeDistances: EMPTY_FILE,
  generalImplantationPlan: EMPTY_FILE,
  generalImplantationPlan2: EMPTY_FILE,
});

const cau = (session: Session): CauParams => ({
  cauRequestId: 0,
  userId: session.get('id'),
  distributorId: 0,
  address: '',
  locality: '',
  pc: '',
  cups: '',
  terrainType: 0,
  code: constants.CAU_CODE,
  formId: CAU_FORM_ID,
  powerInstalation: 0,
  powerSource: 0,
  powerSourceDesc: '',
  selfconsumptionType: 0,
  connectionType: 0,
  networked: 0,
  receivedCompensation: 24,
  formCode: constants.CAU_CODE,
  tension: 0,
  provinceId: 78,
  observations: '',
  municipalityId: 0,
});

const existCups: ExistCupsParams = {
  streetTypeId: 0,
  cp: '',
  street: '',
  number: '',
};

const contact = (session: Session, select: string): ContactParams => ({
  name: _.isEqual(select, 'Notificar un fraude') ? '' : session.get('name'),
  email: _.isEqual(select, 'Notificar un fraude') ? '' : session.get('email'),
  comment: '',
  subject: select,
  descriptionForOthers: '',
  file: '',
  fileName: '',
  distributorId: 1,
  municipality: '',
  address: '',
  description: '',
  own: 0,
  addressContract: '',
  conditions: false,
});

const formInit = {
  login,
  resetPassword,
  changePassword,
  user,
  authorized,
  alias,
  supplyGuide,
  supply,
  existCups,
  cups,
  selfconsumption,
  generation,
  cau,
  editUser,
  contact,
};

export default formInit;
