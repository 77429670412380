import _ from 'lodash/fp';
import React, { FunctionComponent, useEffect, useState } from 'react';
// eslint-disable-next-line
import { RotatingLines } from "react-loader-spinner";

import Icons from '../../assets/icons';
import ActionButton from '../../components/buttons/ActionButton';
import Wrap from '../../components/containers/Wrap';
import Modal from '../../components/Modal';
import Select from '../../components/Select';
import Bodycopy from '../../components/text-components/Bodycopy';
import Title from '../../components/text-components/Title';
import { useStoreActions, useStoreState } from '../../store/hooks';
import AuthenticatedLayout from '../../layout/AuthenticatedLayout';
import { Contract } from '../../store/model/contract/contract';
import Tag from '../../components/Tag';
import date from '../../services/date';
import InternalLink from '../../components/InternalLink';
import { routes } from '../../services/routing';
import Caption from '../../components/text-components/Caption';
import helpers from '../../services/helpers';
import useNavigation from '../../services/navigation';

type CTData = {
  power: string;
  state: string;
};

type MeasureData = {
  measurePower: number;
  measureConsumption: number;
};

const parseDateLabel = (dateString: string) => {
  const parsedLabel = `${date.format(dateString, 'DD.MM.YYYY - HH:mm')}h`;

  return parsedLabel;
};

const isValidResponse = _.flow(_.get('status'), _.inRange(200, 300));

const ICPState: FunctionComponent = () => {
  const { contracts, contract } = useStoreState((state) => state.contract);
  const { show } = useStoreActions((actions) => actions.contract);
  const {
    getctdata,
    getscheduledsbycups,
    deletescheduledmeasure,
    takemeasure,
    reconnecticp,
  } = useStoreActions((actions) => actions.contract);
  const [scheduledMeasure, setScheduledMeasure] = useState([]);
  const [consumption, setConsumption] = useState<CTData>({
    power: '',
    state: '',
  });
  const [hasErrorRequest, setHasErrorRequest] = useState(false);
  const [isLoadingTakenMeasure, setIsLoadingTakenMeasure] = useState(false);
  const [hasErrorTakenMeasure, setHasErrorTakeMeasure] = useState(false);
  const [measureRequested, setMeasureRequested] = useState(false);
  const [measureData, setMeasureData] = useState<null | MeasureData>(null);
  const [hasScheduledMeasures, setHasScheduledMeasures] = useState(true);
  const [scheduleds, setScheduleds] = useState([]);
  const [power, setPower] = useState(0);
  const [counterDataRequested, setCounterDataRequested] = useState(false);
  const [counterDataLoading, setCounterDataLoading] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const navigate = useNavigation();

  const [isOpen, setIsOpen] = useState(false);
  const toggleModal = (): void => setIsOpen(!isOpen);
  const toggleModelRequest = (): void => {
    setMeasureRequested(false);
    setMeasureData(null);
  };

  useEffect(() => {
    (async () => {
      setConsumption({ power: '', state: '' });
      setCounterDataLoading(false);
      setCounterDataRequested(false);
      setHasScheduledMeasures(true);
      setScheduledMeasure([]);
      const cups: string = await contract.get('cups');

      if (cups.length > 0) {
        const response = await getscheduledsbycups(cups);
        const { data, status } = response;

        if (status !== 200) {
          setHasErrorRequest(true);
          setHasScheduledMeasures(false);
        } else {
          const scheduledMeasures = data;
          setScheduleds(data);

          const filteredMeasures = scheduledMeasures.filter(
            (measure: any) => !measure.scheduled.finished,
          );

          if (filteredMeasures.length > 0) {
            setHasScheduledMeasures(true);
            const initDate = filteredMeasures[0].scheduled.startMeasureDate;
            setIsEditable(!date.isBrefore(initDate, new Date()));
            setScheduledMeasure(filteredMeasures[0]);
          } else {
            setHasScheduledMeasures(false);
          }
        }
      } else {
        setHasScheduledMeasures(false);
      }
    })();
  }, [contract]);

  useEffect(() => {
    (async () => {
      if (!_.isEmpty(contracts)) {
        show({ id: contracts[0].get('idcontrato'), contracts });
      }
    })();
  }, [contracts]);

  const setTagState = (): string => {
    if (_.isEqual(consumption.state, 'Conectado')) {
      return 'activa';
    }

    if (_.isEqual(consumption.state, 'Desconectado')) {
      return 'desconectado';
    }

    return 'mantenimiento';
  };

  const handleContractChange = (id: string): void => {
    show({ id, contracts });
  };

  const deleteSecheduled = async () => {
    // @ts-ignore
    const id = scheduledMeasure.scheduled.meterMeasureScheduledId;

    const response = await deletescheduledmeasure(id);
    if (response.status === 200) {
      window.location.reload();
    }
  };

  const getCounterData = async () => {
    setConsumption({ state: '', power: '' });
    setCounterDataLoading(true);
    setCounterDataRequested(false);
    const cups: string = await contract.get('cups');
    const response = await getctdata(cups);

    if (isValidResponse(response)) {
      setCounterDataLoading(false);
      setConsumption(response.data);
      setCounterDataRequested(true);
    } else {
      setConsumption({ state: 'Desconectado', power: '' });
    }
  };

  const takeMeasure = async () => {
    setMeasureRequested(true);
    setIsLoadingTakenMeasure(true);
    setHasErrorTakeMeasure(false);

    const cups: string = await contract.get('cups');
    const response = await takemeasure(cups);
    setPower(contract.highPower());
    setIsLoadingTakenMeasure(false);
    if (response.status === 200) {
      setMeasureData(response.data);
    } else {
      setHasErrorTakeMeasure(true);
    }
  };

  const reconnectICP = async () => {
    const cups: string = await contract.get('cups');
    await reconnecticp(cups);
    setConsumption({ state: '', power: '' });
    setCounterDataRequested(false);
  };

  // const editScheduled = async () => history.push({
  //   pathname: routes.counterSchedule,
  //   // @ts-ignore
  //   state: scheduledMeasure.scheduled,
  // });

  return (
    <div>
      <div className="relative py-12 bg-white">
        <div className="absolute top-0 hidden h-full bg-white left-full w-screen-full lg:block" />
        <Wrap
          size="wrap-authenticated"
          className="flex flex-wrap lg:items-center"
        >
          <Title
            size="small"
            tag="h4"
            className="w-1/2 mb-4 lg:mb-0 lg:w-auto lg:mr-10"
          >
            Detalles de
          </Title>
          <div className="flex justify-end w-1/2 mb-4 lg:mb-0 lg:order-3 lg:flex-1">
            <InternalLink
              label="Ver contrato"
              to={routes.contract(contract.get('idcontrato'))}
              className="text-blue"
            />
          </div>
          <Select selected={contract.title} type="select-underline">
            {_.map((contractItem: Contract) => (
              <div
                key={contractItem.get('idcontrato')}
                onClick={() => handleContractChange(contractItem.get('idcontrato'))}
              >
                <Bodycopy className="truncate">{contractItem.title}</Bodycopy>
              </div>
            ))(contracts)}
          </Select>
        </Wrap>
      </div>
      <div className="pt-6 bg-white lg:bg-transparent">
        <Wrap
          size="wrap-authenticated"
          className="flex flex-wrap lg:items-center"
        >
          <div className="flex flex-col justify-between w-full mt-10 lg:justify-start">
            <Title tag="h1" className="hidden w-full mb-12 lg:flex lg:w-1/2">
              Mi contador
            </Title>
            <div className="flex justify-between w-full lg:justify-start lg:mb-12">
              <div className="flex items-center lg:mr-16">
                <Title
                  size="small"
                  tag="h3"
                  className="mr-6 lg:mb-0 lg:w-auto lg:mr-10"
                >
                  Estado ICP
                </Title>
                <ActionButton
                  type="link"
                  label=""
                  onClick={toggleModal}
                  className="h-12 lg:hidden"
                  icon={<Icons.Help color="blue" />}
                />
                <ActionButton
                  type="link"
                  label=""
                  onClick={toggleModal}
                  className="h-14"
                  icon={<Icons.Info color="blue" className="hidden lg:flex" />}
                />
              </div>
              {!counterDataRequested && !counterDataLoading && (
                <ActionButton
                  type="link"
                  label="Consultar"
                  onClick={() => getCounterData()}
                  className="text-sm font-semibold"
                />
              )}
              {counterDataLoading && (
                <div className="flex items-center">
                  <RotatingLines
                    strokeColor="black"
                    strokeWidth="2"
                    animationDuration="1.5"
                    width="24"
                    visible
                  />
                  <p className="ml-4 text-xs font-medium text-gray-500">
                    Conectando...
                  </p>
                </div>
              )}
              {counterDataRequested && (
                <div>
                  <Tag
                    type={setTagState()}
                    label={consumption.state}
                    className="text-xs"
                  />
                </div>
              )}
              <div className="hidden lg:flex">
                {setTagState() === 'activa' && (
                  <button
                    type="button"
                    className="text-xs font-medium text-blue lg:text-base lg:font-semibold lg:ml-10"
                    onClick={() => getCounterData()}
                  >
                    Volver a consultar
                  </button>
                )}

                {setTagState() === 'desconectado' && (
                  <div>
                    <ActionButton
                      type="secondary"
                      label="Reconectar"
                      onClick={() => reconnectICP()}
                      className="mb-8 lg:mb-0 lg:ml-6 lg:-mt-4"
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="flex justify-end w-full mb-12 lg:hidden">
              {setTagState() === 'activa' && (
                <button
                  type="button"
                  className="text-xs font-medium text-blue"
                  onClick={() => getCounterData()}
                >
                  Volver a consultar
                </button>
              )}

              {setTagState() === 'desconectado' && (
                <div>
                  <ActionButton
                    type="secondary"
                    label="Reconectar"
                    onClick={() => reconnectICP()}
                    className="mb-8"
                  />
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-col w-full lg:flex-row lg:align-middle">
            <div className="lg:w-4/5">
              <Bodycopy>
                Puedes saber tu consumo de potencia en tiempo real o programar
                una medición para el futuro.
              </Bodycopy>
            </div>
            <div className="flex w-full mt-16 mb-16 lg:justify-center lg:mt-0 lg:mb-0 min-h-18 max-h-18">
              <ActionButton
                type="secondary"
                label="Realizar medición"
                onClick={() => takeMeasure()}
                className="mr-20"
              />
              <ActionButton
                onClick={(): void => navigate(routes.counterSchedule)}
                label="Programar medición"
                className={hasScheduledMeasures ? 'text-gray' : 'text-blue'}
                type="link"
                disabled={hasScheduledMeasures}
              />
            </div>
          </div>
          <Modal isOpen={measureRequested} toggleOpen={toggleModelRequest}>
            <p className="mb-4 btn">Resultado de la medición</p>
            <p className="text-xs font-medium text-gray-500">
              {parseDateLabel(`${new Date()}`)}
            </p>
            {isLoadingTakenMeasure && (
              <div>
                <div className="flex items-start mt-8">
                  <RotatingLines
                    strokeColor="black"
                    strokeWidth="2"
                    animationDuration="1.5"
                    width="24"
                    visible
                  />
                  <p className="ml-6 text-xs">
                    Estamos conectando con tu contador, esto nos puede llevar
                    unos minutos
                  </p>
                </div>
                <div className="flex w-full mt-8 mb-8 border rounded-sm border-cream">
                  <div className="w-1/2 py-4 pl-6">
                    <p className="mb-2 text-xs text-gray-500">Potencia</p>
                    <p className="text-lg font-semibold text-gray-500">-- kW</p>
                  </div>
                  <div className="py-4 pl-6">
                    <p className="mb-2 text-xs text-gray-500">Lectura</p>

                    <p className="text-lg font-semibold text-gray-500">
                      -- kWh
                    </p>
                  </div>
                </div>
              </div>
            )}
            {hasErrorTakenMeasure && (
              <div className="flex p-8 mt-12 rounded-sm mb-28 lg:mb-32 bg-orange-lightest">
                <Icons.Warning color="orange" className="mr-6" />
                <div className="flex-1">
                  <Bodycopy color="orange" className="mb-4">
                    No hemos podido acceder a tu contador
                  </Bodycopy>
                  <ActionButton
                    onClick={() => takeMeasure()}
                    label="Volver a intentar"
                    type="link"
                  />
                </div>
              </div>
            )}
            {measureData && (
              <div className="mt-4">
                <p className="text-xs">
                  ¡Recuerda que tu potencia contratada es de
                  {' '}
                  {power}
                  kW!
                </p>
                <div className="flex w-full mt-8 mb-8 border rounded-sm border-cream">
                  <div className="w-1/2 py-4 pl-6">
                    <p className="mb-2 text-xs">Potencia</p>
                    <p className="text-lg font-semibold">
                      {/* @ts-ignore */}
                      {helpers.roundNumber(measureData.measurePower, 2).toFixed(2)}
                      kW
                    </p>
                  </div>
                  <div className="py-4 pl-6">
                    <p className="mb-2 text-xs">Lectura</p>

                    <p className="text-lg font-semibold">
                      {measureData.measureConsumption}
                      kWh
                    </p>
                  </div>
                </div>
              </div>
            )}
          </Modal>
          <Modal isOpen={isOpen} toggleOpen={toggleModal}>
            <p className="mb-8 btn">Estado del ICP</p>
            <Bodycopy className="mb-4">
              El ICP es el interruptor que da luz a nuestra vivienda o local.
              Cuando superamos la potencia que tenemos contratada porque
              enchufamos demasiados aparatos electrónicos, este interruptor se
              desconecta y nos quedamos sin electricidad
            </Bodycopy>
          </Modal>
          {/* @ts-ignore */}
          {hasErrorRequest && (
            <div className="flex w-full p-8 mt-4 mb-20 rounded-sm lg:mb-20 lg:mt-28 bg-orange-lightest">
              <Icons.Warning color="orange" className="mr-6" />
              <div className="flex-1">
                <Bodycopy color="orange" className="mb-4">
                  No hemos podido acceder a tus mediciones programadas
                </Bodycopy>
                <ActionButton
                  onClick={() => window.location.reload()}
                  label="Volver a intentar"
                  type="link"
                />
              </div>
            </div>
          )}
          {!hasErrorRequest
            && (scheduleds as any)?.filter((m: any) => m.scheduled.finished === false)?.length > 0
            && (
              <div className="w-full px-8 py-8 mt-8 mb-8 bg-white border rounded-sm lg:justify-between lg:flex border-cream lg:mt-28 lg:py-0 lg:px-0">
                <div className="flex justify-between lg:w-2/5 lg:justify-start lg:pl-16">
                  <p className="mb-8 text-sm font-semibold lg:text-base lg:inline-flex lg:items-center lg:mb-0">
                    Medición programada
                  </p>
                  {isEditable && (
                    <div className="flex lg:hidden">
                      {/* <ActionButton
                      label=""
                      type="link"
                      icon={<Icons.Pencil color="blue" />}
                      onClick={() => editScheduled()}
                    /> */}
                      <ActionButton
                        label=""
                        type="link"
                        icon={<Icons.Trash color="blue" />}
                        onClick={() => deleteSecheduled()}
                      />
                    </div>
                  )}
                </div>
                <div className="lg:flex lg:space-x-4 lg:pt-14 lg:pl-16 lg:pb-14 lg:w-full">
                  <Caption className="mb-4 text-gray-500 lg:text-gray-400 lg:text-base lg:font-normal lg:mb-0">
                    {` Del ${
                      /* @ts-ignore */
                      parseDateLabel(
                        /* @ts-ignore */
                        scheduledMeasure?.scheduled?.startMeasureDate,
                      )
                    } `}
                  </Caption>
                  <Caption className="text-gray-500 lg:mb-0 lg:text-gray-400 lg:text-base lg:font-normal">
                    {` al ${
                      /* @ts-ignore */
                      parseDateLabel(scheduledMeasure?.scheduled?.endMeasureDate)
                    }`}
                  </Caption>
                </div>
                {isEditable && (
                  <div className="items-center hidden mr-24 lg:flex">
                    {/* <ActionButton
                    label=""
                    type="link"
                    icon={<Icons.Pencil color="blue" />}
                    onClick={() => editScheduled()}
                  /> */}
                    <ActionButton
                      label=""
                      type="link"
                      icon={<Icons.Trash color="blue" />}
                      onClick={() => deleteSecheduled()}
                    />
                  </div>
                )}
              </div>
            )}
        </Wrap>
      </div>
    </div>
  );
};

const CounterHistory: FunctionComponent = () => {
  const { contract } = useStoreState((state) => state.contract);
  const { getscheduledsbycups } = useStoreActions(
    (actions) => actions.contract,
  );

  const [isLoading, setIsLoading] = useState(false);
  const [finishedMeasures, setFinishedMeasures] = useState<any>([]);
  const [hasErrorRequest, setHasErrorRequest] = useState(false);
  const [power, setPower] = useState(0);

  useEffect(() => {
    (async () => {
      setHasErrorRequest(false);
      setIsLoading(true);
      setFinishedMeasures([]);
      setPower(contract.highPower());
      const cups: string = await contract.get('cups');

      if (cups.length > 0) {
        const response = await getscheduledsbycups(cups);
        const { status, data } = response;

        if (status !== 200) {
          setHasErrorRequest(true);
          setIsLoading(false);
        } else {
          const measuresRec = data;

          let filteredMeasures = measuresRec?.filter((measure: any) => measure.scheduled.finished)
            ?? [];

          filteredMeasures = _.orderBy(
            [(o: any) => o.scheduled.startMeasureDate],
            'desc',
            filteredMeasures,
          );

          setIsLoading(false);
          setFinishedMeasures(filteredMeasures);
        }
      }
    })();
  }, [contract]);

  const [isOpen, setIsOpen] = useState(false);
  const toggleModal = (): void => setIsOpen(!isOpen);

  return (
    <div className="w-full">
      {!isLoading && (
        <div>
          <div className="lg:flex lg:flex-col">
            <Title size="small" tag="h3" className="lg:mb-4">
              Histórico
            </Title>
            <div className="hidden mb-4 space-x-8 lg:flex">
              <Bodycopy>
                Tu potencia contratada es de
                {' '}
                {power}
                kW
              </Bodycopy>
              <ActionButton
                type="link"
                label=""
                onClick={toggleModal}
                icon={<Icons.Help color="blue" />}
              />
            </div>
            {finishedMeasures.length > 0 && (
              <div className="hidden w-full text-sm font-semibold text-gray-400 lg:flex">
                <div className="w-9/20" />
                <p className="mr-6 w-3/20">Potencia máxima</p>
                <p className="w-3/20">Consumo medio</p>
              </div>
            )}
          </div>
          <div className="flex lg:mb-8 lg:hidden">
            <Bodycopy>
              ¡Recuerda tu potencia contratada es de
              {' '}
              {power}
              kW!
              <ActionButton
                type="link"
                label=""
                onClick={toggleModal}
                icon={(
                  <Icons.Help
                    color="blue"
                    height={10}
                    width={10}
                    className="inline-flex items-center pb-2 ml-4"
                  />
                )}
              />
            </Bodycopy>
          </div>
          {hasErrorRequest && (
            <div className="flex p-8 mt-12 mb-20 rounded-sm bg-orange-lightest">
              <Icons.Warning color="orange" className="mr-6" />
              <div className="flex-1">
                <Bodycopy color="orange" className="mb-4">
                  No hemos podido acceder al histórico de tus mediciones
                  programadas
                </Bodycopy>
                <ActionButton
                  onClick={() => window.location.reload()}
                  label="Volver a intentar"
                  type="link"
                />
              </div>
            </div>
          )}

          <Modal isOpen={isOpen} toggleOpen={toggleModal}>
            <p className="mb-8 btn">Potencia contratada</p>
            <Bodycopy className="mb-4">
              Es el número máximo de kW que puedes consumir en un momento
              determinado. Lo contratas a través de tu comercializadora.
            </Bodycopy>
            <Bodycopy className="mb-4">
              A mayor potencia contratada, mayor será tu factura por lo que te
              recomendamos que la ajustes en función de tu consumo.
            </Bodycopy>
          </Modal>

          <div>
            {finishedMeasures.length > 0 && (
              <div className="flex justify-end mt-12 text-xs text-gray-400 lg:hidden lg:mt-0">
                <div className="w-3/5 ml-8" />
                <p className="flex w-1/5 pr-2">Potencia máxima</p>
                <p className="flex w-1/5 mr-8">Consumo medio</p>
              </div>
            )}
            {_.map((measure: any) => (
              <div className="px-8 py-8 mt-8 mb-8 bg-white rounded-sm lg:justify-between lg:flex lg:px-0 lg:py-0" key={measure.scheduled.meterMeasureScheduledId}>
                <div className="flex lg:space-x-4 lg:pt-14 lg:pl-16 lg:pb-14 lg:w-3/4">
                  <div className="w-3/5 lg:flex lg:items-center">
                    <Caption className="mb-4 text-gray-500 lg:text-gray-400 lg:text-base lg:font-normal lg:mb-0">
                      {`Del ${parseDateLabel(
                        measure.scheduled.startMeasureDate,
                      )} `}
                    </Caption>
                    <Caption className="mb-8 text-gray-500 lg:mb-0 lg:text-gray-400 lg:text-base lg:font-normal lg:pl-2">
                      {`al ${parseDateLabel(measure.scheduled.endMeasureDate)}`}
                    </Caption>
                  </div>
                  <div className="w-1/5 text-xs font-medium lg:text-md lg:font-semibold lg:pr-20">
                    {helpers.roundNumber(measure.maxPower, 2)}
                    kW
                  </div>
                  <div className="w-1/5 text-xs font-medium lg:text-md lg:font-semibold">
                    {helpers.roundNumber(measure.averageConsumption, 2)}
                    kWh
                  </div>
                </div>
                <div className="lg:flex lg:pr-16">
                  <InternalLink
                    to={routes.measure(
                      measure.scheduled.meterMeasureScheduledId,
                    )}
                    label="Ver más detalles"
                    className="text-blue lg:text-sm "
                  />
                </div>
              </div>
            ))(finishedMeasures)}
          </div>
        </div>
      )}
      {isLoading && (
        <div className="flex items-start lg:mt-24">
          <RotatingLines
            strokeColor="black"
            strokeWidth="2"
            animationDuration="1.5"
            width="24"
            visible
          />
          <div>
            <p className="ml-8 text-xs font-medium text-gray-500 lg:text-base">
              Estamos cargando la información de tu contador.
            </p>
            <p className="ml-8 text-xs font-medium text-gray-500 lg:text-base">
              Esto puede tardar unos minutos
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

const Counter: FunctionComponent = () => (
  <AuthenticatedLayout title="Mi contador">
    <ICPState />
    <Wrap size="wrap-authenticated" className="py-16 lg:flex">
      <CounterHistory />
    </Wrap>
  </AuthenticatedLayout>
);

export default Counter;
