import React, { FunctionComponent, ReactElement, useState } from 'react';
import classNames from 'classnames';

import Icons from '../assets/icons';

import Bodycopy from './text-components/Bodycopy';

export interface Props {
  title: string;
  text: string | ReactElement;
}

const Accordion: FunctionComponent<Props> = ({ title, text }: Props) => {
  const [open, setOpen] = useState(false);

  const toggeAccordion = (): void => setOpen(!open);

  const titleClass = classNames('flex justify-between items-center cursor-pointer', {
    'mb-4': open,
  });

  const answerClass = classNames('overflow-hidden', {
    'max-h-0': !open,
    'max-h-none': open,
  });

  return (
    <div className="p-8 shadow-blur rounded-sm lg:p-12 mb-4 lg:mb-8 bg-white">
      <div className={titleClass} onClick={toggeAccordion}>
        <p className="btn">{title}</p>
        <div className="min-w-12 ml-8">
          {open ? <Icons.Minus color="blue" /> : <Icons.Plus color="blue" /> }
        </div>
      </div>
      <div className={answerClass}>
        {(typeof text === 'string'
          ? <Bodycopy>{text}</Bodycopy>
          : text
        )}
      </div>
    </div>
  );
};

export default Accordion;
