import logoWhite from './images/logo-cuerva-white.png';
import bannerDesktop from './images/banner-image.jpg';
import bannerMobile from './images/banner-mobile.png';
import logoBlack from './images/logo-cuerva-black.png';
import logoIcon from './images/logo-icon.png';
import logoBermejales from './images/bermejales-logo.png';
import logoBuenaventura from './images/buenaventura-logo.png';
import logoGregorio from './images/gregorio-logo.png';
import logoGuadalfeo from './images/guadalfeo-logo.png';
import subBannerDesktop from './images/subbanner-desktop.jpg';
import subBannerMobile from './images/subbanner-mobile.png';
import selfConsumptionSharedVecinos from './images/autoconsumo-compartido-vecinos.svg';
import selfConsumptionSharedEmpresas from './images/autoconsumo-compartido-empresas.svg';
import selfConsumptionSharedMunicipios from './images/autoconsumo-compartido-municipios.svg';
import instalacionColectiva from './images/instalacion-colectiva.svg';
import instalacionColectivaSinExcedentes from './images/instalacion-colectiva-sin-excedentes.svg';
import instalacionColectivaConExcedentes from './images/instalacion-colectiva-con-excedentes.svg';
import selfConsumptionResidential from './images/autoconsumo-residencial.png';
import selfConsumptionBuilding from './images/autoconsumo-edificio.png';
import selfConsumptionCard from './images/autoconsumo-card.png';
import selfConsumptionShared from './images/autoconsumo-compartido.png';
import selfConsumptionCompensation from './images/autoconsumo-compensacion.png';
import ahorro from './images/autoconsumo-ahorro.png';
import ahorroMVL from './images/autoconsumo-ahorro-mvl.png';
import keyConcepts1 from './images/conceptos-clave-1.png';
import keyConcepts2 from './images/conceptos-clave-2.png';
import individualExcedentes from './images/autoconsumo-individual-con-excedentes.png';
import individualSinExcedentes from './images/autoconsumo-individual-sin-excedentes.png';
import individualSinExcedentesFactura from './images/autoconsumo-individual-sin-excedentes-factura.png';
import individualSinExcedentesFacturaMVL from './images/autoconsumo-individual-sin-excedentes-factura-mvl.png';
import individualConExcedentesFacturaMVL from './images/autoconsumo-individual-con-excedentes-factura-mvl.png';
import individualConExcedentesCompensacion from './images/autoconsumo-individual-con-excedentes-compensacion.png';
import individualConExcedentesCompensacionMVL from './images/autoconsumo-individual-con-excedentes-compensacion-mvl.png';
import individualConExcedentesFactura from './images/autoconsumo-individual-con-excedentes-factura.png';
import euFund from './images/eu-funded-project.svg';
import projectPlan from './images/transformation-plan-eu.svg';
import imageSorteo from './images/sorteo.png';
import imageSorteoMobile from './images/sorteo_movil.png';
import imageSorteoHeader from './images/sorteo-header-pc.png';
import imageSorteoHeaderMobile from './images/sorteo-header-movil.png';
import imagenGanadores1 from './images/ganadores-1.jpg';
import imagenGanadores2 from './images/ganadores-2.jpg';

const images = {
  sorteoDesktop: imageSorteo,
  sorteoMobile: imageSorteoMobile,
  sorteoHeaderPc: imageSorteoHeader,
  sorteoHeaderMobile: imageSorteoHeaderMobile,
  ganadores1: imagenGanadores1,
  ganadores2: imagenGanadores2,
  logo: {
    white: logoWhite,
    black: logoBlack,
    icon: logoIcon,
  },
  banner: {
    mobile: bannerMobile,
    desktop: bannerDesktop,
  },
  subbanner: {
    mobile: subBannerMobile,
    desktop: subBannerDesktop,
  },
  distribuidoras: {
    bermejales: logoBermejales,
    buenaventura: logoBuenaventura,
    gregorio: logoGregorio,
    guadalfeo: logoGuadalfeo,
  },
  funded: {
    projectPlan,
    euFund,
  },
  autoconsumo: {
    keyConcepts1,
    keyConcepts2,
    individualExcedentes,
    individualSinExcedentes,
    ahorro,
    ahorroMVL,
    individualSinExcedentesFactura,
    individualSinExcedentesFacturaMVL,
    individualConExcedentesFactura,
    individualConExcedentesFacturaMVL,
    individualConExcedentesCompensacion,
    individualConExcedentesCompensacionMVL,
    residencial: selfConsumptionResidential,
    edificio: selfConsumptionBuilding,
    card: selfConsumptionCard,
    compartido: {
      index: selfConsumptionShared,
      vecinos: selfConsumptionSharedVecinos,
      empresas: selfConsumptionSharedEmpresas,
      municipios: selfConsumptionSharedMunicipios,
      inscolectiva1: instalacionColectiva,
      instcolectivacon: instalacionColectivaConExcedentes,
      instcolecticasin: instalacionColectivaSinExcedentes,
    },
    compensacion: selfConsumptionCompensation,
  },
};

export default images;
