import { FormikErrors, FormikTouched } from 'formik';
import _ from 'lodash/fp';
import React, { FunctionComponent } from 'react';

import TextInput from '../../../../components/forms/TextInput';
import Bodycopy from '../../../../components/text-components/Bodycopy';
import Title from '../../../../components/text-components/Title';
import { UserForm } from '../../../../forms/initial-values';
import useSteps from '../../../../store/model/register/register';
import t from '../../../../text-strings';
import FormStepScreen from '../components/FormStepScreen';
import FormStepsNavigation from '../components/FormStepsNavigation';

/* eslint-disable @typescript-eslint/no-explicit-any */
interface Props {
  values: UserForm;
  onChange: {
    (e: React.ChangeEvent<any>): void;
    <T_1 = string | React.ChangeEvent<any>>(
      field: T_1
    ): T_1 extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
  onBlur: {
    (e: React.FocusEvent<any, Element>): void;
    <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
  };
  touched: FormikTouched<UserForm>;
  errors: FormikErrors<UserForm>;
}
/* eslint-enable @typescript-eslint/no-explicit-any */

const YourSuplyPoint: FunctionComponent<Props> = ({
  values,
  onChange,
  onBlur,
  touched,
  errors,
}: Props) => {
  const [step, setStep] = useSteps();

  return (
    <FormStepScreen
      onNext={() => setStep('access-data')}
      onBack={() => setStep('id-card')}
      current={_.isEqual(step, 'supply-point')}
    >
      <FormStepsNavigation
        blockClassNames={['bg-black', 'bg-black', 'bg-black', '']}
      />
      <Title className="mb-4 lg:mb-8">{t('register.punto_suministro')}</Title>
      <Bodycopy className="mb-4 lg:mb-8 max-w-240 w-full">
        {t('register.punto_suministro_subtitle')}
      </Bodycopy>
      <TextInput
        name="address"
        placeholder={t('models.user.attributes.address')}
        value={values.address}
        onChange={onChange('address')}
        onBlur={onBlur('address')}
        touched={touched.address}
        errors={errors.address}
        required
      />
      <TextInput
        name="locality"
        placeholder={t('models.user.attributes.locality')}
        value={values.locality}
        onChange={onChange('locality')}
        onBlur={onBlur('locality')}
        touched={touched.locality}
        errors={errors.locality}
        required
      />
      <TextInput
        name="pc"
        placeholder={t('models.user.attributes.pc')}
        value={values.pc}
        onChange={onChange('pc')}
        onBlur={onBlur('pc')}
        touched={touched.pc}
        errors={errors.pc}
        required
      />
      <TextInput
        name="province"
        placeholder={t('models.user.attributes.province')}
        value={values.province}
        onChange={onChange('province')}
        onBlur={onBlur('province')}
        touched={touched.province}
        errors={errors.province}
        required
      />
    </FormStepScreen>
  );
};

export default YourSuplyPoint;
