import React, { FunctionComponent, ReactNode } from 'react';

import classNames from '../../../services/classNames';

interface Props {
  name: string;
  label: string | ReactNode;
  className?: string;
  required?: boolean;
}

const InputLabel: FunctionComponent<Props> = (
  {
    name,
    className = '',
    label,
    required = false,
  }: Props,
) => (
  <label htmlFor={name} className={classNames('caption', className)}>
    {label}
    {required && <sup>*</sup>}
  </label>
);

export default InputLabel;
