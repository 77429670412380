import React, { FunctionComponent } from 'react';

import { AppColors } from '../../styles';

interface Props {
  color?: AppColors | string;
  width?: number;
  height?: number;
  className?: string;
}

const ArrowRight: FunctionComponent<Props> = (
  {
    color = '#1F61FF',
    width = 8,
    height = 8,
    className = '',
  }: Props,
) => (
  <svg viewBox="0 0 8 12" xmlns="http://www.w3.org/2000/svg" className={`w-${width} h-${height} ${className}`}>
    <path
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M1.29006 2.12002L5.17006 6.00002L1.29006 9.88002C0.900059 10.27 0.900059 10.9 1.29006 11.29C1.68006 11.68 2.31006 11.68 2.70006 11.29L7.29006 6.70002C7.68006 6.31002 7.68006 5.68002 7.29006 5.29002L2.70006 0.700021C2.31006 0.31002 1.68006 0.31002 1.29006 0.700021C0.910058 1.09002 0.900058 1.73002 1.29006 2.12002Z"
      fill={color}
    />
  </svg>
);

export default ArrowRight;
