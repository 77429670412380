import { AxiosResponse } from 'axios';
import { ActionCreator } from 'easy-peasy';
import _ from 'lodash/fp';

import { FAQAttributes } from './model/contact/types';
import {
  RequestAttributesResponse,
  RequestChangeResponseAttributes,
} from './model/requests/types';
import { UserParams } from './model/user/types';

export const isValidResponse = _.flow(_.get('status'), _.inRange(200, 300));
// eslint-disable-next-line
export const handleAxiosResponse = <T>(
  r: AxiosResponse,
  cb?: ActionCreator<T>,
): string | boolean => {
  if (!isValidResponse(r)) return r.data.message;

  if (!_.isUndefined(cb)) cb(r.data);
  return true;
};

export const handleRegister = (r: AxiosResponse): string | UserParams => {
  if (!isValidResponse(r)) return r.data.message;

  return r.data;
};

export const handleAuthorized = (r: AxiosResponse): string | number => {
  if (!isValidResponse(r)) return r.data.message;

  return r.data;
};

export const handleBooleanResponse = (r: AxiosResponse): string | boolean => {
  if (!isValidResponse(r)) return r.data.message;
  return r.data;
};

export const handleDataResponse = (r: AxiosResponse): FAQAttributes[] => {
  if (!isValidResponse(r)) return r.data.message;
  return r.data;
};

export const handleRequestResponse = (
  r: AxiosResponse,
): string | RequestAttributesResponse => {
  if (!isValidResponse(r)) return r.data.message as string;

  return r.data as RequestAttributesResponse;
};

export const handleChangeResponse = (
  r: AxiosResponse,
): string | RequestChangeResponseAttributes => {
  if (!isValidResponse(r)) return r.data.message as string;

  return r.data as RequestChangeResponseAttributes;
};
