import React, { FunctionComponent } from 'react';
import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  Label,
  Tooltip,
  BarChart,
  Bar,
} from 'recharts';

import helpers from '../../services/helpers';
import { Any } from '../../store/model/global';

interface BarChartPrevYearProps {
  data: Any;
}

const BarChartTooltip: FunctionComponent = ({ active, payload }: Any) => {
  if (active && payload && payload.length) {
    return (
      <div className="flex flex-col p-6 bg-white border border-black rounded-sm">
        <div className="flex items-center mb-2">
          <p className="text-xs font-bold">
            {`Mes ${helpers.parseMonthTooltip(
              payload[0].payload.name,
            )}`}
          </p>
        </div>
        <div className="flex items-center mb-2">
          <div className="w-6 h-6 mr-4 rounded bg-yellow min-h-6 min-w-6" />
          <p className="text-xs">
            {`${helpers.toStringDecimal(
              payload[0].payload.energy,
              2,
            )} kW Consumida`}
          </p>
        </div>
        <div className="flex items-center mb-2">
          <div className="w-6 h-6 mr-4 bg-black rounded min-h-6 min-w-6" />
          <p className="text-xs">
            {`${helpers.toStringDecimal(
              payload[1].payload.autoconsumption,
              2,
            )} kW Vertida`}
          </p>
        </div>
      </div>
    );
  }

  return null;
};

const BarChartPrevYear: FunctionComponent<BarChartPrevYearProps> = ({
  data,
}: BarChartPrevYearProps) => (
  <div className="relative bg-white rounded-sm pb-144">
    <ResponsiveContainer width="100%" className="absolute inset-0">
      <BarChart
        data={data}
        margin={{
          top: 55,
          right: 20,
          left: 20,
          bottom: 20,
        }}
      >
        <XAxis
          xAxisId={0}
          dataKey="name"
          axisLine={false}
          tickLine={false}
          tickFormatter={helpers.parseLabelXMonth}
          tick={{ fill: '#A6A6A6' }}
          dy={10}
          interval={0}
        />
        <YAxis
          axisLine={false}
          tickLine={false}
          // eslint-disable-next-line
          tickFormatter={helpers.parseLabelY}
          tick={{ fill: '#A6A6A6' }}
          dx={10}
        >
          <Label
            position="insideTopRight"
            fill="#A6A6A6"
            offset={-37}
            fontSize={15}
            fontWeight="500"
            style={{ textAnchor: 'end' }}
          >
            kWh/Mes
          </Label>
        </YAxis>
        <Tooltip content={<BarChartTooltip />} cursor={false} />
        <Bar
          dataKey="energy"
          stackId="a"
          radius={[20, 20, 20, 20]}
          barSize={7}
          fill="#FFDA00"
        />
        <Bar
          dataKey="autoconsumption"
          radius={[20, 20, 20, 20]}
          barSize={7}
          fill="#000000"
        />
      </BarChart>
    </ResponsiveContainer>
  </div>
);
export default BarChartPrevYear;
