import React, { FunctionComponent } from 'react';
import classnames from 'classnames';
import _ from 'lodash/fp';

import { RequestStep } from '../../../../store/model/requests/types';
import { useStoreActions, useStoreState } from '../../../../store/hooks';

interface Props {
  label: string;
  currentStep: RequestStep;
}

const SideNavLink: FunctionComponent<Props> = (
  {
    label,
    currentStep,
  }: Props,
) => {
  const { requestStep } = useStoreState((state) => state.requests);
  const { setRequestStep } = useStoreActions((actions) => actions.requests);

  const isCurrentStep = _.isEqual(currentStep, requestStep);

  const navLinkClass = classnames('flex items-center', {
    bodycopy: !isCurrentStep,
    btn: isCurrentStep,
  });

  return (
    <div onClick={() => setRequestStep(currentStep)} className="mb-8 cursor-pointer">
      <p className={navLinkClass}>
        <span>{label}</span>
      </p>
    </div>
  );
};

export default SideNavLink;
