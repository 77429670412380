import React, { FunctionComponent } from 'react';

import CircledNumber from './CircledNumber';

interface Props {
  number: number;
  name: string;
  line?: boolean;
}

const InactiveState: FunctionComponent<Props> = ({ number, name, line = true }: Props) => (
  <div className="lg:flex-1">
    <div className="flex items-center lg:mb-8">
      <CircledNumber number={number} current={false} />
      <p className="text-gray-400 btn flex-1 ml-8 lg:hidden">{name}</p>
      {line && (
        <div className="flex-1 flex-col justify-center hidden lg:flex mx-8">
          <div className="bg-gray-400 w-full h-px" />
        </div>
      )}
    </div>
    {line && (
      <div className="w-22 flex justify-center my-4 lg:hidden">
        <div className="bg-gray-400 w-px h-16" />
      </div>
    )}
    <div className="lg:block hidden w-full max-w-72">
      <p className="text-gray-400 btn lg:block hidden">{name}</p>
    </div>
  </div>
);

export default InactiveState;
