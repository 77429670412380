import _ from 'lodash/fp';
import { ChangeEvent, useState } from 'react';

import useNavigation from '../../../services/navigation';
import { routes } from '../../../services/routing';
import { useStoreActions } from '../../../store/hooks';
import {
  ChangeRequestParams,
  RequestAttributes,
  RequestHistoryParams,
  StateDetailsAttributes,
} from '../../../store/model/requests/types';

interface History {
  docs: File[];
  observations: string;
  details: StateDetailsAttributes;
  description: string | null;
  largeHistory: boolean;
  accept: ChangeRequestParams;
  cancel: ChangeRequestParams;
  update: () => Promise<void>;
  handleDocumentsChange: (e: ChangeEvent<HTMLInputElement>) => void;
  handleObservations: (e: ChangeEvent<HTMLInputElement>) => void;
}

const useRequestHistory = (request: RequestAttributes, id: string): History => {
  const { getrequesthistory } = useStoreActions((actions) => actions.requests);
  const [documents, setDocuments] = useState<File[]>([]);
  const [observations, setObservation] = useState(
    request.stateDetails[0].description || '',
  );
  const navigate = useNavigation();

  const currentDetails = request.stateDetails[0];
  const isPrevDetails = _.gt(_.size(request.stateDetails), 1);
  const description = isPrevDetails
    ? request.stateDetails[1].description
    : null;
  const historyParams: RequestHistoryParams = {
    codeRequest: id,
    distributorId: request.distributorId,
  };
  const { stateCode, historialId, abbreviatedCheck } = currentDetails;

  const largeHistory = _.gt(_.size(request.stateDetails), 1);

  const defaultParams = {
    formCode: request.formCode,
    stateCode,
    historicalId: historialId,
    managerCancel: false,
    moreInfo: false,
    request: false,
    description: observations,
    distributorId: request.distributorId,
    abreviatedRequest: abbreviatedCheck,
  };

  const acceptParams: ChangeRequestParams = {
    ...defaultParams,
    accept: true,
    cancel: false,
  };

  const cancelParams: ChangeRequestParams = {
    ...defaultParams,
    accept: false,
    cancel: true,
  };

  const handleObservations = (e: ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    setObservation(value);
  };

  const update = async (): Promise<void> => {
    const response = await getrequesthistory(historyParams);
    if (typeof response === 'string') {
      navigate(`${routes.requests.index}?error=${response}`);
    }
  };

  const handleDocumentsChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { files } = e.target;
    if (_.isNil(files)) return;

    const filesArray = _.map((file: File) => file)(files);
    setDocuments([...filesArray]);
  };

  return {
    docs: documents,
    observations,
    details: currentDetails,
    description,
    largeHistory,
    accept: acceptParams,
    cancel: cancelParams,
    update,
    handleDocumentsChange,
    handleObservations,
  };
};

export default useRequestHistory;
